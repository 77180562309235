import { he } from "date-fns/locale";
import { TEXT_COLOR } from "../../constants/theme";
const { makeStyles } = require("@material-ui/core");

const useStyles = makeStyles({
  board: {
    display: "flex",
    margin: "0 auto",
    width: "90vw",
    fontFamily: 'nunito',
    backgroundColor: "white",
    overflowY: "auto"
  },
  column: {
    height: "80vh",
    margin: "0 1vw",
    backgroundColor: "white",
    flex: 1
  },
  columnHead: {
    textAlign: "center",
    padding: 10,
    fontSize: "1.2em",
    backgroundColor: TEXT_COLOR.ACTIVE,
    borderRadius: "20px",
    color: "white",
    height: "5vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  item: {
    padding: 10,
    margin: "10px 0",
    fontSize: "1em",
    cursor: "pointer",
    backgroundColor: TEXT_COLOR.BACKGROUND,
    height: "2.5vw",
    borderRadius: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  header: {
    fontFamily: 'nunito',
    textAlign: "center",
    padding: 10,
    fontSize: "1.5em",
    color: TEXT_COLOR.ORANGE,
    borderRadius: "20px",
    height: "3vw",
    display: "flex",
    fontWeight: 900
  },
  subHeader: {
    fontFamily: 'nunito',
    textAlign: "center",
    fontSize: "1em",
    color: "grey",
    borderRadius: "20px",
    display: "flex",
    color:"grey"
  },
  headerContent: {
    fontSize: "12px",
    fontFamily: "Nunito",
    color: TEXT_COLOR.DEFAULT,
    fontWeight: "600",
    display: "flex",
    textAlign: "center",
    paddingLeft: "8px",
    justifyContent: "flex-start",
    overflowWrap: "break-word",
    wordBreak: "break-word",
    maxHeight: '32px',
    // overflow:'scroll'
  },
  container: {
    flex: 1,
    display: "flex",
    //justifyContent: "center",
    flexDirection: "column",
    height: "80vh",
    width: "80vw",
    //alignItems: "center",
    overflow: 'auto',
  },
  contents:{
    borderRadius: "15px",
    backgroundColor: "#f7f7f7",
    height: "-webkit-fill-available",
    margin: "0 20px 20px 20px",
    display: "flex",
    flexDirection:"column"
  },
  calender:{
    fontFamily: "Nunito",
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    border: "1px solid grey",
    width: "fit-content",
    float: "right",
    margin: "10px",
    padding: "5px",
    borderRadius: "5px",
    fontSize: "0.85rem"
  },
  cardItem: {
    width: "25vw",
    flex: "20%",
    height: "7vh",
    color: "grey",
    textAlign: "center",
    padding: 10,
    fontSize: "1.2em",
    borderRadius: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "1vw",
  },
  cardsContainer: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    alignItems: "center"
  },
  cardText:{
    fontSize: "1.2rem",
    fontFamily: "nunito",
    lineHeight: "1.5",
    letterSpacing: "0.00938em",
    fontWeight: 900,
  },
});

export default useStyles;
