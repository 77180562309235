import { makeStyles } from "@material-ui/core";
import { TEXT_COLOR } from "../../constants/theme";

const useStyles = makeStyles(theme=>({
    root: {
        background: TEXT_COLOR.BACKGROUND,
        display: 'flex',
        flexDirection: 'column',
        minWidth: 'max-content',
        minHeight:'100vh'
    },
    container: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 30,
    
    },
    paperContainer: {
        height: '95%',
        width: '85%',
        display: 'flex',
        [theme.breakpoints.between("xs", "sm")]: {
            width: '95vw',
            height: "70vh",
            justifyContent: "center",
            alignSelf: "flex-start"
        },
    },
    menuContainer: {
        transitionDuration: '0.5s',
        overflow: 'hidden',
        minHeight: '100%',
        justifySelf: 'left',
        fontSize: 13
    },
    closed: {
        width: '0px'
    },
    open: {
        minWidth: 250
    },
    homeContainer: {
        flex: 1,
        display: 'flex'
    },
    elementContainer: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // maxHeight: '90vh',
        [theme.breakpoints.between("xs", "sm")]: {
            height: "85vh"
        },
    },
    mobileScreenGrid:{
        alignSelf: "center",
        height: "5vh",
        backgroundColor: "rgb(0 0 0 / 77%)",
        color: "turquoise",
        fontWeight: "bold",
        fontSize: "15px",
        width: "100vw",
        [theme.breakpoints.up('sm')]: {
            display: "none",
            paddingTop: "32px"
        },
    },
    element: {
        flex: 1,
        display: 'flex',
        paddingTop: 30,
        width: '90%',
        justifyContent: "center",
        [theme.breakpoints.between("xs", "sm")]: {
            width: '90vw',
            height: "60vh",
            justifyContent: "center"
        },
        
    },
    footer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.up("sm")]: {
            height: '15%',
        },
    },
    menuHeader: {
        height: '5%',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 35,
        fontSize: 15,
        fontFamily: 'Nunito'
    },
    optContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    mainMenu: {
        display: 'flex',
        paddingLeft: 20
    },
    menuText: {
        fontSize: 13,
        cursor: 'pointer',
        fontFamily: 'Nunito'
    },
    subMenuPadding: {
        paddingLeft: 75,
    },
    brand:{
        display:"flex"
    },
    paginationContainer:{
        display:"flex",
    },
    paginationElement:{
        border: "1px solid #30d9c4",
        background: "white",
        borderRadius: 50,
        padding: "5px 10px",
        width: "15vw",
        height: "4.5vh",
        fontFamily: "Nunito",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        cursor: "pointer",
        margin: "5px",
        fontSize: "18px",
        '&:hover': {
            backgroundColor: `${TEXT_COLOR.ACTIVE}99`,
            color: "white"
        },
        [theme.breakpoints.between("xs", "sm")]: {
            width: '20vw',
        },
    }
}));

export default useStyles;