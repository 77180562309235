import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { routes } from "../../constants";

export function PrivateRoute({ component: Component, ...rest }) {
  const email = useSelector(({ authState }) => authState.user?.email);
  return (
    <Route
      {...rest}
      render={(props) =>
        email ? (
          <Component {...props} {...rest} />
        ) : (
            <Redirect
              to={{
                pathname: routes.SIGN_IN,
                state: { from: props.location },
              }}
            />
          )
      }
    />
  );
}
