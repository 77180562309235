import { call } from "redux-saga/effects";
import {
  addCategoryService,
  updateCategoryService,
  deleteCategoryService,
  updateDisplayIndex
} from "../service/api/category";

export function* addCategorySaga({ payload }) {
  const { setCategory, uid } = payload;
  if (uid) {
    const data = yield call(addCategoryService, payload);
    setCategory({
      id: data,
      categoryName: payload.categoryName,
    });
  }
}

export function* updateCategorySaga({ payload }) {
  if (payload.uid) {
    yield call(updateCategoryService, payload);
  }
}

export function* deleteCategorySaga({ payload }) {
  if (payload.uid) {
    yield call(deleteCategoryService, payload);
    yield call(updateDisplayIndex, payload);
  }
}
