import React, { useEffect, useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { get } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import _isEmpty from "lodash/isEmpty";
import { useSelector, useDispatch } from "react-redux";
import { resetMessage } from "../../actions/notificationActions";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  }
}));

export default function Notification() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [prevSeverity, setPreviousSeverity] = useState("error");
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    dispatch(resetMessage());
  };

  const notification = useSelector(
    ({ notificationState }) => notificationState
  );
  useEffect(() => {
    if (!_isEmpty(notification)) {
      setOpen(true);
      setPreviousSeverity(get(notification, "type", "error"));
    } else setOpen(false);
  }, [notification]);
  return (
    <div className={classes.root}>
      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center"
        }}>
        <Alert
        bodystyle={{backgroundColor: prevSeverity === "error"? "#ff6347":"" }}
        style={{ backgroundColor: prevSeverity === "error"? "#ff6347":""  }}
          onClose={handleClose}
          severity={get(notification, "type", prevSeverity)}
        >
          {get(notification, "message", " ")}
        </Alert>
      </Snackbar>
    </div>
  );
}
