import { mdiFire } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useEffect, useState } from 'react';
import { DATABASE } from '../../config/fbConfig';
import { docNames } from '../../constants/firebaseDoc';
import { useSelector } from "react-redux";
import useStyles from './styles';
import { TEXT_COLOR } from '../../constants/theme';

function Stop({ label, subLabel, left, background }) {
    const classes = useStyles();
    return (
        <div className={classes.stopRoot} style={{ left: left }}>
            <div className={classes.stopLabel}>{label}</div>
            <div className={classes.stop} style={{ background: background || '#FF501E' }}></div>
            {subLabel && <div className={classes.subLabel}>{subLabel}</div>}
        </div>
    )
}

function OptimizeAccount() {
    const [optimizedForLanguage, setOptimizedForLanguage] = useState(0);
    const [deliveryFee, setDeliveryFee] = useState(0);
    const [percentageOptimized, setPercentageOptimized] = useState(0);
    const { uid } = useSelector(({ authState }) => authState.user);
    const { defaultBranchId } = useSelector(
        ({ defaultBranchState }) => defaultBranchState.defaultBranch
    );
    const classes = useStyles();

    const docRef = DATABASE
    .collection(docNames.SELLER)
    .doc(uid)
    .collection(docNames.BRANCHES)
    .doc(defaultBranchId)

    useEffect(() => {
        const unsub = docRef

            .onSnapshot(snap => {

                let branchTotalProductsCount = 0;
                let productsWithOneLanguage = 0;
                let productsWithTwoLanguages = 0;

                if (snap.data() && snap.data().productsConfig) {
                    branchTotalProductsCount = snap.data().productsConfig.branchTotalProductsCount;
                    productsWithOneLanguage = snap.data().productsConfig.productsWithOneLanguage;
                    productsWithTwoLanguages = snap.data().productsConfig.productsWithTwoLanguages
                }

                
                if (branchTotalProductsCount === 0) {
                    setOptimizedForLanguage(0);
                } else {
                    if (productsWithTwoLanguages === 0) {
                        setOptimizedForLanguage(25);
                    } else if (productsWithTwoLanguages >= 1 && productsWithTwoLanguages <= 0.5 * branchTotalProductsCount) {
                        setOptimizedForLanguage(25);
                    } else if (productsWithTwoLanguages > 0.5 * branchTotalProductsCount && productsWithTwoLanguages < branchTotalProductsCount) {
                        setOptimizedForLanguage(37.5);
                    } else {
                        setOptimizedForLanguage(50);
                    }
                }
            });

        return () => unsub();
    }, [uid, defaultBranchId]);

    useEffect(() => {
        const unsub = DATABASE
            .collection(docNames.SELLER)
            .doc(uid)
            // .collection(docNames.CONFIG)
            // .doc(uid)
            .onSnapshot(snap => {
                if (snap.data() && snap.data().brandConfig) {
                    setDeliveryFee(snap.data().brandConfig['deliveryFeeSharing']);
                }
            });
        return () => unsub();
    }, [uid, defaultBranchId]);

    useEffect(() => {
        let percentageOptimized = optimizedForLanguage;
        if (optimizedForLanguage === 50) {
            if (deliveryFee === 0.25) {
                percentageOptimized = 60;
            } else if (deliveryFee === 0.5) {
                percentageOptimized = 73.3;
            } else if (deliveryFee === 0.75) {
                percentageOptimized = 86.7;
            } else if (deliveryFee === 1) {
                percentageOptimized = 100;
            }
        }
        setPercentageOptimized(percentageOptimized);
    }, [optimizedForLanguage, deliveryFee])

    return (
        <div className={classes.root}>
            <div style={{ position: 'relative', height: '100%' }}>
                <Stop label={'One language'} left={0} background={'#FFE2D9'} />
                <Stop label={'Two languages'} left={'22.5%'} background={'#FF8663'} />
                <Stop label={''} left={'33.75%'} background={'#FF501E'} />
                <Stop label={''} left={'45%'} background={'#FF501E'} />
                <Stop label={'Share Delivery Fee'} left={'54%'} subLabel={'0.250 fils'} />
                <Stop left={'66%'} subLabel={'0.500 fils'} />
                <Stop left={'78%'} subLabel={'0.750 fils'} />
                <Stop left={'90%'} subLabel={'1.000 KWD'} />
                <div
                    className={classes.bar}
                    style={{
                        background: `transparent 
                            linear-gradient(90deg, #FFE2D9 0%, #FF3900 ${percentageOptimized}%, ${TEXT_COLOR.ORANGE}57 ${percentageOptimized}%, ${TEXT_COLOR.ORANGE}57 100%)
                            0% 0% no-repeat padding-box`
                    }}
                ></div>
                <div className={classes.icon}>
                    <Icon
                        path={mdiFire}
                        size='45px'
                        color='#FF8E6E'
                    />
                </div>
                <div className={classes.subText}>
                    Appear on top search results in Mashkor App
                </div>
            </div>
        </div>
    )
}

export default OptimizeAccount;