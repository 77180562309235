import { makeStyles } from "@material-ui/core";
import { TEXT_COLOR } from "../../constants/theme";
export const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent:"center",
    height:"100vh"
  },
  logoStyle:{
    padding: "10% 10% 10% 10%", 
    alignSelf:"center", 
    verticalAlign:"middle" 
  },
  paper:{
    width:"300px",
    borderRadius:"8px" 
  },
  backgroundColor:{
    backgroundColor:TEXT_COLOR.ACTIVE,
    borderRadius:"8px 8px 0px 0px",
  },
  marginBottom:{
    marginBottom:"16px"
  },
  button:{
    width: "268px",
    height: "2.5rem",
    backgroundColor: TEXT_COLOR.ACTIVE,
    borderRadius: "4px",
    border: `0px solid ${TEXT_COLOR.ACTIVE}`,
    color: TEXT_COLOR.WHITE,
    fontSize: "12px",
    cursor: "pointer",
  }
});