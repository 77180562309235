import { Divider, Grid, Paper, Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Flow, FlowStep, FlowStepWindow } from "../../components/Flow";
import { Table } from "../AddProducts";
import SelectDeliveryFee from "../SelectDeliveryFee";
import Footer from "./Footer";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "./styles";
import { DATABASE } from "../../config/fbConfig";
import { setNotificationMessage } from "../../actions/notificationActions";
import {
  addProductAction,
  addProductImage,
  setBrandSettingsActions,
} from "../../actions/addProducts";
import { BrandSettings } from "../BrandSettings";
import Icon from "@mdi/react";
import {
  mdiPackageVariantClosed,
  mdiTruckDeliveryOutline,
  mdiSparkles,
  mdiCheck,
} from "@mdi/js";
import { docNames } from "../../constants/firebaseDoc.ts";
import { TEXT_COLOR } from "../../constants/theme";
import { StyledButton } from "../../components/StyledButton";
import OptimizeAccount from "../OptimizeAccount";
import { MashkorLargeIcon } from "../../assets/icons";
import { TrainRounded } from "@material-ui/icons";
function OnBoardingFlow() {
  const generateProductId = () => {
    const date = new Date();
    const id = `${Date.now()}_${date.getDate()}_${date.getMonth()+1}_${date.getFullYear()}`;
  
    return id;
  }
  const dispatch = useDispatch();
  const [add, setAdd] = useState([
    {
      name: "",
      description: "",
      price: "",
      imageUrl: "",
      category: "",
      id: "",
    },
  ]);
  const listByName = useSelector(
    ({ productState }) => productState.productList
  );
  const [list, setList] = useState([]);
  useEffect(() => {
    const newList = [...listByName];
    if (newList.length > 1) {
      newList.sort(function (a, b) {
      if(a.createdAt===null){
        return 1;
      }else if(b.createdAt===null){
        return -1;
      }else return a.createdAt - b.createdAt;
      });
    }
    setList(newList);
  }, [listByName]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [borderImageColor, setBorderImageColor] = useState(
    TEXT_COLOR.BACKGROUND
  );
  const [borderCategoryColor, setBorderCategoryColor] = useState(
    TEXT_COLOR.BACKGROUND
  );
  const [imageUrl, setImage] = useState();
  const [category, setCategory] = useState({
    categoryName: "Select Category",
    id: "",
  });
  const [nameError, setNameError] = useState(false);
  const [nameArError, setNameArError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [priceError, setPriceError] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [backButtonDisabled, setBackButtonDisabled] = useState(false);
  const [barcode, setBarcode]= useState("");
  const [imageAnalytics, setImageAnalytics] = useState({});
  const [barcodeError, setBarcodeError]= useState(false);
  const classes = useStyles();
  const [progress, setProgress] = useState(
    Array.from({ length: 3 }, () => false)
  );
  const [hasStarted, setStarted] = useState(false);
  const [finished, setFinished] = useState(false);
  const [step, setStep] = useState(0);
  const uid = useSelector(({ authState }) => authState.user?.uid);
  const { defaultBranchId } = useSelector(
    ({ defaultBranchState }) => defaultBranchState.defaultBranch
  );
  const brandConfig = useSelector(({brandSettingState})=>brandSettingState?.brandConfig);
  const deliveryFeeinDb=useSelector(({deliveryFeeState})=>deliveryFeeState?.deliveryFee);
  const [deliveryFee, setDeliveryFee] = useState(null);
  const [businessType, setBusinessType] = useState("");
  const [logo, setLogo] = useState(null);
  const [brandName, setBrandName] = useState(null);
  const [brandNameAr, setBrandNameAr] = useState(null);
  const [productImageUrl, setImageUrl] = useState(null);
  const [addedImageUrl, setAddedImageUrl] = useState("");
  const [isImageUploading, setUpload] = useState(false);
  const [arabicDetails, setArabicDetails] = useState({
    nameAr: "",
    descriptionAr: "",
  });
  const [productId, setProductId]= useState(generateProductId());

  useEffect(() => {
    if (Object.keys(brandConfig).length !== 0) {
      setBrandName(brandConfig["brandName"] || brandName);
      setBrandNameAr(brandConfig["brandNameAr"] || brandNameAr);
      setBusinessType(brandConfig["businessType"] || businessType);
      setLogo(brandConfig["brandLogo"] || logo);
      setImageUrl(brandConfig["brandLogo"] || addedImageUrl);
      setDeliveryFee(brandConfig["deliveryFeeSharing"] || deliveryFee);
    }
  }, [brandConfig]);
  useEffect(() => {
    if (deliveryFee !== null && deliveryFee !== undefined) {
      const progressbar = progress;
      progressbar[1] = true;
      setProgress(progressbar);
    }
    if (
      logo !== null &&
      logo !== undefined &&
      brandName !== null &&
      brandName !== undefined &&
      brandNameAr !== null &&
      brandNameAr !== undefined &&
      businessType !== "" &&
      businessType !== undefined
    ) {
      const progressbar = progress;
      progressbar[2] = true;
      setProgress(progressbar);
    }
  }, [deliveryFee, logo, brandName, brandNameAr, businessType, progress]);

  const handleProgress = (val) => {
    setProgress(val);
    setStarted(true);
  };

  useEffect(() => {
    if (step === 0) {
      setBackButtonDisabled(true);
      if (list.length !== 0 && add.length === 0) {
        let currentProgress = progress;
        currentProgress[0] = true;
        setProgress(currentProgress);
        setDisabled(false);
      } else if (add.length === 0) {
        let currentProgress = progress;
        currentProgress[0] = false;
        setProgress(currentProgress);
        setDisabled(true);
      } else if (list.length !== 0) {
        setDisabled(false);
      } else {
        setDisabled(false);
      }
    } else {
      setBackButtonDisabled(false);
    }

    if (step === 1) {
      if (deliveryFee !== null) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }
    if (step === 2 && (!brandName ) && (!brandNameAr)) {
      setDisabled(true);
    }
    if (step === 2 && ((brandName && brandName.length >= 26) || (brandNameAr && brandNameAr.length >= 26))) {
      setDisabled(true);
      dispatch(
        setNotificationMessage({
          message: "Brand name cannt be greater than 25 characters.",
        })
      );
    }
    if (step === 2 && (brandName) && (!brandNameAr)) {
      setDisabled(false);
    }
  }, [list, add, step, deliveryFee, progress, brandName, brandNameAr]);

  const move = (obj) => {
    switch (obj.type) {
      case "Back":
        if (step !== 0) {
          setStep(step + obj.increament);
        } else {
          setBackButtonDisabled(true);
        }
        break;
      case "Skip":
        setStep(step + obj.increament);
        break;
      case "Proceed":
        let currentProgress = progress;
        if (step === 0) {
          if (add.length !== 0) {
            handleValidateForms(obj.increament);
          } else if (list.length !== 0) {
            currentProgress[step] = true;
            setStep(step + obj.increament);
          } else {
            setDisabled(true);
          }
        }
        if (step === 1) {
          if (deliveryFee !== null) {
            currentProgress[1] = true;
            setStep(step + obj.increament);
            setDisabled(false);
          } else {
            currentProgress[1] = false;
          }
        } else if (step === 2) {
          const schema = {
            brandName: brandName,
            brandNameAr: brandNameAr,
            businessType: businessType === undefined ? "": businessType,
          };
          if (productImageUrl === logo) {
            dispatch(
              setBrandSettingsActions({
                schema,
                uid,
                defaultBranchId,
                config: Object.keys(brandConfig).length === 0 ? "set" : "update",
              })
            );
          } else {
            dispatch(
              setBrandSettingsActions({
                schema,
                uid,
                defaultBranchId,
                imageUrl: logo,
                config: Object.keys(brandConfig).length === 0 ? "set" : "update",
              })
            );
          }
          setStep(step + obj.increament);
        }
        setProgress(currentProgress);
        break;
      default:
        return;
    }
  };
  
  const handleAddProduct = () => {
    const newList = [];
    newList.push({
      name: "",
      description: "",
      price: "",
      imageUrl: "",
      category: "",
      id: "",
    });
    setImage();
    setCategory({ categoryName: "Select Category", id: "" });
    setArabicDetails({ nameAr: "", descriptionAr: "" });
    setName("");
    setDescription("");
    setPrice("");
    setBarcode("");
    setAdd(newList);
    setAddedImageUrl("");
    setProductId(generateProductId())
  };
  //for validation
  const handleValidateForms = async (increment) => {
    if (imageUrl === null || imageUrl === undefined || imageUrl === "") {
      setBorderImageColor("red");
      dispatch(
        setNotificationMessage({ message: "Fill all the required fields." })
      );
    }
    if (
      category.categoryName === null ||
      category.categoryName === undefined ||
      category.categoryName === "" ||
      category.categoryName === "Select Category"
    ) {
      setBorderCategoryColor("red");
      dispatch(
        setNotificationMessage({ message: "Fill all the required fields." })
      );
    }
    if (category.categoryName.length > 60) {
      setBorderCategoryColor("red");
      dispatch(
        setNotificationMessage({
          message: "Category cannot be greater than 60 chars.",
        })
      );
    }
    if (name.trim().length === 0 && arabicDetails.nameAr.trim().length === 0) {
      setNameError(true);
      setNameArError(true);
      dispatch(
        setNotificationMessage({
          message: "Fill all the required fields.",
        })
      );
    }
    if((name.trim().length !== 0 && arabicDetails.nameAr.trim().length === 0) || (name.trim().length === 0 && arabicDetails.nameAr.trim().length !== 0)){
        setNameError(false);
        setNameArError(false);
      }
    if (name.trim().length > 25 || arabicDetails.nameAr.trim().length > 25 ) {
      setNameError(true);
      setNameArError(true);
      dispatch(
        setNotificationMessage({
          message: "Name cannot be greater than 25 chars.",
        })
      );
    }
    if (description.length > 120) {
      setDescriptionError(true);
      dispatch(
        setNotificationMessage({
          message: "Description cannot be greater than 120 chars.",
        })
      );
    }
    if (barcode.length > 64) {
      setBarcodeError(true);
      dispatch(
        setNotificationMessage({
          message: "Barcode cannot be greater than 64 chars.",
        })
      );
    }
    if (price === null || price === "") {
      setPriceError(true);
      dispatch(
        setNotificationMessage({ message: "Fill all the required fields." })
      );
    }else if (price <= 0) {
      setPriceError(true);
      dispatch(
        setNotificationMessage({
          message: "Price cannot be less than or equal to 0",
        })
      );
    }
    if (
      price !== null &&
      price !== "" &&
      description.length < 120 &&
      ((name.length !== 0 && name.length < 60) || (arabicDetails.nameAr.length !== 0 && arabicDetails.nameAr.length < 60)) &&
      imageUrl !== null &&
      imageUrl !== undefined &&
      imageUrl !== "" &&
      barcode.length<64 &&
      category.categoryName !== null &&
      category.categoryName !== undefined &&
      category.categoryName !== "" &&
      category.categoryName !== "Select Category"
    ) {
      const schema = {
        name: name,
        description: description,
        price: price,
        category: category,
        nameAr: arabicDetails["nameAr"],
        descriptionAr: arabicDetails["descriptionAr"],
        availability: true, //availability TBD
        barcode: barcode,
      };
      const key = list.length;
      dispatch(
        addProductImage({ imageUrl, setImageUrl: setAddedImageUrl, setImageAnalytics, uid,productId })
      );
      await dispatch(addProductAction({ schema, uid, defaultBranchId, key, productId: productId, imageUrl, productImageUrl, setImageUrl: setAddedImageUrl}));
      if (increment !== null) {
        const newList = [];
        setImage();
        setAddedImageUrl("");
        setCategory({ categoryName: "Select Category", id: "" });
        setName("");
        setDescription("");
        setPrice("");
        setArabicDetails({ nameAr: "", descriptionAr: "" });
        setBarcode("");
        setAdd(newList);
        let currentProgress = progress;
        currentProgress[step] = true;
        setProgress(currentProgress);
        setStep(step + increment);
      } else {
        handleAddProduct();
      }
    }
  };

  const changeDeliveryFee = (val) => {
    const docRef = DATABASE.collection(docNames.SELLER)
      .doc(uid)

      docRef.update({
        "brandConfig.deliveryFeeSharing": val,
      });
    // if (Object.keys(brandConfig).length === 0 && deliveryFeeinDb===null) {
    //   docRef.set({
    //     "brandConfig.deliveryFeeSharing": val,
    //   });
    // } else {
    //   docRef.update({
    //     "brandConfig.deliveryFeeSharing": val,
    //   });
    // }
  };

  useEffect(() => {
    if (step === 3) {
      setFinished(true);
    }
  }, [step]);
  useEffect(() => {
    if (finished === true) {
      setTimeout(function () {
        DATABASE.collection(docNames.SELLER)
          .doc(uid)
          .update({ firstLogin: false })
      }, 1000);
    }
  }, [finished, uid]);
  useEffect(() => {
    if (addedImageUrl || imageUrl) {
      setUpload(false);
    }
  }, [addedImageUrl, imageUrl]);

  return (
    <div
      className={`${classes.root} ${classes.item} ${
        hasStarted ? classes.flowSeleted : classes.flowUnselected
      }`}
    >
      <Grid
        container
        alignItems="center"
        spacing={6}
        direction="column"
        className={classes.gridContainer}
      >
        <Grid
          item
          className={`${classes.item} ${
            hasStarted ? classes.hidden : classes.visible
          }`}
        >
          <img src={MashkorLargeIcon} className={classes.mashkorIcon} alt="" />
        </Grid>
        <Grid
          item
          className={`${classes.item} ${
            hasStarted ? classes.hidden : classes.visible
          }`}
        >
          <Typography
            variant="h3"
            component="h5"
            className={`${classes.typography}`}
          >
            Welcome to Mashkor
          </Typography>
        </Grid>
        <Grid
          item
          className={`${classes.item} ${
            hasStarted ? classes.hidden : classes.visible
          }`}
        >
          <Typography
            variant="body1"
            component="p"
            className={`${classes.typography}`}
          >
            We'll go through 3 simple steps
            <br />
            to help you set up your registry
          </Typography>
        </Grid>
        <Grid
          item
          className={`${classes.flowContainer} 
          ${finished ? classes.item : ""}`}
        >
          <Flow setProgress={handleProgress} progress={progress}>
            <FlowStep name={"Add Product"}>
              <Icon
                path={
                  step === 0
                    ? mdiPackageVariantClosed
                    : progress[0]
                    ? mdiCheck
                    : mdiPackageVariantClosed
                }
                size="18px"
                color={progress[0] ? TEXT_COLOR.WHITE : TEXT_COLOR.ACTIVE}
              />
            </FlowStep>
            <FlowStep name="Select Delivery Fees">
              <Icon
                path={
                  step === 1
                    ? mdiTruckDeliveryOutline
                    : progress[1]
                    ? mdiCheck
                    : mdiTruckDeliveryOutline
                }
                size="18px"
                color={progress[1] ? TEXT_COLOR.WHITE : TEXT_COLOR.ACTIVE}
              />
            </FlowStep>
            <FlowStep name="Brand Settings">
              <Icon
                path={
                  step === 1
                    ? mdiSparkles
                    : progress[2]
                    ? mdiCheck
                    : mdiSparkles
                }
                size="18px"
                color={progress[2] ? TEXT_COLOR.WHITE : TEXT_COLOR.ACTIVE}
              />
            </FlowStep>
          </Flow>
        </Grid>
        <Grid
          className={`${classes.startButton}   ${
            hasStarted ? classes.hidden : classes.visible
          }`}
        >
          <Paper className={`${classes.fullWIdth} `}>
            <StyledButton
              className={` ${classes.fullWIdth}`}
              onClick={() => {
                setStep(0);
                setStarted(true);
              }}
            >
              Start
            </StyledButton>
          </Paper>
        </Grid>
        <Grid
          item
          className={`${classes.fullWIdth} ${classes.mainContainer} ${
            classes.item
          } ${hasStarted ? classes.visible : classes.hidden}`}
        >
          <Grid item>
            <div className={classes.alignCenter}>
              <OptimizeAccount />
            </div>
          </Grid>
          <FlowStepWindow position={step}>
            <Table
              category={category}
              imageUrl={imageUrl}
              name={name}
              description={description}
              price={price}
              borderImageColor={borderImageColor}
              setBorderImageColor={setBorderImageColor}
              borderCategoryColor={borderCategoryColor}
              setBorderCategoryColor={setBorderCategoryColor}
              setCategory={setCategory}
              setImage={(imageUrl) => {
                if (imageUrl) {
                  setUpload(true);
                  dispatch(
                    addProductImage({ imageUrl, setImageUrl: setAddedImageUrl, setImageAnalytics, uid,productId })
                  );
                }
                setImage(imageUrl);
              }}
              barcode={barcode}
              setBarcode={setBarcode}
              barcodeError={barcodeError}
              setBarcodeError={setBarcodeError}
              setPrice={setPrice}
              setName={setName}
              setDescription={setDescription}
              nameError={nameError}
              setNameError={setNameError}
              nameArError={nameArError}
              setNameArError={setNameArError}
              descriptionError={descriptionError}
              setDescriptionError={setDescriptionError}
              priceError={priceError}
              setPriceError={setPriceError}
              list={list}
              handleValidateForms={handleValidateForms}
              add={add}
              setAdd={setAdd}
              handleAddProduct={handleAddProduct}
              arabicDetails={arabicDetails}
              setArabicDetails={setArabicDetails}
              isImageUploading={isImageUploading}
            />
            <SelectDeliveryFee
              selectFee={changeDeliveryFee}
              deliveryFee={deliveryFee}
              setDeliveryFee={setDeliveryFee}
            />
            <BrandSettings
              brandName={brandName}
              setBrandName={setBrandName}
              brandNameAr={brandNameAr}
              setBrandNameAr={setBrandNameAr}
              setBusinessType={setBusinessType}
              setLogo={setLogo}
              logo={logo}
              businessType={businessType}
            />
          </FlowStepWindow>
        </Grid>
        <Grid
          item
          className={`${classes.fullWIdth} ${classes.item} ${
            classes.floatBottom
          } ${hasStarted && !finished ? classes.visible : classes.hidden} `}
        >
          <Divider orientation="horizontal" className={classes.divider} />
          <Footer
            move={move}
            step={step}
            disableProceedToNext={disabled || isImageUploading}
            backButtonDisabled={backButtonDisabled}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default OnBoardingFlow;
