import {
  ButtonBase,
  Checkbox,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import { AddProduct } from "../NewProduct/index";
import { mdiArrowLeft, mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useEffect, useState } from "react";
import { useStyles } from "./styles";
import {
  AddProductButton,
  DeleteDialog,
} from "../../components/AddProductButton";
import { setNotificationMessage } from "../../actions/notificationActions";
import {
  addProductAction,
  addProductImage,
  deleteProductAction,
  updateProductAction,
} from "../../actions/addProducts";
import { useDispatch, useSelector } from "react-redux";
import { TableHeader } from "../TableHeader";
import { TableBody } from "../TableBody";
import { DATABASE } from "../../config/fbConfig";
import { docNames } from "../../constants/firebaseDoc";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import { DividerComponent } from "../../components/Divider";
import { CustomSwitch } from "../../components/CustomSwitch";
import ShadowlessButton from "../../components/ShadowlessButton";
import OptimizeAccount from "../OptimizeAccount";
import { TEXT_COLOR } from "../../constants/theme";

const generateProductId = () => {
  const date = new Date();
  const id = `${Date.now()}_${date.getDate()}_${date.getMonth()+1}_${date.getFullYear()}`;

  return id;
}

export function AddProductsHome({ handleComponentValue }) {
  const { uid } = useSelector(({ authState }) => authState.user);
  const { defaultBranchId } = useSelector(
    ({ defaultBranchState }) => defaultBranchState.defaultBranch
  );
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [borderImageColor, setBorderImageColor] = useState(TEXT_COLOR.BACKGROUND);
  const [borderCategoryColor, setBorderCategoryColor] = useState(TEXT_COLOR.BACKGROUND);
  const [borderColor, setBorderColor] = useState(TEXT_COLOR.BACKGROUND);
  const [imageUrl, setImage] = useState("");
  const [category, setCategory] = useState({
    categoryName: "Select Category",
    id: "",
  });
  const [nameError, setNameError] = useState(false);
  const [nameArError, setNameArError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [priceError, setPriceError] = useState(false);
  const [checkboxList, setCheckboxList] = useState({});
  const [checkbox, setCheckbox] = useState(false);
  const classes = useStyles();
  const [addTextField, setAddTextfield] = useState(true);
  const [orderLength, setOrderLength] = useState(null);
  const [deletedId, setDeletedId] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteDisabled, setDeleteDisabled] = useState(false);
  const [deleteAllDialog, setDeleteAllDialog] = useState(false);
  const [switchList, setSwitchList] = useState({});
  const [productImageUrl, setImageUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [disableSave, setDisableSave] = useState(true);
  const [arabicDetails, setArabicDetails] = useState({
    nameAr: "",
    descriptionAr: "",
  });
  const [productId, setProductId] = useState('');
  const [barcode, setBarcode]= useState("");
  const [imageAnalytics, setImageAnalytics] = useState({});
  const [barcodeError, setBarcodeError]= useState(false);
  const [addedProducts, setAddedProducts] = useState([]);
  useEffect(() => {
    let productId = generateProductId();
    setProductId(productId);
  }, []);


  useEffect(() => {
    if (productImageUrl || imageUrl) {
      setLoading(false);
    }
  }, [productImageUrl, imageUrl]);

  const handleCheckboxChange = (id) => {
    setCheckboxList((value) => ({ ...value, [id]: !checkboxList[id] }));
    setSwitchList((value) => ({ ...value, [id]: !switchList[id] }));
  };
  const handleDialogClose = () => {
    setDeleteAllDialog(false);
    setAddTextfield(true);
  };
  const handleDelete = () => {
    const value = Object.values(checkboxList);
    if (value.length !== 0 && value.every((v) => v === true)) {
      //all products are to be deleted
      setDeleteAllDialog(true);
    } else {
      //some products are to be deleted
      deleteProducts();
    }
  };
  const deleteProducts = () => {
    const newCheckboxList = { ...checkboxList };
    const deletedIdList = [...deletedId];
    addedProducts.forEach((item) => {
      const id = item.id;
      if (checkboxList[id] === true) {
        deletedIdList.push(id);
        dispatch(deleteProductAction({ id: id, product: item,  productImageUrl: item.imageUrl, defaultBranchId, uid }));
        delete newCheckboxList[id];
      }
      setCheckboxList(newCheckboxList);
      setDeletedId(deletedIdList);
    });
  };
  useEffect(()=>{
    if(addedProducts.length===0){
      setCheckbox(false);
    }
  },[addedProducts, checkboxList]);
  useEffect(() => {
    const value = Object.values(checkboxList);
    const isTrue = value.some((item) => item === true);
    setShowDelete(isTrue);
  }, [checkboxList]);

  useEffect(() => {
    if (addedProducts.length === 0) {
      setDeleteDisabled(true);
      setAddTextfield(true);
    } else {
      setDeleteDisabled(false);
    }
  }, [addedProducts]);

  useEffect(() => {
    let list= {}
    if (checkbox === true) {
      addedProducts.forEach((item) => {
        const id = item.id;
        list[id]=true;
      });
    } else {
      addedProducts.forEach((item) => {
        const id = item.id;
        list[id]=false
      });
    }
    setCheckboxList(list)
  }, [checkbox, addedProducts]);
  const deleteAllValues = () => {
    setCategory({
      categoryName: "Select Category",
      id: "",
    });
    setArabicDetails({
      nameAr: "",
      descriptionAr: "",
    });
    setImage("");
    setName("");
    setDescription("");
    setPrice("");
    setBarcode("");
    setBorderImageColor(TEXT_COLOR.BACKGROUND);
    setBorderCategoryColor(TEXT_COLOR.BACKGROUND);
    setNameError(false);
    setNameArError(false);
    setDescriptionError(false);
    setPriceError(false);
    setBarcodeError(false);
    setBorderColor(TEXT_COLOR.BACKGROUND);
  };
  useEffect(()=>{
    if (
      imageUrl === null ||
      imageUrl === undefined ||
      imageUrl === "" ||
      category.categoryName === null ||
      category.categoryName === undefined ||
      category.categoryName === "" ||
      category.categoryName === "Select Category" ||
      ((name && name.toString().trim().length === 0 ||
      name && name.toString().trim().length > 60) &&
      (arabicDetails.nameAr && arabicDetails.nameAr.toString().trim().length === 0 ||
      arabicDetails.nameAr && arabicDetails.nameAr.toString().trim().length > 60)) ||
      price === null ||
      price === ""
    ) {
      if (imageUrl === null || imageUrl === undefined || imageUrl === "") {
        setDisableSave(true)
      }
      if (
        category.categoryName === null ||
        category.categoryName === undefined ||
        category.categoryName === "" ||
        category.categoryName === "Select Category"
      ) {
        setDisableSave(true)
      }
      if ( name.length === 0 && arabicDetails.nameAr.length === 0) {
        setDisableSave(true)
      }
      if ( name.length > 60 || arabicDetails.nameAr.length>60) {
        setDisableSave(true)
      }
      if (price === null || price === "") {
        setDisableSave(true)
      }
    } else {
      if (category.categoryName.length > 60) {
        setDisableSave(true)
      }
      if (name.length > 60 || arabicDetails.nameAr.length>60) {
        setDisableSave(true)
      }
      if (description.length > 120) {
        setDisableSave(true)
      }
      if (barcode.length > 64) {
        setBarcodeError(true);
        dispatch(
          setNotificationMessage({
            message: "Barcode cannot be greater than 64 chars.",
          })
        );
      }
      if (price <= 0) {
        setDisableSave(true)
      }
      if (
        price !== null &&
        price !== "" &&
        price > 0 &&
        description.length <= 120 &&
        ((name && name.toString().trim().length!== 0  && name.toString().trim().length <= 60 )||(arabicDetails.nameAr && arabicDetails.nameAr.toString().trim().length !== 0 &&arabicDetails.nameAr.toString().trim().length <= 60 ))&&
        imageUrl !== null &&
        imageUrl !== undefined &&
        imageUrl !== "" &&
        category.categoryName !== null &&
        category.categoryName !== undefined &&
        category.categoryName !== "" &&
        category.categoryName !== "Select Category"
      ) {
        setDisableSave(false)
      }
    }
  }, [imageUrl, productId, dispatch,price, name, description, category, arabicDetails.nameAr])

  const handleValidateForms = async (increment) => {
    let recentAddedProducts = []
    if (
      imageUrl === null ||
      imageUrl === undefined ||
      imageUrl === "" ||
      category.categoryName === null ||
      category.categoryName === undefined ||
      category.categoryName === "" ||
      category.categoryName === "Select Category" ||
      ((name.trim().length === 0 ||
        name.length > 60) &&
        (arabicDetails.nameAr.trim().length === 0 ||
        arabicDetails.nameAr.length > 60)) ||
      price === null ||
      price === ""
    ) {
      if (imageUrl === null || imageUrl === undefined || imageUrl === "") {
        setBorderImageColor("red");
      }
      if (
        category.categoryName === null ||
        category.categoryName === undefined ||
        category.categoryName === "" ||
        category.categoryName === "Select Category"
      ) {
        setBorderCategoryColor("red");
      }
      if ( name.trim().length === 0 && arabicDetails.nameAr === "") {
        setNameError(true);
        setNameArError(true);
      }
      if((name.trim().length !== 0 && arabicDetails.nameAr === "") || (name.trim().length === 0 && arabicDetails.nameAr === "")){
        setNameError(false);
        setNameArError(false);
      }
      if ( name.length > 60) {
        setNameError(true);
        dispatch(
          setNotificationMessage({
            message: "Product name cannot be greater than 60 chars.",
          })
        );
      }
      if (price === null || price === "" ) {
        setPriceError(true);
      }
      dispatch(
        setNotificationMessage({ message: "Fill all the required fields." })
      );
    } else {
      if (category.categoryName.length > 60) {
        setBorderCategoryColor("red");
        dispatch(
          setNotificationMessage({
            message: "Category cannot be greater than 60 chars.",
          })
        );
      }
      if (arabicDetails.nameAr.length > 60) {
        setNameArError(true);
        dispatch(
          setNotificationMessage({
            message: "Name cannot be greater than 60 chars.",
          })
        );
      }
      if (description.length > 120) {
        setDescriptionError(true);
        dispatch(
          setNotificationMessage({
            message: "Description cannot be greater than 120 chars.",
          })
        );
      }
      if (price <= 0) {
        setPriceError(true);
        dispatch(
          setNotificationMessage({
            message: "Price cannot be less than or equal to 0",
          })
        );
      }
      if (
        price !== null &&
        price !== "" &&
        price > 0 &&
        description.length <= 120 &&
        barcode.length<64 &&
        ((name.trim().length !== 0 &&name.trim().length <= 60 ) ||(arabicDetails.nameAr.trim().length !== 0 &&arabicDetails.nameAr.trim().length <= 60 ))&&
        imageUrl !== null &&
        imageUrl !== undefined &&
        imageUrl !== "" &&
        category.categoryName !== null &&
        category.categoryName !== undefined &&
        category.categoryName !== "" &&
        category.categoryName !== "Select Category"
      ) {
        const schema = {
          name: name,
          description: description,
          price: price,
          imageUrl: imageUrl,
          category: category,
          nameAr: arabicDetails["nameAr"],
          descriptionAr: arabicDetails["descriptionAr"],
          availability: true, //availability TBD
          barcode: barcode,
          imageAnalytics: imageAnalytics || {}
        };
        setLoading(true);

        // const key = orderLength;
        await dispatch(addProductAction({ schema, uid, productId, defaultBranchId, productId: productId, imageUrl, setImageUrl, setImage}));
        recentAddedProducts.push(schema);
        setAddedProducts((prevState) => {
          const newState = [...prevState, schema ]
          return newState
        });
        setLoading(false);
        if (increment === null) {
          let productId = generateProductId();
          setProductId(productId);
          setAddTextfield(true);
          deleteAllValues();
        } else if (increment === "save") {
          setAddTextfield(false);
          handleComponentValue(1);
        } else {
          let productId = generateProductId();
          setProductId(productId);
          setAddTextfield(false);
          deleteAllValues();
        }
      }
    }
  };
  const handleAddedProductsList = (id) => {
    const deletedIdList = [...deletedId];
    deletedIdList.push(id);
    setDeletedId(deletedIdList);
  };
  const handleDeactivate = (deactivate) => {
    let schema = {
      availability: Number(!deactivate),
    };
    addedProducts.forEach((item) => {
      const id = item.id;
      if (checkboxList[id] === true) {
        dispatch(
          updateProductAction({
            schema,
            defaultBranchId: defaultBranchId,
            productUid: id,
            uid
          })
        );
      }
    });
  };
  return (
    <Paper className={classes.container}>
      <Grid container direction="column" className={classes.root}>
        <Grid item>
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <IconButton onClick={() => handleComponentValue(1)}>
                <Icon path={mdiArrowLeft} color={TEXT_COLOR.DEFAULT} size="2vh" />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography className={classes.title}>Add Product(s)</Typography>
            </Grid>
          </Grid>
          <Grid item>
            <div className={classes.alignCenter}>
              <OptimizeAccount />
            </div>
          </Grid>
          <Grid item>
            {addedProducts && addedProducts.length ? (
              checkbox || showDelete ? (
                <Grid
                  container
                  direction="row"
                  className={classes.iconContainer}
                >
                  <Grid item className={`${classes.tableHeadCheckbox} `}>
                    <Checkbox
                      disableRipple
                      icon={
                        <CheckBoxOutlineBlank
                          className={`${classes.checkboxIconSize} ${classes.inActiveCheckbox}`}
                          fontSize="small"
                        />
                      }
                      checkedIcon={
                        <CheckBox
                          style={{ color: TEXT_COLOR.ACTIVE }}
                          fontSize="small"
                        />
                      }
                      checked={checkbox}
                      onChange={() => setCheckbox(!checkbox)}
                      size="small"
                      style={{ display: "flex", alignItems: "center" }}
                    />
                  </Grid>
                  <DividerComponent height="32px" color={TEXT_COLOR.WHITE} />
                  <IconButton
                    disabled={deleteDisabled}
                    size="small"
                    onClick={handleDelete}
                  >
                    <Icon
                      path={mdiClose}
                      size="16px"
                      color={TEXT_COLOR.DELETE_COLOR}
                      style={{
                        backgroundColor: TEXT_COLOR.WHITE,
                        borderRadius: "20px",
                        padding: "4px",
                      }}
                    />
                  </IconButton>
                  <ButtonBase disabled={deleteDisabled} onClick={handleDelete}>
                    <Typography
                      className={classes.button}
                      style={{ color: TEXT_COLOR.DELETE_COLOR, marginRight: "16px" }}
                    >
                      Delete
                    </Typography>
                  </ButtonBase>
                  <CustomSwitch
                    onChange={(event) => {
                      handleDeactivate(event.target.checked);
                    }}
                  />
                  <Typography className={classes.button}>Deactivate</Typography>
                </Grid>
              ) : (
                <TableHeader
                  checkbox={checkbox}
                  setCheckbox={setCheckbox}
                  comingFrom="home"
                />
              )
            ) : (
              ""
            )}
          </Grid>
          <Grid item className={classes.tableBody}>
            {addedProducts && addedProducts.map((item) => (
              <TableBody
                key={item.id}
                item={item}
                checkboxList={checkboxList}
                handleCheckboxChange={handleCheckboxChange}
                handleAddedProductsList={handleAddedProductsList}
                comingFrom="addProductsHome"
              />
            ))}
          </Grid>
          <Grid item>
            {addTextField && (
              <AddProduct
                comingFrom="home"
                setCategory={setCategory}
                category={category}
                setImage={setImage}
                imageUrl={imageUrl}
                borderCategoryColor={borderCategoryColor}
                borderImageColor={borderImageColor}
                setBorderImageColor={setBorderImageColor}
                setBorderCategoryColor={setBorderCategoryColor}
                setName={setName}
                setDescription={setDescription}
                setPrice={setPrice}
                setPriceError={setPriceError}
                setNameError={setNameError}
                setNameArError={setNameArError}
                setDescriptionError={setDescriptionError}
                priceError={priceError}
                nameError={nameError}
                nameArError={nameArError}
                descriptionError={descriptionError}
                name={name}
                price={price}
                description={description}
                loading={loading}
                handleAddProductDelete={() => {
                  if (addedProducts.length !== 0) {
                    setAddTextfield(false);
                  }
                  deleteAllValues();
                }}
                handleSave={() => {
                  handleValidateForms(0);
                }}
                borderColor={borderColor}
                setBorderColor={setBorderColor}
                arabicDetails={arabicDetails}
                setArabicDetails={setArabicDetails}
                barcode={barcode}
                setBarcode={setBarcode}
                barcodeError={barcodeError}
                setBarcodeError={setBarcodeError}

              />
            )}
          </Grid>
          <Grid item className={classes.marginBottom}>
            <AddProductButton
              label="Save and add another"
              disabled={disableSave}
              handleClick={() => {
                if (addTextField === false) {
                  setAddTextfield(true);
                } else {
                  handleValidateForms(null);
                }
              }}
            />
          </Grid>
          <Grid item className={classes.alignButton}>
            <ShadowlessButton
              disableElevation
              disabled={disableSave}
              variant="contained"
              onClick={() => {
                if (addTextField === false) {
                  handleComponentValue(1);
                } else {
                  handleValidateForms("save");
                }
              }}
              className={classes.saveButton}
            >
              Save
            </ShadowlessButton>
          </Grid>
        </Grid>
        <DeleteDialog
          open={deleteAllDialog}
          handleClick={() => {
            deleteProducts();
            handleDialogClose();
          }}
          handleDialogClose={handleDialogClose}
        />
      </Grid>
    </Paper>
  );
}
