import React, { useEffect, useState, useRef } from "react";
import { Paper, CircularProgress, Grid, Typography, IconButton, } from "@material-ui/core";
import Icon from "@mdi/react";
import { DATABASE } from "../../config/fbConfig";
import { docNames } from "../../constants/firebaseDoc.ts";
import { TEXT_COLOR } from "../../constants/theme";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { mdiCalendarRangeOutline } from '@mdi/js';
import useStyles from "./styles";
import algoliasearch from 'algoliasearch';

export function SellersInsights() {
  const classes = useStyles();
  const now = useRef(new Date());
  const [liveSellers, setLiveSellers] = useState(0);
  const [funnelActivatedSellers, setFunnelActivatedSellers] = useState(0);
  const [sellerOrders, setSellerOrders] = useState(0);
  const [sellersProducts, setSellersProducts] = useState(0);
  const [showCalender, setShowCalender] = useState(false);
  const [index, setIndex] = useState(null);


  const today = new Date();
  const [selectionRange, setSelectionRange] = useState([{
    startDate: today.setHours(0,0,0,0),
    endDate: new Date(),
    key: 'selection',
    color: TEXT_COLOR.ACTIVE
  }]);

  const getSearchKeys = async () => {
    const configDoc = await DATABASE.collection("config").doc("algolia_search").get();
    const config = configDoc.data();
    const searchApiKey = config.SEARCH_API_KEY;
    const indexName = config.products_index_name;
    const appId = config.APP_ID;
    const searchClient = algoliasearch(appId, searchApiKey);

    const index = searchClient.initIndex(indexName);
    setIndex(index);
    await search(index);
  }

  useEffect(() => {
    getSearchKeys();
  }, []);

  const search = async (index) => {
    let start = new Date(selectionRange[0].startDate);
    let end = selectionRange[0].endDate;

    var d = start;
    var m = d.getMonth();
    d.setMonth(d.getMonth() - 1);
    if (d.getMonth() == m) d.setDate(0);
    d.setHours(0, 0, 0, 0);

    const startDate = d / 1000 | 0;
    const endDate = end.getTime();


    try {
      const result = await index.search('', {
        filters: `createdAt._seconds:${startDate} TO ${endDate}`,
        attributesToRetrieve: null,
      });
      const hits = result.hits;
      setSellersProducts(result.nbHits);
    } catch (e) {
      console.log(e);
    }

  }

  useEffect(() => {
    getLiveSellers();
    getfunnelActivatedSellers();
    getSellerOrders();
    getSellersProducts();
  }, [selectionRange]);

  const getLiveSellers = async () => {
    let start = new Date(selectionRange[0].startDate);
    let end = selectionRange[0].endDate;

    const query = await DATABASE.collection(docNames.SELLER)
      .where("isLiveOnApp", "==", true)
      .where("timestamps.liveAt", ">", start)
      .where("timestamps.liveAt", "<=", end)
      .get();
    if (!query.empty) {
      setLiveSellers(query.docs.length);
      return;
    }
    setLiveSellers(0);

  }

  const getfunnelActivatedSellers = async () => {
    let start = new Date(selectionRange[0].startDate);
    let end = selectionRange[0].endDate;
    const query = await DATABASE.collection(docNames.SELLER)
      .where("FunnelActivated", "==", true)
      .where("timestamps.createdAt", ">", start)
      .where("timestamps.createdAt", "<=", end)
      .get();
    if (!query.empty) {

      setFunnelActivatedSellers(query.docs.length);
      return;
    }
    setFunnelActivatedSellers(0);
  }

  const getSellerOrders = async () => {
    let start = new Date(selectionRange[0].startDate);
    let end = selectionRange[0].endDate;
    const query = await DATABASE.collection(docNames.ORDERS)
      .where("orderType", "==", "SELLER")
      .where("timestamps.placedAt", ">", start)
      .where("timestamps.placedAt", "<=", end)
      .get();
    if (!query.empty) {
      setSellerOrders(query.docs.length);
      return;
    }
    setSellerOrders(0);
  }

  const getSellersProducts = async () => {
    if (index && index.search) {
      const serchResultes = await search(index);
      return;
    }
  }

  const handleSelect = (ranges) => {
    const { selection } = ranges;
    setSelectionRange([selection]);
  }

  const renderSelectionValue = () => {
    const start = new Date(selectionRange[0] && selectionRange[0].startDate);
    const end = selectionRange[0].endDate;
    const dateStringStart = start.getDate() + "-" + (start.getMonth() + 1) + "-" + start.getFullYear();
    const dateStringEnd = end.getDate() + "-" + (end.getMonth() + 1) + "-" + end.getFullYear();
    return (
      <div className={classes.calender}>
        {dateStringStart}
        {" - "}
        {dateStringEnd}
        <div>
          <Icon
            onClick={() => { setShowCalender(!showCalender) }}
            path={mdiCalendarRangeOutline}
            size="16px"
            style={{
              padding: "4px",
              cursor: "pointer"
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div>
      <Paper className={classes.container}>
        <Grid style={{ margin: "20px" }}>
          <Typography className={classes.header}>Insights </Typography>
          <Typography className={classes.subHeader}>Overview of the live sellers, orders and products. </Typography>
        </Grid >
        <Grid className={classes.contents}>
          <div>{renderSelectionValue()}</div>
          {showCalender && (
            <Grid style={{ display: "flex", justifyContent: "end" }}>
              <DateRangePicker
                ranges={[selectionRange]}
                onChange={handleSelect}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={1}
                maxDate={now.current}
                showDateDisplay={false}
                direction="horizontal"
                color={TEXT_COLOR.ACTIVE}
              />
            </Grid>
          )}
          <div className={classes.cardsContainer}>
            <Grid className={classes.cardItem} style={{ background: "#e4e8f3" }}><Typography className={classes.cardText}>Total live seller: {liveSellers}</Typography></Grid>
            <Grid className={classes.cardItem} style={{ background: "#f8e8e3" }}><Typography className={classes.cardText}>Total in funnel: {funnelActivatedSellers}</Typography></Grid>
            <Grid className={classes.cardItem} style={{ background: "#e8ece3" }}><Typography className={classes.cardText}>Total Orders: {sellerOrders}</Typography></Grid>
            <Grid className={classes.cardItem} style={{ background: "#CBC3E3" }}><Typography className={classes.cardText}>Total products: {sellersProducts}</Typography></Grid>
          </div>
        </Grid>
      </Paper>
    </div>



  );
}

