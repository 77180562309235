const INTITIAL_STATE = {
    liveOrder: [],
  };
export const  orderHistoryReducer= (state = INTITIAL_STATE, action) => {
    switch (action.type) {
      case "SAVE_ORDER_HISTORY":
        return {
          ...state,
          orders: action.payload,
        };
      default:
        return state;
    }
  };