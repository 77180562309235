import { TEXT_COLOR } from "../../constants/theme";

const { makeStyles } = require("@material-ui/core");

export const useStyles = makeStyles(theme=>({
  tableBody: {
    fontFamily: "Nunito",
    display: "flex",
    alignItems: "center",
    paddingLeft: "8px",
    justifyContent: "flex-start",
    fontSize: "12px",
    fontWeight: "600",
  },
  checkbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.between("xs", "sm")]: {
      display:"none"
    },
  },
  actionContainer:{
    display:"flex",
    [theme.breakpoints.between("xs", "sm")]: {
      display:"none"
    },
  },
  statusContainer:{
    [theme.breakpoints.between("xs", "sm")]: {
      display:"none"
    },
  },
  checkboxIconSize: {
    height: "16px",
    width: "16px",
  },
  category:{
    fontFamily: "Nunito",
    display: "flex",
    alignItems: "center",
    paddingLeft: "8px",
    justifyContent: "flex-start",
    fontSize: "12px",
    fontWeight: "600",
    flex:4,
    [theme.breakpoints.between("xs", "sm")]: {
      display:"none"
    },
  },
  flexOne: {
    flex: 1,
  },
  flexOneHalf:{
    flex: 1.5,
  },
  flexTwo: {
    flex: 2,
  },
  flexThree: {
    flex: 3,
  },
  flexSeven: {
    flex: 7,
  },
  flexFour: {
    flex: 4,
  },
  flexThirteen: {
    flex: 13,
  },
  bottomMargin: {
    marginBottom: "4px",
  },
  flexFive:{
    flex: 5,
  },
  tableRow1:{
    height: "40px",
    [theme.breakpoints.between("xs", "sm")]: {
      height: "50px",
      display: "flex !important",
      alignItems: "stretch"
    },
  },
  tableRow2:{
    height: "40px",
    [theme.breakpoints.between("xs", "sm")]: {
      display: "none",
    },
  },
  text: {
    overflowWrap: "break-word",
    wordBreak: "break-word",
    color: TEXT_COLOR.BLUE,
    overflowX: "hidden",
    //overflowY: "auto",
    maxHeight: "40px",
    fontSize: "12px",
    fontFamily: "Nunito",
    marginLeft: "8px",
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "10px",
    },
  },
  iconStyling: {
    backgroundColor: TEXT_COLOR.DEFAULT_BACKGROUND,
    borderRadius: "50%",
    padding: "1px",
    boxShadow: "0px 0px 0px 2px #E8E8E8",
    position: "sticky",
    zIndex: 10,
    right: 0,
  },
  iconButtonStyling: {
    alignSelf: "center",
    margin:"5px"
  },
  fullWidth: {
    maxWidth: "18vw",
  },
  description: {
    maxWidth: "8vw",
  },
  descriptionText: {
    overflowWrap: "break-word",
    wordBreak: "break-word",
    color: TEXT_COLOR.BLUE,
    overflowX: "scroll",
    //overflowY: "auto",
    maxHeight: "40px",
    fontSize: "8px",
    fontFamily: "Nunito",
    marginLeft: "8px",
  },
  error: {
    color: TEXT_COLOR.ERROR,
    fontSize: 9
  },
  imageCtn:{
    width:"256px",
    height:"256px",
    paddingLeft:"16px",
    paddingRight:"16px",
    paddingBottom:"16px",
    display:"flex",
    justifyContent:"center",
    alignItems:"center"
  },
  img:{
    width:"256px",
    height:"256px",
  }

}));
