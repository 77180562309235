import { Button, Grid, Paper, Typography, withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import { useSelector, useDispatch } from "react-redux";
import { StyledTextField } from '../../../components/Textfield';
import { DATABASE } from '../../../config/fbConfig';
import { docNames } from '../../../constants/firebaseDoc';
import { setNotificationMessage } from '../../../actions/notificationActions';
import Icon from '@mdi/react';
import { mdiMinusCircle, mdiPlus } from '@mdi/js';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { TEXT_COLOR } from "../../../constants/theme";
import ShadowlessButton from "../../../components/ShadowlessButton"

const GreenCheckbox = withStyles({
    root: {
        color: "rgba(70, 221,202,0.5)",
        '&$checked': {
            color: "rgba(70, 221,202)",
        },
        '&:hover': {
            backgroundColor: "transparent",
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const TimePicker = withStyles({
    root: {
        '& .MuiInputBase-root': {
            height: '3.5vw'
        },
        '& input': {
            textTransform: 'lowercase',
            fontSize: 14
        }
    }
})(StyledTextField);

const DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

function ConfigRow({ opening, closing, day, onChange, index, onSave, isLast, onClickAdd, onClickCancel }) {
    const classes = useStyles();
    return (
        <Grid item style={{ marginBottom: "15px" }}>
            <Grid container direction='row' spacing={4} className={classes.noWrap}>
                <Grid item>
                    <TimePicker
                        variant='outlined'
                        label='Opening'
                        name='opening'
                        type='time'
                        format="hh:mm"
                        value={opening}
                        onChange={(e) => {
                            onChange(e, day, index);
                        }}
                        onBlur={(e) => {
                            onSave(e);
                        }}
                        InputLabelProps={{
                            style: {
                              color: TEXT_COLOR.ACTIVE,
                              fontFamily: "Nunito",
                              fontSize: 13
                            },
                        }}
                    />
                </Grid>
                <Typography style={{ alignSelf: "center" }}>to</Typography>

                <Grid item>
                    <TimePicker
                        variant='outlined'
                        label='Closing'
                        name='closing'
                        type='time'
                        format="hh:mm"
                        value={closing}
                        onChange={(e) => {
                            onChange(e, day, index);
                        }}
                        onBlur={(e) => {
                            onSave(e);
                        }}
                        InputLabelProps={{
                            style: {
                              color: TEXT_COLOR.ACTIVE,
                              fontFamily: "Nunito",
                              fontSize: 13
                            },
                        }}
                    />
                </Grid>
                {isLast &&
                    <Grid item style={{ alignSelf: "center" }}>
                        <Icon
                            onClick={onClickCancel}
                            path={mdiMinusCircle}
                            size={.95}
                            color={'#f58998'}
                        />
                    </Grid>
                }
                <Grid item style={{ alignSelf: "center" }}>
                    <Button
                        variant='outlined'
                        className={classes.button}
                        onClick={onClickAdd}
                    >
                        <Icon
                            path={mdiPlus}
                            size={1}
                            color={'#46ddca'}
                        />
                    </Button>

                </Grid>
            </Grid>
        </Grid>
    )
}

function OperationalHours() {
    const classes = useStyles();
    const [workingDays, setWorkingDays] = useState();
    const [closingDays, setClosingDays] = useState([]);
    const { uid } = useSelector(({ authState }) => authState.user);
    const { defaultBranchId } = useSelector(
        ({ defaultBranchState }) => defaultBranchState.defaultBranch
    );
    const [operationHourConfig, setOperationHourConfig] = useState();
    const [validIntervals, setValidIntervals] = useState(true);
    const [disableSave, setDisableSave] = useState(false);
    const [startTime, setStartsTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const dispatch = useDispatch();
    
    useEffect(() => {
        DATABASE.collection("config").doc("business_config").get().then((data) => {
          const document = data.data();
          setStartsTime(document.startTime);
          setEndTime(document.endTime)
        })
    }, []);
    
    const docRef = DATABASE
        .collection(docNames.SELLER)
        .doc(uid)
        .collection(docNames.BRANCHES)
        .doc(defaultBranchId)

    useEffect(() => {
        docRef
            .onSnapshot(snap => {
                if (snap.data() && snap.data().branchTimings && snap.data().branchTimings.operationalHours) {
                    setOperationHourConfig(snap.data().branchTimings.operationalHours);

                    setWorkingDays(snap.data().branchTimings.workingDays)
                    let closingDays = DAYS.reduce((allInactiveDays, day) => {

                        if ( snap.data().branchTimings.workingDays[day.toLocaleLowerCase()] && !snap.data().branchTimings.workingDays[day.toLocaleLowerCase()][0]["active"]) {
                            allInactiveDays.push(day.toLocaleLowerCase())
                        }

                        return allInactiveDays
                    }, [])
                    setClosingDays(closingDays)
                }
            });

    }, [uid, closingDays.length]);

    const handleCheckChange = (e, day) => {

        workingDays[day.toLowerCase()][0]['active'] = e.target.checked;
        setWorkingDays(workingDays[day.toLowerCase()][0]['active'])
        docRef
            .update({
               "branchTimings.workingDays": workingDays
            })

    };

    const handleChange = (e, day, index) => {
        e.persist();
        setOperationHourConfig(prevState => {
            let dayConfig = prevState[day.toLowerCase()];
            dayConfig[index][e.target.name] = e.target.value;
            return ({
                ...prevState,
                [day.toLowerCase()]: dayConfig
            })
        });
    }

    const getTimeInMins = (time) => {
        let parts = [time.split(':')[0], time.split(':')[1]];
        const timeInMins = parseInt(parts[0]) * 60 + parseInt(parts[1]);
        return timeInMins;
    }

    const isValidTime = (day) => {
        let configPossible = !(operationHourConfig[day.toLowerCase()].map((config, index) => {
            let startTime = getTimeInMins(config.opening);
            let closeTime = getTimeInMins(config.closing);
            let nextStartTime = operationHourConfig[day.toLowerCase()][index + 1] ? getTimeInMins(operationHourConfig[day.toLowerCase()][index + 1].opening) : -1;
            return (startTime < closeTime) && (nextStartTime !== -1 ? closeTime < nextStartTime : true);
        }).includes(false));
        return configPossible;
    }

    const handleDaySave = (e, day, index) => {
        if (isValidTime(day, index)) {
            setValidIntervals(true)
            setDisableSave(false)
        } else {
            setValidIntervals(false)
            setDisableSave(true)
            dispatch(setNotificationMessage({ message: 'Invalid time. Opening time for current shift cannot be less than closing time of previous shift' }));
        }
    }

    const handleSave = (e) => {        
        
        if (validIntervals && !disableSave) {
            docRef
            .update({
               "branchTimings.workingDays": workingDays,
               "branchTimings.operationalHours": operationHourConfig
            }).then(() => {
                dispatch(
                    setNotificationMessage({
                      type: "success",
                      message: "Operational hours updated successfully.",
                    })
                  );
            })
        } else {
            dispatch(setNotificationMessage({ message: 'Invalid time. Opening time for current shift cannot be less than closing time of previous shift' }));
        }

    }

    const handleAdd = (day) => {
        const operationalHours = {
            ...operationHourConfig,
            [day.toLowerCase()]: [
                ...operationHourConfig[day.toLowerCase()],
                {
                    opening: '08:00',
                    closing: '17:00'
                }
            ]
        };
        setOperationHourConfig(operationalHours)
        
    }

    const handleDelete = (day, index) => {
        let dayConfig = operationHourConfig[day.toLowerCase()];
        if (dayConfig.length === 1) {
            dispatch(setNotificationMessage({ message: "Cannot delete config, day should have more than one set of operational hours for this operation" }));
        } else {
            dayConfig.splice(index, 1);
            const operationalHours = {
                ...operationHourConfig,
                [day.toLowerCase()]: dayConfig
            };
            setOperationHourConfig(operationalHours)
        }
    }

    return (
        <Paper className={classes.root}>
            <Grid container spacing={3} direction='column' className={classes.grid}>
                <Typography variant='body1' component='p' className={classes.title}>
                    Operational Hours
                </Typography>
                <Grid item>
                    <Typography
                        variant="body1"
                        component="p"
                        className={classes.onlyMobile}
                    >
                        Please use a desktop to update your operational hours.
                    </Typography>
                </Grid>
                <Grid item className={classes.operationsInfo}>

                    {closingDays && closingDays.length > 0 ?
                        <div style={{ display: "flex" }}>
                            <Typography variant='body1' component='p' className={classes.subTitle}>
                                Closing Days:
                            </Typography>
                            {closingDays && closingDays.map(closingDay => (
                                <Typography variant='body1' component='p' className={classes.subTitle}>
                                    {closingDay + ", "}
                                </Typography>

                            ))}</div> : null}

                    <Typography variant='body1' component='p' className={classes.subTitle}>
                        {`Operatinal hours for mashkor from ${startTime && startTime} to ${endTime && endTime}`}
                    </Typography>

                </Grid>
                <Grid className={classes.container} direction='column' spacing={4}>
                    {DAYS.map(day => (
                        <div className="row" item key={day}
                            style={{
                                backgroundColor: "#f8f9fb", marginBottom: "20px", display: "flex",
                                alignSelf: "center", padding: "20px", width: "55vw ",
                            }}>
                            <div style={{ display: "flex", flex: 2 }}>

                                {workingDays && workingDays[day.toLowerCase()] && workingDays[day.toLowerCase()].map((config, index) => (
                                    <FormControlLabel
                                        control={<GreenCheckbox
                                            checked={config['active']}
                                            onChange={e => { handleCheckChange(e, day) }} />}
                                    />
                                ))}

                                <Typography variant='body1' component='p' className={classes.day}>
                                    {day}
                                </Typography>
                            </div>
                            {operationHourConfig && !closingDays.includes(day.toLowerCase()) && operationHourConfig[day.toLowerCase()] ?
                                <Grid item style={{ display: "flex", flex: 5, flexFlow: "column" }}>
                                    {operationHourConfig[day.toLowerCase()].map((config, index) => (

                                        <ConfigRow
                                            opening={config.opening}
                                            closing={config.closing}
                                            key={`${day}-${index}`}
                                            onChange={handleChange}
                                            index={index}
                                            day={day}
                                            onSave={(e) => handleDaySave(e, day, index)}
                                            isLast={index === operationHourConfig[day.toLowerCase()].length - 1}
                                            onClickAdd={() => handleAdd(day)}
                                            onClickCancel={() => handleDelete(day, index)}
                                        />
                                    ))}
                                </Grid> :
                                <Grid item style={{ display: "flex", flex: 5, justifyContent: "end" }}>
                                    <Typography variant='body1' component='p'
                                        className={classes.day}>
                                        Closing
                                    </Typography>
                                </Grid>}

                        </div>
                    ))}
                </Grid>
                <Grid style={{ alignSelf: "flex-end" }}>
                    <ShadowlessButton
                        disableElevation
                        disabled={disableSave}
                        variant="contained"
                        onClick={handleSave}
                        className={classes.saveButton}
                    >
                        Save
                    </ShadowlessButton>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default OperationalHours;
