const INTITIAL_STATE = {
    defaultBranch: null,
  };
  
  const defaultBranch = (state = INTITIAL_STATE, action) => {
    switch (action.type) {
      case "SAVE_DEFAULT_BRANCH":
        return {
          ...state,
          defaultBranch: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default defaultBranch;
  