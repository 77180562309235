import { makeStyles } from "@material-ui/core";
import { TEXT_COLOR } from "../../constants/theme";

const useStyles = makeStyles(theme=>({
  root: {
    flex: 1,
    display: "flex",
    alignItems: "flex-start",
    padding: "2%",
    margin: "auto 0px",
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
  flowUnselected: {
    height: "initial",
  },
  flowSeleted: {
    height: "inherit",
  },
  item: {
    transitionDuration: "0.5s",
  },
  typography: {
    fontFamily: "nunito",
    color: TEXT_COLOR.DEFAULT,
  },
  visible: {
    visibility: "visible",
    opacity: 1,
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: "18px !important",
    },
  },
  hidden: {
    visibility: "collapse",
    opacity: 0,
    height: 0,
    padding: "0px !important",
  },
  fullWIdth: {
    width: "100%",
  },
  gridContainer: {
    padding: "0% 2%",
    minHeight: "100%",
    marginBottom: 0,
    marginTop: 0,
    textAlign: "center",
  },
  floatBottom: {
    marginTop: "auto",
  },
  divider: {
    marginBottom: "2%",
  },
  mainContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  flowContainer: {
    width: "60%",
  },
  startButton: {
    width: "10%",
  },
  alignCenter: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "1vh",
  },
  mashkorIcon: {
    verticalAlign: "middle",
    height: "13vh",
  },
}));

export default useStyles;
