const INTITIAL_STATE = {
    category: [],
  };
  
  const categoryReducer = (state = INTITIAL_STATE, action) => {
    switch (action.type) {
      case "SAVE_CATEGORIES":
        return {
          ...state,
          category: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default categoryReducer;
  