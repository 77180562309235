import { NotificationsNone } from '@material-ui/icons';
import React from 'react';
import useStyles from './styles';

function NotificationIcon({ hasNotification }) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            {hasNotification && <div className={classes.notificationSign}></div>}
            <NotificationsNone/>
        </div>
    )
}

export default NotificationIcon;