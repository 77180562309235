import {
  ButtonBase,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Typography,
  Switch
} from "@material-ui/core";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import { mdiPlaylistPlus, mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DATABASE } from "../../config/fbConfig";
import { docNames } from "../../constants/firebaseDoc.ts";
import {
  deleteProductAction,
  updateProductAction,
} from "../../actions/addProducts";
import { setNotificationMessage } from "../../actions/notificationActions";
import { EmptyProductList } from "../../assets/images";
import {
  AddProductButton,
  DeleteDialog,
} from "../../components/AddProductButton";
import { CustomSwitch } from "../../components/CustomSwitch";
import { DividerComponent } from "../../components/Divider";
import { TEXT_COLOR } from "../../constants/theme";
import OptimizeAccount from "../OptimizeAccount";
import { TableBody } from "../TableBody";
import { TableHeader } from "../TableHeader";
import useStyles from "./styles";
import { withStyles } from '@material-ui/core/styles';

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: TEXT_COLOR.ACTIVE,
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: TEXT_COLOR.ACTIVE,
        borderColor: TEXT_COLOR.ACTIVE,
      },
    },
  },
  thumb: {
    width: 10,
    height: 10,
    boxShadow: 'none',
  },
  track: {
    height: "80%",
    border: `1px solid ${TEXT_COLOR.ACTIVE}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

function AddProductMenu({ anchorEl, handleCloseMenu, handleComponentValue }) {
  const classes = useStyles();
  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleCloseMenu}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      getContentAnchorEl={null}
    >
      <MenuItem onClick={() => handleComponentValue(0)}>
        <Icon
          path={mdiPlaylistPlus}
          size="16px"
          color= {TEXT_COLOR.DEFAULT}
          className={classes.listIconStyle}
        />
        <Typography className={classes.listTitle}>One by one</Typography>
      </MenuItem>
      <MenuItem onClick={() => handleComponentValue(2)}>
        <Icon
          path={mdiPlaylistPlus}
          size="16px"
          color= {TEXT_COLOR.DEFAULT}
          className={classes.listIconStyle}
        />
        <Typography className={classes.listTitle}>
          Import Excel sheet
        </Typography>
      </MenuItem>
    </Menu>
  );
}

export function ProductManagementScreen({ 
  comingFrom,
  handleComponentValue, 
  checkbox, setCheckbox, 
  verifiedProductsSwitch, setVerifiedProductsSwitch,
  showEmptyList, setShowEmptyList,
  showAddProducts, setShowAddProducts}) {
  const { defaultBranchId } = useSelector(
    ({ defaultBranchState }) => defaultBranchState.defaultBranch
  );
  const { uid, isAdmin } = useSelector(({ authState }) => authState.user);
  
  // const [deleteDisabled, setDeleteDisabled] = useState(false);
  const [deleteAllDialog, setDeleteAllDialog] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [productList, setProductList] = useState([]);
  const [checkboxList, setChcekboxList] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [showDeleteButtons, setShowDeleteButtons] = useState(false);
  const categoryList = useSelector(
    ({ categoryState }) => categoryState.category
  );
  const [category, setCategory] = useState({});
  const [filterProduct, setFilterProduct] = useState(null);
  const [filterProductAr, setFilterProductAr] = useState(null);
  const [searchResultProducts, setSearchResultProducts] = useState(null);
  const allProducts = useSelector(
    ({ productState }) => productState.productList
  );
  const [loading] = useState(false);

  const filterProductsByName = async() =>{
    let tempList = searchResultProducts && searchResultProducts;

    if (!tempList || tempList.length === 0) {
      setShowEmptyList(true);
    } 
    else {
      setProductList(tempList);
      
      setShowEmptyList(false);
    }
  }

  useEffect(()=>{
      if(comingFrom === "Home" && !showEmptyList && allProducts.length === 0){
        setShowEmptyList(true);
      }
  },[showEmptyList]);
  useEffect(() => {
    if (allProducts.length > 0) {
      const categoryValues = Object.values(category);
      //search by name only 
      if ((filterProduct || filterProductAr)) {
        filterProductsByName();
      }

      //search by category if all are active/inactive
      if ((!filterProduct) && (categoryValues.every((v) => v === true) || categoryValues.every((v) => v === false)) && categoryValues.length) {
        setProductList(allProducts);
        setShowEmptyList(false);
      }
      //search by category if some are active/inactive
      else if (categoryValues.some(a => a === true)) {
        let tempList = [];
        let activeCategories = []
        allProducts.forEach((product) => {
          const categoryId = product.category.id;
          if (category[categoryId]) {
            activeCategories.push(categoryId)
          }
        });
        let uniqueCategoriesSet = [...new Set(activeCategories)];

        if (uniqueCategoriesSet.length > 0) {
          DATABASE.collection(docNames.SELLER)
            .doc(uid)
            .collection(docNames.BRANCHES)
            .doc(defaultBranchId)
            .collection(docNames.PRODUCT)
            .where("verificationStatus", "==", verifiedProductsSwitch ? "VERIFIED" : ( "PENDING" || "PENDING"))
            .where("category.id", "in", uniqueCategoriesSet)
            .get().then((querySnap) => {
              if (querySnap.docs.length) {
                querySnap.docs.forEach((item) => {
                  const data = item.data();
                  tempList.push(data);
                });
              }
            }).then(() => {
              if (tempList.length === 0) {
                setShowEmptyList(true);
              } else {
                setProductList(tempList);
                setShowEmptyList(false);
              }
            })
        }
      }
    }
  }, [filterProduct, filterProductAr, allProducts, category]);

  useEffect(() => {
    categoryList.forEach((item) => {
      const id = item.id;
      setCategory((previousState) => ({ ...previousState, [id]: false }));
    });
  }, [categoryList]);
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    const value = Object.values(checkboxList);
    const isTrue = value.some((item) => item === true);
    setShowDeleteButtons(isTrue);
  }, [checkboxList]);
  const handleCheckboxChange = (id) => {
    setChcekboxList((value) => ({ ...value, [id]: !checkboxList[id] }));
  };
  const handleDelete = () => {
    const value = Object.values(checkboxList);
    if (value.every((v) => v === false) || value.length === 0) {
      // no items to delete
      dispatch(setNotificationMessage({ message: "Nothing to delete" }));
    } else if (value.length !== 0 && value.every((v) => v === true)) {
      //all products are to be deleted
      setDeleteAllDialog(true);
    } else {
      //some products are to be deleted
      // const newCheckboxList = { ...checkboxList };
      productList.forEach((item) => {
        const id = item.id;
        if (checkboxList[id] === true) {
          dispatch(deleteProductAction({ id: id, product: item, productImageUrl: item.imageUrl, defaultBranchId, uid }));
          // delete newCheckboxList[id];
        }
      });
      // setChcekboxList(newCheckboxList);
    }
  };
  const deleteAllProducts = () => {
    productList.forEach((item) => {
      dispatch(deleteProductAction({ id: item.id, product: item, productImageUrl: item.imageUrl, defaultBranchId, uid }));
    });
    setCheckbox(false);
  };
  useEffect(() => {
    if (checkbox === true ) {
      let newList = {};
      productList.forEach((item) => {
        const id = item.id;
        newList[id] = true;
      });
      setChcekboxList(newList);
    } else {
      let newList = {};
      productList.forEach((item) => {
        const id = item.id;
        newList[id] = false;
      });
      setChcekboxList(newList);
    }
  }, [checkbox, productList]);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleDialogClose = () => {
    setDeleteAllDialog(false);
  };
  const handleAvailabilityChange = (availabilityStatus) => {
    let schema = {
      availability: Number(availabilityStatus) === 1? true: false,
    };
    productList.forEach((item) => {
      const id = item.id;
      if (checkboxList[id] === true) {
        dispatch(
          updateProductAction({
            schema,
            defaultBranchId: defaultBranchId,
            productUid: id,
            uid
          })
        );
      }
    });
  };

  const handleVerifiedProductsSwitchChange = () =>{
    setVerifiedProductsSwitch(!verifiedProductsSwitch);
   }

  return (
    <Paper className={classes.container}>
      {loading ? (
        <CircularProgress style={{ 'color': '#30D9C4' }}  />
      ) : (
        <Grid
          container
          direction="column"
          className={`${classes.root} ${classes.noWrap}`}
        >
          <Grid container direction="row" className={classes.marginBottom}>
            <Grid item>
              <Typography className={classes.title}>Products</Typography>
            </Grid>
            <Grid  className={classes.addProduct} item style={{ marginLeft: "auto" }}>
              <AddProductButton
                label="Add product"
                handleClick={(event) => {
                  setAnchorEl(event.currentTarget);
                }}
                handleCloseMenu={handleCloseMenu}
              />
            </Grid>
            <AddProductMenu
              anchorEl={anchorEl}
              handleCloseMenu={handleCloseMenu}
              handleComponentValue={handleComponentValue}
            />
          </Grid>
            {!showAddProducts ?
              <Grid item>
                <div className={classes.alignCenter}>
                  <OptimizeAccount />
                </div>
                <div className={classes.alignRight}>
                  <Typography component="div">
                    <Grid component="label" container alignItems="center" spacing={1}>
                      <Grid item>Pending</Grid>
                      <Grid item>
                        <AntSwitch
                          checked={verifiedProductsSwitch}
                          onChange={handleVerifiedProductsSwitchChange}
                          name="checkedC" />
                      </Grid>
                      <Grid item>Verified</Grid>
                    </Grid>
                  </Typography>
                </div>
              </Grid> : null}
          {productList.length ? (
            <>
              <Grid item className={classes.productsHeader}>
                {checkbox || showDeleteButtons ? (
                  <Grid
                    container
                    direction="row"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: TEXT_COLOR.BACKGROUND
                    }}
                  >
                    <Grid item className={`${classes.tableHeadCheckbox} `}>
                      <Checkbox
                        disableRipple
                        icon={
                          <CheckBoxOutlineBlank
                            className={classes.checkboxIconSize}
                            style={{
                              color: TEXT_COLOR.WHITE,
                              backgroundColor: TEXT_COLOR.WHITE
                            }}
                            fontSize="small"
                          />
                        }
                        checkedIcon={
                          <CheckBox
                            style={{ color: TEXT_COLOR.ACTIVE }}
                            fontSize="small"
                          />
                        }
                        checked={checkbox}
                        onChange={() => setCheckbox(!checkbox)}
                        size="small"
                      />
                    </Grid>
                    <DividerComponent height="32px" color= {TEXT_COLOR.WHITE} />
                    <Grid item>
                      <IconButton
                        // disabled={deleteDisabled}
                        size="small"
                        onClick={handleDelete}
                      >
                        <Icon
                          path={mdiClose}
                          size="16px"
                          color={TEXT_COLOR.DELETE_COLOR}
                          style={{
                            backgroundColor: TEXT_COLOR.WHITE,
                            borderRadius: "20px",
                            padding: "4px",
                          }}
                        />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <ButtonBase
                        // disabled={deleteDisabled}
                        onClick={handleDelete}
                      >
                        <Typography
                          className={classes.button}
                          style={{ color: TEXT_COLOR.DELETE_COLOR, marginRight: "16px" }}
                        >
                          Delete
                        </Typography>
                      </ButtonBase>
                    </Grid>
                    <Grid>
                        <Grid item>
                          <ButtonBase
                          //event.stopPropagation()
                          onClick={(event) => {
                            event.stopPropagation()
                            handleAvailabilityChange(1)
                          }}
                          >
                            <Typography
                              className={classes.button}
                              style={{ color: TEXT_COLOR.DELETE_COLOR, marginRight: "16px" }}
                            >
                              {checkbox ? "Activate All" : "Activate"}
                          </Typography>
                          </ButtonBase>
                        </Grid>
                      </Grid>

                      <Grid item>
                        <ButtonBase
                          onClick={(event) => {
                            event.stopPropagation()
                            handleAvailabilityChange(0)
                          }}
                        >
                          <Typography
                            className={classes.button}
                            style={{ color: TEXT_COLOR.DELETE_COLOR, marginRight: "16px" }}
                          >
                            {checkbox ? "Deactivate All" : "Deactivate"}
                          </Typography>
                        </ButtonBase>
                      </Grid>
                  </Grid>
                ) : (
                  <TableHeader
                    checkbox={checkbox}
                    setCheckbox={setCheckbox}
                    comingFrom="home"
                    categoryList={categoryList}
                    category={category}
                    setCategory={setCategory}
                    handleClose={handleClose}
                    filterProduct={filterProduct}
                    filterProductAr={filterProductAr}
                    setFilterProduct={setFilterProduct}
                    setFilterProductAr={setFilterProductAr}
                    searchResultProducts={searchResultProducts}
                    setSearchResultProducts={setSearchResultProducts}
                    emptyList={showEmptyList}
                    verifiedProductsSwitch={verifiedProductsSwitch}
                  />
                )}
              </Grid >
                <Grid item className={classes.mobileHeader}>
                  <Grid
                    item
                    className={`${classes.tableHead} ${classes.flexFour}`}
                  >
                    Product Name [EN]
                  </Grid>
                  <DividerComponent height="32px" color={TEXT_COLOR.WHITE} />
                  <Grid
                    item
                    className={`${classes.tableHead} ${classes.flexFour}`}
                  >
                      Product Name [AR]
                  </Grid>
                  <DividerComponent height="32px" color={TEXT_COLOR.WHITE} />
                  <Grid
                    item
                    className={`${classes.tableHead} ${classes.flexTwo}`}
                  >
                    Image
                  </Grid>
                  <DividerComponent height="32px" color={TEXT_COLOR.WHITE} />
                  <Grid
                    item
                    className={`${classes.tableHead} ${classes.flexThree}`}
                  >
                    Price
                  </Grid>
                </Grid>
            </>
            ) : showAddProducts ? (
              <Grid
                container
                direction="column"
                alignContent="center"
                spacing={3}
                className={classes.noWrap}
              >
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifycontent="center"
                    style={{justifyContent: "center"}}
                  >
                    <img src={EmptyProductList} alt="empty list" />
                  </Grid>
                </Grid>
                <Grid item>
                  <div className={classes.alignCenter}>
                    <OptimizeAccount />
                  </div>
                </Grid>
                <Grid item>
                  <Typography
                    className={`${classes.heading} ${classes.marginBottom}`}
                  >
                    Add your products to start selling!
                  </Typography>
                </Grid>
                <Grid item className={classes.addProduct}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifycontent="space-evenly"
                    style={{justifyContent: "center"}}
                  >
                    <Grid item>
                      <AddProductButton
                        label="Add product"
                        handleClick={() => handleComponentValue(0)}
                      />
                    </Grid>
                    <Grid item onClick={() => handleComponentValue(2)}>
                      <IconButton size="small">
                        <Icon
                          path={mdiPlaylistPlus}
                          size="16px"
                          color={TEXT_COLOR.WHITE}
                          style={{
                            backgroundColor: TEXT_COLOR.FF8E6E,
                            borderRadius: "8px",
                          }}
                        />
                      </IconButton>
                      <ButtonBase>
                        <Typography className={`${classes.button} `}>
                          Import Excel sheet
                        </Typography>
                      </ButtonBase>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : showEmptyList? (
              <div className={classes.emptyProductsList}>
                <img
                  src={EmptyProductList}
                  alt="empty list"
                  className={classes.marginBottom}
                />
                <Typography align="center">No products found</Typography>
              </div>): null}

          {productList.length > 0 ? (
            <Grid
              item
              className={`${classes.tableBody} ${classes.marginBottom}`}
            >
              {showEmptyList ? (
                <div className={classes.emptyImageContainer}>
                  <img
                    src={EmptyProductList}
                    alt="empty list"
                    className={classes.marginBottom}
                  />
                  <Typography align="center">No products found</Typography>
                </div>
              ) : (
                productList.map((item, key) => (
                  <TableBody
                    key={item.id || item.objectID}
                    item={item}
                    checkboxList={checkboxList}
                    handleCheckboxChange={handleCheckboxChange}
                    comingFrom="home"
                  />
                ))
              )}
            </Grid>
          ) : (
            ""
          )}
          <DeleteDialog
            open={deleteAllDialog}
            handleClick={() => {
              deleteAllProducts();
              handleDialogClose();
            }}
            handleDialogClose={handleDialogClose}
          />
        </Grid>
      )}
    </Paper>
  );
}
