import { makeStyles } from "@material-ui/core";
import { TEXT_COLOR } from "../../constants/theme";

const useStyles = makeStyles({
  paperContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  textStyle: {
    fontFamily: "Nunito",
    fontSize: "16px",
  },
  uploadContainer: {
    border: `1px dashed ${TEXT_COLOR.DEFAULT}`,
    borderRadius: "4px",
    alignItems: "center",
    display: "flex",
  },
  dimension: {
    width: "470px",
    height: "40px",
  },
  marginBottom: {
    marginBottom: "8px",
  },
  saveButton: {
    backgroundColor: TEXT_COLOR.ACTIVE,
    borderRadius: "4px",
    border: `0px solid ${TEXT_COLOR.ACTIVE}`,
    color: TEXT_COLOR.WHITE,
    fontSize: "12px",
    cursor: "pointer",
  },
  count: {
    backgroundColor: `${TEXT_COLOR.ACTIVE}21`,
    borderRadius: "50%",
    width: "24px",
    height: "24px",
    border: `1px solid ${TEXT_COLOR.ACTIVE}`,
    marginRight: "8px",
    display: "flex",
    justifyContent: "center",
  },
  box: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    marginLeft: "8px",
    marginRight: "8px",
  },
  imageUrl: {
    width: "70px",
    height: "93px",
  },
  imageLabel: {
    textAlign: "center",
    marginTop: "4%",
    marginLeft: "18px",
    fontSize: "16px",
    position: "absolute",
    color: TEXT_COLOR.WHITE,
  },
  flexEnd: {
    marginLeft: "50%",
  },
  activeColor: {
    color: TEXT_COLOR.ACTIVE,
  },
  orangeColor: {
    color: TEXT_COLOR.ORANGE,
  },
  imageMismatchLabel: {
    fontSize: "40px",
    color: TEXT_COLOR.DEFAULT,
    fontFamily: "Nunito",
    fontWeight: "bold",
  },
  defaultColor: {
    color: TEXT_COLOR.DEFAULT,
  },
  chatbox: {
    backgroundColor: "#F1FEFC",
    boxShadow: "0px 9px 27px #CECDCD3F",
    border: `1px solid ${TEXT_COLOR.ACTIVE}`,
    borderRadius: "4px",
    padding: "4px 8px 4px 8px",
  },
  container: {
    display: "flex",
    width: "96%",
    marginLeft: "2%",
  },
  tableBody: {
    overflowY: "auto",
    width: "100%",
    maxHeight: "65vh",
  },
  duplicateMessage: {
    fontSize: "12px",
    textAlign: "left",
    fontFamily: "Nunito",
  },
  loadingText: {
    textAlign: "left",
    fontSize: "12px",
    color: "#586285",
    fontFamily: "Nunito",
  },
  tableHeadCheckbox: {
    backgroundColor: TEXT_COLOR.BACKGROUND,
    display: "flex",
    alignItems: "center",
    height: "32px",
    width: "32px",
  },
  checkboxIconSize: {
    height: "16px",
    width: "16px",
  },
  inActiveCheckbox:{
    color: TEXT_COLOR.WHITE,
    backgroundColor: TEXT_COLOR.WHITE,
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    backgroundColor: TEXT_COLOR.BACKGROUND,
  },
  alignCenter: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '1vh'
  }
});

export default useStyles;
