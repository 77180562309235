const setNotificationMessage = (data) => ({
  type: "SET_NOTIFICATION_MESSAGE",
  payload: data, // { type: 'success/error', message: '' }
});

const resetMessage = () => ({
  type: "RESET_MESSAGE",
});

export { setNotificationMessage, resetMessage };
