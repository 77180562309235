const addProductAction = (data) => ({
  type: "ADD_PRODUCT",
  payload: data,
});
const deleteProductAction = (data) => ({
  type: "DELETE_PRODUCT",
  payload: data,
});
const setBrandSettingsActions = (data) => ({
    type: "ADD_BRAND_SETTINGS",
    payload: data,
  });
const updateProductAction = (data) => ({
    type: "UPDATE_PRODUCT",
    payload: data,
});

const addProductImage = (data) => ({
  type: 'ADD_IMAGE',
  payload: data
})

export { addProductAction, deleteProductAction, setBrandSettingsActions, updateProductAction, addProductImage };
