import { Grid } from "@material-ui/core";
import React from "react";
import { TEXT_COLOR } from "../../constants/theme";
import useStyles from "./styles";

export function FlowStep({ children, position, progress, setCurrent, name }) {
  const classes = useStyles();

  return (
    <>
      {position > 0 && (
        <Grid item className={classes.element}>
          <div className={`${classes.connector} `}></div>
        </Grid>
      )}
      <Grid item >
        <div className={classes.container}>
          <div
            className={`${classes.flowStep} ${classes.boxShadow} ${
              progress[position]
                ? classes.backgroundGreen
                : classes.backgroundWhite
            } ${classes.alignChildren}`}
          >
            {children}
          </div>
        </div>
      </Grid>
    </>
  );
}

export function Flow({ children, setProgress, progress }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container direction="row" justify="space-between">
        {children.map((child, index) => {
          let element = {
            ...child,
            props: {
              ...child.props,
              setCurrent: setProgress,
              progress,
              position: index,
            },
          };
          return element;
        })}
      </Grid>
      <div className={`${classes.titles} `}>
        {children.map((child, key) => {
          return (
            <div
              key={`flow-${key}`}
              style={{
                color: progress[key] ? TEXT_COLOR.ACTIVE : TEXT_COLOR.DEFAULT, 
                flexWrap: "wrap",
              }}
            >
              {child.props.name}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export function FlowStepWindow({ position, children }) {
  return <>{children.filter((child, index) => index === position)[0]}</>;
}
