import { Button, withStyles } from "@material-ui/core";
import { TEXT_COLOR } from "../../constants/theme";

const ShadowlessButton = withStyles({
    root: {
        '&:hover': {
            backgroundColor: `${TEXT_COLOR.ACTIVE}99`
        }
    }
})(Button);

export default ShadowlessButton;