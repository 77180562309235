import { makeStyles } from "@material-ui/core";
import { TEXT_COLOR } from "../../../../constants/theme";

export const useStyles = makeStyles(theme=>({
  headerCtn: {
    backgroundColor: TEXT_COLOR.BACKGROUND,
    height: "32px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.between("xs", "sm")]: {
      display: "none"
    },
  },
  checkbox:{
    display: "flex",
    // flex: 1,
    [theme.breakpoints.between("xs", "sm")]: {
      display: "none"
    },
  },
  checkboxIconSize: {
    height: "16px",
    width: "16px",
  },
  unchecked: {
    color: TEXT_COLOR.WHITE,
    backgroundColor:TEXT_COLOR.WHITE,
  },
  uncheckedBodyIcon: {
    color: TEXT_COLOR.BACKGROUND,
    backgroundColor: TEXT_COLOR.BACKGROUND,
  },
  checked: {
    color: TEXT_COLOR.ACTIVE,
  },
  checkboxAlignment: {
    display: "flex",
    // flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "row",
    },
  },
  headerContent: {
    fontSize: "12px",
    fontFamily: "Nunito",
    // color: TEXT_COLOR.DEFAULT,
    fontWeight: "600",
    display: "flex",
    textAlign: "center",
    paddingLeft: "3%",
    justifyContent: "flex-start",
    wordBreak: "break-word",
    overflowX: "hidden",
    // overflowY: "auto",
    maxHeight: "32px",
    flexWrap:'nowrap',
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "10px",
    },
  },
  flexOne: {
    flex: 1,
  },
  flexTwo: {
    flex: 2,
  },
  flexThree: {
    flex: 3,
  },
  flexfour: {
    flex: 4,
  },
  flexSix: {
    flex: 6,
  },
  iconCtn: {
    borderRadius: "4px",
    width: "24px",
    height: "24px",
    marginRight: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  activeBackground: {
    backgroundColor: "#F2FFFD"
  },
  mobileContainer1:{
    display: "flex",
    flex: 10,
    [theme.breakpoints.between("xs", "sm")]: {
      flexWrap: "wrap",
      margin: 10
    },
  },
  mobileContainer2:{
    display: "flex",
    flex: 2,
    [theme.breakpoints.between("xs", "sm")]: {
      flexWrap: "wrap",
      margin: 10
    },
  },
  mobileSubContainer1:{
    display: "flex",
    flex: 2,
    [theme.breakpoints.between("xs", "sm")]: {
      display: "flex",
      flexWrap: "wrap",
    },
  },
  mobileSubContainer2:{
    flex: 1,
    display: "flex",
    [theme.breakpoints.between("xs", "sm")]: {
      display: "none",
    },
  },
  cartUpdatedText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 10,
    color: TEXT_COLOR.ERROR,
  },
  cartUpdatedTextBlack: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 10,
    color: "black",
  },
  defaultBackground: {
    backgroundColor: TEXT_COLOR.F6F7F8,
  },
  defaultColor: {
    color: TEXT_COLOR.DEFAULT,
  },
  errorColor: {
    color: TEXT_COLOR.ERROR,
  },
  alignRight: {
    marginLeft: "auto",
    marginRight: "4px",
    [theme.breakpoints.between("xs", "sm")]: {
      marginLeft: "6vw",
    },
  },
  alignCenter: {
    alignSelf: "center",
  },
  listTitle: {
    fontSize: "14px",
    fontFamily: "nunito",
    marginLeft: "8px",
  },
  container: {
    display: "flex",
    flex: 1,
    width: "100%",
  },
  date: {
    backgroundColor: TEXT_COLOR.WHITE,
  },
  cursor: {
    cursor: "pointer",
  },
  dateMenuRoot: {
    outline: "none",
    display: "flex",
    flexDirection: "column",
    marginLeft: "8px",
    marginRight: "8px",
  },
  label: {
    fontSize: "14px",
    fontFamily: "nunito",
    color: TEXT_COLOR.B2B2B2,
  },
  menu: {
    outline: "none",
  },
  listIconStyle: {
    backgroundColor: TEXT_COLOR.WHITE,
    borderRadius: "50%",
    boxShadow: `0px 0px 0px 2px ${TEXT_COLOR.F6F7F8}`,
  },
  flexZero: {
    display: "none",
  },
  alignment: {
    paddingLeft: "16px",
    paddingRight: "16px",
    [theme.breakpoints.between("xs", "sm")]: {
      paddingLeft: 0,
      paddingRight: 0
    },
  },
  textfieldHeight:{
    height:'32px'
  }
}));
