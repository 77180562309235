import {
  Dialog,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { TextFieldComponent } from "../../components/Textfield";
import { LOGIN_VALIDATION } from "../../Validations/login";
import { yupResolver } from "@hookform/resolvers/yup";
import { useStyles } from "./styles";
import * as yup from "yup";
import { useHistory, Link } from "react-router-dom";
import { ErrorMessage } from "../../components/ErrorMessage";
import { signIn } from "../../actions/authActions";
import { routes } from "../../constants";
import ForgotPassword from "../ForgotPassword";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { SigninButton, SigninCard } from "../../components/SignupCard";
import { TEXT_COLOR } from "../../constants/theme";
const loginSchema = yup.object().shape({
  email: LOGIN_VALIDATION.EMAIL,
  password: LOGIN_VALIDATION.PASSWORD,
});

export default function Login() {
  const { register, errors, handleSubmit } = useForm({
    resolver: yupResolver(loginSchema),
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const [disableLogin, setDisableLogin] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const classes = useStyles();

  const handleLogin = (data) => {
    const email  = data.email.toLowerCase().trim();
    const password  = data.password;
    setDisableLogin(true);
    dispatch(signIn({ email, password, history, setDisableLogin }));
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleKeyPressed = (event) => {
    if (event.key === "Enter") {
      handleSubmit(handleLogin)();
    }
  };

  return (
    <>
      <SigninCard>
        <Grid
          container
          direction="column"
          style={{ marginLeft: "16px", marginRight: "16px" }}
        >
          <Grid item>
            <Typography
              className={`${classes.heading} ${classes.marginBottom} `}
            >
              Login to seller account
            </Typography>
          </Grid>
          <Grid item>
            <InputLabel className={classes.inputLabel}>Email</InputLabel>
          </Grid>
          <Grid item className={`${classes.marginBottom} `}>
            <TextFieldComponent
              inputRef={register}
              name="email"
              variant="outlined"
              placeholder="Enter your email address"
              size="small"
              styleProps={{
                width: "268px",
              }}
              error={errors.email ? true : false}
              inputProps={{
                style: {
                  height: "14px",
                  fontSize: "14px",
                  fontFamily: "nunito",
                  color: TEXT_COLOR.BLUE,
                  fontWeight: "550",
                },
              }}
            />
            <Grid>
              {errors && errors.email && (
                <ErrorMessage
                  style={{ width: "268px" }}
                  message={errors.email.message}
                />
              )}
            </Grid>
          </Grid>
          <Grid item>
            <InputLabel className={classes.inputLabel}>Password</InputLabel>
          </Grid>
          <Grid>
            <TextFieldComponent
              onKeyDown={handleKeyPressed}
              inputRef={register}
              name="password"
              size="small"
              variant="outlined"
              placeholder="Enter Password"
              styleProps={{
                width: "268px",
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    >
                      {showPassword ? (
                        <Visibility fontSize="small" className={classes.icon} />
                      ) : (
                        <VisibilityOff
                          fontSize="small"
                          className={classes.icon}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              type={showPassword ? "text" : "password"}
              inputProps={{
                style: {
                  height: "14px",
                  fontSize: "14px",
                  fontFamily: "nunito",
                  color: TEXT_COLOR.BLUE,
                  fontWeight: "550",
                },
              }}
              error={errors.password ? true : false}
            />
            <Grid>
              {errors && errors.password && (
                <ErrorMessage
                  style={{ width: "268px" }}
                  message={errors.password.message}
                />
              )}
            </Grid>
          </Grid>
          <Grid
            item
            className={`${classes.forgotPasswordDiv} ${classes.marginBottom}`}
          >
            <button
              className={classes.forgotPassword}
              onClick={() => {
                setOpenDialog(true);
              }}
            >
              Forgot Password?
            </button>
          </Grid>
          <Grid item className={`${classes.marginBottom}`}>
            <SigninButton
              label={disableLogin ? "Logging in..." : "Login"}
              handleClick={handleSubmit(handleLogin)}
              disabled={disableLogin}
            />
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            className={`${classes.marginBottom}`}
          >
            <Grid item>
              <Typography className={classes.text}>
                Don't have an account?
              </Typography>
            </Grid>
            <Grid item>
              <Link to={routes.SIGN_UP} style={{ textDecoration: "none" }}>
                <Typography className={classes.link}>Sign Up Now</Typography>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </SigninCard>
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <ForgotPassword onClose={handleDialogClose} />
      </Dialog>
    </>
  );
}
