import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Icon from "@mdi/react";
import useStyles from "./styles";
import { mdiFire } from "@mdi/js";
import { TEXT_COLOR } from "../../constants/theme";

const StyledFormControlLabel = withStyles({
  root: {
    '& .MuiTypography-root': {
      flex: 1
    }
  }
})(FormControlLabel);

const feeOptions = [
  {
    value: 0.25,
    label: "0.250 fils",
  },
  {
    value: 0.5,
    label: "0.500 fils",
  },
  {
    value: 0.75,
    label: "0.750 fils",
  },
  {
    value: 1.0,
    label: "1.000 KWD",
  },
  {
    value: 0,
    label: "Do not share delivery fee",
  },
];

function SelectDeliveryFee({ selectFee, setDeliveryFee, deliveryFee }) {
  const uid = useSelector(({ authState }) => authState.user?.uid);

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid
        container
        direction="column"
        spacing={3}
        alignItems="center"
        justify="center"
        className={classes.gridContainer}
      >
        <Grid item style= {{padding: "15px 0",
          margin: "10px 0"}}>
          <Typography
            variant="body1"
            component="span"
            className={classes.textElement}
          >
            Increase your sales by sharing the delivery cost with your customers
          </Typography>
        </Grid>
        <Grid fullWidthContainerMobile><Typography>Please use a desktop to add your delivery fee sharing</Typography></Grid>
        <FormControl component="fieldset">                      
        <RadioGroup
          value={deliveryFee?deliveryFee:""}
          onChange={(event) => {
            event.preventDefault();
            event.stopPropagation();
            const value = event.target.value;
            setDeliveryFee(parseFloat(value));
            selectFee(parseFloat(value));
          }}
          className={classes.fullWidthContainer}
        >
          {feeOptions.map((item) => (
            <StyledFormControlLabel
              className={`${classes.labelContainer} ${classes.feeButtonBorder}`}
              style={{
                border: `1px solid ${
                  item.value === deliveryFee ?  TEXT_COLOR.ACTIVE :  TEXT_COLOR.BACKGROUND
                }`,
              }}
              key={`option-row-${item.value}`}
              value={item.value}
              label={
                <Grid
                  container
                  direction="row"
                  className={`${classes.fullWidthContainer} ${classes.justifySpaceBetween}`}
                >
                  <Typography align="left">{item.label}</Typography>
                  <div className={classes.flexBox}>
                  <Typography align="right"
                    className={classes.title}
                  >
                    {item.value === 1 ? "Increased sales" :item.value === 0 ?"Not recommended": ""}
                  </Typography>
                  {item.value === 1 ? (
                    <Icon
                      path={mdiFire}
                      style={{ alignSelf: "center" }}
                      size="18px"
                      color={TEXT_COLOR.ORANGE}
                    />
                  ) : (
                    ""
                      )}
                    </div>
                </Grid>
              }
              control={
                <Radio
                  style={{
                    color: `${
                      item.value === deliveryFee ?  TEXT_COLOR.ACTIVE :  TEXT_COLOR.BACKGROUND                    }`,
                  }}
                  checked={item.value === deliveryFee}
                />
              }
            />
          ))}
        </RadioGroup>
        </FormControl>
      </Grid>
    </div>
  );
}

export default SelectDeliveryFee;
