import { Button, withStyles } from '@material-ui/core';
import React from 'react';
import { TEXT_COLOR } from '../../../constants/theme';
import useStyles from './styles';

const ProceedToNext = withStyles({
    root: {
        background: TEXT_COLOR.ACTIVE,
        textTransform: "uppercase",
        color: 'white',
        fontFamily: "Nunito",
        ":disabled": {
            backgroundColor: "red"
          }
    },
    disabled:{
        backgroundColor: "grey"
    }
})(Button);

const SkipForNow = withStyles({
    root: {
        textTransform: "uppercase",
        color: TEXT_COLOR.DEFAULT,
        fontFamily: "Nunito"
    }
})(Button);

const Back = withStyles({
    root: {
        borderColor: TEXT_COLOR.ACTIVE,
        color: TEXT_COLOR.ACTIVE,
        textTransform: 'uppercase',
        fontFamily: "Nunito"
    }
})(Button)

function Footer({ move, disableProceedToNext, backButtonDisabled, step }) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className={`${classes.btnContainer} ${classes.flexStart}`}>
                <Back disabled={backButtonDisabled} variant='outlined' disableElevation onClick={() => {
                    move({
                        increament: -1,
                        type: 'Back'
                    })
                }}
                >Back</Back>
            </div>
            {step !== 2 ?
            <div className={`${classes.btnContainer} ${classes.flexEnd} ${classes.marginRight}`}>
                <SkipForNow disableElevation onClick={() => {
                    move({
                        increament: 1,
                        type: 'Skip'
                    })
                }}
                >Skip for now</SkipForNow>
            </div>
            :null}
            {step === 2 ? <div className={`${classes.finishButton} `}>
                <ProceedToNext className={disableProceedToNext || step === 2 ? classes.disabled : null} variant='contained' disableElevation disabled={disableProceedToNext} onClick={() => {
                    move({
                        increament: 1,
                        type: 'Proceed'
                    })
                }}>Finish</ProceedToNext>
            </div> :
                <div className={`${classes.nextButton} `}>
                    <ProceedToNext className={disableProceedToNext || step === 2 ? classes.disabled : null} variant='contained' disableElevation disabled={disableProceedToNext} onClick={() => {
                        move({
                            increament: 1,
                            type: 'Proceed'
                        })
                    }}>Next</ProceedToNext>
                </div>}
            
        </div>
    )
}

export default Footer;