import { makeStyles } from "@material-ui/core";
import { TEXT_COLOR } from "../../constants/theme";

const useStyles = makeStyles({
    root: {
        padding: 15,
    },
    close: {
        position: 'absolute',
        right: 15,
        top: 15,
        cursor: 'pointer'
    },
    textField: {
        width: '100%'
    },
    sendMail: {
        background: '#2F62FF',
        color: TEXT_COLOR.ACTIVE
    },
    error: {
        color: TEXT_COLOR.ERROR,
        fontSize: 12,
        letterSpacing: 0.5,
        wordSpacing: 1
    }
});

export default useStyles;