import {
  Avatar,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Typography,
  withStyles,
  TextField, 
  styled,
  InputAdornment,
} from "@material-ui/core";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import {
  mdiClose,
  mdiCheck,
  mdiMagnify,
  mdiCheckCircleOutline,
  mdiCloseCircleOutline,
  mdiPlus,
  mdiMinus,
  mdiFileReplaceOutline,
} from "@mdi/js";
import { useDispatch, useSelector } from "react-redux";
import Icon from "@mdi/react";
import React, { useState, useEffect } from "react";
import { ReplaceProducts } from "../../../../assets/images";
import { DividerComponent } from "../../../../components/Divider";
import { TEXT_COLOR } from "../../../../constants/theme";
import { useStyles } from "./styles";
import {
  cancelOrderAction,
  changeOrderItemStatus,
  changeOrderStatuses,
} from "../../../../actions/orderActions";
import firebase, { DATABASE } from "../../../../config/fbConfig";
import {
  docNames,
  itemStatusEnum,
  orderSubStatuses,
  primaryStatus,
  timelineTypes,
} from "../../../../constants/firebaseDoc";
import { StyledRadio } from "../../../../components/CustomSwitch";
import { formatString } from "../../../../utils/functions/formatString";
import { formattedPrice } from "../../../../utils/functions/formatPrice";
import { BASE_URL } from "../../../../config/baseUrlConfig";
import CustomerDialogContent from "./Dialog/index";
import algoliasearch from 'algoliasearch/lite';

export const StyledButton = withStyles({
  root: {
    borderRadius: "20px",
    textTransform: "none",
  },
})(Button);


export const StyledTextField = styled(TextField, {
  shouldForwardProp: (props) => props !== "focusColor"
})((p) => ({
  // input label when focused
  "& label.Mui-focused": {
    color: p.focusColor
  },
  // focused color for input with variant='standard'
  "& .MuiInput-underline:after": {
    borderBottomColor: p.focusColor
  },
  // focused color for input with variant='filled'
  "& .MuiFilledInput-underline:after": {
    borderBottomColor: p.focusColor
  },
  // focused color for input with variant='outlined'
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: p.focusColor
    }
  }
}));

export function OrderDetails({ expand, order, comingFrom, openAddItemDialog, setOpenAddItemDialog }) {
  const classes = useStyles();
  return (
    <Collapse in={expand} className={`${classes.root}`}>
      {order.orderInfo.items.map((item, key) => {
        return (
          <div key={key} className={`${classes.marginBottom} `}>
            <OrderItems
              openAddItemDialog={openAddItemDialog}
              setOpenAddItemDialog={setOpenAddItemDialog}
              item={item}
              itemId={key}
              order={order}
              comingFrom={comingFrom}
            />
          </div>
        );
      })}
      <div className={classes.buddyDetailsCtn}>
        <Typography
          className={`${classes.buttonLabel} ${classes.marginRight} ${classes.labelFont}`}
        >
          Driver
        </Typography>

        <div className={classes.root}>
          {order.deliveryInfo && order.deliveryInfo.buddyName?
          <Typography
            className={`${classes.buddyDetails} ${classes.marginRight} ${classes.labelFont}`}
          >
            {order.deliveryInfo && order.deliveryInfo.buddyName}
          </Typography>:null}
          {comingFrom === 0 ? (
            ""
          ) : (
            order.deliveryInfo && order.deliveryInfo.trackingLink?
            <Link
              target="_blank"
              href={order.deliveryInfo &&  order.deliveryInfo.trackingLink}
              onClick={(event) => event.preventDefault}
            >
              <Typography
                className={`${classes.link} ${classes.marginRight} ${classes.labelFont}`}
              >
                Track 
              </Typography>
            </Link>:null
          )}
        </div>

        <Typography className={`${classes.buddyDetails} ${classes.labelFont}`}>
          {order.deliveryInfo && order.deliveryInfo.buddyPhone}
        </Typography>
        <div className={`${classes.alignRight} ${classes.root}`}>
          {/* <Typography
            align="left"
            className={`${classes.buttonLabel} ${classes.labelFont} ${classes.marginBottom}`}
          >
            Invoice order
          </Typography> */}
          <Typography
            align="left"
            className={`${classes.buddyDetails} ${classes.labelFont} ${classes.marginBottom}`}
          >
            Total Price
          </Typography>
          <Typography
            align="left"
            className={`${classes.buddyDetails} ${classes.labelFont} ${classes.marginBottom}`}
          >
            Delivery fees
          </Typography>
          <Typography
            align="left"
            className={`${classes.buttonLabel} ${classes.labelFont} ${classes.marginBottom}`}
          >
            Total
          </Typography>
        </div>
        <div className={classes.root}>
          {/* <Typography
            align="left"
            className={`${comingFrom===0?classes.buddyDetails:classes.link} ${classes.labelFont} ${classes.marginBottom}`}
          >
            Invoice link
          </Typography> */}
          <Typography
            align="left"
            className={`${classes.buddyDetails} ${classes.labelFont} ${classes.marginBottom}`}
          >
            {`${formattedPrice(comingFrom === 0 ? order.orderInfo.items.filter((item) => item.status === itemStatusEnum.CONFIRMED).reduce((total, item ) => total + (item.price * item.quantity), 0)  : order.orderInfo.itemTotal)} KWD`}
          </Typography>
          <Typography
            align="left"
            className={`${classes.buddyDetails} ${classes.labelFont} ${classes.marginBottom}`}
          >
            {`${formattedPrice(order.orderInfo.deliveryFee)} KWD`}
          </Typography>
          <Typography
            align="left"
            className={`${classes.buttonLabel} ${classes.labelFont} ${classes.marginBottom}`}
          >
            {formattedPrice(comingFrom === 0 ? order.orderInfo.items.filter((item) => item.status === itemStatusEnum.CONFIRMED).reduce((total, item) => total + (item.price * item.quantity), 0) + order.orderInfo.deliveryFee : order.orderInfo.itemTotal + order.orderInfo.deliveryFee)}
          </Typography>
        </div>
      </div>
    </Collapse>
  );
}

function OrderItems({ item, order, itemId, comingFrom, openAddItemDialog, setOpenAddItemDialog }) {
  const dispatch = useDispatch();
  const { uid } = useSelector(({ authState }) => authState.user);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null); //rejection menu
  const [itemStatus, setItemStatus] = useState(item.status ? item.status : "");
  const [openReplaceDialog, setOpenReplaceDialog] = useState(false);
  const [barcode, setBarcode]= useState("");
  const brandConfig = useSelector(({ brandSettingState }) => brandSettingState?.brandConfig);
  const [productList, setProductList] = useState([]);
  const { defaultBranchId } = useSelector(
    ({ defaultBranchState }) => defaultBranchState.defaultBranch
  );
  useEffect(()=>{ 
    const list =[];
    const query = DATABASE.collection(docNames.SELLER).doc(uid)
    .collection(docNames.BRANCHES).doc(defaultBranchId)
    .collection(docNames.PRODUCT).limit(20).where("availability", "==", true)
    .where("verificationStatus", "==", "VERIFIED").get().then(docs=>{
      docs.forEach(doc=>{
        list.push({...doc.data(), id: doc.id});
      });
    });
    setProductList(list)
    const index = productList.findIndex(
      (product) => product.name === item.name
    );
      setBarcode(productList[index]?.barcode)
  },[item, openReplaceDialog]);

  const handleCloseRejectionMenu = () => {
    setAnchorEl(null);
  };
  const handleReplaceDialogClose = () => {
    setOpenReplaceDialog(false);
  };
  const handleAddItemDialogClose = () =>{
    setOpenAddItemDialog(false)
  }
  const handleStatusChange = async(status, reason) => {
    const items = [...order.orderInfo.items];
    items[itemId].status = status;
    if(status === "REJECTED"){
      items[itemId].isCancelled = true;
    }
    if (status === itemStatusEnum.REJECTED) {
      items[itemId].comment = reason;
    }
    dispatch(
      changeOrderItemStatus({
        orderNo: order.orderNo,
        uid: order.id,
        items: items,
        userUid:uid,
        handleItemStatusChange: ()=>handleItemStatusChange(reason),
      })
    );
    if (status === itemStatusEnum.REJECTED) {
      const amount = items[itemId].price * items[itemId].quantity;
      const originalTotalAmount = order.orderInfo.items.reduce((total, item ) => total + (item.price * item.quantity), 0)

      //Reclculate the promo code discount after item replacement
      let discount = 0;
      if(order.orderInfo.promoCode && order.orderInfo.promoCode.title){

        const promoCodeResponse = await fetch(`${BASE_URL}/applyPromoCode`, {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/json'
          }),
          body: JSON.stringify(
            {
              "title": order.orderInfo.promoCode.title,
              "serviceFee": order.orderInfo.serviceFee,
              "deliveryFee": order.orderInfo.deliveryFee,
              "itemTotal": formattedPrice(order.orderInfo.itemTotal - amount),
            }
          )
        }).then((res) => res.json())
          discount = promoCodeResponse.discount;
      }

      const schema = {
        "orderInfo.itemTotal": formattedPrice(order.orderInfo.itemTotal - amount),
        "orderInfo.itemCount": order.orderInfo.itemCount - 1,
        "orderInfo.itemQuantity": order.orderInfo.itemQuantity - items[itemId].quantity,
        "orderInfo.totalAmount": formattedPrice(order.orderInfo.itemTotal - amount) + order.orderInfo.deliveryFee - discount,
        "orderInfo.discount": discount
      };
      dispatch(changeOrderStatuses({ id: order.id, orderNo:order.orderNo,schema, uid }));
    }
  };

  useEffect(() => {
    setItemStatus(item.status === undefined ? "" : item.status);
  }, [item.status]);

  const cancelOrder = async (reason) => {
    const cancelOrderSchema = {
      cancelledAt: firebase.firestore.FieldValue.serverTimestamp(),
      reason: `SELLER_REJECTED - ${reason}`,
      cancelledBy: {
        name: brandConfig.brandName || "",
        id: uid,
      },
    };
    const triggerEventSchema = {
      orderId: order.id,
      orderNo: order.orderNo,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      type: timelineTypes.ORDER_STATUS,
      name: orderSubStatuses.CANCELLED,
      actorId: uid,
      actorType: timelineTypes.VENDOR,
      agentId: order.agent?order.agent.id:"",
      agentName: order.agent?order.agent.name:"",
      referenceId: "",
      referenceCollection: "",
      comment: reason,
    };
    const rawResponse = await fetch(`${BASE_URL}/omsOrderComplete`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(
        {
          "orderNo": order.orderNo,
          action: "CANCELLATION",
        }
      )
    })
    dispatch(
      cancelOrderAction({
        cancelOrderSchema: cancelOrderSchema,
        id: order.id,
        uid,
        data: triggerEventSchema,
        agentId: order.agent?order.agent.id:'',
      })
    );
    const schema = {
      "orderInfo.itemTotal": order.orderInfo.itemTotal,
      "orderInfo.itemCount": order.orderInfo.itemCount,
      "orderInfo.itemQuantity": order.orderInfo.itemQuantity,
      "orderInfo.deliveryFee": order.orderInfo.deliveryFee,
      // subStatus: orderSubStatuses.SELLER_REJECTED,
    };
    dispatch(changeOrderStatuses({ id: order.id, orderNo:order.orderNo, schema, uid }));
  };

  const handleItemStatusChange = (reason) => {
    const items = [...order.orderInfo.items];
    if (
      items !== [] &&
      items.every((item) => item.status === itemStatusEnum.REJECTED)
    ) {
      cancelOrder(reason);
    } else if (
      items !== [] &&
      items.every(
        (item) =>
          item.status === itemStatusEnum.CONFIRMED ||
          item.status === itemStatusEnum.REJECTED ||
          item.status === itemStatusEnum.REPLACED
      )
    ) {
      const schema = {
        primaryStatus: primaryStatus.PENDING,
        subStatus: orderSubStatuses.SELLER_ORDER_ACCEPTED,
        "timestamps.sellerOrderAcceptedAt": firebase.firestore.FieldValue.serverTimestamp(),
      };

      dispatch(changeOrderStatuses({ id: order.id,orderNo:order.orderNo, schema,uid }));
    }
  };

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      style={{
        opacity:
          comingFrom === 0 && item.status === itemStatusEnum.REJECTED ? 0.6 : 1,
      }}
      className={`${classes.container} ${classes.fullWidth}`}
    >
      <Grid  className={`${classes.checkox}`}>
        <Grid item className={`${classes.flexOne}`}>
          <Checkbox
            disableRipple
            icon={
              <CheckBoxOutlineBlank
                className={`${classes.uncheckedBodyIcon}`}
                fontSize="small"
              />
            }
            checkedIcon={
              <CheckBox className={classes.checked} fontSize="small" />
            }
            size="small"
          />
        </Grid>
      </Grid>
      <Grid item className={`${classes.flexOne}`}>
        <Avatar className={classes.avatar} src={item.imageUrl} />
      </Grid>
      <Grid item style={{display:"flex"}} className={`${classes.flexTen}`}>
        <Grid item className={`${classes.flexFour}`}>
          <Typography className={classes.headerContent}>
            {item.name ? formatString(item.name) : "-"}
          </Typography>
        </Grid>
        {comingFrom === 0 ?
          <Grid item className={`${classes.flexFour}`}>
            <Typography className={classes.headerContent}>
              {item.status ? formatString(item.status) : "-"}
            </Typography>
          </Grid>:null}
        <Grid item className={`${classes.flexfour}`}>
          <Typography className={classes.headerContent}>
            {barcode ? barcode : "-"}
          </Typography>
        </Grid>
      </Grid>
      
      <Grid item style={{display:"flex", marginRight:"15wv"}} className={`${classes.flexTen}`}>
        <Grid item className={`${classes.flexTen}`}>
          <Typography
            className={classes.headerContent}
          >{`${formattedPrice(item.price)} KWD`}</Typography>
        </Grid>
        <Grid item className={`${classes.flexTen}`}>
          <Typography className={classes.headerContent}>
            x{item.quantity}
          </Typography>
        </Grid>
        <Grid item className={`${classes.flexTen}`}>
          <Typography className={classes.headerContent}>
            {`${
              isNaN(formattedPrice(item.price * item.quantity)) ? "-" : formattedPrice(item.price * item.quantity)
            } KWD`}
          </Typography>
        </Grid>
      </Grid>
      
      {!itemStatus && comingFrom !== 0 ? (
        <Grid item className={`${classes.flexSix}`}>
          <Grid container direction="row" className={classes.end}>
            <Grid item className={`${classes.iconButton}`}>
              <StyledButton
                startIcon={<Icon path={mdiCheck} size="12px" color={TEXT_COLOR.WHITE} />}
                className={classes.checkIcon}
                size="small"
                onClick={() => handleStatusChange(itemStatusEnum.CONFIRMED)}
              >
                <Typography
                  className={`${classes.buttonLabel} ${classes.buttonFont}`}
                  style={{ color: TEXT_COLOR.WHITE }}
                >
                  Confirm
                </Typography>
              </StyledButton>
            </Grid>
            <Grid item className={`${classes.iconButton}`}>
              <StyledButton
                onClick={() => setOpenReplaceDialog(true)}
                variant="outlined"
                startIcon={
                  <Icon
                    path={mdiFileReplaceOutline}
                    size="12px"
                    color={TEXT_COLOR.DEFAULT}
                  />
                }
                className={classes.replaceIcon}
                size="small"
              >
                <Typography
                  className={`${classes.buttonLabel} ${classes.buttonFont}`}
                  style={{ color: TEXT_COLOR.DEFAULT }}
                >
                  Replace
                </Typography>
              </StyledButton>
            </Grid>
            <Grid item className={`${classes.iconButton}`}>
              <StyledButton
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                }}
                startIcon={<Icon path={mdiClose} size="12px" color={TEXT_COLOR.ERROR} />}
                className={classes.rejectIcon}
                size="small"
              >
                <Typography
                  className={`${classes.buttonLabel} ${classes.buttonFont}`}
                  style={{ color: TEXT_COLOR.ERROR }}
                >
                  Reject
                </Typography>
              </StyledButton>
            </Grid>
          </Grid>
        </Grid>
      ) : itemStatus === itemStatusEnum.CONFIRMED || itemStatus === itemStatusEnum.NEW_ITEM? (
        <Grid item className={`${classes.flexSix}`}>
          <Icon
            path={mdiCheckCircleOutline}
            size="16px"
            color={TEXT_COLOR.ACTIVE}
            className={classes.alignIcon}
          />
        </Grid>
      ) : itemStatus === itemStatusEnum.REJECTED ? (
        <Grid item className={`${classes.flexSix}`}>
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <Icon
                path={mdiCloseCircleOutline}
                size="16px"
                color={TEXT_COLOR.ERROR}
                className={classes.alignIcon}
              />
            </Grid>
            <Grid item style={{ marginRight: "4px", marginLeft: "8px" }}>
              <Typography
                className={`${classes.buddyDetails} ${classes.labelFont}`}
              >
                {item.comment}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      ) : itemStatus === itemStatusEnum.REPLACED ? (
        <Grid item className={`${classes.flexSix}`}>
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <Icon
                path={mdiFileReplaceOutline}
                size="16px"
                color={TEXT_COLOR.DEFAULT}
              />
            </Grid>
            <Grid item style={{ marginRight: "4px", marginLeft: "8px" }}>
              <Typography
                className={`${classes.buddyDetails} ${classes.labelFont}`}
              >
                Replaced
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid item className={`${classes.flexSix}`}></Grid>
      )}
      <RejectionMenu
        anchorEl={anchorEl}
        handleCloseMenu={handleCloseRejectionMenu}
        hanldeRejectItem={(reason) =>
          handleStatusChange(itemStatusEnum.REJECTED, reason)
        }
        item={item}
        order={order}
        itemId={itemId}
      />
      <ReplaceDialog
        productList={productList}
        setProductList={setProductList}
        open={openReplaceDialog}
        handleClose={handleReplaceDialogClose}
        item={item}
        order={order}
        itemId={itemId}
        handleItemStatusChange={handleItemStatusChange}
      />
      <AddItemDialog
        productList={productList}
        setProductList={setProductList}
        openAddItemDialog={openAddItemDialog}
        handleClose={handleAddItemDialogClose}
        item={item}
        order={order}
        itemId={itemId}
        handleItemStatusChange={handleItemStatusChange}
      />
    </Grid>
  );
}
function RejectionMenu({ anchorEl, handleCloseMenu, hanldeRejectItem, item, order, itemId }) {
  const classes = useStyles();
  const [openRejectionDialog,setOpenRejectionDialog] = useState(false)
  const [calledCustomer, setCalledCustomer] = useState(false);

  const handleDialogOpen = () => {
    setOpenRejectionDialog(true);
  };
  const handleDialogClose = () => {
    setOpenRejectionDialog(false);
  };
  const handleRequest = () => {
    setCalledCustomer(true);
    setOpenRejectionDialog(false);
    handleRejection("Item not available");
  };
  const handleRejection = (reason) => {
    hanldeRejectItem(reason);
    handleCloseMenu();
  };
  return (
    <div>
    <Menu
      anchorEl={anchorEl}
      elevation={0}
      open={Boolean(anchorEl)}
      onClose={handleCloseMenu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
    >
      <MenuItem
        onClick={() => handleDialogOpen(true)}
        className={`${classes.menuItem} ${classes.labelFont}`}
      >
        Item not available
      </MenuItem>
    </Menu>
    <Dialog
      open={openRejectionDialog}
      onExited={() => setCalledCustomer(false)}
      onClose={handleDialogClose}
    >
        <DialogContent
          className={classes.dialog}
        >
          <CustomerDialogContent order={order} confirm={handleRequest}/>
        </DialogContent>
    </Dialog>
    </div>
  );
}

function ReplaceDialog({
  open,
  handleClose,
  item,
  order,
  itemId,
  productList,
  setProductList,
  handleItemStatusChange,
}) {
  const { uid } = useSelector(({ authState }) => authState.user);
  const { defaultBranchId } = useSelector(
    ({ defaultBranchState }) => defaultBranchState.defaultBranch
  );
  const classes = useStyles();
  const dispatch = useDispatch();
  const [index, setIndex] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [calledCustomer, setCalledCustomer] = useState(false);
  const [qty, setQty] = useState(1);
  // const [productList, setProductList] = useState([]);

  // useEffect(()=>{ 
  //   const list =[];
  //   const query = DATABASE.collection(docNames.SELLER).doc(uid)
  //   .collection(docNames.BRANCHES).doc(defaultBranchId)
  //   .collection(docNames.PRODUCT).limit(20).where("availability", "==", true)
  //   .where("verificationStatus", "==", "VERIFIED").get().then(docs=>{
  //     docs.forEach(doc=>{
  //       list.push({...doc.data(), id: doc.id});
  //     });
  //   });
  //   setProductList(list);
  // },[]);
  const handleReplaceRequestCompleted = () => {
    setCalledCustomer(true);
  };
  const [replacedOrder, setReplacedOrder] = useState(null);
  const handleDialogClose = () => {
    handleClose();
    setProductList([]);
    setSearchQuery("");
  };

  const getSearchKeys = async () => {
    const configDoc = await DATABASE.collection("config").doc("algolia_search").get();
    const config = configDoc.data();
    const searchApiKey = config.SEARCH_API_KEY;
    const indexName = config.products_index_name;
    const appId = config.APP_ID;
    const searchClient = algoliasearch(appId, searchApiKey);
    
    const index = searchClient.initIndex(indexName);
    
    setIndex(index);
  }

  useEffect(() => {
    getSearchKeys();
  }, []);

  const search = async () => {
    const result = await index.search(searchQuery.toString(), {
      filters: `branchId:${defaultBranchId} AND availability:${true} AND verificationStatus:VERIFIED`
    });
    const hits = result.hits;
    const filtredHitsList = [];
    hits.forEach(hit => {
      filtredHitsList.push({
        "id": hit.objectID,
        "descriptionAr": hit.descriptionAr,
        "description": hit.description,
        "imageUrl": hit.imageUrl,
        "price": hit.price,
        "barcode": hit.barcode || null,
        "nameAr": hit.nameAr,
        "name": hit.name,
        "category": hit.categoryObj
      })
    });
    
    if (filtredHitsList.length > 0) {
      setProductList(filtredHitsList);
    } else {
      setProductList(productList);
    }
  }

  useEffect(() => {
    if (productList.length > 0) {
      const index = productList.findIndex(
        (product) => product.name === item.name
      );
      setReplacedOrder(index);
      setQty(item.quantity);
    }
  }, [productList, item]);

  const handleReplaceOrder = (key) => {
    if (replacedOrder === key) {
      setReplacedOrder(null);
    } else setReplacedOrder(key);
  };

  const handleQty = (type) => {
    if (type === "decrement") {
      if (qty !== 0) {
        setQty((prevValue) => prevValue - 1);
      }
    } else {
      setQty((prevValue) => prevValue + 1);
    }
  };

  const handleReplaceOrderDb = async() => {
    if (replacedOrder !== null && qty !== null) {
      
      const items = [...order.orderInfo.items];
      items[itemId].status = itemStatusEnum.REPLACED;
      items[itemId].isCancelled = true;
      items.push({
        name: productList[replacedOrder].name,
        description: productList[replacedOrder].description,
        imageUrl: productList[replacedOrder].imageUrl,
        price: formattedPrice(productList[replacedOrder].price),
        quantity: qty,
        variants: null,
        id: productList[replacedOrder].id,
        status: itemStatusEnum.CONFIRMED,
      });
      const amount = items[itemId].price * items[itemId].quantity;
      const newReplacedItem = productList[replacedOrder].price * qty;
      const oldReplacedItem = items[itemId].price * items[itemId].quantity;
      const totalAmountAfterReplacement = (order.orderInfo.itemTotal + formattedPrice(newReplacedItem - oldReplacedItem));

      //Reclculate the promo code discount after item replacement
      let discount = 0;
      if(order.orderInfo.promoCode && order.orderInfo.promoCode.title){

        const promoCodeResponse = await fetch(`${BASE_URL}/applyPromoCode`, {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/json'
          }),
          body: JSON.stringify(
            {
              "title": order.orderInfo.promoCode.title,
              "serviceFee": order.orderInfo.serviceFee,
              "deliveryFee": order.orderInfo.deliveryFee,
              "itemTotal": totalAmountAfterReplacement
            }
          )
        }).then((res) => res.json())
          discount = promoCodeResponse.discount;
      }
      
      const totalAmount =  totalAmountAfterReplacement + order.orderInfo.serviceFee + order.orderInfo.deliveryFee - discount;

      const schema = {
        "orderInfo.itemTotal": totalAmountAfterReplacement,
        "orderInfo.itemQuantity": order.orderInfo.itemQuantity - items[itemId].quantity + qty,
        "orderInfo.totalAmount": totalAmount,
        "orderInfo.discount": discount
      };
      dispatch(
        changeOrderItemStatus({
          orderNo: order.orderNo,
          uid: order.id,
          items: items,
          userUid:uid,
          handleItemStatusChange: ()=>handleItemStatusChange()
        })
      );
      dispatch(changeOrderStatuses({ id: order.id, orderNo:order.orderNo,schema, uid }));
      handleDialogClose();
    }
  };
  return (
    <Dialog
      open={open}
      onExited={() => setCalledCustomer(false)}
      onClose={handleDialogClose}
    >
      {calledCustomer ? (
        <DialogContent
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
          className={classes.replaceDialog}
        >
          <Typography
            align="left"
            className={`${classes.buttonLabel} ${classes.headingFont}`}
          >
            Replace Product
          </Typography>
          <Grid>
            <StyledTextField
              id="outlined-select-gender"
              label={""}
              autoFocus={true}
              value={searchQuery}
              onKeyUp={search}
              onChange={(event) => {
                const value = event.target.value;
                setSearchQuery(event.target.value);
                //setFilterProduct(value);
              }}
              InputLabelProps={{ shrink: false }}
              margin="normal"
              variant="outlined"
              focusColor={TEXT_COLOR.ACTIVE}
              fullWidth
              InputProps={{
                style: { height: "32px" },
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon
                      path={mdiMagnify}
                      size="16px"
                      color={TEXT_COLOR.ACTIVE}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid
            container
            direction="row"
            alignItems="center"
            style={{
              backgroundColor: TEXT_COLOR.BACKGROUND,
              display: "flex",
              height: "32px",
            }}
            className={classes.marginBottom}
          >
            <Grid item className={classes.flexThree}>
              <Typography className={`${classes.headerContent}`}>
                Product name
              </Typography>
            </Grid>
            <DividerComponent height="32px" color={TEXT_COLOR.WHITE} />
            <Grid item className={`${classes.flexOne}`}>
              <Typography
                className={`${classes.headerContent} ${classes.flexOne}`}
              >
                Image
              </Typography>
            </Grid>
            <DividerComponent height="32px" color={TEXT_COLOR.WHITE} />
            <Grid item className={` ${classes.flexTwo}`}>
              <Typography
                className={`${classes.headerContent} ${classes.flexTwo}`}
              >
                Price
              </Typography>
            </Grid>
            <DividerComponent height="32px" color={TEXT_COLOR.WHITE} />
            <Grid item className={` ${classes.flexThree}`}>
              <Typography
                className={`${classes.headerContent} ${classes.flexThree}`}
              >
                Category
              </Typography>
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              maxHeight: "30vh",
              overflowY: "auto",
              flexDirection: "column",
            }}
          >
            {productList && productList.map((product, key) => {
              return (
                <Grid
                  key={product.id}
                  className={`${classes.marginBottom}`}
                  container
                  direction="row"
                  alignItems="center"
                  style={{
                    backgroundColor:
                      replacedOrder === key ? "#EAFBF9" : TEXT_COLOR.WHITE,
                    border: `1px solid ${
                      replacedOrder === key
                        ? TEXT_COLOR.ACTIVE
                        : TEXT_COLOR.BACKGROUND
                    }`,
                    borderRadius: "4px",
                  }}
                >
                  <Grid item className={classes.flexOne}>
                    <StyledRadio
                      checked={replacedOrder === key}
                      onChange={() => handleReplaceOrder(key)}
                    />
                  </Grid>
                  <Grid item className={classes.flexTwo}>
                    <Typography className={`${classes.headerContent}`}>
                      {product.name ? formatString(product.name) : "-"}
                    </Typography>
                  </Grid>
                  <DividerComponent height="32px" color={TEXT_COLOR.WHITE} />
                  <Grid
                    item
                    className={`${classes.flexOne} ${classes.imageAlignment}`}
                  >
                    <Avatar src={product.imageUrl} className={classes.avatar} />
                  </Grid>
                  <DividerComponent height="32px" color={TEXT_COLOR.WHITE} />
                  <Grid item className={` ${classes.flexTwo}`}>
                    <Typography className={`${classes.headerContent}`}>
                      {`${product.price} KWD`}
                    </Typography>
                  </Grid>
                  <DividerComponent height="32px" color={TEXT_COLOR.WHITE} />
                  <Grid item className={` ${classes.flexThree}`}>
                    <Grid container direction="row" alignItems="center">
                      <Grid item>
                        <Typography className={`${classes.headerContent}`}>
                          {product.category?.categoryName
                            ? formatString(product.category?.categoryName)
                            : "-"}
                        </Typography>
                      </Grid>
                      {replacedOrder === key ? (
                        <Grid
                          item
                          className={classes.qty}
                          style={{ marginLeft: "auto", marginRight: "8px" }}
                        >
                          <Grid
                            container
                            direction="row"
                            alignItems="center"
                            justify="space-between"
                          >
                            <Grid item>
                              <IconButton
                                size="small"
                                disabled={qty === 1}
                                onClick={() => handleQty("decrement")}
                              >
                                <Icon
                                  path={mdiMinus}
                                  size="16px"
                                  className={classes.alignIcon}
                                />
                              </IconButton>
                            </Grid>
                            <Grid item>
                              <Typography
                                style={{ alignSelf: "center" }}
                                className={`${classes.labelFont}`}
                              >
                                {qty}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <IconButton
                                size="small"
                                onClick={() => handleQty("increment")}
                              >
                                <Icon
                                  path={mdiPlus}
                                  size="16px"
                                  className={classes.alignIcon}
                                />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </div>
          <Grid container direction="row">
            <Grid item>
              <StyledButton
                onClick={handleReplaceOrderDb}
                startIcon={
                  <Icon
                    path={mdiFileReplaceOutline}
                    size="12px"
                    color={TEXT_COLOR.WHITE}
                  />
                }
                style={{
                  backgroundColor: TEXT_COLOR.DEFAULT,
                  marginRight: "4px",
                  width: "64px",
                }}
                size="small"
              >
                <Typography
                  className={`${classes.buttonLabel} ${classes.buttonFont}`}
                  style={{ color: TEXT_COLOR.WHITE }}
                >
                  Replace
                </Typography>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                onClick={handleDialogClose}
                startIcon={
                  <Icon
                    path={mdiCloseCircleOutline}
                    size="12px"
                    color={TEXT_COLOR.DEFAULT}
                  />
                }
                style={{
                  backgroundColor: `${TEXT_COLOR.DEFAULT}00`,
                  border: `1px solid ${TEXT_COLOR.DEFAULT}`,
                  marginRight: "4px",
                  width: "64px",
                }}
                size="small"
              >
                <Typography
                  className={`${classes.buttonLabel} ${classes.buttonFont}`}
                  style={{ color: TEXT_COLOR.DEFAULT }}
                >
                  Cancel
                </Typography>
              </StyledButton>
            </Grid>
          </Grid>
        </DialogContent>
      ) : (
        <DialogContent
          className={classes.dialog}
        >
          <CustomerDialogContent order={order} confirm={handleReplaceRequestCompleted}/>
        </DialogContent>
      )}
    </Dialog>
  );
}

function AddItemDialog({
  openAddItemDialog,
  handleClose,
  productList,
  setProductList,
  item,
  order,
  itemId,
  handleItemStatusChange,
}) {
  const { uid } = useSelector(({ authState }) => authState.user);
  const { defaultBranchId } = useSelector(
    ({ defaultBranchState }) => defaultBranchState.defaultBranch
  );
  const classes = useStyles();
  const dispatch = useDispatch();
  const [index, setIndex] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [calledCustomer, setCalledCustomer] = useState(false);
  const [qty, setQty] = useState(null);
  
  const handleReplaceRequestCompleted = () => {
    setCalledCustomer(true);
  };

  const [addedItem, setAddedItem] = useState(null);
  const handleDialogClose = () => {
    handleClose();
    setProductList([]);
    setSearchQuery("");
  };

  const getSearchKeys = async () => {
    const configDoc = await DATABASE.collection("config").doc("algolia_search").get();
    const config = configDoc.data();
    const searchApiKey = config.SEARCH_API_KEY;
    const indexName = config.products_index_name;
    const appId = config.APP_ID;
    const searchClient = algoliasearch(appId, searchApiKey);
    
    const index = searchClient.initIndex(indexName);
    
    setIndex(index);
  }

  useEffect(() => {
    getSearchKeys();
  }, []);

  const search = async () => {
    const result = await index.search(searchQuery.toString(), {
      filters: `branchId:${defaultBranchId} AND availability:${true} AND verificationStatus:VERIFIED`
    });
    const hits = result.hits;
    const filtredHitsList = [];
     hits.forEach(hit=>{
      filtredHitsList.push({
        "id": hit.objectID,
        "descriptionAr": hit.descriptionAr,
        "description": hit.description,
        "imageUrl": hit.imageUrl,
        "price": hit.price,
        "barcode": hit.barcode || null,
        "nameAr": hit.nameAr,
        "name": hit.name,
        "category": hit.categoryObj
      })
    });
    if(filtredHitsList.length > 0){
      setProductList(filtredHitsList);
    }else{
      setProductList(productList);
    }
  }

  useEffect(() => {
    if (productList.length > 0) {
      const index = productList.findIndex(
        (product) => product.name === item.name
      );
      setAddedItem(index);
      setQty(item.quantity);
    }
  }, [productList, item]);

  const handleAddItem = (key) => {
    if (addedItem === key) {
      setAddedItem(null);
    } else setAddedItem(key);
  };

  const handleQty = (type) => {
    if (type === "decrement") {
      if (qty !== 0) {
        setQty((prevValue) => prevValue - 1);
      }
    } else {
      setQty((prevValue) => prevValue + 1);
    }
  };

  const handleAddItemDb = async() => {
    if (addedItem !== null && qty !== null) {
      
      const items = [...order.orderInfo.items];
      items.push({
        name: productList[addedItem].name,
        description: productList[addedItem].description,
        imageUrl: productList[addedItem].imageUrl,
        price: formattedPrice(productList[addedItem].price),
        quantity: qty,
        variants: null,
        id: productList[addedItem].id,
        status: itemStatusEnum.NEW_ITEM,
      });
      const newItem = productList[addedItem].price * qty;
      const totalAmountAfterReplacement = (order.orderInfo.itemTotal + formattedPrice(newItem));

      //Reclculate the promo code discount after item replacement
      let discount = 0;
      if(order.orderInfo.promoCode && order.orderInfo.promoCode.title){

        const promoCodeResponse = await fetch(`${BASE_URL}/applyPromoCode`, {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/json'
          }),
          body: JSON.stringify(
            {
              "title": order.orderInfo.promoCode.title,
              "serviceFee": order.orderInfo.serviceFee,
              "deliveryFee": order.orderInfo.deliveryFee,
              "itemTotal": totalAmountAfterReplacement
            }
          )
        }).then((res) => res.json())
          discount = promoCodeResponse.discount;
      }
      
      const totalAmount =  totalAmountAfterReplacement + order.orderInfo.serviceFee + order.orderInfo.deliveryFee - discount;

      const schema = {
        "orderInfo.itemTotal": totalAmountAfterReplacement,
        "orderInfo.itemQuantity": order.orderInfo.itemQuantity - items[itemId].quantity + qty,
        "orderInfo.totalAmount": totalAmount,
        "orderInfo.discount": discount
      };
      dispatch(
        changeOrderItemStatus({
          orderNo: order.orderNo,
          uid: order.id,
          items: items,
          userUid:uid,
          handleItemStatusChange: ()=>handleItemStatusChange()
        })
      );
      dispatch(changeOrderStatuses({ id: order.id, orderNo:order.orderNo,schema, uid }));
      handleDialogClose();
    }
  };
  return (
    <Dialog
      open={openAddItemDialog}
      onExited={() => setCalledCustomer(false)}
      onClose={handleDialogClose}
    >
      {calledCustomer ? (
        <DialogContent
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
          className={classes.replaceDialog}
        >
          <Typography
            align="left"
            className={`${classes.buttonLabel} ${classes.headingFont}`}
          >
            Add a new Product
          </Typography>
          <Grid>
            <StyledTextField
              id="outlined-select-gender"
              label={""}
              autoFocus={true}
              value={searchQuery}
              onKeyUp={search}
              onChange={(event) => {
                const value = event.target.value;
                setSearchQuery(event.target.value);
                //setFilterProduct(value);
              }}
              InputLabelProps={{ shrink: false }}
              margin="normal"
              variant="outlined"
              focusColor={TEXT_COLOR.ACTIVE}
              fullWidth
              InputProps={{
                style: { height: "32px" },
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon
                      path={mdiMagnify}
                      size="16px"
                      color={TEXT_COLOR.ACTIVE}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid
            container
            direction="row"
            alignItems="center"
            style={{
              backgroundColor: TEXT_COLOR.BACKGROUND,
              display: "flex",
              height: "32px",
            }}
            className={classes.marginBottom}
          >
            <Grid item className={classes.flexThree}>
              <Typography className={`${classes.headerContent}`}>
                Product name
              </Typography>
            </Grid>
            <DividerComponent height="32px" color={TEXT_COLOR.WHITE} />
            <Grid item className={`${classes.flexOne}`}>
              <Typography
                className={`${classes.headerContent} ${classes.flexOne}`}
              >
                Image
              </Typography>
            </Grid>
            <DividerComponent height="32px" color={TEXT_COLOR.WHITE} />
            <Grid item className={` ${classes.flexTwo}`}>
              <Typography
                className={`${classes.headerContent} ${classes.flexTwo}`}
              >
                Price
              </Typography>
            </Grid>
            <DividerComponent height="32px" color={TEXT_COLOR.WHITE} />
            <Grid item className={` ${classes.flexThree}`}>
              <Typography
                className={`${classes.headerContent} ${classes.flexThree}`}
              >
                Category
              </Typography>
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              maxHeight: "30vh",
              overflowY: "auto",
              flexDirection: "column",
            }}
          >
            {productList && productList.map((product, key) => {
              return (
                <Grid
                  key={product.id}
                  className={`${classes.marginBottom}`}
                  container
                  direction="row"
                  alignItems="center"
                  style={{
                    backgroundColor:
                    addedItem === key ? "#EAFBF9" : TEXT_COLOR.WHITE,
                    border: `1px solid ${
                      addedItem === key
                        ? TEXT_COLOR.ACTIVE
                        : TEXT_COLOR.BACKGROUND
                    }`,
                    borderRadius: "4px",
                  }}
                >
                  <Grid item className={classes.flexOne}>
                    <StyledRadio
                      checked={addedItem === key}
                      onChange={() => handleAddItem(key)}
                    />
                  </Grid>
                  <Grid item className={classes.flexTwo}>
                    <Typography className={`${classes.headerContent}`}>
                      {product.name ? formatString(product.name) : "-"}
                    </Typography>
                  </Grid>
                  <DividerComponent height="32px" color={TEXT_COLOR.WHITE} />
                  <Grid
                    item
                    className={`${classes.flexOne} ${classes.imageAlignment}`}
                  >
                    <Avatar src={product.imageUrl} className={classes.avatar} />
                  </Grid>
                  <DividerComponent height="32px" color={TEXT_COLOR.WHITE} />
                  <Grid item className={` ${classes.flexTwo}`}>
                    <Typography className={`${classes.headerContent}`}>
                      {`${product.price} KWD`}
                    </Typography>
                  </Grid>
                  <DividerComponent height="32px" color={TEXT_COLOR.WHITE} />
                  <Grid item className={` ${classes.flexThree}`}>
                    <Grid container direction="row" alignItems="center">
                      <Grid item>
                        <Typography className={`${classes.headerContent}`}>
                          {product.category.categoryName
                            ? formatString(product.category.categoryName)
                            : "-"}
                        </Typography>
                      </Grid>
                      {addedItem === key ? (
                        <Grid
                          item
                          className={classes.qty}
                          style={{ marginLeft: "auto", marginRight: "8px" }}
                        >
                          <Grid
                            container
                            direction="row"
                            alignItems="center"
                            justify="space-between"
                          >
                            <Grid item>
                              <IconButton
                                size="small"
                                disabled={qty === 0}
                                onClick={() => handleQty("decrement")}
                              >
                                <Icon
                                  path={mdiMinus}
                                  size="16px"
                                  className={classes.alignIcon}
                                />
                              </IconButton>
                            </Grid>
                            <Grid item>
                              <Typography
                                style={{ alignSelf: "center" }}
                                className={`${classes.labelFont}`}
                              >
                                {qty}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <IconButton
                                size="small"
                                onClick={() => handleQty("increment")}
                              >
                                <Icon
                                  path={mdiPlus}
                                  size="16px"
                                  className={classes.alignIcon}
                                />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </div>
          <Grid container direction="row">
            <Grid item>
              <StyledButton
                onClick={handleAddItemDb}
                startIcon={
                  <Icon
                    path={mdiFileReplaceOutline}
                    size="12px"
                    color={TEXT_COLOR.WHITE}
                  />
                }
                style={{
                  backgroundColor: TEXT_COLOR.DEFAULT,
                  marginRight: "4px",
                  width: "64px",
                }}
                size="small"
              >
                <Typography
                  className={`${classes.buttonLabel} ${classes.buttonFont}`}
                  style={{ color: TEXT_COLOR.WHITE }}
                >
                  Add
                </Typography>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                onClick={handleDialogClose}
                startIcon={
                  <Icon
                    path={mdiCloseCircleOutline}
                    size="12px"
                    color={TEXT_COLOR.DEFAULT}
                  />
                }
                style={{
                  backgroundColor: `${TEXT_COLOR.DEFAULT}00`,
                  border: `1px solid ${TEXT_COLOR.DEFAULT}`,
                  marginRight: "4px",
                  width: "64px",
                }}
                size="small"
              >
                <Typography
                  className={`${classes.buttonLabel} ${classes.buttonFont}`}
                  style={{ color: TEXT_COLOR.DEFAULT }}
                >
                  Cancel
                </Typography>
              </StyledButton>
            </Grid>
          </Grid>
        </DialogContent>
      ) : (
        <DialogContent
        className={classes.dialog}
        >
          <CustomerDialogContent order={order} confirm={handleReplaceRequestCompleted}/>        
        </DialogContent>
      )}
    </Dialog>
  );
}
