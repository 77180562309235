import { ButtonBase, Grid, IconButton, Typography,Dialog,
    DialogActions,
    DialogTitle, Button } from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import React from "react";
import useStyles from "./styles";
export function AddProductButton({
  style,
  handleClick,
  label,
}) {
  const classes = useStyles();
  return (
    <Grid
      item
      style={style}
      // onMouseEnter={handleClick}
      // onMouseLeave={handleCloseMenu}
      onClick={handleClick}
    >
      <IconButton size="small">
        <AddCircle
          className={classes.greenColor}
          style={{ fontSize: "20px" }}
        />
      </IconButton>
      <ButtonBase>
        <Typography className={`${classes.button} ${classes.greenColor}`}>
          {label}
        </Typography>
      </ButtonBase>
    </Grid>
  );
}

export function DeleteDialog({open,handleDialogClose, handleClick }) {
  return (
    <Dialog open={open} onClose={handleDialogClose}>
      <DialogTitle>
      The selected products will be permanently deleted. Are you sure?
      </DialogTitle>
      <DialogActions>
        <Button onClick={handleDialogClose}>Cancel</Button>
        <Button
          onClick={handleClick}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
