const INITIAL_STATE = {
    deliveryFee:null
}

const deliveryFeeReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SAVE_DELIVERY_FEE':
            return {
                ...state,
                deliveryFee: action.payload
            }
        default: return state;
    }
}

export default deliveryFeeReducer;