
import React from "react";
import { AddImage } from "../../assets/icons";
import { useStyles } from "./styles";

export function AddImageComponent() {
  const classes = useStyles();
  return (
    <img
      src={AddImage}
      className={classes.img}
      alt=''
    />
  );
}
