const INITIAL_STATE = {
    branchAddress:{}
}

const branchAddressReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SAVE_BRANCH_ADDRESS':
            return {
                ...state,
                branchAddress: action.payload
            };
        default: return state;
    }
}

export default branchAddressReducer;