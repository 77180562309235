export const getTime = (placedAt, comingFrom) => {
    const date = placedAt.toDate();
    const value = new Date(date);
    const hours =
      value.getHours() > 12 ? value.getHours() - 12 : value.getHours();
    const minutes = value.getMinutes();
    const type =
      value.getHours() === 12 ? "PM" : value.getHours() > 12 ? "PM" : "AM";
    const inMins =
      new Date().getTime() - new Date(date).getTime() < 60 * 60 * 1000
        ? (new Date().getTime() - new Date(date).getTime()) / 60000
        : "";
    if (comingFrom === 0) {
      const date = `${value.getDate()}/${
        value.getMonth() + 1
      }/${value.getFullYear()}`;
      return inMins
        ? `${Math.floor(inMins)} mins ago`
        : `${date}  ${hours}:${minutes<10? `0${minutes}`: minutes} ${type}`;
    } else {
      return inMins
        ? `${Math.floor(inMins)} mins ago`
        : `${hours}: ${minutes<10? `0${minutes}`: minutes} ${type}`;
    }
  };