import { makeStyles } from "@material-ui/core";
import { TEXT_COLOR } from "../../constants/theme";

const useStyles = makeStyles(theme=>({
  root: {
    width: "100%",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  gridContainer: {
    width: "70%",
    flex: 1,
  },
  fullWidthContainer: {
    width: "100%",
    flex: 1,
    display: "flex",
    [theme.breakpoints.between("xs", "sm")]: {
      display:"none"
    },
    // flexDirection: 'column'
  },
  fullWidthContainerMobile:{
    [theme.breakpoints.up("sm")]: {
      display:"none"
    },
  },
  feeButtonContainer: {
    flex: 1,
  },
  feeButtonRow: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  btnGridContainer: {
    flex: 1,
  },
  textElement: {
    fontFamily: "nunito",
    fontSize: 16,
    color: TEXT_COLOR.DEFAULT,
    lineHeight:"1rem"
  },
  feeButtonBorder: {
    borderRadius: "4px",
    marginBottom: "10px",
  },
  greenlabelColor: {
    color: TEXT_COLOR.ACTIVE,
  },
  blackLabelColor: {
    color: TEXT_COLOR.DEFAULT,
  },
  labelContainer: {
    width: "100%",
    flex: 1,
    display: "flex",
    alignSelf: "center",
    
  },
  title: {
    fontSize: "12px",
    color: TEXT_COLOR.ORANGE,
    textAlign: "left",
    fontFamily: "nunito",
    marginRight:"5px",
    alignSelf:"center",
    display: "flex",
    justifyContent: "flex-end",
  },
  justifySpaceBetween: {
    justifyContent: 'space-between'
  },
  flexBox: {
    display: 'flex',
    alignItems:'center'
  }
}));

export default useStyles;
