import React, { useEffect, useState } from "react";
import { DATABASE } from "../../config/fbConfig";
import { docNames } from "../../constants/firebaseDoc.ts";
import {
    Grid,
    Paper,
    Typography,
    ButtonBase,
    Checkbox
} from "@material-ui/core";
import { CheckBoxOutlineBlank, CheckBox } from "@material-ui/icons";
import { CircularProgress } from '@material-ui/core';
import Select from 'react-select';
import { TableBody } from "../TableBody";
import { TableHeader } from "../TableHeader";
import useStyles from "./styles";
import { TEXT_COLOR } from "../../constants/theme";

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        '&:focus': { borderColor: "#30D9C4", boxShadow: "none" },
        '&:hover': { borderColor: "#30D9C4", boxShadow: "none" },
        border: "1px solid #30D9C4",
        boxShadow: "none",
        width: '100%',
        marginBottom: "1vw"
    }),
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? `#30D9C499` : 'black',
    }),
    multiValueLabel: base => ({
        ...base,
        border: "1px solid #30D9C499",
        backgroundColor: 'white',
    }),
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    menu: provided => ({ ...provided, zIndex: 9999 })
}

export function ProductsVerification(props) {
    const classes = useStyles();
    const [sellerProducts, setSellerProducts] = useState([]);
    const [loading, setloading] = useState(true);
    const [data, setData] = useState([]);
    const [selectedSeller, setSelectedSeller] = useState({});
    const [checkbox, setCheckbox] = useState(false);
    const [checkboxList, setCheckboxList] = useState({});
    const [action, setAction] = useState("");
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [rejectedProductsList, setRejectedProductsList] = useState();
    const [openProductRejectDialog, setOpenProductRejectDialog] = useState(false);
    const [multipleProductsVerify, setMultipleProductsVerify] = useState(false);
    const [multipleProductsReject, setMultipleProductsReject] = useState(false);
    const [sellerId, setSellerId] = useState();
    const [branchId, setBranchId] = useState();
    const docRef = DATABASE.collection(docNames.SELLER);

    const getAllSellers = async () => {
        let decisionMetrics;
        await DATABASE.collection("config").doc("seller_config").get().then((data) => {
            const document = data.data();
            decisionMetrics = document.decisionMetricsWeights;
        });
        const sellers = [];
        const sellerDocs = await docRef.get();
        sellerDocs.docs.forEach(async seller => {
            const sellerProducts = [];
            const defaultBranch = await docRef.doc(seller.id).collection(docNames.BRANCHES).get();
            const defaultBranchId = defaultBranch.docs[0].id;
            const unverifiedProducts = await docRef.doc(seller.id).collection(docNames.BRANCHES).doc(defaultBranchId).collection(docNames.PRODUCT)
                .where("verificationStatus", "==", "PENDING").orderBy("name" || "nameAr").get();

            if (unverifiedProducts.docs.length) {
                const sellerObj = {};
                unverifiedProducts.docs.forEach(product => {
                    sellerObj.sellerId = seller.id;
                    sellerObj.sellerName = seller.data().brandConfig.brandName || seller.data().brandConfig.brandNameAr;
                    let productObj = product.data();
                    productObj.id = product.id;
                    productObj.sellerId = seller.id;
                    productObj.branchId = defaultBranchId;

                    if (productObj.imageAnalytics && productObj.imageAnalytics.safeSearchAnnotation) {

                        let safeSearchAnnotation = productObj.imageAnalytics.safeSearchAnnotation;

                        let confidenceLevel = [];
                        Object.keys(productObj.imageAnalytics.safeSearchAnnotation).forEach(key => {
                            let value = safeSearchAnnotation[key];
                            for (const property in decisionMetrics) {
                                let obj = {}
                                if (property === value) {
                                    value = decisionMetrics[property];
                                    obj.key = key
                                    obj.value = value
                                    confidenceLevel.push(obj)
                                }

                            }

                        });
                        const confidenceLevelValue = confidenceLevel.reduce((acc, level) => acc + level.value, 0);
                        productObj.confidenceLevel = confidenceLevelValue;
                    }
                    sellerProducts.push(productObj);
                });
                sellerObj.branchId = defaultBranchId;
                sellerObj.products = sellerProducts;
                sellers.push(sellerObj);
                const sellersOptions = sellers.map(seller => ({
                    "value": seller.sellerId,
                    "label": seller.sellerName,
                }));
                setData(sellersOptions);
            }
        });

        await setSellerProducts(sellers);
        setloading(false);
    }
    useEffect(() => {
        getAllSellers();
    }, []);

    useEffect(() => {
        setCheckboxList({});
        setCheckbox(false);
        setSelectedProducts([]);
    }, [sellerId]);

    useEffect(() => {
        if (checkbox) {
            selectedSeller.products.forEach(product => {
                setCheckboxList((value) => ({ ...value, [product.id]: !checkboxList[product.id] }));
            });
        } else {
            setCheckboxList({});
            setMultipleProductsVerify(false);
            setMultipleProductsReject(false);
        }
    }, [checkbox]);

    const handleSellerChange = async (e) => {
        let seller = sellerProducts.find(seller => seller.sellerId === (e && e.value));
        setSellerId(seller && seller.sellerId);
        setBranchId(seller && seller.branchId);
        setSelectedSeller(seller);
    }

    const handleProductReject = (sellerId, productId) => {
        setSelectedSeller((prevState) => {
            let seller = { ...prevState };
            let unRejectedProducts = seller.products.filter(product => { return product.id !== productId });
            seller.products = unRejectedProducts;
            return seller;
        });

        setSellerProducts((prevState) => {
            let sellersList = [...prevState];
            let sellerObj = {}
            sellersList.forEach(seller => {
                if (seller.sellerId === sellerId) {
                    sellerObj = seller;
                    sellerObj.products = seller.products.filter(product => { return product.id !== productId });
                }
            });
            return sellersList;
        });

        setCheckboxList({});
        setMultipleProductsVerify(false);
        setMultipleProductsReject(false);
    }

    const handleMultiProductAction = (action) => {
        const products = Object.keys(checkboxList).filter(key => { return checkboxList[key] === true });
        if (products.length) {
            if (action === "Reject") {
                setMultipleProductsReject(true);
            } else {
                setMultipleProductsVerify(true);
            }

            const sellerId = selectedSeller.sellerId;
            const list = [];
            products.forEach(product => {
                list.push(sellerId, product)
                return list;
            });
            setSelectedProducts(products);
        }

    }

    const handleCheckboxChange = (sellerId, productId) => {
        setCheckboxList((value) => ({ ...value, [productId]: !checkboxList[productId] }));
        setRejectedProductsList((value) => ({ ...value, [productId]: !checkboxList[productId], sellerId: sellerId }));
    };

    return (
        <Paper className={classes.container}>
            <Grid style={{ flex: "0.8", padding: "1vw" }}>
                <Grid item>
                    {!loading ? (
                        <Grid>
                            <Grid item style={{ marginTop: "5vh" }}>
                                <Select
                                    styles={customStyles}
                                    isSearchable
                                    isClearable
                                    onChange={handleSellerChange}
                                    options={data}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                />
                            </Grid>

                            {selectedSeller && selectedSeller.products ? (
                                checkbox || (Object.keys(checkboxList).filter(key => { return checkboxList[key] === true }).length > 1) ? (
                                    <Grid
                                        container
                                        direction="row"
                                        className={classes.iconContainer}
                                    >
                                        <Checkbox
                                            disableRipple
                                            icon={
                                                <CheckBoxOutlineBlank
                                                    className={classes.checkboxIconSize}
                                                    style={{ color: TEXT_COLOR.WHITE, backgroundColor: TEXT_COLOR.WHITE }}
                                                    fontSize="small"
                                                />
                                            }
                                            checkedIcon={
                                                <CheckBox
                                                    style={{ color: TEXT_COLOR.ACTIVE, }}
                                                    fontSize="small"
                                                    className={classes.checkboxIconSize}
                                                />
                                            }
                                            checked={checkbox}
                                            onChange={() => setCheckbox(!checkbox)}
                                            size="small"
                                            style={{ display: "flex", alignItems: "center" }}
                                        />

                                        <ButtonBase onClick={() => handleMultiProductAction("Reject")}>
                                            <Typography
                                                className={classes.buttonReject}
                                            >
                                                Reject
                                            </Typography>
                                        </ButtonBase>
                                        <ButtonBase onClick={() => handleMultiProductAction("Verify")}>
                                            <Typography
                                                className={classes.buttonVerify}
                                            >
                                                Verify
                                            </Typography>
                                        </ButtonBase>
                                    </Grid>
                                ) : (
                                    <TableHeader
                                        checkbox={checkbox}
                                        setCheckbox={setCheckbox}
                                        comingFrom="productVerification"
                                    />
                                )
                            ) : (
                                ""
                            )}
                            <Grid className={classes.verificationTable}>
                                {selectedSeller && selectedSeller.products ? selectedSeller.products.map((item) => (
                                    <Grid>
                                        <TableBody
                                            key={item.id}
                                            item={item}
                                            sellerId={sellerId}
                                            branchId={branchId}
                                            checkboxList={checkboxList}
                                            selectedProducts={selectedProducts}
                                            multipleProductsVerify={multipleProductsVerify}
                                            setMultipleProductsVerify={setMultipleProductsVerify}
                                            setMultipleProductsReject={setMultipleProductsReject}
                                            multipleProductsReject={multipleProductsReject}
                                            openProductRejectDialog={openProductRejectDialog}
                                            setOpenProductRejectDialog={setOpenProductRejectDialog}
                                            handleProductReject={handleProductReject}
                                            handleCheckboxChange={handleCheckboxChange}
                                            setAction={setAction}
                                            comingFrom="productVerification"
                                        />
                                    </Grid>

                                )) : <Typography>Please select a seller </Typography>}
                            </Grid>
                        </Grid>
                    ) : (
                        <CircularProgress style={{ 'color': '#30D9C4' }} />
                    )}
                </Grid>
            </Grid>
        </Paper>

    );
}

