const INTITIAL_STATE = {
    user: null,
  };
  
  const authReducer = (state = INTITIAL_STATE, action) => {
    switch (action.type) {
      case "SAVE_AUTH":
        return {
          ...state,
          user: action.payload,
        };
      case "REMOVE_AUTH":
        return {
          ...state,
          user: null,
        };
      default:
        return state;
    }
  };
  
  export default authReducer;
  