const INITIAL_STATE = {
    preparationTime: {
        hours: "00",
        minutes: "10"
    }
}

const preparationTimeReducer = (state = INITIAL_STATE, action)=>{
    switch (action.type) {
        case 'SAVE_PREPARATION_TIME':
            const prepTime = action.payload;
            const hours = Math.floor(prepTime / 60);
            const minutes = prepTime - hours * 60;

            return {
                ...state,
                preparationTime: {
                    hours: hours < 10 ? `0${hours}` : hours,
                    minutes: minutes < 10 ? `0${minutes}` : minutes
                }
            };
        default: return state;
    }
}

export default preparationTimeReducer;