import { call, put, spawn } from "redux-saga/effects";
import {
  addCategoryService,
  checkCategoryAlreadyExists,
  getHighestDisplayIndex
} from "../service/api/category";
import {
  addProductService,
  deleteProductService,
  saveImage,
  addBrandSettingsService,
  updateBrandLogoService,
  updateProductService,
} from "../service/api/sellerCollection";

export function* addProductSaga({ payload }) {
  if (payload.uid) {
    const { productId,uid } = payload;
    const path = `/sellers/${uid}/products/${productId}/${productId}`;
    const imageDetailsObj  = yield call(saveImage, payload, path);
    payload.setImageUrl(imageDetailsObj)
    yield call(addProductService, {...payload, imageUrl:imageDetailsObj.url, imageAnalytics:imageDetailsObj.imageAnalytics });
    yield put({
      type: "SET_NOTIFICATION_MESSAGE",
      payload: {
        type: "success",
        message: "Product saved and will be displayed in the pending products list",
      },
    });
  }
}
export function* addBrandSettingsSaga({ payload }) {
  if (payload.uid) {
    if (payload.imageUrl) {
      const path = `/sellers/${payload.uid}/brandLogo`;
      const imageDetailsObj = yield call(saveImage, payload, path);
      yield call(updateBrandLogoService, payload.uid, imageDetailsObj.url, payload);
    }
    yield call(addBrandSettingsService, payload, payload.uid);
  }
}

export function* deleteProductSaga({ payload }) {
  if (payload.uid) {
      yield call(deleteProductService, payload);
      // try {
      //   yield call(deleteImageService, payload);
      // } catch (err) {
      //   console.error(err);
      // }
  }
}
export function* updateProductSaga({ payload }) {
  let imageDetailsObj;
  if (payload.uid) {
    if (payload.imageUrl) {
      let path = `/sellers/${payload.uid}/products/${payload.productUid}/${payload.productUid}`;
      // try {
      //   yield call(deleteImageService, { productImageUrl: payload.oldImageUrl });
      // } catch (err) {
      //   console.error(err);
      // }
      imageDetailsObj = yield call(saveImage, payload, path);
    }
    yield call(updateProductService, payload, imageDetailsObj);
  }
}

export function* addImageSaga({ payload }) {
  const { imageUrl, productId, setImageUrl,uid,setImageAnalytics, productImageUrl } = payload;
  if (uid) {
    if (imageUrl || productImageUrl ) {
      const path = `/sellers/${uid}/products/${productId}/${productId}`;
      const imageDetailsObj  = yield call(saveImage, payload, path);
      setImageUrl(imageDetailsObj.url);
      setImageAnalytics(imageDetailsObj.imageAnalytics);
      return {imageUrl: imageDetailsObj.url, imageAnalytics:imageDetailsObj.imageAnalytics};
    }
  }
}

export function* addExcelProuctsSaga({ payload }) {
  const { productList, uid, defaultBranchId, setDisableNextButton } = payload;
  if (uid) {
    try{
      yield* productList.map((product) => {
        const dbSchema = {
          price: product.price ? product.price : "",
          name: product.name ? product.name : "",
          description: product.description ? product.description : "",
          nameAr: product.nameAr ? product.nameAr : "",
          descriptionAr: product.descriptionAr ? product.descriptionAr : "",
          barcode: product.barcode?product.barcode:""
        };
        const categoryName = product.category.categoryName;
        return call(handleSingleProduct, {
          schema: dbSchema,
          imageUrl: product.imageFile,
          categoryName,
          uid,
          defaultBranchId,
        });
      });
      yield put({
        type: "SET_NOTIFICATION_MESSAGE",
        payload: {
          type: "success",
          message: "Successfully imported excel sheet. Products will be displayed in the peding product tab.",
        },
      });
      setDisableNextButton(false);
    }catch(error){
      console.log(error);
      yield put({
        type: "SET_NOTIFICATION_MESSAGE",
        payload: {
          type: "error",
          message: "Something went wrong.",
        },
      });
    }
  }
}


const generateProductId = () => {
  const date = new Date();
  const id = `${Date.now()}_${date.getDate()}_${date.getMonth()+1}_${date.getFullYear()}`;
  return id;
}
export function* handleSingleProduct(payload) {
  try {
    const productId=generateProductId();
    const { schema, uid, defaultBranchId,  categoryName, imageUrl  } = payload;
    const { name, description, price, nameAr, descriptionAr, barcode } = schema;
    if (uid) {
      const path = `/sellers/${uid}/products/${productId}/${productId}`;
      const imageDetailsObj = yield call(saveImage, { imageUrl: imageUrl }, path);
      let categoryId = yield call(checkCategoryAlreadyExists, payload);
      if (categoryId === "Add") {
        const displayIndex= yield call(getHighestDisplayIndex, payload);
        categoryId = yield call(addCategoryService, {defaultBranchId, uid, categoryName, displayIndex});
      }
      const newSchema = {
        name: name,
        description: description,
        nameAr: nameAr,
        descriptionAr: descriptionAr,
        price: price,
        category: {
          id: categoryId,
          categoryName: categoryName,
        },
        availability: true,
        imageUrl: imageDetailsObj.url,
        imageAnalytics : imageDetailsObj.imageAnalytics,
        barcode:barcode
      };
      yield call(addProductService, { schema: newSchema, uid, defaultBranchId, productId });
    }
  } catch (error) {
    console.log(error);
  }
}
