import { TEXT_COLOR } from "../../constants/theme";

const { makeStyles } = require("@material-ui/core");

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    padding: "10px 1%",
    background: TEXT_COLOR.WHITE,
  },
  brand: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight:"15vw"
  },
  activity: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    margin:"auto 40px"
  },
  clickable: {
    cursor: "pointer",
  },
  flexContainer: {
    flex: 1,
    display: "flex",
  },
  right: {
    justifyContent: "flex-end",
  },
  title: {
    fontFamily: "nunito",
    color: TEXT_COLOR.ACTIVE,
  },
  mashkorTitle:{
    fontSize:"1.5rem",
    fontFamily: "nunito",
    color: TEXT_COLOR.ACTIVE,
  },
  subTitle:{
    fontSize:"0.9rem",
    fontFamily: "nunito",
    color: TEXT_COLOR.ACTIVE,
    width:"max-content"
  }
});

export default useStyles;
