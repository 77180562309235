import { IconButton, Typography, FormControlLabel } from "@material-ui/core";
import { Menu, Tune } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TEXT_COLOR } from "../../constants/theme";
import useStyles from "./styles";
import Vendor from "./Vendor";
import firebase, { DATABASE } from "../../config/fbConfig";
import { docNames } from "../../constants/firebaseDoc.ts";
import { CustomSwitch } from "../CustomSwitch"
import { setNotificationMessage } from "../../actions/notificationActions";

function NavBar({menuOpen, onMenuClick}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { uid } = useSelector(({ authState }) => authState.user);
    const brandConfig = useSelector(({brandSettingState})=>brandSettingState?.brandConfig);
    const branchAddress = useSelector(({branchAddressState})=>branchAddressState.branchAddress);
    const { defaultBranchId } = useSelector(
        ({ defaultBranchState }) => defaultBranchState.defaultBranch
    );
    const [isLiveOnApp, setIsLiveOnApp] = useState();
    const [isAdmin, setIsAdmin] = useState(null);
    const [loading, setLoading] = useState(true);
    const [sellerObject, setSellerObject] = useState({});
    const [branchProductsCount, setBranchProductsCount] = useState(null);
    const docRef = DATABASE.collection(docNames.SELLER).doc(uid);
    useEffect(() => {
        docRef.onSnapshot(snap => {
            setIsLiveOnApp(snap.data().isLiveOnApp);
            setIsAdmin(snap.data().isAdmin);
            setSellerObject(snap.data());
            setLoading(false);
        });
        docRef.collection(docNames.BRANCHES)
        .doc(defaultBranchId).onSnapshot((snap)=>{
            if (snap.data() && snap.data().productsConfig){
              setBranchProductsCount(snap.data().productsConfig.branchTotalProductsCount);
            }
          });
    }, []);

    const handleActiveStatusChange = () => {
        if (branchProductsCount > 0 && Object.keys(brandConfig).length !== 0 && Object.keys(brandConfig).length >= 4 &&
            brandConfig.brandName  && brandConfig.businessType  && brandConfig.brandLogo  &&
            branchAddress && Object.keys(branchAddress).length !== 0 && Object.keys(branchAddress).length >= 8) {
            docRef.update({ isLiveOnApp: !isLiveOnApp });
            setIsLiveOnApp(!isLiveOnApp);

            if (sellerObject && sellerObject.timestamps && !sellerObject.timestamps.liveAt) {
                docRef
                    .update({
                        "timestamps.liveAt": firebase.firestore.FieldValue.serverTimestamp(),
                    });
            }
        }
        else {
            if (!isLiveOnApp) {
                dispatch(
                    setNotificationMessage({
                        type: "error",
                        message: "Please complete your brand settings and add at least one product to be able to go live",
                    })
                );
            }
            if (isLiveOnApp) {
                docRef.update({ isLiveOnApp: !isLiveOnApp });
                setIsLiveOnApp(false)
            }
        }
    }
    return (
        <div className={classes.root}>
            <div className={`${classes.clickable} ${classes.flexContainer}`}>
                <IconButton
                    color='inherit'
                    aria-label='open menu'
                    onClick={onMenuClick}
                    edge='start'
                >
                    <Menu htmlColor={menuOpen ? TEXT_COLOR.ACTIVE : TEXT_COLOR.DEFAULT}/>
                </IconButton>
            </div>
            
            
            <div className={`${classes.flexContainer} ${classes.right}`}>
                {!loading && !isAdmin? <FormControlLabel
                    control={
                        <CustomSwitch
                        style={{ width: "20px" }}
                        onClick={handleActiveStatusChange}
                        checked={Boolean(isLiveOnApp)}
                    />
                    }
                    labelPlacement="end"
                    label={isLiveOnApp? <Typography className={classes.title}>OPEN</Typography>
                        : <Typography className={classes.subTitle}>CLOSED</Typography>}
                /> :null}
            
                <Vendor name={brandConfig.brandName} />
            </div>
        </div>
    );
}

export default NavBar;
