import { makeStyles } from "@material-ui/core";
import { TEXT_COLOR } from "../../constants/theme";

export const useStyles = makeStyles({
  container: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  root: {
    marginLeft: "32px",
    marginRight: "32px",
    marginTop: "2vh",
    height: "100%",
  },
  titel: {
    fontSize: "2vh",
    color: TEXT_COLOR.DEFAULT,
    textAlign: "left",
  },
  saveButton: {
    backgroundColor: TEXT_COLOR.ACTIVE,
    borderRadius: "4px",
    border: `0px solid ${TEXT_COLOR.ACTIVE}`,
    color: TEXT_COLOR.WHITE,
    fontSize: "2vh",
    cursor: "pointer",
  },
  alignButton: {
    display: "flex",
    justifyContent: "flex-end",
  },
  marginBottom: {
    marginBottom: "1vh",
  },
  tableBody: {
    overflowY: "auto",
    width: "100%",
    maxHeight:"65vh"
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    backgroundColor: TEXT_COLOR.BACKGROUND
  },
  inActiveCheckbox:{
    color: TEXT_COLOR.WHITE,
    backgroundColor: TEXT_COLOR.WHITE,
  },
  alignCenter: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '1vh'
  }
});
