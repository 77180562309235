import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import App from "./containers/App";
import rootReducer from "./reducer/rootReducer";
import { rootSaga } from "./sagas/rootSaga";

const sagaMiddleware= createSagaMiddleware();

const configureStore = () => {
  const store = createStore(
    rootReducer,
    compose(applyMiddleware(sagaMiddleware))
  );
  return store;
};

const store = configureStore();
sagaMiddleware.run(rootSaga);
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
