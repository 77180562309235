import { makeStyles } from "@material-ui/core";
import { TEXT_COLOR } from "../../../constants/theme";

export const useStyles = makeStyles(theme=>({
  root: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    alignItems: "center",
  },
  paperCtn: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    maxHeight: "100vh",
    overflowY: "auto",
    flex:1
  },
  paper: {
    display: "flex",
    width: "100%",
  },
  container: {
    marginLeft: "16px",
    marginRight: "16px",
  },
  marginTop: {
    marginTop: "16px",
  },
  marginBottom: {
    marginBottom: "8px",
  },
  heading: {
    fontSize: "24px",
    fontFamily: "Nunito",
    color: TEXT_COLOR.DEFAULT,
  },
  fullWidth: {
    width: "100%",
  },
  label: {
    fontFamily: "Nunito",
    cursor: "pointer",
    fontSize: "12px",
  },
  header:{
    marginLeft: "16px",
    marginRight: "16px",
    marginTop: "16px",
    [theme.breakpoints.between("xs", "sm")]: {
      display: "none"
    },
  },
  excellExport: {
    marginLeft: "auto",
    [theme.breakpoints.between("xs", "sm")]: {
      display: "none"
    },
  },
  progressbarCtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  emptyImageContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex:1
  },
}));
