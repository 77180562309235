import { call } from "redux-saga/effects";
import {
  changeOrderItemService,
  cancelOrderService,
  changeOrderStatusesService,
  triggerPubSubEventCloudFunction,
} from "../service/api/orders";

export function* changeOrderItemSaga({ payload }) {
  if (payload.userUid) {
    yield call(changeOrderItemService, payload);
    payload.handleItemStatusChange();
  }
}
export function* cancelOrderSaga({ payload }) {
  if (payload.uid) {
    try{
      yield call(cancelOrderService, payload);
      yield call(triggerPubSubEventCloudFunction, {
        data: payload.data,
        topicName: "TIMELINE_EVENT",
      });
      yield call(triggerPubSubEventCloudFunction, {
        data: payload.agentId,
        topicName: "ORDER_COMPLETE_EVENT",
      });
    }catch(error){
      console.log(error);
    }
  }
}
export function* changeOrderStatusesSaga({ payload }) {
  if (payload.uid) {
    yield call(changeOrderStatusesService, payload);
  }
  if(payload.schema.subStatus === "SELLER_ORDER_READY"){
    yield call(triggerPubSubEventCloudFunction, {
      data: payload,
      topicName: "SELLER_ORDER_READY",
    });
  }
}
