import { takeEvery } from "redux-saga/effects";
import {
  signInSaga,
  signUpSaga,
  isAuthenticatedSaga,
  signOutSaga,
} from "./auth";
import { addCategorySaga, deleteCategorySaga, updateCategorySaga } from "./category";
import {
  addProductSaga,
  addExcelProuctsSaga,
  deleteProductSaga,
  addBrandSettingsSaga,
  updateProductSaga,
  addImageSaga
} from "./product";
import {
  updateBrandDetailsSaga
} from './brand';
import { preparationTimeSaga } from "./prepTime";
import { changeOrderItemSaga, cancelOrderSaga, changeOrderStatusesSaga } from "./orders";
export function* rootSaga() {
  yield takeEvery("SIGN_IN", signInSaga);
  yield takeEvery("SIGN_UP", signUpSaga);
  yield takeEvery("IS_AUTHENTICATED", isAuthenticatedSaga);
  yield takeEvery("SIGN_OUT", signOutSaga);
  yield takeEvery("ADD_CATEGORY", addCategorySaga);
  yield takeEvery("ADD_PRODUCT", addProductSaga);
  yield takeEvery("DELETE_PRODUCT", deleteProductSaga);
  yield takeEvery("ADD_BRAND_SETTINGS", addBrandSettingsSaga);
  yield takeEvery("UPDATE_PRODUCT", updateProductSaga);
  yield takeEvery("UPDATE_CATEGORY", updateCategorySaga);
  yield takeEvery("DELETE_CATEGORY", deleteCategorySaga);
  yield takeEvery("ADD_IMAGE", addImageSaga);
  yield takeEvery("ADD_EXCEL_PRODUCTS", addExcelProuctsSaga);
  yield takeEvery('UPDATE_BRAND_DETAILS', updateBrandDetailsSaga);
  yield takeEvery('PREPARATION_TIME', preparationTimeSaga);
  yield takeEvery('CHANGE_ORDER_ITEM', changeOrderItemSaga);
  yield takeEvery('CANCEL_ORDER', cancelOrderSaga);
  yield takeEvery('CHANGE_STATUSES', changeOrderStatusesSaga);
}
