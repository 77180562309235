import { call, put } from "redux-saga/effects";
import {
  addBrandSettingsService,
  saveImage,
  updateBrandLogoService,
  updateSellerCollection,
} from "../service/api/sellerCollection";

export function* updateBrandDetailsSaga({ payload }) {
  const { brandName, brandNameAr, businessType, email, phone, setDisableSave, uid } = payload;

  if (uid) {
    if (payload.editedImage) {
      const path = `/sellers/${uid}/brandLogo`;
      const imageDetailsObj = yield call(saveImage, { imageUrl: payload.editedImage }, path);
      yield call(updateBrandLogoService, uid, imageDetailsObj.url);
    }
    try {
      if (brandName && brandName.trim() || brandNameAr && brandNameAr.trim() || businessType && businessType.trim()) {
        yield call(
          addBrandSettingsService,
          {
            schema: {
              brandName: brandName || "",
              brandNameAr: brandNameAr || "",
              businessType: businessType || "",
            },
          },
          uid
        );
      }
      if ( phone.trim() || email.trim()) {
        yield call(
          updateSellerCollection,
          {
            phone: phone || "",
            email: email || "",
          },
          uid
        );
      }
      yield put({
        type: "SET_NOTIFICATION_MESSAGE",
        payload: {
          type: "success",
          message: "Brand settings updated successfully.",
        },
      });
      setDisableSave(false);
    } catch (err) {
      console.error(err);
      yield put({
        type: "SET_NOTIFICATION_MESSAGE",
        payload: { type: "error", message: "Something went wrong." },
      });
      setDisableSave(true);
    }
  }
}
