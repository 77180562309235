import { Switch, withStyles, Radio } from "@material-ui/core";
import React from "react";
import { TEXT_COLOR } from "../../constants/theme";
export const CustomSwitch = withStyles({
    switchBase: {
      "&$checked": {
        color: TEXT_COLOR.ACTIVE ,
      },
      "&$checked + $track": {
        backgroundColor: "#46ddca",
      },
    },
    checked: {},
    track: {
      backgroundColor: "lightgrey",
    },
  })(Switch);
  export const StyledRadio = withStyles({
    root: {
      color: TEXT_COLOR.ACTIVE ,
      "&$checked": {
        color: TEXT_COLOR.ACTIVE ,
      },
    },
  })((props) => <Radio color="default" {...props} />);