import React, { useEffect, useState } from 'react';
import { DATABASE } from '../../../config/fbConfig';
import { docNames } from '../../../constants/firebaseDoc';
import { useSelector } from "react-redux";
import usePrevious from '../../../hooks/usePrevious';
import SelectDeliveryFee from '../../SelectDeliveryFee';
import useStyles from './styles';
import { Grid, Paper, Typography } from '@material-ui/core';
import OptimizeAccount from '../../OptimizeAccount';
import { mdiConsoleNetwork } from '@mdi/js';

function DeliveryFee() {
    const [deliveryFee, setDeliveryFee] = useState(useSelector(({ deliveryFeeState }) => deliveryFeeState?.deliveryFee));
    const { uid } = useSelector(({ authState }) => authState.user);
    const prevDeliveryFee = usePrevious(deliveryFee);
    const classes = useStyles();

    useEffect(() => {
        if (prevDeliveryFee !== undefined || deliveryFee !== undefined || prevDeliveryFee !== deliveryFee) {
            let docRef = DATABASE
                .collection(docNames.SELLER)
                .doc(uid)
            // .collection(docNames.CONFIG)
            // .doc(uid);
            docRef.get()
                .then(doc => {
                    if (doc.data()) {
                        docRef.update({
                            "brandConfig.deliveryFeeSharing": deliveryFee
                        })
                    } else {
                        docRef.set({
                            "brandConfig.deliveryFeeSharing": deliveryFee
                        })
                    }
                });
        }
    }, [deliveryFee, uid, prevDeliveryFee]);


    return (
        <Paper className={classes.root}>
            <Grid container spacing={3} direction='column' className={classes.grid}>
                <Grid item>
                    <Typography variant='body1' component='p' className={classes.title}>
                        Delivery fee sharing
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                        variant="body1"
                        component="p"
                        className={classes.onlyMobile}
                    >
                        Please use a desktop to update your delivery fee.
                    </Typography>
                </Grid>
                <Grid item>
                    <div className={classes.alignCenter}>
                        <OptimizeAccount />
                    </div>
                </Grid>
                <Grid item className={classes.deliverFee}>
                    <SelectDeliveryFee
                        setDeliveryFee={setDeliveryFee}
                        selectFee={() => { }}
                        deliveryFee={deliveryFee}
                    />
                </Grid>
            </Grid>
        </Paper>
    )
}

export default DeliveryFee;