import { makeStyles } from "@material-ui/core";
import { TEXT_COLOR } from "../../constants/theme";

export const useStyles = makeStyles(theme=>({
    root: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        padding: 10,
        height: '100%',
        flexWrap: 'wrap'
    },
    paperContainer: {
        flex: 1,
        overflow: 'hidden',
        transitionDuration: '0.5s',
        marginRight: 40,
        padding: 10,
        display: 'flex',
        height: '100%',
        minWidth: 300,
        maxHeight: '70vh',
        [theme.breakpoints.between("xs", "sm")]: {
            marginRight: 0,
            height: '85%',
        },
    },
    scroll: {
        overflow: 'scroll'
    },
    title: {
        fontSize: "20px",
        color: TEXT_COLOR.DEFAULT,
        textAlign: "left",
    },
    text: {
        overflowWrap: "break-word",
        wordBreak: "break-word",
        color: TEXT_COLOR.BLUE,
        overflowX: "hidden",
        maxWidth: "280px",
        // overflowY: "auto",
        maxHeight: "49px",
        fontSize: "12px",
        fontFamily: "Nunito",
        marginLeft: "8px",
    },
    headerText: {
        fontSize: 13,
        fontFamily: 'Nunito'
    },
    headerField: {
        background: TEXT_COLOR.BACKGROUND,
        height: 32,
        display: 'flex',
        alignItems: 'center'
    },
    categoryRow: {
        border: `1px solid ${TEXT_COLOR.BACKGROUND}`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 50,
        borderRadius: 8,
        '&:hover': {
            borderColor: TEXT_COLOR.ACTIVE
        }
    },
    fitContentHeight: {
        height: 'fit-content'
    },
    button: {
        height: "30px",
        backgroundColor:TEXT_COLOR.ACTIVE,
        borderRadius: "4px",
        border: `0px solid ${TEXT_COLOR.ACTIVE}`,
        color: TEXT_COLOR.WHITE,
        fontSize: "12px",
        cursor: "pointer",
    },
    textField: {
        width: '90%',
    },
    justifyCenter: {
        display: 'flex',
        justifyContent: 'center'
    },
    flex1: {
        flex: 1
    },
    flex4: {
        flex: 4
    },
    flex6: {
        flex: 6,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        [theme.breakpoints.between("xs", "sm")]: {
            display: "none"
        },
    },
    gridRow: {
        paddingLeft: '0px !important',
        paddingRight: '0px !important'
    },
    floatRight: {
        float: 'right'
    },
    iconStyling: {
        backgroundColor: TEXT_COLOR.ACTIVE_BACKGROUND,
        borderRadius: "50%",
        padding: "1px",
        boxShadow: `0px 0px 0px 2px ${TEXT_COLOR.LIGHT_GREY}`,
        position: "sticky",
        zIndex: 10,
        right: 0
    },
    progressContainer: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    noWrap: {
        flexWrap: 'nowrap !important'
    },
    paddedDiv: {
        flex: 1,
        padding: 10,
        [theme.breakpoints.between("xs", "sm")]: {
            display: "none"
        },
    }
}));