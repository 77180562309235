import React, { useEffect, useState } from "react";
import { DATABASE } from "../../config/fbConfig";
import { docNames } from "../../constants/firebaseDoc.ts";
import { TEXT_COLOR } from "../../constants/theme";
import {
    mdiEmail,
    mdiPhoneInTalkOutline,
    mdiAccountCircleOutline,
} from "@mdi/js";
import {
    Paper,
    Grid,
    Dialog,
    DialogContent,
    Typography,
} from "@material-ui/core";
import Icon from "@mdi/react";
import useStyles from "./styles";
import ShadowlessButton from "../../components/ShadowlessButton";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export function SellersFunnel() {
    const classes = useStyles();
    const [sellers, setSellers] = useState([]);
    const [openSellerDetailsDialog, setOpenSellerDetailsDialog] = useState(false);
    const [selectedSeller, setSelectedSeller] = useState({});
    const [sellerNotes, setSellerNotes] = useState(null);
    const [loading, setLoading] = useState(null);
    const docRef = DATABASE.collection(docNames.SELLER);

    const funnelPhases = ["registeredNoProducts", "followUp1", "productsUploaded", "followUp2", "LiveSeller"];
    const labelsMap = {
        registeredNoProducts: "Sign up no product upload-4 Hrs",
        followUp1: "Follow up 1",
        productsUploaded: "Uploaded products",
        followUp2: "Follow up 2",
        LiveSeller: "Live sellers",
    };

    const handleDialogOpen = async (id) => {
        sellers.forEach(async (seller) => {
            if (seller._id === id) {
                setSellerNotes(seller.notes);
                setSelectedSeller(seller);
                
            }
        });
        const query = await DATABASE.collection("seller_notes").doc(id).get();
        if(query.data()){
            setSellerNotes(query.data().notes);
        }
        setOpenSellerDetailsDialog(true);
    };

    const handleDialogClose = () => {
        setOpenSellerDetailsDialog(false);
        setSellerNotes(null);
    };

    function GetDayDifference(firestoreTimestamp, dayDiff) {
        const today = new Date();
        let d = firestoreTimestamp.toDate();
        const lastDay = d.setDate(d.getDate() + dayDiff);
        return today < lastDay
    }

    function isTimestampToday(firestoreTimestamp) {
        const now = new Date();
        const firestoreDate = firestoreTimestamp.toDate();
        return now.getFullYear() == firestoreDate.getFullYear()
            && now.getMonth() == firestoreDate.getMonth()
            && now.getDate() == firestoreDate.getDate();
    }

    function isNextDay(firestoreTimestamp, day) {
        const today = new Date();
        let d = firestoreTimestamp.toDate();
        let lastDay = d.setDate(d.getDate() + day);
        lastDay = new Date(lastDay);
        return lastDay.getDate() === today.getDate();
    }

    function formatDate(unix_timestamp) {
        var date = new Date(unix_timestamp * 1000);
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        var year = date.getFullYear();
        var month = months[date.getMonth()];
        var day = date.getDate();
        var hours = date.getHours();
        var minutes = "0" + date.getMinutes();
        var seconds = "0" + date.getSeconds();
        var formattedTime = day + ' ' + month + ' ' + year + ' ' + hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);

        return formattedTime;
    }
    const getTimeDifferance = (createdAt) => {
        const start = new Date(createdAt.toDate());
        const end = new Date();
        const diff = end.getTime() - start.getTime();
        const seconds = Math.floor(diff / 1000);
        const hours = seconds / 3600;
        return hours;
    }


    const getAllSellers = async () => {
        setLoading(true);
        const sellersList = [];
        const notesQuery = await DATABASE.collection("seller_notes").get();

        await DATABASE.collection(docNames.SELLER).get().then(function (querySnap) {
            querySnap.docs.forEach((item) => {
                const sellerObject = {};
                const data = item.data();
                if (data.timestamps && data.timestamps.createdAt) {
                    sellerObject.id = item.id;
                    sellerObject.data = data;
                    notesQuery.docs.forEach(doc=>{
                        if(doc.id === item.id){
                            sellerObject.notes = (doc.data() && doc.data().notes) || ""
                        }
                    });
                    sellerObject.creationTimeDiffInHours = getTimeDifferance(data.timestamps && data.timestamps.createdAt);
                    sellersList.push(sellerObject);

                }
            });
            classifiySellersInFunnel(sellersList)
        });

    }

    const classifiySellersInFunnel = (sellersList) => {
        sellersList && sellersList.map((seller) => {
            const sellerObj = {}
            if (seller.data && !seller.data.isAdmin && seller.data.fallInTheFunnel && GetDayDifference(seller.data.timestamps.createdAt, 7)) {
                sellerObj.title = seller.data.brandConfig && (seller.data.brandConfig.brandName || seller.data.email);
                sellerObj._id = seller.id;
                sellerObj.notes = seller.notes || "";
                sellerObj.data = seller.data;
                if (seller.data.timestamps && seller.data.timestamps.liveAt && seller.creationTimeDiffInHours > 4 && GetDayDifference(seller.data.timestamps.createdAt, 7)) {
                    sellerObj.status = "LiveSeller";
                    docRef.doc(seller.id).update({
                        currentStatusInTheFunnel: "LiveSeller",
                    });
                    sellers.push(sellerObj);
                }
                else if (seller.data.timestamps && seller.data.timestamps.productsUploadedAt && seller.data.timestamps.liveAt === undefined &&
                    seller.data.timestamps && seller.data.timestamps.productsUploadedAt && GetDayDifference(seller.data.timestamps.productsUploadedAt, 2) && isNextDay(seller.data.timestamps.productsUploadedAt, 1)) {
                    sellerObj.status = "productsUploaded";
                    docRef.doc(seller.id).update({ currentStatusInTheFunnel: "productsUploaded", FunnelActivated: true });
                    sellers.push(sellerObj);
                } else if (seller.data.timestamps && seller.data.timestamps.productsUploadedAt && seller.data.timestamps.liveAt === undefined &&
                    seller.data.timestamps && seller.data.timestamps.productsUploadedAt && isNextDay(seller.data.timestamps.productsUploadedAt, 2)) {
                    sellerObj.status = "followUp2";
                    docRef.doc(seller.id).update({ currentStatusInTheFunnel: "followUp2", FunnelActivated: true });
                    sellers.push(sellerObj);
                }
                else if (seller.data.timestamps && seller.data.timestamps.createdAt && seller.data.timestamps.productsUploadedAt === undefined &&
                    seller.creationTimeDiffInHours > 4 && isTimestampToday(seller.data.timestamps.createdAt)) {
                    sellerObj.status = "registeredNoProducts";
                    docRef.doc(seller.id).update({ currentStatusInTheFunnel: "registeredNoProducts", FunnelActivated: true });
                    sellers.push(sellerObj);
                } else if (seller.data.timestamps && seller.data.timestamps.createdAt && seller.data.timestamps.productsUploadedAt === undefined &&
                    isNextDay(seller.data.timestamps.createdAt, 1)) {
                    sellerObj.status = "followUp1";
                    docRef.doc(seller.id).update({ currentStatusInTheFunnel: "followUp1", FunnelActivated: true });
                    sellers.push(sellerObj);
                }
                else {
                    sellerObj.status = seller.data.currentStatusInTheFunnel;
                    sellers.push(sellerObj);
                }

            } else if (seller.data && !seller.data.isAdmin && seller.data.fallInTheFunnel && !GetDayDifference(seller.data.timestamps.createdAt, 7)) {
                docRef.doc(seller.id).update({ currentStatusInTheFunnel: "", fallInTheFunnel: false });
            }
        });
        setSellers(sellers);
        setLoading(false);
        console.log("sellers", sellers);

    }

    useEffect(() => {

        getAllSellers();

    }, []);



    const handleSave = async () => {
        setSellers((prevState)=>{
            let list = [...prevState];
            let objIndex = list.findIndex((obj => obj._id == selectedSeller._id));
            list[objIndex].notes = sellerNotes;
            
            return list;
        });
        await DATABASE.collection("seller_notes").doc(selectedSeller._id).set({
            notes: sellerNotes
        }, { merge: true });
        handleDialogClose();
    }

    return (
        <div>
            <Paper className={classes.container}>
                <Typography className={classes.header}>Sellers funnel </Typography>
                <Grid className={classes.board}>
                    {funnelPhases.map((phase, index) => (
                        <div key={index} className={classes.column}>
                            <div className={classes.columnHead}>{labelsMap[phase]}</div>
                            <div>
                                {sellers && sellers
                                    .filter((item) => item.status === phase)
                                    .map((item) => (
                                        <div key={item._id} onClick={() => handleDialogOpen(item._id)} className={classes.item}>{item.title}</div>
                                    ))}
                            </div>
                        </div>
                    ))}
                </Grid>
            </Paper>
            <Dialog
                open={openSellerDetailsDialog}
                onClose={handleDialogClose}
            >
                <DialogContent
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        padding: "32px 86px 32px 86px",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignSelf: "center",
                            alignItems: "center",
                            marginTop: "16px",
                        }}
                    >
                        <Icon
                            path={mdiAccountCircleOutline}
                            size="16px"
                            color={TEXT_COLOR.DEFAULT}
                        />
                        <Typography
                            className={classes.headerContent}
                            style={{ marginRight: "16px" }}
                        >
                            {selectedSeller && selectedSeller.data && selectedSeller.data.brandConfig && selectedSeller.data.brandConfig.brandName}
                        </Typography>
                        <Icon
                            path={mdiPhoneInTalkOutline}
                            size="16px"
                            color={TEXT_COLOR.DEFAULT}
                        />
                        <Typography className={classes.headerContent}>
                            {selectedSeller && selectedSeller.data && selectedSeller.data.phone}
                        </Typography>
                        <Icon
                            path={mdiEmail}
                            size="16px"
                            color={TEXT_COLOR.DEFAULT}
                        />
                        <Typography className={classes.headerContent}>
                            {selectedSeller && selectedSeller.data && selectedSeller.data.email}
                        </Typography>
                    </div>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignSelf: "flex-start",
                            alignItems: "flex-start",
                            marginTop: "16px",
                        }}
                    >
                        <Typography className={classes.headerContent}>
                            {`created at: ${selectedSeller.data && formatDate(selectedSeller.data.timestamps.createdAt.seconds) || ""}`}
                        </Typography>
                        {selectedSeller && selectedSeller.data && selectedSeller.data.timestamps.productsUploadedAt ?
                            <Typography className={classes.headerContent}>
                                {`products uploaded at:  ${selectedSeller.data && formatDate(selectedSeller.data.timestamps.productsUploadedAt.seconds) || ""}`}
                            </Typography>
                            : null}
                        {selectedSeller && selectedSeller.data && selectedSeller.data.timestamps.liveAt ?
                            <Typography className={classes.headerContent}>
                                {`live at: ${selectedSeller.data && formatDate(selectedSeller.data.timestamps.liveAt.seconds) || ""}`}
                            </Typography>
                            : null}

                    </div>
                    <ReactQuill
                        onChange={(e) => {
                            setSellerNotes(e);
                        }}
                        defaultValue={(selectedSeller && selectedSeller.notes) || ""}
                    >

                    </ReactQuill>
                    <Grid style={{ alignSelf: "flex-end" }}>
                        <ShadowlessButton
                            disableElevation
                            variant="contained"
                            onClick={handleSave}
                            className={classes.saveButton}
                        >
                            Save
                        </ShadowlessButton>
                    </Grid>

                </DialogContent>
            </Dialog>
        </div>

    );
}

