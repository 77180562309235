import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import { CheckBoxOutlineBlank, CheckBox } from "@material-ui/icons";
import {
  mdiCheckboxBlank,
  mdiMagnify,
  mdiCheckboxMarked,
  mdiClose,
  mdiFilter,
} from "@mdi/js";
import Icon from "@mdi/react";
import algoliasearch from 'algoliasearch/lite';
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DividerComponent } from "../../components/Divider";
import { TEXT_COLOR } from "../../constants/theme";
import { useStyles } from "./styles";
import { DATABASE } from "../../config/fbConfig";

export const StyledBorderTextfield = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: "0px",
        border: `1px solid ${TEXT_COLOR.BACKGROUND}`,
      },
      "&:hover fieldset": {
        borderRadius: "0px",
        border: `1px solid ${TEXT_COLOR.BACKGROUND}`,
      },
      "&.Mui-focused fieldset": {
        borderRadius: "0px",
        border: `1px solid ${TEXT_COLOR.BACKGROUND}`,
      },
    },
  },
})((props) => <TextField {...props} />);

function CategoryMenu(props) {
  const classes = useStyles();
  const { anchorEl, handleClose, setCategory, category, categoryList } = props;
  const [localCategory, setLocalCategory] = useState(category);

  useEffect(() => {
    setCategory(localCategory);
  },[localCategory, setCategory])

  const handleCheckboxChange = (event) => {
    setLocalCategory({
      ...localCategory,
      [event.target.name]: event.target.checked,
    });
  };

  // const saveChanges = () => {
  //   setCategory(localCategory);
  //   handleClose();
  // };

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      getContentAnchorEl={null}
      open={anchorEl ? true : false}
      onClose={handleClose}
    >
      <div className={classes.categoryRoot}>
        <div className={classes.header}>
          <div className={classes.categoryMenuTitle}>Category</div>
          <div
            className={classes.baseButton}
            onClick={() => {
              let localCategory = {};
              categoryList.forEach(item => {
                localCategory[item.id] = false;
              })
              setLocalCategory(localCategory);
            }}
          >
            Clear all
          </div>
        </div>
        <FormControl>
          <FormGroup>
            {categoryList.map((item, key) => (
              <FormControlLabel
                key={key}
                control={
                  <Checkbox
                    disableRipple
                    icon={
                      <Icon
                        path={mdiCheckboxBlank}
                        size="22px"
                        color={TEXT_COLOR.BACKGROUND}
                      />
                    }
                    checkedIcon={
                      <Icon
                        path={mdiCheckboxMarked}
                        color={TEXT_COLOR.ACTIVE}
                        size="22px"
                      />
                    }
                    checked={localCategory[item.id]}
                    name={item.id}
                    onChange={handleCheckboxChange}
                    size="small"
                    style={{ display: "flex", alignItems: "center" }}
                    value={localCategory[item.id]}
                  />
                }
                label={
                  <Typography className={classes.categoryLabel}>
                    {item.categoryName}
                  </Typography>
                }
              />
            ))}
          </FormGroup>
        </FormControl>
        {/* <IconButton
          onClick={saveChanges}
          size="small"
          style={{ alignSelf: "flex-start" }}
        >
          <Icon
            path={mdiCheckboxMarkedCircle}
            size="20px"
            color={TEXT_COLOR.ACTIVE}
          />
        </IconButton> */}
      </div>
    </Menu>
  );
}
export function TableHeader({
  setCheckbox,
  checkbox,
  comingFrom,
  categoryList,
  category,
  setCategory,
  handleClose,
  filterProduct,
  setFilterProduct,
  filterProductAr,
  setFilterProductAr,
  searchResultProducts, 
  setSearchResultProducts,
  emptyList,
  verifiedProductsSwitch
}) {
  const { defaultBranchId } = useSelector(
    ({ defaultBranchState }) => defaultBranchState.defaultBranch
  );
  const [showCategories, setShowCategories] = useState(false);
  const [searchProducts, setSearchProducts] = useState(false);
  const [searchProductsAr, setSearchProductsAr] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchClient, setSearchClient] = useState(null);
  const [index, setIndex] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  // const CustomSearchBox = connectSearchBox(StyledBorderTextfield);
  const classes = useStyles();

  const getSearchKeys = async () => {
    const configDoc = await DATABASE.collection("config").doc("algolia_search").get();
    const config = configDoc.data();
    const searchApiKey = config.SEARCH_API_KEY;
    const indexName = config.products_index_name;
    const appId = config.APP_ID;
    const searchClient = algoliasearch(appId, searchApiKey);
    
    const index = searchClient.initIndex(indexName);
    
    setSearchClient(searchClient);
    setIndex(index);
  }
  
  useEffect(()=>{
    getSearchKeys();
  },[]);

  const search = async () =>{
    const query = verifiedProductsSwitch ? `branchId:${defaultBranchId} AND availability:${true} AND verificationStatus:VERIFIED`:
    `branchId:${defaultBranchId} AND availability:${true} AND NOT verificationStatus:VERIFIED`

    const result = await index.search(searchQuery.toString(),{
      filters: query
    });
    const hits = result.hits;
    setSearchResultProducts(hits);
  }
  
  const handleCategoryClose = () => {
    setShowCategories(!showCategories);
    handleClose();
  };
  return (
    <Grid container direction="row" className={classes.bottomMargin} >
      <Grid item className={`${classes.tableHeadCheckbox} ${classes.backgroundColorGrey} ${classes.flexOne}`}>
        {emptyList && (!searchResultProducts || searchResultProducts.length === 0)?
          ''
          :
          <Checkbox
            disableRipple
            icon={
              <CheckBoxOutlineBlank
                className={classes.checkboxIconSize}
                style={{ color: TEXT_COLOR.WHITE, backgroundColor: TEXT_COLOR.WHITE }}
                fontSize="small"
              />
            }
            checkedIcon={
              <CheckBox
                style={{ color: TEXT_COLOR.ACTIVE, }}
                fontSize="small"
                className={classes.checkboxIconSize}
              />
            }
            checked={checkbox}
            onChange={() => setCheckbox(!checkbox)}
            size="small"
            style={{ display: "flex", alignItems: "center" }}
          />
        }
      </Grid>
      <DividerComponent height="32px" color={TEXT_COLOR.WHITE} />
      <Grid
        item
        onBlur={() => {
          if (comingFrom === "home") {
            setSearchProducts(false);
          }
        }}
        className={`${classes.tableHead} ${classes.flexFour} ${
          searchProducts
            ? classes.backgroundColorWhite
            : classes.backgroundColorGrey
        }`}
      >
        {comingFrom === "home" && !searchProducts ? (
          <Grid style={{width: '100%', padding: '0% 5% 0% 0%'}}>
          <div className={classes.flexHeader}>
            <div
              className={classes.alignCenter}
              onClick={() => {
                if (comingFrom === "home") {
                  setSearchProducts(!searchProducts);
                }
              }}
            >
            <IconButton size="small">
              <Icon
                path={mdiMagnify}
                size="16px"
                color={searchProducts ? TEXT_COLOR.ACTIVE : TEXT_COLOR.DEFAULT}
              />
            </IconButton>
            Product Name
            </div>
            {filterProduct && <div className={classes.cancelNameSearch}>
              {filterProduct}
              <IconButton
                size='small'
                onClick={() => {
                  setFilterProduct('');
                }}
                style={{
                  borderRadius: 20,
                  backgroundColor: '#cccccc',
                  marginLeft: 10
                }}
              >
                <Icon
                  path={mdiClose}
                  size='16px'
                  color='#000000'
                  />
              </IconButton>
            </div>}
          </div>

          </Grid>
        ) : comingFrom === "home" && searchProducts ? (
          <StyledBorderTextfield
            autoFocus={true}
            value={searchQuery}
            onKeyUp={search}
            onChange={(event) => {
              const value = event.target.value;
              setSearchQuery(event.target.value);
              setFilterProduct(value);
            }}
            variant="outlined"
            InputProps={{
              style: { height: "32px" },
              startAdornment: (
                <InputAdornment position="start">
                  <Icon
                    path={mdiMagnify}
                    size="16px"
                    color={TEXT_COLOR.ACTIVE}
                  />
                </InputAdornment>
              ),
            }}
          />
        ) : (
          "Product Name"
        )}
      </Grid>
      <Grid
        item
        onBlur={() => {
          if (comingFrom === "home") {
            setSearchProductsAr(false);
          }
        }}
        className={`${classes.tableHead} ${classes.flexFour} ${
          searchProductsAr
            ? classes.backgroundColorWhite
            : classes.backgroundColorGrey
        }`}
      >
        {comingFrom === "home" && !searchProductsAr ? (
          <Grid style={{width: '100%', padding: '0% 5% 0% 0%'}}>
          <div className={classes.flexHeader}>
            <div
              className={classes.alignCenter}
              onClick={() => {
                if (comingFrom === "home") {
                  setSearchProductsAr(!searchProductsAr);
                }
              }}
            >
            <IconButton size="small">
              <Icon
                path={mdiMagnify}
                size="16px"
                color={searchProductsAr ? TEXT_COLOR.ACTIVE : TEXT_COLOR.DEFAULT}
              />
            </IconButton>
            Product Name
            </div>
            {filterProductAr && <div className={classes.cancelNameSearch}>
              {filterProductAr}
              <IconButton
                size='small'
                onClick={() => {
                  setFilterProductAr('');
                }}
                style={{
                  borderRadius: 20,
                  backgroundColor: '#cccccc',
                  marginLeft: 10
                }}
              >
                <Icon
                  path={mdiClose}
                  size='16px'
                  color='#000000'
                  />
              </IconButton>
            </div>}
          </div>

          </Grid>
        ) : comingFrom === "home" && searchProductsAr ? (
          <StyledBorderTextfield
            autoFocus={true}
            value={searchQuery}
            onKeyUp={search}
            onChange={(event) => {
              const value = event.target.value;
              setSearchQuery(event.target.value);
              setFilterProduct(value);
            }}
            variant="outlined"
            InputProps={{
              style: { height: "32px" },
              startAdornment: (
                <InputAdornment position="start">
                  <Icon
                    path={mdiMagnify}
                    size="16px"
                    color={TEXT_COLOR.ACTIVE}
                  />
                </InputAdornment>
              ),
            }}
          />
        ) : (
          "Product Name[AR]"
        )}
      </Grid>
      <DividerComponent height="32px" color={TEXT_COLOR.WHITE} />
      <Grid
        item
        className={`${classes.tableHead} ${classes.flexTwo} ${classes.backgroundColorGrey}`}
      >
        Image
      </Grid>
      <DividerComponent height="32px" color={TEXT_COLOR.WHITE} />
      {comingFrom !== "productVerification" ?
        <Grid
          item
          className={`${classes.tableHead} ${classes.flexThree} ${classes.backgroundColorGrey}`}
        >
          Price
        </Grid>
        : null}

      {comingFrom !== "productVerification" ?
        <DividerComponent height="32px" color={TEXT_COLOR.WHITE} />
        : null}
        {comingFrom !== "productVerification" ?
          <Grid
            item
            className={`${classes.tableHead} ${classes.flexFour} ${classes.transition
              } ${showCategories
                ? classes.backgroundColorWhite
                : classes.backgroundColorGrey
              }`}
            onClick={(event) => {
              if (comingFrom === "home") {
                setShowCategories(!showCategories);
                setAnchorEl(event.currentTarget);
              }
            }}
          >
            <div className={classes.flexHeader}>
              <div className={classes.alignCenter}>
                {comingFrom === "home" && category && (Object.values(category).includes(true) && !showCategories) && (
                  <IconButton size="small">
                    <Icon
                      path={mdiMagnify}
                      size="16px"
                      color={showCategories ? TEXT_COLOR.ACTIVE : TEXT_COLOR.DEFAULT}
                    />
                  </IconButton>
                )}
                {showCategories ? "Select category" : "Category"}
              </div>
            </div>
          </Grid>
          : null}

        <DividerComponent height="32px" color={TEXT_COLOR.WHITE} />
        {comingFrom === "productVerification" ?
          <Grid
            item
            className={`${classes.tableHead} ${classes.flexOneHalf}  ${classes.backgroundColorGrey}`}
          >
            Verification clarity
          </Grid>
          : null}
        <DividerComponent height="32px" color={TEXT_COLOR.WHITE} />
        <Grid
          item
          className={`${classes.tableHead} ${classes.flexOneHalf}  ${classes.backgroundColorGrey}`}
        >
          Actions
      </Grid>
      {showCategories && (
        <CategoryMenu
          anchorEl={anchorEl}
          handleClose={handleCategoryClose}
          setCategory={setCategory}
          category={category}
          categoryList={categoryList}
        />
      )}
    </Grid>
  );
}
