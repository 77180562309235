export const TEXT_COLOR = {
  DEFAULT: "#0F2027",
  ACTIVE: "#30D9C4",
  BACKGROUND: "#f5f5f5",
  ERROR: "#ff6347",
  WHITE: "#ffffff",
  ACTIVE_BACKGROUND: "#30D9C403",
  DEFAULT_BACKGROUND: "#f5f5f5",
  LIGHT_GREY: "#f5f5f5",
  BLUE: "#231d51",
  DELETE_COLOR: "#E63E54",
  SUCCESS_COLOR: "#42ba96",
  BLACK: "000000",
  ORANGE: "#FF8E6E",
  B2B2B2: "#B2B2B2",
  848484: "#848484",
  F6F7F8:"#F6F7F8",
  FF8E6E: "#ff8e6e",
  
};
