import {
  Grid,
  IconButton,
  Paper,
  Typography,
  Button,
  Box,
  MenuItem,
  LinearProgress,
  withStyles,
  Checkbox,
  ButtonBase,
} from "@material-ui/core";
import {
  mdiArrowLeft,
  mdiProgressUpload,
  mdiCheckboxMarkedCircle,
  mdiClose,
} from "@mdi/js";
import Icon from "@mdi/react";
import { setNotificationMessage } from "../../actions/notificationActions";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { TEXT_COLOR } from "../../constants/theme";
import useStyles from "./styles";
import readXlsxFile from "read-excel-file";
import { StyledTextField } from "../../components/Textfield";
import { StyledButton } from "../../components/StyledButton";
import { excelProductUpload } from "../../actions/uploadExcelProduct";
import { ExcelImage, ImageMismatch } from "../../assets/images";
import JSZip from "jszip";
import { TableHeader } from "../TableHeader";
import { TableBody } from "../TableBody";
import {
  AddProductButton,
  DeleteDialog,
} from "../../components/AddProductButton";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import { DividerComponent } from "../../components/Divider";
import OptimizeAccount from "../OptimizeAccount";
import { AddProduct } from "../NewProduct";
const preDefinedHeaders = [
  {
    label: "Product Name EN",
    value: "name",
  },
  {
    label: "Product Name AR",
    value: "nameAr",
  },
  {
    label: "Price",
    value: "price",
  },
  {
    label: "Category",
    value: "category",
  },
  {
    label: "Product Description EN",
    value: "description",
  },
  {
    label: "Product Description AR",
    value: "descriptionAr",
  },
  {
    label: "Barcode",
    value: "barcode",
  },

];

export const StyledProgressbar = withStyles({
  colorPrimary: {
    backgroundColor: TEXT_COLOR.ACTIVE,
  },
  barColorPrimary: {
    backgroundColor: TEXT_COLOR.ACTIVE,
  },
})((props) => <LinearProgress {...props} />);

function ImageMismatchComponent({ handleBack }) {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      style={{ margin: "auto" }}
    >
      <Grid item>
        <img src={ImageMismatch} alt="" />
      </Grid>
      <Typography
        className={`${classes.imageMismatchLabel} ${classes.marginBottom}`}
      >
        OOPS!
      </Typography>
      <Typography
        className={`${classes.textStyle} ${classes.defaultColor}  ${classes.marginBottom}`}
      >
        We were not able to know which image goes with which product.
      </Typography>
      <Typography
        className={`${classes.textStyle} ${classes.defaultColor}  ${classes.marginBottom}`}
      >
        Name your images exactly as your products.
      </Typography>
      <Typography
        className={`${classes.textStyle} ${classes.orangeColor}  ${classes.marginBottom}`}
      >
        For example: whitemouse.jpeg for product White Mouse
      </Typography>

      <Box className={classes.chatbox}>
        <Typography className={`${classes.textStyle} ${classes.defaultColor}`}>
          Chat with us if you need help
        </Typography>
      </Box>
      <IconButton onClick={handleBack}>
        <Icon path={mdiArrowLeft} size="16px" color={TEXT_COLOR.DEFAULT} />
      </IconButton>
    </Grid>
  );
}

function LoadingPage({ progressbar, excelLoaded, excelSheet }) {
  const classes = useStyles();
  const size = Math.floor(excelSheet.size / 1024);
  return (
    <Grid container direction="column" justify="center" alignItems="center">
      {progressbar !== 100 ? (
        <Grid item style={{ height: "93px", marginBottom: "16px" }}>
          <Typography className={classes.imageLabel}>Xlsx</Typography>
          <img src={ExcelImage} alt="" className={classes.imageUrl} />
        </Grid>
      ) : (
        ""
      )}
      <Grid item>
        <Grid
          container
          direction="row"
          alignItems="center"
          className={classes.marginBottom}
        >
          {progressbar === 100 ? (
            <Icon
              color={TEXT_COLOR.ACTIVE}
              path={mdiCheckboxMarkedCircle}
              size="24px"
            />
          ) : (
            ""
          )}
          <Typography className={classes.textStyle}>
            {excelSheet.name}
          </Typography>
        </Grid>
      </Grid>

      <Grid item style={{ width: "40%" }}>
        <StyledProgressbar
          variant="determinate"
          value={progressbar}
          style={{ color: TEXT_COLOR.ACTIVE }}
        />
      </Grid>
      <Grid item style={{ width: "40%" }}>
        <Grid container direction="row">
          <Typography
            className={classes.loadingText}
            style={{ color: "#586285" }}
          >
            {`${excelLoaded} Kb `}
          </Typography>
          <Typography
            className={classes.loadingText}
            style={{ color: "#ADB5D1" }}
          >
            {`from ${size} Kb`}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export function UploadExcel({ handleComponentValue, setDisableNextButton }) {
  const [progressbar, setProgressbar] = useState(0);
  const [zipFile, setZipFile] = useState(null);
  const [excelLoaded, setExcelLoaded] = useState();
  const [showLoadingPage, setShowLoadingPage] = useState(false);
  const [showImageLoadingPage, setImageLoadingPage] = useState(false);
  const [checkbox, setCheckbox] = useState(false);
  const [disableSave, setDisableSave] = useState(true);
  const [showDuplicateScreen, setShowDuplicateScreen] = useState(false);
  const [dataUploading, setDataUploading] = useState(false);
  const { uid } = useSelector(({ authState }) => authState.user);
  const [checkboxList, setCheckboxList] = useState({});
  const [imageError, setImageError] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [products, setProducts] = useState([]);
  const [productList, setProductsList] = useState([]);
  const [deleteAllDialog, setDeleteAllDialog] = useState(false);
  const { defaultBranchId } = useSelector(
    ({ defaultBranchState }) => defaultBranchState.defaultBranch
  );
  const [disableImageUpload, setDisableImageUpload] = useState(true);
  const [excelSheet, setExcelSheet] = useState(null);
  const [selectedSheetHeaders, setSelectedSheetHeaders] = useState([]);
  const [mapHeaders, setMappedHeaders] = useState({
    name: "",
    price: "",
    category: "",
    description: "",
    nameAr: "",
    descriptionAr: "",
    barcode:""
  });
  const [duplicateProducts, setDuplicateProducts] = useState([]);
  const allProducts = useSelector(
    ({ productState }) => productState.productList
  );
  const [showDeleteButton, setDeleteButton] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [borderImageColor, setBorderImageColor] = useState(TEXT_COLOR.BACKGROUND);
  const [borderCategoryColor, setBorderCategoryColor] = useState(TEXT_COLOR.BACKGROUND);
  const [showTextfield, setTextfield] = useState(false);
  const [imageUrl, setImage] = useState();
  const [category, setCategory] = useState({
    categoryName: "Select Category",
    id: "",
  });
  const [nameError, setNameError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [barcodeError, setBarcodeError]= useState(false);
  const [priceError, setPriceError] = useState(false);
  const [arabicDetails, setArabicDetails] = useState({
    nameAr: "",
    descriptionAr: "",
  });
  const [newProduct, setNewProduct] = useState({
    name: "",
    description: "",
    price: "",
    barcode:""
  });
  const [imageLoading, setImageLoading] = useState(false);
  const [isMacOS,setIsMacOS] = useState(false);
  const handleAddNewProduct = (name, value) => {
    setNewProduct({ ...newProduct, [name]: value });
  };
  const handleCheckboxChange = (id) => {
    setCheckboxList((value) => ({ ...value, [id]: !checkboxList[id] }));
  };
  const hiddenFileInput = useRef(null);
  const handleClick = (event) => {
    event.target.value = "";
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    const fileReader = new FileReader();
    setShowLoadingPage(true);
    fileReader.onprogress = function (event) {
      setExcelLoaded(Math.floor(event.loaded / 1024));
      setProgressbar(Math.round((event.loaded / event.total) * 100));
    };
    fileReader.readAsArrayBuffer(fileUploaded);
    const acceptableFormats = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];
    const isFormatAccepted = acceptableFormats.includes(fileUploaded.type);
    if (fileUploaded && isFormatAccepted) {
      readXlsxFile(fileUploaded, {
        transformData(data) {
          return data.filter(
            (row) => row.filter((column) => column !== null).length > 0
          );
        },
      }).then((rows) => {
        if(rows.length<2){
          dispatch(
            setNotificationMessage({
              type: "error",
              message: "File cannot be empty.",
            })
          );
        }else{
          setSelectedSheetHeaders(rows[0]);
          setExcelSheet(fileUploaded);
        }
      });
      
    } else if (isFormatAccepted) {
      dispatch(
        setNotificationMessage({
          type: "error",
          message: "No file selected",
        })
      );
    } else {
      dispatch(
        setNotificationMessage({
          type: "error",
          message: "Please select an .xlsx file.",
        })
      );
    }
  };

  const hiddenImageInput = useRef(null);

  const handleImageUploadClick = (event) => {
    if (disableImageUpload === false) {
      event.target.value = "";
      hiddenImageInput.current.click();
    } else {
      dispatch(
        setNotificationMessage({
          type: "error",
          message: "Match the columns correctly.",
        })
      );
    }
  };
  const handleImageLoad = (fileUploaded) => {
      var zipFile = new JSZip();
      zipFile.loadAsync(fileUploaded).then(async (zippedFile) => {
        const fileNames = Object.keys(zippedFile.files);
        if (fileNames.find(a => a.includes("DS_Store")) || navigator.appVersion.includes("Mac")) {
          setIsMacOS(true)
        }
        
        const imageDetails = await Promise.all(
          fileNames.map(async (name) => {
            const imageUrl = await getImageInBlob(zippedFile.files[name]);
            const fileDetails = {};
            if (imageUrl !== null) {
              if (imageUrl.width >= 500 && imageUrl.height >= 500) {
                if((isMacOS || navigator.appVersion.includes("Mac")) && name.includes("/")){
                  fileDetails.name = name.split("/")[1];
                }else{fileDetails.name = name;}
                fileDetails.imageUrl = imageUrl.imageUrl;
              } else {
                dispatch(
                  setNotificationMessage({
                    type: "error",
                    message: "Poor quality image found. We recommend an image size of 500x500 px.",
                  })
                );
              }
            }
            return fileDetails;
          })
        );
        const filtered = imageDetails.filter(
          (items) => Object.keys(items).length !== 0
        );
        setImageFile(filtered);
        setImageLoading(false);
      });
  };
  const getImageInBlob = (file) => {
    return new Promise((resolve) => {
      return file.async("blob").then((fileData) => {
        resolve(fileData);
      });
    }).then(success, failure);
  };
  const success = (blobImage) => {
    var imageUrl = new Image();
    const productImageUrl = URL.createObjectURL(blobImage);
    imageUrl.src = productImageUrl;
    return new Promise((resolve) => {
      imageUrl.onload = function (evt) {
        resolve({
          imageUrl: blobImage,
          width: evt.target.width,
          height: evt.target.height,
        });
      };
      imageUrl.onerror = function () {
        resolve(null);
      };
    });
  };
  const failure = () => {};
  const handleImageUploadChange = (event) => {
    const fileUploaded = event.target.files[0];
    if(fileUploaded && (fileUploaded.type==='application/zip' || fileUploaded.type==="application/x-zip-compressed")){
      setZipFile(fileUploaded);
      setImageLoading(true);
      handleImageLoad(fileUploaded);
    }else {
      dispatch(
        setNotificationMessage({
          type: "error",
          message: "Please select a zip file.",
        })
      );
    }
  };
  const handleCancel = () => {
    setMappedHeaders({
      name: "" ,
      price: "",
      category: "",
      description: "",
      nameAr: "",
      descriptionAr: "",
      barcode: ""
    });
    setDisableImageUpload(true);
    setImageFile(null);
    setProducts([]);
    setProductsList([]);
    setZipFile(null);
  };

  const handleValidateColumnMapping = () => {
    const values = [];
    values.push(
      mapHeaders["name"] || mapHeaders["nameAr"],
      mapHeaders["price"],
      mapHeaders["category"]
    );
     const schema = {
        [mapHeaders["name"].length > 0 ? mapHeaders["name"] : mapHeaders["nameAr"]]: {
          prop: mapHeaders["name"].length > 0 ? "name": "nameAr",
          required: true,
          type: String,
        },

        [mapHeaders["name"]]: {
          prop: "name",
          required: false,
          type: String,
        },
        [mapHeaders["nameAr"]]: {
          prop: "nameAr",
          required: false,
          type: String,
        },
        [mapHeaders["price"]]: {
          prop: "price",
          required: true,
          type: (value) => {
            const price = parseFloat(value);
            if (!price) {
              throw new Error(
                `Please provide numeric price in ${mapHeaders["price"]}`
              );
            }
            if (price >= 0) {
              const requiredValue =
                Math.floor(price) === price
                  ? price
                  : price.toString().split(".")[1].length > 3
                  ? parseFloat(price.toFixed(3))
                  : price;
              return requiredValue;
            }
            return 0;
          },
        },
        [mapHeaders["category"]]: {
          prop: "category",
          type: String,
          required: true,
        },
        [mapHeaders["description"]]: {
          prop: "description",
          required: false,
          type: String,
        },
        [mapHeaders["descriptionAr"]]: {
          prop: "descriptionAr",
          required: false,
          type: String,
        },
        [mapHeaders["barcode"]]: {
          prop: "barcode",
          required: false,
          type: String,
        },
      };
      readXlsxFile(excelSheet, {
        schema,
        transformData(data) {
          return data.filter(
            (row) => row.filter((column) => column !== null).length > 0
          );
        },
      }).then(({ rows, errors }) => {
        if (errors && errors.length !== 0) {
          if (errors[0].error === "required") {
            let columnName = null;
            preDefinedHeaders.forEach((item) => {
              if (mapHeaders[item.value] === errors[0].column) {
                columnName = item.label;
              }
            });
            dispatch(
              setNotificationMessage({
                message: `${columnName} cannot be empty.`,
              })
            );
          } else {
            dispatch(
              setNotificationMessage({
                message: errors[0].error,
              })
            );
          }
        } else if(!values.includes("")){
          setProducts(rows);
          setDisableImageUpload(false);
        }
      });
  };

  const handleValidateImageAndUpload = () => {
    handleValidateColumnMapping();
    if (imageFile !== null) {
      setImageLoadingPage(true);
      //saving only acceptable formats
      const acceptedFiles = imageFile.filter(
        (data) =>
          data.name.endsWith(".tiff") ||
          data.name.endsWith(".jpeg") ||
          data.name.endsWith(".jpg") ||
          data.name.endsWith(".png") ||
          data.name.endsWith(".bmp") ||
          data.name.endsWith(".gif")
      );
      const sizeVerify = imageFile.filter(
        (file) => file.imageUrl.size < 20 * 1024 * 1024
      );
      if (acceptedFiles.length === 0 && imageFile.length !== 0) {
        setImageLoadingPage(false);
        dispatch(
          setNotificationMessage({
            type: "error",
            message: "Accepting only JPG/PNG/GIF/TIFF/BMP formats.",
          })
        );
      } else if (acceptedFiles.length < products.length) {
        setImageLoadingPage(false);
        dispatch(
          setNotificationMessage({
            type: "error",
            message: "Accepted images are less than the number of products.",
          })
        );
      } else if (sizeVerify.length < imageFile.length) {
        setImageLoadingPage(false);
        dispatch(
          setNotificationMessage({
            type: "error",
            message: "Image size cannot be greater than 20Mb.",
          })
        );
      } else {
        const fileReader = new FileReader();
        fileReader.onprogress = function (event) {
          setExcelLoaded(Math.floor(event.loaded / 1024));
          setProgressbar(Math.round((event.loaded / event.total) * 100));
        };
        fileReader.readAsArrayBuffer(zipFile);
        const lowerCaseArray = acceptedFiles.map((value) =>
          value.name.toLowerCase()
        );
        const removeType = lowerCaseArray.map((value) => value.split(".")[0]);
        const removeWhiteSpace = removeType.map((value) =>
          value.replace(/\s+/g, "")
        );
        const errorList = [];
        const charLengthError = [];
        products.forEach((product) => {
          const productName = product && product.name && product.name.toString().toLowerCase().replace(/\s+/g, "");
          const productNameAr = product && product.nameAr && product.nameAr.toString().toLowerCase().replace(/\s+/g, "");
          const index = removeWhiteSpace.indexOf(productName);
          const indexAr = removeWhiteSpace.indexOf(productNameAr);
          if (index === -1 && indexAr === -1) {
            errorList.push(true);
            setImageLoadingPage(false);
            setImageError(true);
          } else {
            errorList.push(false);
          }
        });
        if (errorList && errorList.every((value) => value === false)) {
          const duplicateProductList = [];
          const productSchema = [];
          products.forEach((product, key) => {
            const productName = product && product.name && product.name.toString().toLowerCase().replace(/\s+/g, "");
            const productNameAr = product && product.nameAr && product.nameAr.toString().toLowerCase().replace(/\s+/g, "");
            const imageIndex = removeWhiteSpace.indexOf(productName || productNameAr);
            const productStructure = product;
            const list = products.filter(
              (value) => (value.name && (value.name === product.name)) || (value.nameAr && (value.nameAr === product.nameAr))
            );
            const isPresentinDb = allProducts.filter(
              (value) => (value.name && (value.name === product.name)) || (value.nameAr && (value.nameAr === product.nameAr))
            );
            if (list.length > 1) {
              duplicateProductList.push(key);
              dispatch(
                setNotificationMessage({
                  type: "error",
                  message: "Duplicate product names found in the excel file, Names in EN and AR must be unique.",
                })
              );
            }
            if (isPresentinDb.length) {
              duplicateProductList.push(key);
              dispatch(
                setNotificationMessage({
                  type: "error",
                  message: "Duplicate product names found, product names already exist in youe list, Names in EN and AR must be unique.",
                })
              );
            }
            const file = imageFile && imageFile[imageIndex] && imageFile[imageIndex].imageUrl;
            const productImageUrl = imageFile && imageFile[imageIndex] && URL.createObjectURL(imageFile[imageIndex].imageUrl);
            productStructure.imageUrl = productImageUrl; //to display
            productStructure.name = product.name ? product.name : "";
            productStructure.price = product.price ? product.price : "";
            productStructure.description = product.description
              ? product.description
              : "";
              productStructure.barcode = product.barcode
            ? product.barcode
            : "";
            productStructure.nameAr = product.nameAr ? product.nameAr : "";
            productStructure.descriptionAr = product.descriptionAr
              ? product.descriptionAr
              : "";
            productStructure.category = {
              categoryName: product.category ? product.category : "",
              id: "",
            };
            productStructure.imageFile = file; //to save in the db
            productSchema.push(productStructure);
            if (productStructure.name.length > 60 || productStructure.nameAr.length > 60 ) {
              charLengthError.push(key);
              dispatch(
                setNotificationMessage({
                  type: "error",
                  message: "Product name cannot be greater than 60 chars.",
                })
              );
            }
            if (productStructure.category.categoryName.length > 60) {
              charLengthError.push(key);
              dispatch(
                setNotificationMessage({
                  type: "error",
                  message: "Category cannot be greater than 60 chars.",
                })
              );
            }
            if (productStructure.description.length > 120) {
              charLengthError.push(key);
              dispatch(
                setNotificationMessage({
                  type: "error",
                  message:
                    "Product description cannot be greater than 120 chars.",
                })
              );
            }
            if (productStructure.barcode.length > 64) {
              charLengthError.push(key);
              dispatch(
                setNotificationMessage({
                  type: "error",
                  message:
                    "Barcode cannot be greater than 64 chars.",
                })
              );
            }
          });
          setProductsList(productSchema);
          setDuplicateProducts(duplicateProductList);
          if (charLengthError.length === 0) {
            if (duplicateProductList.length === 0) {
              setDataUploading(true);
              setDisableSave(true);
              setDisableNextButton(true);
              dispatch(
                excelProductUpload({
                  uid,
                  defaultBranchId,
                  productList: productSchema,
                  setDisableNextButton
                })
              );
            } else {
              setShowDuplicateScreen(true);
            }
          } else {
            setImageLoadingPage(false);
          }
        }
      }
    } else if (disableImageUpload) {
      dispatch(
        setNotificationMessage({
          type: "error",
          message: "Match the columns first.",
        })
      );
    } else {
      dispatch(
        setNotificationMessage({
          type: "error",
          message: "Image is required.",
        })
      );
    }
  };

  const handleValidateDuplicate = () => {
    if (showTextfield) {
      handleValidateForms(1);
    } else {
      setDataUploading(true);
      setDisableSave(true);
      setDisableNextButton(true);
      dispatch(
        excelProductUpload({
          uid,
          defaultBranchId,
          productList,
          setDisableNextButton
        })
      );
      handleComponentValue(1);
    }
  };

  const handleValidateForms = (increment) => {
    if (
      imageUrl === null ||
      imageUrl === undefined ||
      imageUrl === "" ||
      category.categoryName === null ||
      category.categoryName === undefined ||
      category.categoryName === "" ||
      category.categoryName === "Select Category" ||
      (newProduct.name.length === 0 && newProduct.nameAr.length === 0)|| 
      newProduct.price === null ||
      newProduct.price === ""
    ) {
      if (imageUrl === null || imageUrl === undefined || imageUrl === "") {
        setBorderImageColor("red");
      }
      if (
        category.categoryName === null ||
        category.categoryName === undefined ||
        category.categoryName === "" ||
        category.categoryName === "Select Category"
      ) {
        setBorderCategoryColor("red");
      }
      if (newProduct.name.length === 0 && newProduct.nameAr.length === 0) {
        setNameError(true);
      }
      // if((newProduct.name.length !== 0 && newProduct.nameAr.length === 0) || (newProduct.name.length === 0 && newProduct.nameAr.length !== 0)){
      //   setNameError(false);
      //   setNameArError(false);
      // }
      if (newProduct.price === null || newProduct.price === "") {
        setPriceError(true);
      }
      dispatch(
        setNotificationMessage({ message: "Fill all the required fields." })
      );
    } else {
      if (category.categoryName.length > 60) {
        setBorderCategoryColor("red");
        dispatch(
          setNotificationMessage({
            message: "Category cannot be greater than 60 chars.",
          })
        );
      }
      if (newProduct.name.length > 60 || newProduct.nameAr.length > 60) {
        setNameError(true);
        dispatch(
          setNotificationMessage({
            message: "Name cannot be greater than 60 chars.",
          })
        );
      }
      if (newProduct.description.length > 120 || newProduct.descriptionAr.length > 120) {
        setDescriptionError(true);
        dispatch(
          setNotificationMessage({
            message: "Description cannot be greater than 120 chars.",
          })
        );
      }
      if (newProduct.barcode.length > 64) {
        setBarcodeError(true);
        dispatch(
          setNotificationMessage({
            message: "Barcode cannot be greater than 64 chars.",
          })
        );
      }
      if (newProduct.price <= 0 || newProduct.price === "0") {
        setPriceError(true);
        dispatch(
          setNotificationMessage({
            message: "Price cannot be less than or equal to 0",
          })
        );
      }
      if (
        newProduct.price !== null &&
        newProduct.price !== "" &&
        newProduct.price > 0 &&
        newProduct.description.length <= 120 &&
        newProduct.barcode.length<64 &&
        ((newProduct.name.length <= 60 && newProduct.name.length !== 0 )|| (newProduct.nameAr.length <= 60 && newProduct.nameAr.length !== 0)) &&
        imageUrl !== null &&
        imageUrl !== undefined &&
        imageUrl !== "" &&
        category.categoryName !== null &&
        category.categoryName !== undefined &&
        category.categoryName !== "" &&
        category.categoryName !== "Select Category"
      ) {
        const productImageUrl = URL.createObjectURL(imageUrl);
        const schema = {
          name: newProduct.name,
          description: newProduct.description,
          price: newProduct.price,
          imageUrl: productImageUrl,
          imageFile: imageUrl,
          category: category,
          nameAr: arabicDetails["nameAr"],
          descriptionAr: arabicDetails["descriptionAr"],
          availability: true, //availability TBD
          barcode: newProduct.barcode || null,
        };
        const prodList = [...productList];
        prodList.push(schema);
        setProductsList(prodList);
        if (increment === 0) {
          //add another
          setTextfield(true);
          deleteTextfieldValues();
        } else if (increment === 2) {
          setTextfield(false);
          deleteTextfieldValues();
        } else {
          //just save the current textfield
          setTextfield(false);
          deleteTextfieldValues();
          setDataUploading(true);
          setDisableSave(true);
          setDisableNextButton(true);
          dispatch(
            excelProductUpload({
              uid,
              defaultBranchId,
              productList,
              setDisableNextButton
            })
          );
          handleComponentValue(1);
        }
      }
    }
  };

  const deleteProductExcel = (id) => {
    setProductsList((previousValue) =>
      previousValue.filter((value, index) => {
        return index !== id;
      })
    );
  };

  const deleteProducts = () => {
    const newList = [...productList];
    const deleteList = [];
    productList.forEach((item, key) => {
      if (checkboxList[key] === true) {
        deleteList.push(key);
      }
    });
    deleteList.sort(function (a, b) {
      return b - a;
    });
    deleteList.forEach((index) => {
      newList.splice(index, 1);
    });
    setProductsList(newList);
  };
  const handleDelete = () => {
    const value = Object.values(checkboxList);
    if (value.length !== 0 && value.every((v) => v === true)) {
      //all products are to be deleted
      setDeleteAllDialog(true);
    } else {
      //some products are to be deleted
      deleteProducts();
    }
  };

  const handleDialogClose = () => {
    setDeleteAllDialog(false);
  };

  const handleBack = () => {
    setImageError(false);
    setImageFile(null);
    setZipFile(null);
    setMappedHeaders({
      name: "",
      price: "",
      category: "",
      description: "",
      nameAr: "",
      descriptionAr: "",
      barcode:""
    });
    setProducts([]);
    setDisableImageUpload(true);
    setProgressbar(0);
  };

  const deleteTextfieldValues = () => {
    setNewProduct({
      name: "",
      description: "",
      price: "",
      barcode:""
    });
    setArabicDetails({
      nameAr: "",
      descriptionAr: "",
    });
    setCategory({
      categoryName: "Select Category",
      id: "",
    });
    setImage("");
    setNameError(false);
    // setNameArError(false);
    setPriceError(false);
    setDescriptionError(false);
    setBarcodeError(false);
    setBorderImageColor(TEXT_COLOR.BACKGROUND);
    setBorderCategoryColor(TEXT_COLOR.BACKGROUND);
  };

  const handleExcelBackButton = () => {
    if (excelSheet && duplicateProducts.length) {
      setShowDuplicateScreen(false);
      setDuplicateProducts([]);
    } else if (showImageLoadingPage && excelSheet) {
      setImageLoadingPage(false);
      handleBack();
    } else if (showLoadingPage && excelSheet) {
      setShowLoadingPage(false);
      setExcelSheet(null);
      setProgressbar(0);
      setExcelLoaded();
    } else if (excelSheet) {
      setShowLoadingPage(false);
      setExcelSheet(null);
      setProgressbar(0);
      setExcelLoaded();
      setSelectedSheetHeaders([]);
      setMappedHeaders({
        name: "",
        price: "",
        category: "",
        description: "",
        nameAr: "",
        descriptionAr: "",
        barcode:""
      });
      setProducts([]);
      setDisableImageUpload(true);
      setImageFile(null);
      setZipFile(null);
    } else {
      handleComponentValue(1);
    }
  };

  useEffect(() => {
    if (progressbar === 100) {
      setTimeout(() => {
        if (showLoadingPage) {
          setProgressbar(0);
          setExcelLoaded();
        }
        setShowLoadingPage(false);
        if (showImageLoadingPage && duplicateProducts.length !== 0) {
          setImageLoadingPage(false);
          setProgressbar(0);
          setExcelLoaded();
        }else if(showImageLoadingPage && duplicateProducts.length === 0){
            handleComponentValue(1);
        }
      }, [500]);
    }
  }, [progressbar]);
  useEffect(() => {
    const duplicateProductList = [];
    productList.forEach((product, key) => {
      const list = productList.filter((value) => value.name === product.name && value.nameAr === product.nameAr );
      const isPresentinDb = allProducts.filter(
        (value) => (value.name && (value.name === product.name)) || (value.nameAr && (value.nameAr === product.nameAr))
      );
      if (list.length > 1) {
        duplicateProductList.push(key);
      }
      if (isPresentinDb.length) {
        duplicateProductList.push(key);
      }
      setDuplicateProducts(duplicateProductList);
    });
    if (duplicateProductList.length === 0) {
      setDisableSave(false);
    } else {
      setDisableSave(true);
    }
    if (productList.length === 0 && products.length !== 0) {
      handleComponentValue(1);
    }
  }, [productList]);

  useEffect(() => {
    const values = [];
    values.push(
      mapHeaders["name"] || mapHeaders["nameAr"],
      mapHeaders["price"],
      mapHeaders["category"]
    );
    const isEmpty = values.some(value=>value!=="");
      if ( isEmpty) {
        handleValidateColumnMapping();
      } 
      // else if(isEmpty){
      //   setDisableImageUpload(true);
      // }
    
  }, [mapHeaders]);

  useEffect(() => {
    let list = {};
    if (checkbox === true) {
      productList.forEach((item, key) => {
        list[key] = true;
      });
    } else {
      productList.forEach((item, key) => {
        list[key] = false;
      });
    }
    setCheckboxList(list);
  }, [checkbox, productList]);

  useEffect(() => {
    const values = Object.values(checkboxList);
    if (values.length !== 0 && values.some((item) => item === true)) {
      setDeleteButton(true);
    } else if (
      values.length === 0 ||
      (values.length !== 0 && values.every((item) => item === false))
    ) {
      setDeleteButton(false);
      setCheckbox(false);
    }
  }, [checkboxList]);

  return (
    <Paper className={classes.paperContainer}>
      {excelSheet && imageError ? (
        <ImageMismatchComponent handleBack={handleBack} />
      ) : (
        <Grid container direction="column">
          <Grid item>
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <IconButton onClick={handleExcelBackButton}>
                  <Icon
                    path={mdiArrowLeft}
                    size="16px"
                    color={TEXT_COLOR.DEFAULT}
                  />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography className={classes.textStyle}>
                  Import Excel sheet
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {showImageLoadingPage ? (
            <Grid item style={{ width: "100%", marginTop: "15%" }}>
              <LoadingPage
                excelLoaded={excelLoaded} // in kb
                progressbar={progressbar} // in %
                excelSheet={zipFile}
              />
            </Grid>
          ) : excelSheet && showDuplicateScreen ? (
            <Grid container direction="column" className={classes.container}>
              <Grid item>
                <div className={classes.alignCenter}>
                  <OptimizeAccount />
                </div>
              </Grid>
              <Grid item>
                <Typography
                  className={`${classes.duplicateMessage} ${classes.orangeColor} ${classes.marginBottom}`}
                >
                  We found duplicated products!
                </Typography>
              </Grid>
              <Grid item>
                {checkbox || showDeleteButton ? (
                  <Grid
                    container
                    direction="row"
                    className={classes.iconContainer}
                  >
                    <Grid item className={`${classes.tableHeadCheckbox} `}>
                      <Checkbox
                        disableRipple
                        icon={
                          <CheckBoxOutlineBlank
                            className={`${classes.checkboxIconSize} ${classes.inActiveCheckbox}`}
                            fontSize="small"
                          />
                        }
                        checkedIcon={
                          <CheckBox
                            style={{ color: TEXT_COLOR.ACTIVE }}
                            fontSize="small"
                          />
                        }
                        checked={checkbox}
                        onChange={() => setCheckbox(!checkbox)}
                        size="small"
                        style={{ display: "flex", alignItems: "center" }}
                      />
                    </Grid>
                    <DividerComponent height="32px" color={TEXT_COLOR.WHITE} />
                    <IconButton size="small" onClick={handleDelete}>
                      <Icon
                        path={mdiClose}
                        size="16px"
                        color= {TEXT_COLOR.DELETE_COLOR}
                        style={{
                          backgroundColor: TEXT_COLOR.WHITE,
                          borderRadius: "20px",
                          padding: "4px",
                        }}
                      />
                    </IconButton>
                    <ButtonBase onClick={handleDelete}>
                      <Typography
                        className={classes.button}
                        style={{ color: TEXT_COLOR.DELETE_COLOR, marginRight: "16px" }}
                      >
                        Delete
                      </Typography>
                    </ButtonBase>
                  </Grid>
                ) : (
                  <TableHeader
                    checkbox={checkbox}
                    setCheckbox={setCheckbox}
                    comingFrom="home"
                  />
                )}
              </Grid>
              <Grid item className={classes.tableBody}>
                {productList.map((item, key) => {
                  return (
                    <TableBody
                      key={`${key}-${item.name}-excel`}
                      keyId={key}
                      item={item}
                      checkboxList={checkboxList}
                      handleCheckboxChange={handleCheckboxChange}
                      comingFrom="excelUpload"
                      duplicateProducts={duplicateProducts}
                      setProductsList={setProductsList}
                      productList={productList}
                      deleteProductExcel={deleteProductExcel}
                    />
                  );
                })}
              </Grid>
              <Grid item>
                {showTextfield && (
                  <AddProduct
                    setCategory={setCategory}
                    category={category}
                    setImage={(imageUrl) => {
                      setImage(imageUrl);
                    }}
                    imageUrl={imageUrl}
                    borderCategoryColor={borderCategoryColor}
                    borderImageColor={borderImageColor}
                    setBorderImageColor={setBorderImageColor}
                    setBorderCategoryColor={setBorderCategoryColor}
                    setName={(value) => {
                      handleAddNewProduct("name", value);
                    }}
                    setDescription={(value) => {
                      handleAddNewProduct("description", value);
                    }}
                    setBarcode={(value) => {
                      handleAddNewProduct("barcode", value);
                    }}
                    setPrice={(value) => {
                      handleAddNewProduct("price", value);
                    }}
                    setPriceError={setPriceError}
                    setNameError={setNameError}
                    setDescriptionError={setDescriptionError}
                    priceError={priceError}
                    nameError={nameError}
                    descriptionError={descriptionError}
                    setBarcodeError={setBarcodeError}
                    barcodeError={barcodeError}
                    name={newProduct["name"]}
                    price={newProduct["price"]}
                    description={newProduct["description"]}
                    barcode= {newProduct["barcode"]}
                    laoding={false}
                    handleSave={() => handleValidateForms(2)}
                    handleAddProductDelete={() => {
                      deleteTextfieldValues();
                      setTextfield(false);
                    }}
                    arabicDetails={arabicDetails}
                    setArabicDetails={setArabicDetails}
                    comingFrom="excelUpload"
                  />
                )}
              </Grid>
              <Grid item>
                <AddProductButton
                  label="Add another"
                  handleClick={() => {
                    if (showTextfield === true) {
                      handleValidateForms(0);
                    }
                    setTextfield(true);
                  }}
                />
              </Grid>
              <Grid item style={{ alignSelf: "flex-end" }}>
                <Grid container direction="row">
                  <Button
                    onClick={() => handleComponentValue(1)}
                    style={{ marginRight: "4px" }}
                    variant="contained"
                    disableElevation
                  >
                    Cancel
                  </Button>
                  <StyledButton
                    disabled={disableSave}
                    onClick={handleValidateDuplicate}
                  >
                    {dataUploading ? "Saving..." : "Save"}
                  </StyledButton>
                </Grid>
              </Grid>
            </Grid>
          ) : excelSheet && showLoadingPage ? ( //excel uploading page
            <Grid item style={{ width: "100%", marginTop: "15%" }}>
              <LoadingPage
                excelLoaded={excelLoaded}
                progressbar={progressbar}
                excelSheet={excelSheet}
              />
            </Grid>
          ) : excelSheet ? (
            <Grid
              container
              direction="column"
              style={{ alignSelf: "center" }}
              alignItems="center"
            >
              <Grid item className={`${classes.marginBottom}`}>
                <Typography
                  className={`${classes.textStyle} ${classes.activeColor}`}
                >
                  Please match your columns with Mashkor data
                </Typography>
              </Grid>
              <Grid item>
                {preDefinedHeaders.map((item, key) => (
                  <Grid
                    container
                    style={{ height: "40px" }}
                    direction="row"
                    key={key}
                    className={`${classes.marginBottom}`}
                  >
                    <Grid item style={{ alignSelf: "center" }}>
                      <Typography
                        style={{ color: TEXT_COLOR.ACTIVE }}
                        className={classes.count}
                      >
                        {`${key + 1}`}
                      </Typography>
                    </Grid>
                    <Grid item key={key}>
                      <StyledTextField
                        name={item.value}
                        className={`${classes.dimension}`}
                        size="small"
                        variant="outlined"
                        label={item.label}
                        InputProps={{
                          style: {
                            color: TEXT_COLOR.ACTIVE,
                            fontFamily: "Nunito",
                            fontSize: "16px",
                            textAlign: "left",
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            color: TEXT_COLOR.DEFAULT,
                            fontFamily: "Nunito",
                            fontSize: "16px",
                          },
                        }}
                        select
                        value={
                          mapHeaders[item.value] ? mapHeaders[item.value] : ""
                        }
                        onChange={(event) => {
                          setMappedHeaders({
                            ...mapHeaders,
                            [event.target.name]: event.target.value,
                          });
                        }}
                      >
                        {selectedSheetHeaders.map((item, key) => {
                          const values = Object.values(mapHeaders);
                          let disabled = null;
                          if (values.includes(item)) {
                            disabled = true;
                          } else {
                            disabled = false;
                          }
                          return (
                            <MenuItem
                              disabled={disabled}
                              key={`${key}-${item}`}
                              value={item}
                            >
                              {item}
                            </MenuItem>
                          );
                        })}
                      </StyledTextField>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <Grid item>
                <Typography
                  className={`${classes.textStyle} ${classes.orangeColor} ${classes.marginBottom}`}
                >
                  Image name should be same as product name.
                </Typography>
              </Grid>
              <Box
                onClick={handleImageUploadClick}
                className={`${classes.uploadContainer} ${classes.dimension} ${classes.marginBottom}`}
              >
                <Icon
                  path={mdiProgressUpload}
                  size="16px"
                  color={TEXT_COLOR.DEFAULT}
                  className={classes.icon}
                />
                <Typography>
                  {zipFile && imageLoading
                    ? `Loading...`
                    : zipFile
                    ? `${zipFile.name} selected`
                    : "Select image zip file"}
                </Typography>
                <input
                  name="excel sheet"
                  type="file"
                  style={{ display: "none" }}
                  ref={hiddenImageInput}
                  onChange={handleImageUploadChange}
                />
              </Box>
              <Grid item className={`${classes.marginBottom} `}>
                <Grid container direction="row" className={classes.flexEnd}>
                  <Button
                    style={{ marginRight: "4px" }}
                    variant="contained"
                    disableElevation
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                  <StyledButton
                    disabled={dataUploading || imageLoading}
                    onClick={handleValidateImageAndUpload}
                  >
                    {dataUploading ? "Uploading..." : "Match and upload"}
                  </StyledButton>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              style={{ marginTop: "10%" }}
            >
              <Grid item style={{ height: "93px", marginBottom: "16px" }}>
                <Typography className={classes.imageLabel}>Xlsx</Typography>
                <img src={ExcelImage} alt="" className={classes.imageUrl} />
              </Grid>
              <Box
                onClick={handleClick}
                className={`${classes.uploadContainer} ${classes.dimension}`}
              >
                <Icon
                  path={mdiProgressUpload}
                  size="16px"
                  color={TEXT_COLOR.DEFAULT}
                  className={classes.icon}
                />
                <Typography>Select Excel sheet</Typography>
                <input
                  name="excel sheet"
                  type="file"
                  style={{ display: "none" }}
                  ref={hiddenFileInput}
                  onChange={handleChange}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      )}
      <DeleteDialog
        open={deleteAllDialog}
        handleClick={() => {
          deleteProducts();
          handleDialogClose();
        }}
        handleDialogClose={handleDialogClose}
      />
    </Paper>
  );
}
