import { TEXT_COLOR } from "../../constants/theme";

const { makeStyles } = require("@material-ui/core");

export const useStyles = makeStyles(theme=>({
  title: {
    fontFamily: "nunito",
    color: TEXT_COLOR.DEFAULT,
    fontSize: "16px",
  },
  root: {
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
  },
  bottomMargin: {
    marginBottom: "2vh",
  },
  imageContainer: {
    border: `1px dashed ${TEXT_COLOR.DEFAULT}`,
    borderRadius: "4px",
  },
  alignImage: {
    alignItems: "center",
    display: "flex",
  },
  dimension: {
    [theme.breakpoints.up("sm")]: {
      width: "470px",
    },
    height: "5vh",
  },
  mobileItem:{
    [theme.breakpoints.down("sm")]: {
      display: "none"
    },
  },
  mobileItem2:{
    [theme.breakpoints.up("sm")]: {
      display: "none"
    },
  },
  greenColor: {
    color: TEXT_COLOR.ACTIVE,
  },
  imageLabel: {
    fontFamily: "nunito",
    fontSize: "16px",
  },
  businessTypeContainer: {
    border: `1px solid ${TEXT_COLOR.ACTIVE}`,
    borderRadius: "4px",
  },
  inputStyle: {
    fontFamily: "Nunito",
    fontSize: "16px",
    textAlign: "left"
  },
  defaultColor:{
    color: TEXT_COLOR.DEFAULT
  },
  error: {
    color: TEXT_COLOR.ERROR,
    fontSize: 12
}
}));
