import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
});

export default useStyles;