import { call, put } from "redux-saga/effects";
import { routes } from "../constants";
import {
  handleSignInRequest,
  sendingVerificationLink,
  checkIfUserExists,
  handleAuthentication,
  signOutService,
  OTPAuthRequest
} from "../service/api/authService";
import {
  createSellerCollection,
  checkSellerCollection,
  createDefaultBranch, getDefaultBranchId
} from "../service/api/sellerCollection";

export function* signInSaga({ payload }) {
  const { history, setDisableLogin } = payload;
  try {
    //signing in
    const { response } = yield call(handleSignInRequest, payload);
    const collectionExists = yield call(checkSellerCollection, payload);
    yield call(signOutService);
        yield put({
          type: 'REMOVE_AUTH'
        });
    if (collectionExists.docs.length === 0) {
      //if email not verified or entry not present, then showing alert.
      const payload = {
        type: "error",
        message: "Unable to login. Please check your email id or password",
      };
      yield put({
        type: "SET_NOTIFICATION_MESSAGE",
        payload,
      });
      setDisableLogin(false);
    } 
    // else if (!response.user.emailVerified) {
    //   const payload = {
    //     type: "error",
    //     message: "Unable to login. Please check your email id or password ",
    //   };
    //   yield put({
    //     type: "SET_NOTIFICATION_MESSAGE",
    //     payload,
    //   });
    //   setDisableLogin(false);
    // } 
    else {
       yield call(handleSignInRequest, payload);//to access default branch
      const data = {
        uid: response.user.uid,
        email: payload.email,
      };
      const defaultBranchId = yield call(getDefaultBranchId, {
        uid: response.user.uid,
      });
      yield put({
        type: 'SAVE_DEFAULT_BRANCH',
        payload: {
          defaultBranchId: defaultBranchId
        }
      });
      yield put({
        type: "SAVE_AUTH",
        payload: data,
      });
      setDisableLogin(false);
      history.replace(routes.HOME);
    }
  } catch (error) {
    setDisableLogin(false);
    const payload = { type: "error", message: error.message };
    yield put({
      type: "SET_NOTIFICATION_MESSAGE",
      payload,
    });
  }
}

export function* signUpSaga({ payload }) {
  const { history, setDisableLogin, email, phone } = payload;
  try {
    //checking if user exists or not
    const response = yield call(checkIfUserExists, payload);

    if (response.value === "created") {
      //if doesn't exist, then creating seller collection, sending verification link
      // and navigating to home
      yield call(createSellerCollection, payload, response.uid);
     const branch= yield call(createDefaultBranch, response.uid);
     yield put({
      type: 'SAVE_DEFAULT_BRANCH',
      payload: {
        defaultBranchId: branch.branchId
      }
    });
      yield put({
        type: 'SAVE_AUTH',
        payload: {
          email,
          uid: response.uid
        }
      })

      history.replace(routes.HOME);
    } else if (response.value === "Already registered") {
      //if email already exist, then checking entry in the seller collection
      
      const userDetails = yield call(handleSignInRequest, payload);
      const collectionExists = yield call(checkSellerCollection, payload);
      if (collectionExists.docs.length === 0) {
        if (userDetails.response) {
          yield call(
            createSellerCollection,
            payload,
            userDetails.response.user.uid
          );
        const branch=  yield call(createDefaultBranch, userDetails.response.user.uid);
        yield put({
          type: 'SAVE_DEFAULT_BRANCH',
          payload: {
            defaultBranchId: branch.branchId
          }
        });
          yield put({
            type: 'SAVE_AUTH',
            payload: {
              email,
              uid: userDetails.response.user.uid
            }
          });
          history.replace(routes.HOME);
        }
      } else {
        yield put({
          type: "SET_NOTIFICATION_MESSAGE",
          payload: {
            type: "error",
            message: "Email already exists. Please sign in",
          },
        });
        yield call(signOutService);
        yield put({
          type: 'REMOVE_AUTH'
        });
        setDisableLogin(false);
      }
    }
  }catch ( error) {
    let payload;
    if( error.code==="auth/wrong-password"){
      yield put({
        type: 'REMOVE_AUTH'
      });
         payload = { type: "error", message: "Email already in use." };
        
    }else{
      yield call(signOutService);
      yield put({
        type: 'REMOVE_AUTH'
      });
         payload = { type: "error", message: error.message };
        
      }
      yield put({
        type: "SET_NOTIFICATION_MESSAGE",
        payload,
      });
      setDisableLogin(false);
    }
}  



export function* isAuthenticatedSaga({ payload }) {
  const { history, setLoading } = payload;
  try {
    const res = yield call(handleAuthentication);
    if (res) {
      const userData = yield call(checkSellerCollection, res);

      yield put({
        type: "SAVE_AUTH",
        payload: {
          ...userData.docs[0].data(),
          uid: res.uid,
        },
      });
    const defaultBranchId = yield call(getDefaultBranchId, {
        uid: res.uid,
      });
      yield put({
        type: 'SAVE_DEFAULT_BRANCH',
        payload: {
          defaultBranchId: defaultBranchId
        }
      });
      history.push(routes.HOME);
    }
  } catch (err) {
    history.push(routes.SIGN_IN);
    const payload = { type: "error", message: "Something went wrong" };
    yield put({
      type: "SET_NOTIFICATION_MESSAGE",
      payload,
    });
  } finally {
    setLoading(false);
  }
}

export function* signOutSaga() {
  try {
    let res = yield call(handleAuthentication);
    if (res) {
      yield call(signOutService);
      yield put({
        type: "REMOVE_AUTH",
      });
    }
  } catch (err) {
    const payload = { type: "error", message: err.message };
    yield put({
      type: "SET_NOTIFICATION_MESSAGE",
      payload,
    });
  }
}
