import {
  ButtonBase,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  RadioGroup,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React, { useRef, useState } from "react";
import { useStyles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { setNotificationMessage } from "../../actions/notificationActions";
import { addCategoryAction } from "../../actions/addCategory";
import { AddImageComponent } from "../../components/AddImage";
import Icon from "@mdi/react";
import { mdiClose, mdiContentSave, mdiCheckCircle } from "@mdi/js";
import { StyledTextFieldComponent } from "../../components/Textfield";
import { AddProductButton } from "../../components/AddProductButton";
import { StyledRadio } from "../../components/CustomSwitch";
import { productField } from "../../constants/firebaseDoc";
import { TEXT_COLOR } from "../../constants/theme";
import { DividerComponent } from "../../components/Divider";
import NumberFormat from 'react-number-format';

const StyledTextfield = withStyles({
  root: {
    "& label.Mui-focused": {
      color: TEXT_COLOR.BACKGROUND,
    },

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: `2px solid ${TEXT_COLOR.BACKGROUND}`,
      },
      "&:hover fieldset": {
        border: `2px solid ${TEXT_COLOR.BACKGROUND}`,
      },
      "&.Mui-focused fieldset": {
        border: `2px solid ${TEXT_COLOR.BACKGROUND}`,
      },
    },
  },
})((props) => <TextField {...props} />);

export function CategoryMenu(props) {
  const { uid } = useSelector(({ authState }) => authState.user);
  const { anchorEl, setAnchorEl, setCategory, category } = props;
  // const [categoryList, setCategoryList] = useState([]);
  const categoryList = useSelector(
    ({ categoryState }) => categoryState.category
);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [displayTextfield, setDisplayTextfield] = useState(false);
  const [typedCategory, setTypedCategory] = useState("");
  const { defaultBranchId } = useSelector(
    ({ defaultBranchState }) => defaultBranchState.defaultBranch
  );
  const inputRef = useRef();
  const handleClose = () => {
    setAnchorEl(null);
    setDisplayTextfield(false);
    setTypedCategory("");
  };
  const submitCategory = () => {
    if (typedCategory !== "" && typedCategory !== undefined) {
      const isPresent = categoryList.filter(
        (value) =>
          value.categoryName.toUpperCase() === typedCategory.toUpperCase()
      );
      if (isPresent.length === 0) {
        const displayIndex = findDisplayIndex();
        dispatch(
          addCategoryAction({
            categoryName: typedCategory,
            displayIndex,
            uid,
            setCategory,
            defaultBranchId,
          })
        );
        handleClose();
      } else {
        setCategory(isPresent[0]);
        handleClose();
      }
    } else {
      dispatch(
        setNotificationMessage({
          message: "Category cannot be empty",
        })
      );
    }
  };
  const findDisplayIndex = () => {
    if (categoryList.length === 0) {
      return 0;
    } else if (categoryList.length === 1) {
      return categoryList[0].displayIndex + 1;
    } else {
      const maxIndex = categoryList.reduce(function (a, b) {
        return a.displayIndex > b.displayIndex ? a : b;
      });
      return maxIndex.displayIndex + 1;
    }
  };
  return (
    <Menu
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      getContentAnchorEl={null}
      open={anchorEl ? true : false}
      onClose={handleClose}
    >
      <div className={classes.categoryRoot}>
        <div className={classes.categoryMenuTitle}>Category</div>
        <FormControl>
          <RadioGroup
            value={category && category.categoryName}
            onChange={(event) => {
              categoryList.forEach(({ categoryName, id }) => {
                if (categoryName === event.target.value) {
                  setCategory({
                    categoryName: categoryName,
                    id: id,
                  });
                }
              });
              handleClose();
            }}
          >
            {categoryList.map((item, key) => (
              <FormControlLabel
                key={`${key}-${item.categoryName}`}
                value={item.categoryName}
                control={<StyledRadio />}
                label={
                  <Typography
                    style={{
                      fontSize: "12px",
                      fontFamily: "Nunito",
                      fontWeight: "bold",
                      color: `${
                        category.categoryName === item.categoryName
                          ? TEXT_COLOR.ACTIVE
                          : "#B2B2B2"
                      }`,
                    }}
                  >
                    {item.categoryName}
                  </Typography>
                }
              />
            ))}
          </RadioGroup>
        </FormControl>
        {!displayTextfield && (
          <AddProductButton
            style={{ marginBottom: "16px" }}
            label="Add Category"
            handleClick={() => setDisplayTextfield(true)}
          />
        )}
        {displayTextfield && (
          <StyledTextfield
            style={{ marginRight: "16px" }}
            variant="outlined"
            label="Add new category"
            value={typedCategory}
            size="small"
            InputLabelProps={{
              style: {
                fontSize: "16px",
                fontFamily: "Nunito",
                color: TEXT_COLOR.B2B2B2,
              },
            }}
            inputRef={(input) => {
              inputRef.current = input;
            }}
            onChange={(event) => {
              const value = event.target.value;
              if (value.length > 60) {
                dispatch(
                  setNotificationMessage({
                    message: "Category cannot be more than 60 characters",
                  })
                );
              } else if (value.replace(/^\s+|\s+$/g, "").length) {
                setTypedCategory(event.target.value);
              } else {
                setTypedCategory("");
              }
            }}
            onBlur={() => {
              inputRef.current.focus();
            }}
          />
        )}
        {displayTextfield && (
          <Grid
            alignItems="center"
            container
            direction="row"
            style={{ marginBottom: "16px" }}
            onClick={submitCategory}
          >
            <Icon path={mdiContentSave} size="16px" color={TEXT_COLOR.ACTIVE} />
            <ButtonBase>
              <Typography className={`${classes.button} ${classes.greenColor}`}>
                Save
              </Typography>
            </ButtonBase>
          </Grid>
        )}
      </div>
    </Menu>
  );
}

export function AddProduct({
  comingFrom,
  handleSave,
  category,
  imageUrl,
  setImage,
  setCategory,
  setBorderCategoryColor,
  setName,
  setPrice,
  setDescription,
  name,
  description,
  price,
  priceError,
  setPriceError,
  nameError,
  setNameError,
  nameArError,
  setNameArError,
  setDescriptionError,
  descriptionError,
  borderCategoryColor,
  setBorderImageColor,
  borderImageColor,
  handleAddProductDelete,
  borderColor,
  setBorderColor,
  loading,
  arabicDetails,
  setArabicDetails,
  barcode,
  setBarcode,
  setBarcodeError,
  barcodeError

}) {
  const dispatch = useDispatch();
  const [previewImage, setPreviewImage] = useState("");
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [descriptionArError, setDescriptionArError] = useState(false);
  const acceptableFormats = [
    "image/jpeg",
    "image/png",
    "image/gif",
    "image/tiff",
    "image/bmp",
  ];
  const handleMenu = (event) => {
    setBorderCategoryColor(TEXT_COLOR.BACKGROUND);
    comingFrom === "home"
      ? setBorderColor(TEXT_COLOR.ACTIVE)
      : setBorderCategoryColor(TEXT_COLOR.BACKGROUND);
    setAnchorEl(event.currentTarget);
  };

  const handleChange = (event) => {
    setBorderImageColor(TEXT_COLOR.BACKGROUND);
    comingFrom === "home"
      ? setBorderColor(TEXT_COLOR.ACTIVE)
      : setBorderImageColor(TEXT_COLOR.BACKGROUND);
    const fileUploaded = event.target.files[0];
    if (fileUploaded) {
      const isFormatAccepted = acceptableFormats.filter(
        (value) => value === fileUploaded.type
      );
      if (
        fileUploaded.size < 20 * 1024 * 1024 &&
        isFormatAccepted.length !== 0
      ) {
        var imageUrl = new Image();
        const productImageUrl = URL.createObjectURL(fileUploaded);
        imageUrl.src = productImageUrl;
        imageUrl.onload = function (evt) {
          const width = evt.target.width;
          const height = evt.target.height;
          if (width >= 500 && height >= 500) {
            setImage(fileUploaded);
            setPreviewImage(productImageUrl);
          } else {
            dispatch(
              setNotificationMessage({
                type: "error",
                message: "Poor quality image. We recommend an imageUrl size of 500x500 px.",
              })
            );
          }
        };
      } else if (fileUploaded.size < 20 * 1024 * 1024) {
        dispatch(
          setNotificationMessage({
            message: "Format should be JPG/PNG/GIF/TIFF/BMP.",
          })
        );
      } else if (isFormatAccepted.length !== 0) {
        dispatch(
          setNotificationMessage({ message: "File should be less than 20MB" })
        );
      }
    }
  };
  const hiddenFileInput = useRef(null);
  const handleClick = (event) => {
    event.target.value = "";
    hiddenFileInput.current.click();
  };
  const handleArabicDetailsChange = (event) => {
    if(event.target.name === "nameAr" && (event.target.value.trim().length > 60 || event.target.value.trim().length === 0) ){
      setNameArError(true)
    }else {
      setNameArError(false)
      setNameError(false)
    }
    if(event.target.name === "descriptionAr" && event.target.value.length > 120){
      setDescriptionArError(true)
    }else{setDescriptionArError(false)}

    setArabicDetails({
      ...arabicDetails,
      [event.target.name]: event.target.value,
    });
  };
  const handleImageDelete = () => {
    setPreviewImage('');
  }

  return (
    <Grid
      container
      direction="column"
      className={`${classes.bottomMargin}  ${classes.addProcuctBorder}`}
      style={{
        border: comingFrom === "home" ? `1px solid ${borderColor}` : "",
      }}
    >
      <Grid item className={`${classes.body}`}>
        <Grid container direction="row">
          <Grid item className={`${classes.tableBody} ${classes.flexFive}`}>
            <StyledTextFieldComponent
              onFocus={() =>
                comingFrom === "home" ? setBorderColor(TEXT_COLOR.ACTIVE) : ""
              }
              value={name}
              error={nameError}
              label="Product name [EN]"
              onChange={(event) => {
                const value = event.target.value;
                ( value.length > 60) ? setNameError(true) : (setNameError(false));
                ( value.trim().length.length > 0 && arabicDetails.nameAr === "" ) ? 
                (setNameArError(true) ): 
                (setNameArError(false));
                setName(event.target.value);
              }}
              onInput={e => {
                if(e.target.value.length >= 60){
                  e.target.value = (e.target.value).toString().slice(0, 60);}
              }}
            />
            {nameError?<Typography
              variant="body2"
              component="span"
              className={classes.error}
            >
              Max length 60 characters
            </Typography>:null}
          </Grid>
          <Grid item className={`${classes.tableBody} ${classes.flexFour}`}>
            <StyledTextFieldComponent
              onFocus={() =>
                comingFrom === "home" ? setBorderColor(TEXT_COLOR.ACTIVE) : ""
              }
              error={nameArError}
              value={arabicDetails[productField.ARABIC_NAME]}
              name={productField.ARABIC_NAME}
              onChange={handleArabicDetailsChange}
              label="Product name [AR]"
              InputLabelProps={{
                style: {
                  color: name ? TEXT_COLOR.ACTIVE : "#787878",
                  fontFamily: "Nunito",
                  fontSize: "12px",
                },
              }}
              // className={classes.error}
              onInput={e => {
                if(e.target.value.length >= 60){
                  e.target.value = (e.target.value).toString().slice(0, 60);}
              }}
            />
            {nameArError?<Typography 
              variant="body2"
              component="span"
              className={classes.error}
            >
              Max length 60 characters
            </Typography>:null}
          </Grid>
          <Grid
            item
            className={`${classes.tableBody} ${classes.flexTwo}`}
            style={{
              borderRight: `1px solid ${borderImageColor}`,
              borderLeft: `1px solid ${borderImageColor}`,
              borderTop: `${
                borderImageColor === "red" ? "1px solid #FF0000" : ""
              }`,
              borderBottom: `${
                borderImageColor === "red" ? "1px solid #FF0000" : ""
              }`,
              display: "flex",
              justifyContent: "center",
            }}
          >
            {(imageUrl && previewImage) ? (
              <div
              style={{
                position: "relative",
              }}
              >
                <img
                  alt="product"
                  src={previewImage}
                  width="30px"
                  height="30px"
                />
                <IconButton
                  size="small"
                  style={{
                    position: "absolute",
                    display: "flex",
                    top: -10,
                    right: -10,
                  }}
                  onClick={handleImageDelete}
                  edge="end"
                >
                  <Icon
                    path={mdiClose}
                    size="6px"
                    color={TEXT_COLOR.BLACK}
                    className={classes.iconStyling}
                  />
                </IconButton>
              </div>
            ) : (
              <IconButton onClick={handleClick}>
                <AddImageComponent />
                <input
                  name="imageUrl"
                  type="file"
                  style={{ display: "none" }}
                  ref={hiddenFileInput}
                  onChange={handleChange}
                />
              </IconButton>
            )}
          </Grid>
          <Grid item className={`${classes.tableBody} ${classes.flexThree} `}>
          <StyledTextFieldComponent
              value={price}
              onFocus={() =>
                comingFrom === "home" ? setBorderColor(TEXT_COLOR.ACTIVE) : ""
              }
              type="number"
              label="Enter price"
              error={priceError}
              onInput={e => {
                if(e.target.value.length > 8){
                  e.target.value = Math.max(0, parseFloat(e.target.value)).toString().slice(0, 8);}
              }}
              onKeyDown={(evt) => {
                if (evt.key === "e" || evt.key === "E") {
                  evt.preventDefault();
                }
              }}
              onChange={(event) => {
                setPriceError(false);
                const floatValue = parseFloat(event.target.value);
                if (floatValue >= 0.01) {
                  const requiredValue =
                    Math.floor(floatValue) === floatValue
                      ? floatValue
                      : floatValue.toString().split(".")[1].length > 3
                      ? parseFloat(floatValue.toFixed(3))
                      : floatValue;

                  setPrice(isNaN(requiredValue) ? "" : requiredValue);
                } else {
                  setPrice("")
                  setPriceError(true);};
              }}
            />
          </Grid>
          <Grid
            item
            className={`${classes.tableBody} ${classes.flexFour}`}
            style={{
              borderRight: `1px solid ${borderCategoryColor}`,
              borderLeft: `1px solid ${borderCategoryColor}`,
              borderTop: `${
                borderCategoryColor === "red" ? "1px solid #FF0000" : ""
              }`,
              borderBottom: `${
                borderCategoryColor === "red" ? "1px solid #FF0000" : ""
              }`,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid
              onClick={handleMenu}
              container
              direction="row"
              alignItems="flex-end"
              wrap="nowrap"
              justify="space-between"
            >
              <Typography
                style={{
                  color:
                    category.categoryName === "Select Category"
                      ? "#787878"
                      : TEXT_COLOR.BLUE,
                }}
                className={classes.text}
              >
                {category.categoryName}
              </Typography>
              {/* <IconButton > */}
              <ExpandMore />
              {/* </IconButton> */}
            </Grid>
          </Grid>
          <CategoryMenu
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            category={category}
            setCategory={setCategory}
          />
        </Grid>
      </Grid>
      <Grid item className={`${classes.body}`}>
        <Grid
          container
          direction="row"
          style={{ borderTop: `1px solid ${TEXT_COLOR.BACKGROUND}` }}
        >
          <Grid className={`${classes.tableBody} ${classes.flexSeven}`} item>
            <StyledTextFieldComponent
              onFocus={() =>
                comingFrom === "home" ? setBorderColor(TEXT_COLOR.ACTIVE) : ""
              }
              value={description}
              error={descriptionError}
              label="Description [EN]"
              onChange={(event) => {
                const value = event.target.value;
                value.length > 120
                  ? setDescriptionError(true)
                  : setDescriptionError(false);
                setDescription(event.target.value);
              }}
              onInput={e => {
                if(e.target.value.length >= 120){
                  e.target.value = (e.target.value).toString().slice(0, 120);}
              }}
            />
            {descriptionError?<Typography
              variant="body2"
              component="span"
              className={classes.error}
            >
              Max length 120 characters
            </Typography>:null}
          </Grid>
          <DividerComponent height='40px' color={TEXT_COLOR.BACKGROUND}/>
          <Grid className={`${classes.tableBody} ${classes.flexSeven}`} item>
            <StyledTextFieldComponent
              onFocus={() =>
                comingFrom === "home" ? setBorderColor(TEXT_COLOR.ACTIVE) : ""
              }
              InputLabelProps={{
                style: {
                  color: description ? TEXT_COLOR.ACTIVE : "#787878",
                  fontFamily: "Nunito",
                  fontSize: "12px",
                },
              }}
              value={arabicDetails[productField.ARABIC_DESCRIPTION]}
              name={productField.ARABIC_DESCRIPTION}
              onChange={handleArabicDetailsChange}
              label="Description [AR]"
              error={false}
              onInput={e => {
                if(e.target.value.length >= 120){
                  e.target.value = (e.target.value).toString().slice(0, 120);}
              }}
            />
            {descriptionArError?<Typography 
              variant="body2"
              component="span"
              className={classes.error}
            >
              Max length 120 characters
            </Typography>:null}
          </Grid>
          {!barcodeError && <DividerComponent height='40px' color={TEXT_COLOR.BACKGROUND}/>}
          <Grid className={`${classes.tableBody} ${classes.flexFour}`} item>
            <StyledTextFieldComponent
              onFocus={() =>
                comingFrom === "home" ? setBorderColor(TEXT_COLOR.ACTIVE) : ""
              }
              value={barcode}
              error={barcodeError}
              label="Barcode"
              onChange={(event) => {
                const value = event.target.value;
                value.length > 64
                  ? setBarcodeError(true)
                  : setBarcodeError(false);
                setBarcode(event.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Grid item>
                      {comingFrom === "home" || comingFrom === "excelUpload" ? (
                        <IconButton
                          onClick={handleSave}
                          size="small"
                          edge="end"
                          disabled={loading}
                        >
                          <Icon
                            path={mdiCheckCircle}
                            size="16px"
                            color= {TEXT_COLOR.BLACK}
                            className={classes.iconStyling}
                          />
                        </IconButton>
                      ) : (
                        ""
                      )}

                      <IconButton
                        size="small"
                        onClick={handleAddProductDelete}
                        edge="end"
                      >
                        <Icon
                          path={mdiClose}
                          size="16px"
                          color= {TEXT_COLOR.BLACK}
                          className={classes.iconStyling}
                        />
                      </IconButton>
                    </Grid>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
