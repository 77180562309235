import { Grid, MenuItem, Paper, Typography } from "@material-ui/core";
import React, { useState } from "react";
import ShadowlessButton from "../../../components/ShadowlessButton";
import { StyledTextField } from "../../../components/Textfield";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "./styles";
import { preparationTimeAction } from "../../../actions/preparationTime";
import { PrepTimeImage } from "../../../assets/images";
import { TEXT_COLOR } from "../../../constants/theme"

function PreparationTime() {
  const  defaultBranchId = useSelector(
    ({ defaultBranchState }) => defaultBranchState.defaultBranch?.defaultBranchId
  );
  const uid = useSelector(({ authState }) => authState.user.uid);
  const [disable, setDisable]= useState(false);
  const dispatch = useDispatch();
  const [time, setTime] = useState(useSelector(({preparationTimeState})=>preparationTimeState.preparationTime));
  const classes = useStyles();
  function getHours() {
    const hours = [];
    for (let i = 0; i <= 23; i++) hours.push(i < 10 ? `0${i}` : i);
    return hours;
  }
  function getMinutes() {
    const minutes = [];
    for (let i = 0; i <= 59; i++) minutes.push(i < 10 ? `0${i}` : i);
    return minutes;
  }
  const handleChange = (event) => {
    setTime({ ...time, [event.target.name]: event.target.value });
  };
  const handleSave = () => {
    setDisable(true);  
    const timeInMins = parseInt(time["hours"]) * 60 + parseInt(time["minutes"]);
    dispatch(preparationTimeAction({uid, branchId: defaultBranchId,preparationTime:timeInMins, setDisable}));
  };
  return (
    (
      <Paper className={classes.root}>
        <Grid container spacing={3} direction='column' className={classes.grid}>
          <Grid item style={{ alignSelf: "start" }}>
            <Typography className={classes.label}>Preparation time</Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              component="p"
              className={classes.onlyMobile}
            >
              Please use a desktop to update your preparation time.
            </Typography>
          </Grid>
          <Grid className={classes.container}>
            <Grid item className={classes.imgCtn}>
              <img src={PrepTimeImage} alt="" />
            </Grid>
            <Grid item className={classes.contentCtn}>
              <Typography className={classes.content}>
                The time required to prepare the request
              </Typography>
            </Grid>
            <Grid item className={classes.timeCtn}>
              <Grid container direction="row">
                <Grid item style={{ marginRight: "2vw" }}>
                  <StyledTextField
                    select
                    variant="outlined"
                    name="hours"
                    label="Hours"
                    defaultValue="00"
                    value={time.hours}
                    onChange={handleChange}
                    SelectProps={{
                      MenuProps: {
                        style: {
                          maxHeight: "45%",
                        },
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "center",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "center",
                        },
                        getContentAnchorEl: null,
                      },
                    }}
                    InputProps={{ className: classes.time }}
                    InputLabelProps={{
                      style: {
                        color: TEXT_COLOR.ACTIVE,
                        fontFamily: "Nunito",
                        fontSize: 13
                      },
                    }}
                  >
                    {getHours().map((item, key) => (
                      <MenuItem key={`${key}-${item}`} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </StyledTextField>
                </Grid>
                <Grid item style={{ marginRight: "2vw" }}>
                  <StyledTextField
                    select
                    variant="outlined"
                    label="Minutes"
                    name="minutes"
                    defaultValue="25"
                    value={time.minutes}
                    onChange={handleChange}
                    SelectProps={{
                      MenuProps: {
                        style: {
                          maxHeight: "45%",
                        },
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "center",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "center",
                        },
                        getContentAnchorEl: null,
                      },
                    }}
                    InputProps={{ className: classes.time }}
                    InputLabelProps={{
                      style: {
                        color: TEXT_COLOR.ACTIVE,
                        fontFamily: "Nunito",
                        fontSize: 13
                      },
                    }}
                  >
                    {getMinutes().map((item, key) => (
                      <MenuItem key={`${key}-${item}`} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </StyledTextField>
                </Grid>
                <Grid item>
                  <ShadowlessButton
                    disabled={disable}
                    onClick={handleSave}
                    className={`${classes.saveButton} ${classes.time}`}
                  >
                    {disable ? "Saving.." : "Save"}
                  </ShadowlessButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    )
  );
}

export default PreparationTime;
