import { makeStyles } from "@material-ui/core";
import { TEXT_COLOR } from "../../constants/theme";
export const useStyles = makeStyles({
  marginBottom: {
    marginBottom: "16px",
  },
  heading: {
    fontSize: "14px",
    fontWeight: "550",
    alignSelf: "flex-start",
  },
  inputLabel: {
    fontSize: "11px",
    marginBottom: "4px",
  },
  link: {
    color: TEXT_COLOR.ORANGE,
    fontFamily: "nunito",
    fontSize: "12px",
  },
  text: {
    fontFamily: "nunito",
    fontSize: "12px",
    marginRight: "8px",
  },
  icon: {
    fontSize: "16px",
  },
  blueColor: {
    color: TEXT_COLOR.BLUE,
  },
  inputProps: {
    height: "14px",
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: "550",
    color: TEXT_COLOR.BLUE,
  },
  errorContainer:{
    marginTop:"5px"
  },
  radio: {
    '&$checked': {
      color: 'red'
    }
  },
  checked: {}
});
