import { Button, Grid, Paper } from "@material-ui/core";
import React from "react";
import { useStyles } from "./styles";
import { MashkorSignature } from "../../assets/icons";

export function SigninCard({children}){
    const classes= useStyles();
return(
    <div className={`${classes.root}`}>
        <Paper elevation={1} className={`${classes.paper}`} >
          <Grid container direction="column">
            <Grid
              container
              direction="column"
              justify="center"
              className={`${classes.backgroundColor} ${classes.marginBottom}`}
            >
              <img className={`${classes.logoStyle} `}  src={MashkorSignature} width="50%" alt=''/>
            </Grid>
            {children}
        </Grid>
        </Paper>
    </div>
    
);
}

export function SigninButton({label, handleClick, disabled}){
    const classes= useStyles();
return(
    <Button
    disabled={disabled}
    onClick={handleClick}
    className={classes.button}
  >
    {label}
  </Button>
);
}