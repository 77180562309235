import {
    Button,
    withStyles,
  } from "@material-ui/core";
import { TEXT_COLOR } from "../../constants/theme";
export const StyledButton = withStyles({
    root: {
      background: TEXT_COLOR.ACTIVE,
      textTransform: "uppercase",
      color: "white",
      fontFamily: "Nunito",
      boxShadow:  `0px 9px 27px ${TEXT_COLOR.ACTIVE}4F`,
    },
  })(Button);