import { makeStyles } from "@material-ui/core";
import { TEXT_COLOR } from "../../../constants/theme";

export const useStyles = makeStyles(theme=>({
  root: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    alignItems: "center",
    [theme.breakpoints.between("xs", "sm")]: {
     width:"90vw"
    },
  },
  paperCtn: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxHeight: "100vh",
    overflowY: "auto",
    flex:1
  },
  paper: {
    display: "flex",
    width: "100%",
  },
  container: {
    marginLeft: "16px",
    marginRight: "16px",
  },
  containerLiveOrder:{
    marginLeft: "16px",
    marginRight: "16px",
    [theme.breakpoints.between("xs", "sm")]: {
      display:"none"
    },
  },
  marginTopLiveOrder: {
    marginTop: "16px",
    [theme.breakpoints.between("xs", "sm")]: {
      display:"none"
    },
  },
  marginBottom: {
    marginBottom: "8px",
  },
  heading: {
    fontSize: "24px",
    fontFamily: "Nunito",
    color: TEXT_COLOR.DEFAULT,
  },
  fullWidth: {
    width: "100%",
  },
  progressbarCtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  emptyImageContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex:1
  },
}));
