export function checkBrowserAcceptNotifications(notification) {
  //to check if the browser supports notifications or not
  if (!("Notification" in window)) {
    console.log("Browser doesn't support notifications");
  } else {
    getPermission(notification);
  }
}
function getPermission(notification) {
  //to check if promise is allowed or not
  Promise.resolve(Notification.requestPermission()).then(function(permission) {
    handlePermission(permission, notification);
});


}
function checkPromiseAllowed() {
  try {
    Notification.requestPermission().then();
  } catch (e) {
    return false;
  }
  return true;
}
function handlePermission(permission, notification) {
  if (!("permission" in Notification)) {
    Notification.permission = permission;
  }
  if (
    Notification.permission === "denied" ||
    Notification.permission === "default"
  ) {
    console.log(Notification.permission, notification);
  } else {
    console.log(Notification.permission, notification);
    const notify = new Notification(notification.title, {
      body: notification.body,
    });
    notify.onclick = function (event) {
      event.preventDefault();
      notify.close();
    };
    notify.onshow = function (event) {
      event.preventDefault();
    };
  }
}
