import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Notification from "../../components/Notification";
import { routes } from "../../constants";
import AuthProvider from "../AuthProvider";
import Home from "../Home";
import Login from "../Login";
import NotFound from "../NotFound";
import { PrivateRoute } from "../PrivateRoute";
import { SignUp } from "../SignUp";

export default function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Notification />
        <Switch>
          <PrivateRoute path={routes.HOME} component={Home} />
          <Route exact path={routes.SIGN_IN} component={Login} />
          <Route path={routes.SIGN_UP} component={SignUp} />
          <Route component={NotFound} />
        </Switch>
      </AuthProvider>
    </BrowserRouter>
  );
}
