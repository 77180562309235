import { TEXT_COLOR } from "../../constants/theme";

const { makeStyles } = require("@material-ui/core");

export const useStyles = makeStyles(theme =>({
  root:{
    [theme.breakpoints.between("xs", "sm")]: {
      display:"none"
    },
  },
  tableHead: {
    backgroundColor: TEXT_COLOR.BACKGROUND,
    fontFamily: "Nunito",
    display: "flex",
    alignItems: "center",
    paddingLeft: "8px",
    justifyContent: "flex-start",
    color: TEXT_COLOR.DEFAULT,
    height: "32px",
    fontSize: "12px",
    fontWeight: "600",
  },
  tableHeadCheckbox: {
    backgroundColor: TEXT_COLOR.BACKGROUND,
    display: "flex",
    alignItems: "center",
    height: "32px",
    width: "32px",
  },
  checkboxIconSize: {
    height: "16px",
    width: "16px",
  },
  body: {
    height: "48px",
   },
  tableBody: {
    display: "flex",
    flexDirection:"column",
    alignItems: "center",
    justifyContent: "flex-start",
    //overflowY: "auto",
    width: "100%",
    maxHeight: "50vh",
  },
  button: {
    cursor: "pointer",
    fontSize: "14px",
    fontFamily: "Nunito",
    padding: "8px 8px 8px 8px",
  },

  buttonEnd: {
    justifyContent: "flex-end",
  },
  greenColor: {
    color: TEXT_COLOR.ACTIVE,
  },
  deleteIcon: {
    // display: "flex",
    // justifyContent: "flex-end",
    color: TEXT_COLOR.BLUE,
    fontSize: "22px",
  },
  categoryMenuTitle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "16px",
    paddingRight: "16px",
    backgroundColor: TEXT_COLOR.BLUE,
    height: "30px",
    color: TEXT_COLOR.WHITE,
    fontFamily: "Nunito",
    fontSize: "12px",
    marginBottom: "16px",
  },
  flexOne: {
    flex: 1,
  },
  flexThree: {
    flex: 3,
  },
  flexTwo: {
    flex: 2,
  },
  flexFive: {
    flex: 5,
  },
  bottomMargin: {
    marginBottom: "8px",
  },
  text: {
    color: TEXT_COLOR.BLUE,
    overflowWrap: "break-word",
    wordBreak: "break-word",
    // overflowY: "auto",
    overflowX: "hidden",
    fontSize: "12px",
    fontFamily: "Nunito",
    marginLeft: "8px",
  },
  addProcuctBorder: {
    border: `1px solid ${TEXT_COLOR.ACTIVE}`,
    backgroundColor: TEXT_COLOR.ACTIVE_BACKGROUND,
  },
  iconStyling: {
    backgroundColor: TEXT_COLOR.DEFAULT_BACKGROUND,
    borderRadius: "20px",
    padding: "4px",
  },
  errorColor:{
    color: TEXT_COLOR.DELETE_COLOR
  }
}));
