import { Paper, Grid, Typography, CircularProgress } from "@material-ui/core";
import React, { useState } from "react";
import { useStyles } from "./styles";
import { LiveOrderTableBody, LiverOrderHeader } from "./ProductList";
import { useSelector } from "react-redux";
import { EmptyProductList } from "../../../assets/images";
function LiveOrder() {
  const classes = useStyles();
  const orders = useSelector(
    ({ liveOrderState }) => liveOrderState.liveOrder
  );
  const [loading] = useState(false);
  return (
    <Grid container direction="column" className={classes.root}>
      <Paper className={`${classes.paper} ${classes.marginBottom}`}>
        <Grid container direction="column" className={classes.marginBottom}>
          <Grid item className={`${classes.container} ${classes.marginTop}`}>
            <Typography className={classes.heading}>Order Real time</Typography>
          </Grid>
          <Grid item className={`${classes.containerLiveOrder} ${classes.marginTopLiveOrder}`}>
            <LiverOrderHeader />
          </Grid>
        </Grid>
      </Paper>
      {loading ? (
        <div className={classes.progressbarCtn}>
          <CircularProgress style={{ 'color': '#30D9C4' }}  />
        </div>
      ) : (
        <div className={classes.paperCtn}>
          {orders.length?orders.map((order, key) => (
            <Paper
              key={order.id}
              className={`${classes.paper} ${classes.marginBottom}`}
            >
              <div className={` ${classes.fullWidth}`}>
                <LiveOrderTableBody key={order.id} order={order} />
              </div>
            </Paper>
          )):<Paper className={classes.emptyImageContainer}>
          <img
            src={EmptyProductList}
            alt="empty list"
            className={classes.marginBottom}
          />
          <Typography align="center">No orders found</Typography>
        </Paper>
          }
        </div>
      )}
    </Grid>
  );
}
export default LiveOrder;
