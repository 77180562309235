export enum docNames {
    SELLER = "seller",
    ORDERS="orders",
    BRANCHES = "branches",
    CATEGORY = "category",
    CONFIG= "config",
    PRODUCT= "product"
  }
export enum productField {
  ARABIC_NAME = "nameAr",
  ARABIC_DESCRIPTION ="descriptionAr"
}

export enum subStatuses { //for UI
  NEW = "New",
  VERIFIED = "Order verified",
  PAYMENT_PENDING = "Payment Pending",
  BUDDY_REQ = "Buddy Requested",
  BUDDY_REQ_CONFIRMED = "Buddy Request Confirmed",
  BUDDY_ASSIGNED = "Buddy Assigned",
  BUDDY_UNASSIGNED = "Buddy Unassigned",
  BUDDY_QUEUED = "Buddy Queued",
  IN_PICKUP = "In Pickup",
  ARRIVED_PICKUP = "Arrived pickup",
  PICKED_UP = "Picked up",
  IN_DELIVERY = "In delivery",
  ARRIVED_DESTINATION = "Arrived destination",
  DELIVERED = "Delivered",
  CANCELLED = "Cancelled",
  ON_HOLD = "On-hold",
  SELLER_ORDER_READY="Order ready",
  SELLER_ORDER_ACCEPTED= "Order accepted",
  SELLER_REJECTED= "Rejected"
}
export enum timelineTypes {
  PAYMENT = "PAYMENT",
  CART = "CART",
  TRANSFER = "ORDER_TRANSFER",
  ORDER_STATUS = 'ORDER_STATUS',
  VENDOR= "VENDOR"
}
export enum itemStatusEnum{
  REJECTED="REJECTED",
  REPLACED="REPLCAED",
  CONFIRMED= "CONFIRMED",
  NEW_ITEM= "NEW_ITEM"
}
export enum orderSubStatuses{// for storing in db
  CANCELLED = "CANCELLED",
  SELLER_ORDER_READY="SELLER_ORDER_READY",
  SELLER_ORDER_ACCEPTED= "SELLER_ORDER_ACCEPTED",
  SELLER_REJECTED= "SELLER_REJECTED",
  DELIVERED="DELIVERED",
  PAYMENT_PENDING="PAYMENT_PENDING"
}

export enum primaryStatus {
  PENDING = "PENDING",
  PICKUP = "PICKUP",
  DELIVERY = "DELIVERY",
}