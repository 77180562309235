export const signIn=(payload)=>{
    return{
        type: "SIGN_IN",
        payload,
    }
}
export const signUp=(payload)=>{
    return{
        type: "SIGN_UP",
        payload,
    }
}

export const isAuthenticated = (payload) => {
    return {
        type: 'IS_AUTHENTICATED',
        payload
    }
}

export const signOut = () => {
    return {
        type: 'SIGN_OUT'
    }
}