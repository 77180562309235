import { CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isAuthenticated } from '../../actions/authActions';
import useStyles from './styles';

function AuthProvider({ children }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(isAuthenticated({ setLoading, history }));
    },[dispatch,history]);

    if (loading) {
        return (
            <div className={classes.root}>
                <CircularProgress style={{ 'color': '#30D9C4' }} />
            </div>
        )
    }

    return (<div>{children}</div>);
}

export default AuthProvider;