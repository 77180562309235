import { TEXT_COLOR } from "../../constants/theme";

const { makeStyles } = require("@material-ui/core");

export const useStyles = makeStyles(theme=>({
  tableHead: {
    fontFamily: "Nunito",
    display: "flex",
    alignItems: "center",
    paddingLeft: "8px",
    justifyContent: "flex-start",
    color: TEXT_COLOR.DEFAULT,
    height: "32px",
    fontSize: "12px",
    fontWeight: "600",
  },
  tableHeadCheckbox: {
    display: "flex",
    justifyContent:"center",
    alignItems: "center",
    height: "32px",
    [theme.breakpoints.between("xs", "sm")]: {
      display:"none"
    },
  },
  tableHeadRow2:{
    [theme.breakpoints.between("xs", "sm")]: {
      display:"none"
    },
  },
  checkboxIconSize: {
    height: "16px",
    width: "16px",
  },
  flexHalf:{
    flex: 0.8,
  },
  flexOne: {
    flex: 1,
  },
  flexOneHalf:{
    flex: 1.5,
  },
  flexTwo:{
      flex:2
  },
  flexThree: {
    flex: 3,
  },
  flexSeven: {
    flex: 7,
  },
  flexFour: {
    flex: 4,
  },
  bottomMargin: {
    marginBottom: "4px",
  },
  backgroundColorGrey:{
    backgroundColor: TEXT_COLOR.BACKGROUND,
  },
  backgroundColorWhite:{
    backgroundColor: TEXT_COLOR.WHITE,
    border:`1px solid ${TEXT_COLOR.BACKGROUND}`
  },
  categoryMenuTitle: {
    color: TEXT_COLOR.B2B2B2,
    fontFamily: "Nunito",
    fontSize: "16px",
    marginBottom: "8px",
    fontWeight: 600
  },
  categoryRoot:{
    display: "flex", 
    flexDirection: "column", 
    width:"200px", 
    outline: "none",
    padding: 15
  },
  categoryLabel:{
    fontSize: "14px",
    fontFamily: "Nunito",
    color: TEXT_COLOR.DEFAULT,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  baseButton: {
    color: TEXT_COLOR.ACTIVE,
    cursor: 'pointer',
    fontFamily: "Nunito",
    textDecoration: 'underline'
  },
  flexHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  cancelNameSearch: {
    display: 'flex',
    borderRadius: 20,
    backgroundColor: '#46ddca',
    alignItems: 'center',
    paddingLeft: '5px'
  },
  alignCenter: {
    display: 'flex',
    alignItems: 'center'
  }

}));
