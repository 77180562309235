const saveLiveOrders = (data) => ({
    type: "SAVE_LIVE_ORDERS",
    payload: data, 
  });
  const saveOrderHistory = (data) => ({
    type: "SAVE_ORDER_HISTORY",
    payload: data, 
  });
  
  export { saveLiveOrders, saveOrderHistory };
  