import {
  Paper,
  Grid,
  Typography,
  IconButton,
  ButtonBase,
  CircularProgress,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  orderSubStatuses,
  subStatuses,
} from "../../../constants/firebaseDoc";
import { useStyles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { LiverOrderHeader, LiveOrderTableBody } from "../LiveOrder/ProductList";
import { TEXT_COLOR } from "../../../constants/theme";
import ReactExport from "react-export-excel";
import { getTime } from "../../../utils/functions/formatTime";
import { mdiProgressDownload } from "@mdi/js";
import Icon from "@mdi/react";
import { formattedPrice } from "../../../utils/functions/formatPrice";
import { EmptyProductList } from "../../../assets/images";
import { setNotificationMessage } from "../../../actions/notificationActions";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function OrderHistory() {
  const classes = useStyles();
  const [orders, setOrders] = useState([]);
  const { defaultBranchId } = useSelector(
    ({ defaultBranchState }) => defaultBranchState.defaultBranch
  );
  const { uid } = useSelector(({ authState }) => authState.user);
  const allOrders = useSelector(
    ({ orderHistoryState }) => orderHistoryState.orders
  );
  const [filter, setFilter] = useState("ALL");
  const [excelSheetData, setExcelSheetData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (orders.length) {
      const reqdList = [];
      orders.forEach((order) => {
        const data = {};
        data.orderNo = order.orderNo;
        data.createdAt = getTime(order.placedAt, 0);
        data.clientName = order.user.name;
        data.paymentMethod = order.orderInfo.paymentType;
        data.totalPrice = formattedPrice(order.orderInfo.itemTotal);
        data.deliveryfee = formattedPrice(order.orderInfo.deliveryFee);
        data.totalInvoice = formattedPrice(order.orderInfo.totalAmount);
        data.subStatus = subStatuses[order.subStatus];
        reqdList.push(data);
      });
      setExcelSheetData(reqdList);
    } else {
      setExcelSheetData([]);
    }
  }, [orders]);
  useEffect(() => {
    setLoading(true);
    const filteredList = getFilteredOrders();
    setOrders(filteredList);
    setLoading(false);
  }, [uid, filter, defaultBranchId, allOrders]);

  const getFilteredOrders=()=>{
    if (filter !== "ALL") {
        return allOrders.filter((order) => order.subStatus === filter);
      } else {
        return allOrders;
      }
  }
  const handleReset = () => {
    setLoading(true);
    const filteredList = getFilteredOrders();
    setOrders(filteredList);
    setLoading(false);
  };

  const handleSave = async (from, to) => {
    setLoading(true);
    from.setHours(0, 0, 0);
    to.setHours(23, 59, 59);
    const filteredList = getFilteredOrders();
    const newList= filteredList.filter((order)=>(order.placedAt).toDate()>=from && to>= (order.placedAt).toDate());
    setOrders(newList);
    setLoading(false);
    };
  const filters = [
    { label: "All", value: "ALL" },
    { label: "Delivered", value: orderSubStatuses.DELIVERED },
    { label: "Rejected", value: orderSubStatuses.SELLER_REJECTED },
    { label: "Cancelled", value: orderSubStatuses.CANCELLED },
  ];
  return (
    <Grid container direction="column" className={classes.root}>
      <Paper className={`${classes.paper} ${classes.marginBottom}`}>
        <Grid container direction="column" className={classes.marginBottom}>
          <Grid item className={`${classes.container} ${classes.marginTop}`}>
            <Typography className={classes.heading}>History</Typography>
          </Grid>
          <Grid item className={`${classes.container} ${classes.marginTop}`}>
            <Grid container direction="row">
              {filters.map((item, key) => (
                <Grid
                  item
                  key={`${key}-${item.value}`}
                  style={{
                    borderBottom: `1px solid ${
                      item.value === filter
                        ? TEXT_COLOR.ACTIVE
                        : TEXT_COLOR.BACKGROUND
                    }`,
                  }}
                  onClick={() => setFilter(item.value)}
                >
                  <Typography
                    style={{
                      color: `${
                        item.value === filter
                          ? TEXT_COLOR.ACTIVE
                          : TEXT_COLOR.DEFAULT
                      }`,
                      padding: "0px 8px 4px 0px",
                    }}
                    className={classes.label}
                  >
                    {item.label}
                  </Typography>
                </Grid>
              ))}
              <Grid item className={classes.excellExport}>
                {excelSheetData.length ? (
                  <ExcelUpload excelSheetData={excelSheetData} />
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={`${classes.header}`}>
            <LiverOrderHeader
              handleSave={handleSave}
              comingFrom={0}
              handleReset={handleReset}
            />
          </Grid>
        </Grid>
      </Paper>
      {loading ? (
        <div className={classes.progressbarCtn}>
          <CircularProgress style={{ 'color': '#30D9C4' }}  />
        </div>
      ) : (
        <div className={classes.paperCtn}>
          {orders.length ? (
            orders.map((order, key) => (
              <Paper
                key={order.id}
                className={`${classes.paper} ${classes.marginBottom}`}
              >
                <div className={` ${classes.fullWidth}`}>
                  <LiveOrderTableBody
                    filter={filter}
                    key={key}
                    order={order}
                    comingFrom={0}
                  />
                </div>
              </Paper>
            ))
          ) : (
            <Paper className={classes.emptyImageContainer}>
              <img
                src={EmptyProductList}
                alt="empty list"
                className={classes.marginBottom}
              />
              <Typography align="center">No orders found</Typography>
            </Paper>
          )}
        </div>
      )}
    </Grid>
  );
}
export default OrderHistory;

function ExcelUpload({ excelSheetData }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const excelColumns = [
    {
      label: "Order No.",
      value: "orderNo",
    },
    {
      label: "Created ",
      value: "createdAt",
    },
    {
      label: "Client name",
      value: "clientName",
    },
    {
      label: "Payment Method",
      value: "paymentMethod",
    },
    {
      label: "Total price",
      value: "totalPrice",
    },
    {
      label: "Delivery fee",
      value: "deliveryfee",
    },
    {
      label: "Total Invoice",
      value: "totalInvoice",
    },
    {
      label: "Order Status",
      value: "subStatus",
    },
  ];
  const handleClicked = () => {
    dispatch(
      setNotificationMessage({
        type: "success",
        message: "Excel downloaded successfully.",
      })
    );
  };
  return (
    <ExcelFile
      element={
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <IconButton size="small" onClick={handleClicked}>
              <Icon
                path={mdiProgressDownload}
                color={TEXT_COLOR.DEFAULT}
                size="14px"
              />
            </IconButton>
          </Grid>
          <Grid item>
            <ButtonBase onClick={handleClicked}>
              <Typography className={classes.label}>
                Export to Excel Sheet
              </Typography>
            </ButtonBase>
          </Grid>
        </Grid>
      }
    >
      {excelSheetData.length && (
        <ExcelSheet data={excelSheetData} name="Order history">
          {excelColumns.map((columns, index) => (
            <ExcelColumn
              key={index}
              label={columns.label}
              value={columns.value}
            />
          ))}
        </ExcelSheet>
      )}
    </ExcelFile>
  );
}
