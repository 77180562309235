import { makeStyles } from "@material-ui/core";
import { TEXT_COLOR } from "../../constants/theme";

const useStyles = makeStyles({
    root: {
        marginLeft: "32px",
        marginRight: "32px",
        marginTop: "32px",
        height: "100%",
        maxWidth: "90vw"
    },
    container: {
        flex: 1,
        display: "flex",
        justifyContent: "center",
    },
    iconContainer: {
        display: "flex",
        alignItems: "center",
        backgroundColor: TEXT_COLOR.BACKGROUND
    },
    buttonReject: {
        color: TEXT_COLOR.WHITE,
        marginRight: "16px",
        backgroundColor: TEXT_COLOR.DELETE_COLOR,
        borderRadius: "10px",
        padding: "2px 10px"
    },
    buttonVerify: {
        color: TEXT_COLOR.WHITE,
        marginRight: "16px",
        backgroundColor: TEXT_COLOR.SUCCESS_COLOR,
        borderRadius: "10px",
        padding: "2px 10px"
    },
    checkboxIconSize: {
        height: "16px",
        width: "16px",
    },
    verificationTable:{
        flex: "0.8 1 0%",
        padding: "1vw",
        height: "50vh",
        overflowY: "auto",
        width: "75vw",
    }
});

export default useStyles;
