import React from "react";
import { makeStyles, TextField, withStyles } from "@material-ui/core";
import { PropTypes } from "prop-types";
import { TEXT_COLOR } from "../constants/theme";

export const StyledTextField = withStyles({
  root: {
    
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: `1px solid ${TEXT_COLOR.ACTIVE}`,
        borderRadius: "4px",
      },
      "&.Mui-focused fieldset": {
        border: `1px solid ${TEXT_COLOR.ACTIVE}`,
        borderRadius: "4px",
      },
      "&.MuiSelect-focused fieldset": {
        border: `1px solid ${TEXT_COLOR.ACTIVE}`,
        borderRadius: "4px",
      },
    },
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      background: "transparent"
    },
    "& .PrivateNotchedOutline-legendNotched-84": {
      color: TEXT_COLOR.ACTIVE,
    },
    

  },
})(TextField);

const useStyles = makeStyles({
  underline: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
});
export const StyledBorderTextfield = withStyles({
  root: {
    root: {
      "& .MuiInput-underline:before": {
        content: "none",
      },
      "& .MuiInput-underline:after": {
        borderBottom: "0px none",
      },
    },
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none",
    },
  },
})((props) => <TextField {...props} />);
export function TextFieldComponent({ styleProps, ...rest }) {
  return <TextField style={styleProps ? styleProps : {}} {...rest} />;
}
TextFieldComponent.propTypes = {
  styleProps: PropTypes.object,
};

export function StyledTextFieldComponent(props) {
  const classes = useStyles();
  return (
    <StyledBorderTextfield
      {...props}
      inputProps={{
        style: {
          color: TEXT_COLOR.DEFAULT,
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          fontFamily: "Nunito",
          fontSize: "12px",
          marginLeft: "8px",
        },
      }}
      style={{
        width: "100%",
        height: "100%",
        flexWrap: "wrap",
        border: props.error ? `1px solid ${TEXT_COLOR.ERROR}` : "",
      }}
      InputProps={{ classes, ...props.InputProps }}
      InputLabelProps={{
        style: {
          color: props.value ? TEXT_COLOR.ACTIVE : "#787878",
          fontFamily: "Nunito",
          fontSize: "12px",
          marginLeft: "4px",
        },
      }}
    />
  );
}
