import { makeStyles } from "@material-ui/core";
import { TEXT_COLOR } from "../../constants/theme";

const useStyles = makeStyles({
 
 button: {
    cursor: "pointer",
    fontSize: "14px",
    fontFamily: "Nunito",
    padding: "8px 8px 8px 8px",
  },
  greenColor: {
    color: TEXT_COLOR.ACTIVE ,
  },
  
  
});

export default useStyles;
