import authReducer from "./authReducer";
import { combineReducers } from "redux";
import notificationReducer from "./notificationReducer";
import defaultBranch from "./defaultBranchReducer";
import categoryReducer from "./category";
import productReducer from "./products";
import liveOrderReducer from "./liveOrder";
import { orderHistoryReducer } from "./orderHistory";
import brandReducer from "./brandReducer";
import deliveryFeeReducer from "./deliveryFeeReducer";
import operationalHourReducer from "./operationalHourReducer";
import preparationTimeReducer from "./preparationTimeReducer";
import branchAddressReducer from "./branchAddressReducer"
const rootReducer = combineReducers({
  authState: authReducer,
  notificationState: notificationReducer,
  defaultBranchState: defaultBranch,
  productState:productReducer, 
  categoryState: categoryReducer,
  liveOrderState: liveOrderReducer,
  orderHistoryState: orderHistoryReducer,
  brandSettingState: brandReducer,
  deliveryFeeState: deliveryFeeReducer,
  operationalHourState: operationalHourReducer,
  preparationTimeState: preparationTimeReducer,
  branchAddressState: branchAddressReducer
});

export default rootReducer;
