import { FlareSharp } from "@material-ui/icons";
import { file } from "jszip";
import { BASE_URL } from "../../config/baseUrlConfig";
import firebase, { DATABASE, STORAGE, storageBucket } from "../../config/fbConfig";
import { docNames } from "../../constants/firebaseDoc";

export function createSellerCollection(payload, uid) {
  const { email, phone, businessName } = payload;
  return new Promise(function (resolve, reject) {
    DATABASE.collection(docNames.SELLER)
      .doc(uid)
      .set({
        email,
        phone,
        firstLogin: true,
        //isAdmin: false,
        isLiveOnApp: false,
        brandConfig:{
          brandName:"",
          brandNameAr:"",
          businessType:"",
          deliveryFeeSharing:null,
          brandLogo:""
        },
        timestamps:{
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        },
        fallInTheFunnel: true,
        currentStatusInTheFunnel: null,
        FunnelActivated: null
      })
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
}

export function createDefaultBranch(uid) {
  return new Promise(function (resolve, reject) {
    DATABASE.collection(docNames.SELLER)
      .doc(uid)
      .collection(docNames.BRANCHES)
      .add({ createdAt: firebase.firestore.FieldValue.serverTimestamp() })
      .then((data) => {
        resolve({ sellerId: uid, branchId: data.id });
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
}

export function checkSellerCollection(payload) {
  const { email } = payload;
  return new Promise(async function (resolve, reject) {
    await DATABASE.collection(docNames.SELLER)
      .where("email", "==", email)
      .get()
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
}

export function addProductService(payload) {
  const { schema, uid, defaultBranchId,productId,imageUrl, imageAnalytics } = payload;
  const {
    name,
    description,
    price,
    category,
    availability,
    nameAr,
    descriptionAr,
    barcode,
  } = schema;
  const docRef = DATABASE.collection(docNames.SELLER)
  .doc(uid)
  .collection(docNames.BRANCHES)
  .doc(defaultBranchId)
  return new Promise(async function (resolve, reject) {
    await DATABASE.collection(docNames.SELLER)
      .doc(uid)
      .collection(docNames.BRANCHES)
      .doc(defaultBranchId)
      .collection(docNames.PRODUCT)
      .doc(productId)
      .set({
        name,
        description,
        price,
        category,
        availability: 1 || true ? true : false,
        imageUrl,
        nameAr,
        descriptionAr,
        barcode,
        imageAnalytics,
        verificationStatus: "PENDING",
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(()=>{
        const sellerDocRef = DATABASE.collection(docNames.SELLER).doc(uid)
        sellerDocRef.get().then((data)=>{
          const document = data.data();

          if (document && document.timestamps && !document.timestamps.productsUploadedAt ) {
            sellerDocRef
              .update({
                "timestamps.productsUploadedAt": firebase.firestore.FieldValue.serverTimestamp(),
              });
          }
        })
      })
      .then((data) => {
        if ((name && name.length) > 0 && (nameAr && nameAr.length) > 0) {
          docRef.update({
            "productsConfig.branchTotalProductsCount": firebase.firestore.FieldValue.increment(1),
            "productsConfig.productsWithTwoLanguages": firebase.firestore.FieldValue.increment(1),
          })
        } else {
          docRef.update({
            "productsConfig.branchTotalProductsCount": firebase.firestore.FieldValue.increment(1),
            "productsConfig.productsWithOneLanguage": firebase.firestore.FieldValue.increment(1),
          })
        }
      })
      .then(() => {
        resolve()
      })
      .catch(err => {
        reject(err);
      });
  });
}
const verifyImage = async (image) => {
	try {
		let body = JSON.stringify({
        "requests":[
          {
            "image":{
              "content":image
            },
            "features":[
              { type: 'LABEL_DETECTION', maxResults: 10 },
			        { type: 'SAFE_SEARCH_DETECTION', maxResults: 5 },
            ]
          }
        ]
      
		});
		let response = await fetch(
			'https://vision.googleapis.com/v1/images:annotate?key=' +
      process.env.REACT_APP_API_KEY,
			{
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				},
				method: 'POST',
				body: body
			}
		);
		let responseJson = await response.json();
		return responseJson;

	} catch (error) {
		console.log(error);
	}
};

const getBase64 = (file) => new Promise(function (resolve, reject) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject('Error: ', error);
})

export function saveImage(payload, path) {
  const { imageUrl } = payload;
  return new Promise(async function (resolve, reject) {
    const storageRef = STORAGE.ref();
    const childRef = storageRef.child(`${path}`);
    childRef.put(imageUrl).then(function () {
      childRef
        .getDownloadURL()
        .then(async function (url) {
          let idCardBase64 = await getBase64(imageUrl);
          if(imageUrl.name){
            idCardBase64 = idCardBase64.replace(/^data:image\/(png|jpg|jpeg);base64,/, "")
          }else{
            idCardBase64 = idCardBase64.split(',')[1];
          }
          const verificationResponse = await verifyImage(idCardBase64);
            let imageAnalytics = {}
            if (verificationResponse.responses && !verificationResponse.responses.error) {
              let labelAnnotations = [];
              if (verificationResponse.responses[0].labelAnnotations) {
                verificationResponse.responses[0].labelAnnotations.forEach(label => {
                  labelAnnotations.push({
                    description: label.description,
                    score: label.score
                  });
                });
                imageAnalytics.labelAnnotations = labelAnnotations;
              }
              if (verificationResponse.responses[0].safeSearchAnnotation) {
                imageAnalytics.safeSearchAnnotation = verificationResponse.responses[0].safeSearchAnnotation;
              }
            }
            const resolvedUrl = {};
            resolvedUrl.url = url;
            resolvedUrl.imageAnalytics = imageAnalytics;
            resolve(resolvedUrl);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  });
}

export function updateImageProduct(payload, defaultBranchId, url, uid) {
  return new Promise(function (resolve, reject) {
    DATABASE.collection(docNames.SELLER)
      .doc(payload.uid)
      .collection(docNames.BRANCHES)
      .doc(defaultBranchId)
      .collection(docNames.PRODUCT)
      .doc(uid)
      .update({
        imageUrl: url,
        verificationStatus: "PENDING",
      })
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
}

export function updateProductService(payload, imageDetailsObj) {
  const { schema, defaultBranchId, productUid, uid } = payload;
  if (imageDetailsObj) {
    return new Promise(function (resolve, reject) {
      DATABASE.collection(docNames.SELLER)
        .doc(uid)
        .collection(docNames.BRANCHES)
        .doc(defaultBranchId)
        .collection(docNames.PRODUCT)
        .doc(productUid)
        .update({
          ...schema,
          verificationStatus: "PENDING",
          imageUrl: imageDetailsObj.url,
          imageAnalytics: imageDetailsObj.imageAnalytics,
          thumbnail_100: '',
          thumbnail_512: ''
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  } else {
    return new Promise(function (resolve, reject) {
      DATABASE.collection(docNames.SELLER)
        .doc(uid)
        .collection(docNames.BRANCHES)
        .doc(defaultBranchId)
        .collection(docNames.PRODUCT)
        .doc(productUid)
        .update({
          ...schema,
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }
}

export function deleteProductService(payload) {
  const { id, defaultBranchId, uid, product } = payload;
  const docRef = DATABASE.collection(docNames.SELLER)
  .doc(uid)
  .collection(docNames.BRANCHES)
  .doc(defaultBranchId)
  return new Promise(function (resolve, reject) {
    DATABASE.collection(docNames.SELLER)
      .doc(uid)
      .collection(docNames.BRANCHES)
      .doc(defaultBranchId)
      .collection(docNames.PRODUCT)
      .doc(id)
      .delete()
      .then((data) => {
        resolve(data);
      })
      .then((data) => {
        if ((product.name && product.name.length) > 0 && (product.nameAr && product.nameAr.length)>0) {
          docRef.update({
            "productsConfig.branchTotalProductsCount": firebase.firestore.FieldValue.increment(-1),
            "productsConfig.productsWithTwoLanguages": firebase.firestore.FieldValue.increment(-1),
          })
        } else {
          docRef.update({
            "productsConfig.branchTotalProductsCount": firebase.firestore.FieldValue.increment(-1),
            "productsConfig.productsWithOneLanguage": firebase.firestore.FieldValue.increment(-1),
          })
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
}

export function deleteImageService(payload) {
  const { productImageUrl } = payload;
  const reference = STORAGE.refFromURL(productImageUrl);
  const imagePath = reference.fullPath;
  return new Promise(function (resolve, reject) {
    STORAGE.ref(imagePath)
      .delete()
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
}

export function getDefaultBranchId(payload) {
  const { uid } = payload;
  return new Promise(function (resolve, reject) {
    DATABASE.collection(docNames.SELLER)
      .doc(uid)
      .collection(docNames.BRANCHES)
      .get()
      .then((data) => {
        resolve(data.docs[0].id);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
}
export function addBrandSettingsService(payload, uid) {
  const docRef = DATABASE.collection(docNames.SELLER)
    .doc(uid)
  return new Promise(function (resolve, reject) {
    docRef.get()
      .then(doc => {
        let brandConfig = {}
        brandConfig = payload.schema;
        if (doc.data()) {
          docRef.update({
            "brandConfig.businessType": payload.schema.businessType,
            "brandConfig.brandName": payload.schema.brandName,
            "brandConfig.brandNameAr": payload.schema.brandNameAr,
          })
            .then(() => {
              resolve();
            }).catch(err => reject(err));
        } else {
          docRef.set({
            "brandConfig.businessType": payload.schema.businessType,
            "brandConfig.brandName": payload.schema.brandName,
            "brandConfig.brandNameAr": payload.schema.brandNameAr,
          })
            .then(() => {
              resolve();
            }).catch(err => reject(err));
        }
      })
  });
}
export function updateBrandLogoService(uid, url) {
  const docRef = DATABASE.collection(docNames.SELLER)
    .doc(uid)
  return new Promise(function (resolve, reject) {
    docRef.get().then((doc) => {
      let brandConfig = {};
      brandConfig.brandLogo = url
      if (doc.data().brandConfig) {
        docRef
          .update({
            "brandConfig.brandLogo": url,
          })
          .then(() => {
            resolve();
          })
          .catch((err) => reject(err));
      } else {
        docRef
          .set({
            "brandConfig.brandLogo": url,
          })
          .then(() => {
            resolve();
          }).catch(err => reject(err));
        }
      })
  });
}

export function getAllImages(payload) {
  return new Promise(async function (resolve, reject) {
    const storageRef = STORAGE.ref(`/sellers/${payload.uid}/products/${payload.productId}/`);
    storageRef
      .listAll()
      .then((res) => {
        let name = [];
        res.items.forEach((item) => {
          name.push(parseInt(item.name));
        });
        // const maxValue= Math.max(name);
        if (name.length === 0) {
          resolve(0);
        } else if (name.length === 1) {
          const index = res.items[0].name;
          const newIndex = parseInt(index) + 1;
          resolve(newIndex);
        } else {
          const maxValue = name.reduce(function (a, b) {
            return Math.max(a, b);
          });
          const newIndex = parseInt(maxValue) + 1;
          resolve(newIndex);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
}

export function updateSellerCollection(payload, uid) {
  const { phone, email } = payload;
  return new Promise(function (resolve, reject) {
    DATABASE.collection(docNames.SELLER)
      .doc(uid)
      .update({
        email,
        phone,
      })
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
}

export function prepTimeService(payload) {
  const { uid, branchId, preparationTime } = payload;
  const ref = DATABASE.collection(docNames.SELLER)
    .doc(uid)
    .collection(docNames.BRANCHES)
    .doc(branchId)

  return new Promise(function (resolve, reject) {
    ref
      .get()
      .then((data) => {
        if (data.exists) {
          ref.update({ "branchConfig.preparationTime" :preparationTime }).then((data) => {
            resolve(data);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
        } else {
          ref.set({ "branchConfig.preparationTime" :preparationTime  }).then((data) => {
            resolve(data);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
}
