import { makeStyles } from "@material-ui/core";
import { TEXT_COLOR } from "../../constants/theme";

const useStyles = makeStyles(theme => ({
    root: {
        border: `1px solid transparent`,
        borderRadius: 8,
        padding: '0px 40px 20px 40px',
        height: 84,
        width: '60vw',
        position: 'relative',
        // background: `${TEXT_COLOR.ORANGE}1E`

        [theme.breakpoints.between("xs", "sm")]: {
            display: "none"
        },
    },
    bar: {
        position: 'absolute',
        top: '50%',
        left: 0,
        height: 3,
        width: '90%',
        borderRadius: 8
    },
    icon: {
        position: 'absolute',
        top: '25%',
        right: -20,
        height: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    stopRoot: {
        position: 'absolute',
        bottom: 'calc(50% - 4px)',
        background: 'transparent',
        display: 'flex',
        flexDirection: 'column'
    },
    stopLabel: {
        color: TEXT_COLOR.ORANGE,
        fontFamily: 'Nunito',
        fontSize: 12,
        ['@media (max-width:540px)']: {
            display: 'none'
        }
    },
    stop: {
        height: 4,
        width: 4,
        borderRadius: 3,
        zIndex: 10,
        border: '1px solid #FFF2EE'
    },
    subLabel: {
        color: TEXT_COLOR.ORANGE,
        fontFamily: 'Nunito',
        fontSize: 12,
        position: "absolute",
        left: 0,
        bottom: -32,
        width: 32,
        ['@media (max-width:767px)']: {
            display: 'none'
        }
    },
    subText: {
        position: 'absolute',
        top:'calc(50% + 4px)',
        left: 0,
        fontSize: 12,
        fontFamily: 'Nunito'
    }
}));

export default useStyles;