import { makeStyles } from "@material-ui/core";
import { TEXT_COLOR } from "../../constants/theme";
export const useStyles = makeStyles({
  
  forgotPasswordDiv: {
    marginTop:"4px",
    display: 'flex',
    flexDirection: 'row-reverse',
    width: "268px"
  },
  forgotPassword: {
    background: 'transparent',
    color: TEXT_COLOR.ORANGE,
    border: 'none',
    textDecoration: 'underline',
    cursor: 'pointer',
    fontSize:"12px"
  },
  
  heading:{
    fontSize:"14px",
    fontWeight:"550",
    alignSelf:"flex-start",
    color:TEXT_COLOR.BLUE
    
  },
  marginBottom:{
    marginBottom:"16px"
  },
  inputLabel:{
    fontSize:"11px",
    color:TEXT_COLOR.BLUE,
    marginBottom:"4px"
  },
  link:{
    color:TEXT_COLOR.ORANGE,
    fontFamily:"nunito",
    fontSize:"12px",
    
  },
  text:{
    color:TEXT_COLOR.BLUE,
    fontFamily:"nunito",
    fontSize:"12px",
    marginRight:"8px"
  },
  icon:{
    color:TEXT_COLOR.BLUE, 
    fontSize:"16px"
  }
  
});
