const changeOrderItemStatus = (data) => ({
  type: "CHANGE_ORDER_ITEM",
  payload: data,
});

const cancelOrderAction = (data) => ({
  type: "CANCEL_ORDER",
  payload: data,
});

const changeOrderStatuses = (data) => ({
  type: "CHANGE_STATUSES",
  payload: data,
});

export { cancelOrderAction, changeOrderItemStatus, changeOrderStatuses };
