import { makeStyles } from "@material-ui/core";
import { TEXT_COLOR } from "../../../../constants/theme";

export const useStyles = makeStyles(theme=>({
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor:TEXT_COLOR.WHITE,
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  checkox:{
    [theme.breakpoints.between("xs", "sm")]: {
      display: "none"
    },
  },
  marginBottom: {
    marginBottom: "8px",
  },
  fullWidth: {
    width: "100%",
  },
  container: {
    display: "flex",
    flex: 1,
    width: "100%",
    border: `1px solid ${TEXT_COLOR.BACKGROUND}`,
    borderRadius: "8px",
  },
  headerContent: {
    fontSize: "12px",
    fontFamily: "Nunito",
    color: TEXT_COLOR.DEFAULT,
    fontWeight: "600",
    display: "flex",
    textAlign: "center",
    paddingLeft: "8px",
    justifyContent: "flex-start",
    overflowWrap: "break-word",
    wordBreak: "break-word",
    maxHeight:'32px',
    // overflow:'scroll'
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "10px",
    },
  },
  flexOne: {
    flex: 1,
  },
  flexTwo: {
    flex: 2,
  },
  flexThree: {
    flex: 3,
  },
  flexfour: {
    flex: 4,
  },
  flexSix: {
    flex: 6,
  },
  flexNine: {
    flex: 8,
  },
  flexTen: {
    flex: 10,
  },
  visible: {
    display: "flex",
  },
  hidden: {
    display: "none",
    height: 0,
  },
  avatar: {
    width: "22px",
    height: "22px",
  },
  buttonLabel: {
    fontFamily: "Nunito",
    fontWeight: "bold",
    textTransform: "none",
    color: TEXT_COLOR.DEFAULT,
  },
  iconButton:{
    [theme.breakpoints.between("xs", "sm")]: {
      margin: "2px",
    },
  },
  buttonFont: {
    fontSize: "8px",
  },
  labelFont: {
    fontSize: "12px",
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "10px",
    },
  },
  buddyDetails: {
    fontFamily: "Nunito",
    // marginLeft:"16px",
    color: "#828282",
  },
  menuItem: {
    fontFamily: "Nunito",
    padding: "0px 4px 0px 4px",
  },
  headingFont: {
    fontSize: "24px",
  },
  link: {
    fontFamily: "Nunito",
    color: TEXT_COLOR.ACTIVE,
    textDecoration: "underline",
    cursor: "pointer",
  },
  imageAlignment: {
    display: "flex",
    justifyContent: "center",
  },
  qty: {
    border: `1px solid ${TEXT_COLOR.ACTIVE}`,
    backgroundColor: TEXT_COLOR.WHITE,
    borderRadius: "14px",
    height: "24px",
    padding: "0px 8px 0px 8px",
  },
  marginRight: {
    marginRight: "8px",
  },
  buddyDetailsCtn: {
    display: "flex",
    flexDirection: "row",
  },
  alignRight: {
    justifyContent: "flex-start",
    marginLeft: "auto",
    marginRight: "16px",
  },
  end: {
    justifyContent: "flex-end",
    [theme.breakpoints.between("xs", "sm")]: {
      margin: "3px",
    },
  },
  checkIcon: {
    backgroundColor: TEXT_COLOR.ACTIVE,
    marginRight: "4px",
  },
  replaceIcon: {
    backgroundColor: `${TEXT_COLOR.ERROR}00`,
    border: `1px solid ${TEXT_COLOR.DEFAULT}`,
    marginRight: "4px",
  },
  rejectIcon: {
    backgroundColor: `${TEXT_COLOR.ORANGE}4F`,
    marginRight: "4px",
  },
  alignIcon: {
    verticalAlign: "middle",
  },
  dialog:{
    display: "flex",
    with: "fit-content",
    flexDirection: "column",
    alignItems: "center",
    padding: "32px 86px 32px 86px",
    [theme.breakpoints.between("xs", "sm")]: {
      padding: "32px 30px 32px 30px",
    },
  },
  replaceDialog:{
    width: "30vw",
    [theme.breakpoints.between("xs", "sm")]: {
      width: "75vw"
    },
  },
  width:{
    width: "55vw",
    [theme.breakpoints.between("xs", "sm")]: {
      width: "70vw"
    },
    flex: 1,
    display: "flex",
    flexDirection: "column",
  }
}));
