const INTITIAL_STATE = {
    liveOrder: [],
  };
  const liveOrderReducer = (state = INTITIAL_STATE, action) => {
    switch (action.type) {
      case "SAVE_LIVE_ORDERS":
        return {
          ...state,
          liveOrder: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default liveOrderReducer;
  