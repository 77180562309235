import { makeStyles } from "@material-ui/core";
import { TEXT_COLOR } from "../../../constants/theme";

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px 16px',
        overflow: 'scroll',
        height: '80vh',
        flexWrap: 'nowrap'
    },
    title: {
        fontFamily: 'Nunito',
        fontSize: 20,
        fontWeight: 600,
        color: TEXT_COLOR.DEFAULT
    },
    grid: {
        height: '100%',
        flexWrap: 'nowrap !important',
        [theme.breakpoints.between("xs", "sm")]: {
            padding: "20px"
        },
    },
    onlyMobile:{
        [theme.breakpoints.up("sm")]: {
            display: "none"
        },
    },
    alignCenter: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '-2vh'
    },
    deliverFee:{
        [theme.breakpoints.between("xs", "sm")]: {
            display: "none"
        },
    }
}));

export default useStyles;