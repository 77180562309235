const INITIAL_STATE = {
    operationalHours:{}
}

const operationalHourReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SAVE_OPERATIONAL_HOURS':
            return {
                ...state,
                operationalHours: action.payload
            };
        default: return state;
    }
}

export default operationalHourReducer;