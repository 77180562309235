import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import {
  mdiPhoneInTalkOutline,
  mdiAccountCircleOutline,
  mdiMapMarkerOutline,
  mdiDotsHorizontal,
  mdiChevronRight,
  mdiProgressCheck,
  mdiProgressClose,
  mdiChevronDown,
  mdiMagnify,
  mdiCheckCircle,
  mdiClose,
  mdiCartPlus
} from "@mdi/js";
import Icon from "@mdi/react";
import React, { useEffect, useState } from "react";
import { DividerComponent } from "../../../../components/Divider";
import { TEXT_COLOR } from "../../../../constants/theme";
import { OrderDetails } from "../OrderDetails";
import { useStyles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import firebase from "../../../../config/fbConfig";
import {
  cancelOrderAction,
  changeOrderItemStatus,
  changeOrderStatuses,
} from "../../../../actions/orderActions";
import {
  itemStatusEnum,
  orderSubStatuses,
  subStatuses,
  primaryStatus,
} from "../../../../constants/firebaseDoc";
import { timelineTypes } from "../../../../constants/firebaseDoc";
import { StyledTextField } from "../../../../components/Textfield";
import { DATE_VALIDATION } from "../../../../Validations/filterDate";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "../../../../components/ErrorMessage";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { getTime } from "../../../../utils/functions/formatTime";
import { formatString } from "../../../../utils/functions/formatString";
import { formattedPrice } from "../../../../utils/functions/formatPrice";
import { BASE_URL } from "../../../../config/baseUrlConfig";
import { checkBrowserAcceptNotifications } from "../../../../service/sendNotification";
import Notification from "../../../../assets/sounds/notification.mp3";
const dateSchema = yup.object().shape({
  from: DATE_VALIDATION.FROM,
  to: DATE_VALIDATION.TO,
});

export function LiverOrderHeader({ comingFrom, handleSave, handleReset }) {
  const classes = useStyles();
  const [dateFilter, setDateFilter] = useState(false);
  const [dateMenu, setDateMenu] = useState(null);
  const handleTimeClicked = (event) => {
    if (comingFrom === 0) {
      if (dateFilter !== true) {
        setDateMenu(event.currentTarget);
      } else {
        setDateMenu(null);
      }
      setDateFilter((prevValue) => !prevValue);
    }
  };
  const handleCloseMenu = () => {
    setDateMenu(null);
  };
  const handleDatesClear = () => {
    setDateFilter(false);
    handleReset();
  };
  return (
    <Grid wrap='nowrap' container direction="row" style={{ display: 'flex' }}>
      <Grid item className={`${classes.flexOne} ${classes.headerCtn}`}>
        <Checkbox
          disableRipple
          icon={
            <CheckBoxOutlineBlank
              className={`${classes.checkboxIconSize} ${classes.unchecked}`}
              fontSize="small"
            />
          }
          checkedIcon={
            <CheckBox
              fontSize="small"
              className={`${classes.checkboxIconSize} ${classes.checked}`}
            />
          }
          size="small"
          className={classes.checkboxAlignment}
        />
      </Grid>
      <DividerComponent height="32px" color={TEXT_COLOR.WHITE} />
      <Grid item className={`${classes.flexThree} ${classes.headerCtn} `}>
        <Typography noWrap={true} className={classes.headerContent}>Order Id</Typography>
      </Grid>
      <DividerComponent height="32px" color={TEXT_COLOR.WHITE} />
      <Grid
        item
        className={`${comingFrom === 0 ? classes.flexfour : classes.flexThree
          } ${comingFrom === 0 && dateFilter
            ? classes.date
            : comingFrom === 0
              ? classes.cursor
              : ""
          } ${classes.headerCtn}`}
        onClick={handleTimeClicked}
      >
        {comingFrom === 0 && dateFilter ? (
          <Icon path={mdiMagnify} size="12px" />
        ) : (
          ""
        )}
        <Typography noWrap={true} className={classes.headerContent}>
          {comingFrom === 0 ? "Date" : "Time"}
        </Typography>
      </Grid>
      <DividerComponent height="32px" color={TEXT_COLOR.WHITE} />
      <Grid item className={`${classes.flexSix} ${classes.headerCtn}`}>
        <Typography noWrap={true} className={classes.headerContent}>Client</Typography>
      </Grid>
      <DividerComponent height="32px" color={TEXT_COLOR.WHITE} />
      <Grid item className={`${classes.flexThree} ${classes.headerCtn}`}>
        <Typography className={classes.headerContent} noWrap={true}>
          Payment Method
        </Typography>
      </Grid>
      <DividerComponent height="32px" color={TEXT_COLOR.WHITE} />
      <Grid item className={`${classes.flexTwo} ${classes.headerCtn}`}>
        <Typography noWrap={true} className={classes.headerContent}>Total Price</Typography>
      </Grid>
      <DividerComponent height="32px" color={TEXT_COLOR.WHITE} />
      <Grid item className={`${classes.flexThree} ${classes.headerCtn}`}>
        <Typography noWrap={true} className={classes.headerContent}>Delivery fees</Typography>
      </Grid>
      <DividerComponent height="32px" color={TEXT_COLOR.WHITE} />
      <Grid item className={`${classes.flexThree} ${classes.headerCtn}`}>
        <Typography noWrap={true} className={classes.headerContent}>Total Invoice</Typography>
      </Grid>
      <DividerComponent height="32px" color={TEXT_COLOR.WHITE} />
      <Grid
        item
        className={`${comingFrom === 0 ? classes.flexfour : classes.flexThree
          } ${classes.headerCtn}`}
      >
        <Typography noWrap={true} className={classes.headerContent}>Status</Typography>
      </Grid>
      <DividerComponent height="32px" color={TEXT_COLOR.WHITE} />
      <Grid
        item
        className={`${comingFrom === 0 ? classes.flexZero : classes.flexTwo}  ${classes.headerCtn
          }`}
      >
        <Typography noWrap={true} className={classes.headerContent}>Action</Typography>
      </Grid>
      <DateMenu
        handleSave={handleSave}
        anchorEl={dateMenu}
        handleCloseMenu={handleCloseMenu}
        handleDatesClear={handleDatesClear}
      />
    </Grid>
  );
}

export function LiveOrderTableBody({ order, comingFrom, filter }) {
  const classes = useStyles();
  const {
    orderNo,
    placedAt,
    user,
    orderInfo,
    subStatus,
    deliveryAddress,
  } = order;
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElAction, setAnchorElAction] = useState(null);
  const [expand, setExpand] = useState(false);
  const [blink, setBlink] = useState(false);
  const [openAddItemDialog, setOpenAddItemDialog] = useState(false);
  const [menuContent, setMenuContent] = useState({
    icon: "",
    label: "",
  });

  const playSound = audioFile => {
    const audioPromise = audioFile.play();
    if (audioPromise !== undefined) {
      audioPromise
        .then(_ => {
          audioFile.play().autoplay = true;
        })
        .catch(err => {
          console.info(err)
        })
    }
  };

  useEffect(() => {
    if(orderInfo.isCartUpdated){
      const likeAudio = new Audio(Notification);
      likeAudio.load();
      playSound(likeAudio);
      checkBrowserAcceptNotifications({
        body: `Cart has been updated`,
        title: `${orderNo}`,
      });
    }
    
    let interval = setInterval(() => {
      setBlink((blink) => !blink);
    }, 700);
    return () => {
      clearInterval(interval)
    }
  }, [orderInfo.isCartUpdated]);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleCloseActionMenu = () => {
    setAnchorElAction(null);
  };

  const hangleOpenAddItemDialog = () => {
    setOpenAddItemDialog(true)
  }
  useEffect(() => {
    if (filter) {
      setExpand(false);
    }
  }, [filter]);

  return (
    <Grid
      container
      direction="column"
      className={`  ${subStatus === orderSubStatuses.SELLER_ORDER_READY
        ? classes.activeBackground
        : ""
        }`}
    >
      <Grid
        container
        wrap='nowrap'
        direction="row"
        alignItems="center"
        className={classes.alignment}
      >
        <Grid className={`${classes.checkbox}`}>
          <Grid item className={`${classes.flexOne}`}>
            <Checkbox
              disableRipple
              icon={
                <CheckBoxOutlineBlank
                  className={`${classes.uncheckedBodyIcon}`}
                  fontSize="small"
                />
              }
              checkedIcon={
                <CheckBox className={classes.checked} fontSize="small" />
              }
              size="small"
            />
          </Grid>
        </Grid>

        <Grid className={`${classes.mobileContainer1}`}>
          <Grid className={`${classes.mobileSubContainer1}`}>
            <Grid item className={`${classes.flexThree}`}>
              <Typography noWrap={true} className={classes.headerContent}>{orderNo}</Typography>
            </Grid>
            <Grid
              item
              className={`${comingFrom === 0 ? classes.flexfour : classes.flexThree
                }`}
            >
              <Typography noWrap={true} className={classes.headerContent}>
                {getTime(placedAt, comingFrom)}
              </Typography>
            </Grid>
            <Grid item className={`${classes.flexSix}`}>
              <Grid container wrap='nowrap' style={{ flexShrink: 1 }} direction="row" alignItems="center">
                <Icon
                  path={mdiAccountCircleOutline}
                  size="16px"
                  color={TEXT_COLOR.DEFAULT}
                  className={classes.alignCenter}
                />
                <Typography noWrap={true} className={classes.headerContent} style={{flex: 0.5}}>
                  {user.name ? formatString(user.name) : "-"}
                </Typography>

                {orderInfo.isCartUpdated && comingFrom !== 0 ?
                  <Grid
                    item
                    className={`${classes.alignRight}`}
                  >
                    <Typography className={`${orderInfo.isCartUpdated && blink ? classes.cartUpdatedText : classes.cartUpdatedTextBlack}`}>
                      • Cart has been updated
                    </Typography>
                  </Grid> : null}
                
                <Grid
                  item
                  className={`${classes.iconCtn} ${classes.alignRight} ${classes.activeBackground}`}
                >
                  <IconButton
                    size="small"
                    onClick={(event) => {
                      setMenuContent({
                        icon: mdiPhoneInTalkOutline,
                        label: user.phone,
                      });
                      setAnchorEl(event.currentTarget);
                    }}
                  >
                    <Icon
                      path={mdiPhoneInTalkOutline}
                      size="16px"
                      color={TEXT_COLOR.DEFAULT}
                    />
                  </IconButton>
                </Grid>
                <Grid
                  item
                  className={`${classes.iconCtn} ${classes.activeBackground}`}
                >
                  <IconButton
                    size="small"
                    onClick={(event) => {
                      setMenuContent({
                        icon: mdiMapMarkerOutline,
                        label: `${deliveryAddress.addressStr}` ||`${deliveryAddress.block}, ${deliveryAddress.area}, ${deliveryAddress.street}`,
                      });
                      setAnchorEl(event.currentTarget);
                    }}
                  >
                    <Icon
                      path={mdiMapMarkerOutline}
                      size="16px"
                      color={TEXT_COLOR.DEFAULT}
                    />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid className={`${classes.mobileSubContainer2}`}>
            <Grid item className={`${classes.flexThree}`}>
              <Typography noWrap={true} className={classes.headerContent}>
                {orderInfo.paymentType ? formatString(orderInfo.paymentType) : "-"}
              </Typography>
            </Grid>
            <Grid item className={`${classes.flexTwo}`}>
              <Typography noWrap={true} className={classes.headerContent}>{`${formattedPrice(
                comingFrom === 0 ? orderInfo.items.filter((item) => item.status === itemStatusEnum.CONFIRMED).reduce((total, item) => total + (item.price * item.quantity), 0) : orderInfo.itemTotal
              )} KWD`}</Typography>
            </Grid>
            <Grid item className={`${classes.flexThree}`}>
              <Typography noWrap={true} className={classes.headerContent}>{`${formattedPrice(
                orderInfo.deliveryFee
              )} KWD`}</Typography>
            </Grid>
            <Grid item className={`${classes.flexThree}`}>
              <Typography noWrap={true} className={classes.headerContent}>{`${formattedPrice(
                comingFrom === 0 ? orderInfo.items.filter((item) => item.status === itemStatusEnum.CONFIRMED).reduce((total, item) => total + (item.price * item.quantity), 0) + orderInfo.deliveryFee : orderInfo.itemTotal + orderInfo.deliveryFee
              )} KWD`}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid className={`${classes.mobileContainer2}`}>
          <Grid item className={`${classes.flexThree}`}>
            <Typography noWrap={true}
              className={`${classes.headerContent} ${subStatus === orderSubStatuses.SELLER_REJECTED || subStatus === orderSubStatuses.CANCELLED
                ? classes.errorColor
                : subStatus === orderSubStatuses.DELIVERED ||
                  subStatus === orderSubStatuses.SELLER_ORDER_READY
                  ? classes.checked
                  : classes.defaultColor
                }`}
            >
              {subStatuses[subStatus]}
            </Typography>
          </Grid>
          <Grid
            item
            className={`${comingFrom === 0 ? classes.flexOne : classes.flexTwo}`}
          >
            <Grid container wrap='nowrap' direction="row">
              {comingFrom === 0 ? (
                ""
              ) : (
                <Grid
                  item
                  className={`${classes.iconCtn} ${classes.alignRight} ${classes.defaultBackground}`}
                >
                  {/* {expand && ((order.subStatus === "NEW" || order.subStatus === "VERIFIED")) ?
                    <Grid item>
                      <IconButton
                        size="small"
                        style={{ padding: "5px" }}
                        onClick={(e) => {
                           e.preventDefault(); 
                          hangleOpenAddItemDialog()}}

                      >
                        <Icon
                          path={mdiCartPlus}
                          size="16px"
                          color={TEXT_COLOR.DEFAULT}
                        />
                      </IconButton>
                    </Grid> : null} */}

                  <Grid item>
                    <IconButton
                      size="small"
                      onClick={(evt) => setAnchorElAction(evt.currentTarget)}
                    >
                      <Icon
                        path={mdiDotsHorizontal}
                        size="16px"
                        color={TEXT_COLOR.DEFAULT}
                      />
                    </IconButton>
                  </Grid>
                </Grid>
              )}

              <IconButton
                size="small"
                className={classes.alignCenter}
                onClick={() => setExpand(!expand)}
              >
                <Icon
                  path={expand ? mdiChevronDown : mdiChevronRight}
                  size="16px"
                  color={expand ? TEXT_COLOR.ACTIVE : TEXT_COLOR.DEFAULT}
                />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <MenuComponent //rejected reasons
          handleCloseMenu={handleCloseMenu}
          anchorEl={anchorEl}
          content={menuContent}
        />
        <ActionMenu
          handleCloseActionMenu={handleCloseActionMenu}
          anchorElAction={anchorElAction}
          order={order}
        />
      </Grid>
      <OrderDetails expand={expand} order={order} comingFrom={comingFrom}
        openAddItemDialog={openAddItemDialog}
        setOpenAddItemDialog={setOpenAddItemDialog} />
    </Grid>
  );
}

function MenuComponent({ anchorEl, handleCloseMenu, content }) {
  const classes = useStyles();
  return (
    <Menu
      anchorEl={anchorEl}
      elevation={0}
      open={Boolean(anchorEl)}
      onClose={handleCloseMenu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
    >
      <Grid
        container
        direction="row"
        className={classes.menu}
        style={{ marginRight: "4px", marginLeft: "4px" }}
      >
        <Icon path={content.icon} size="16px" color={TEXT_COLOR.DEFAULT} />
        <Typography className={classes.headerContent}>
          {content.label}
        </Typography>
      </Grid>
    </Menu>
  );
}
const rejectionReasons = ["Store closed", "Items not available"];
function ActionMenu({ anchorElAction, handleCloseActionMenu, order }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { uid } = useSelector(({ authState }) => authState.user);
  const brandConfig = useSelector(({ brandSettingState }) => brandSettingState?.brandConfig);
  const [reject, setReject] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");

  const cancelOrder = async (reason) => {
    const cancelOrderSchema = {
      cancelledAt: firebase.firestore.FieldValue.serverTimestamp(),
      reason: `SELLER_REJECTED - ${reason}`,
      cancelledBy: {
        name: brandConfig.brandName || "",
        id: uid,
      },
    };
    const triggerEventSchema = {
      orderId: order.id,
      orderNo: order.orderNo,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      type: timelineTypes.ORDER_STATUS,
      name: orderSubStatuses.CANCELLED,
      actorId: uid,
      actorType: timelineTypes.VENDOR,
      agentId: order.agent ? order.agent.id : "",
      agentName: order.agent ? order.agent.name : "",
      referenceId: "",
      referenceCollection: "",
      comment: reason,
    };
    const items = [...order.orderInfo.items];
    order.orderInfo.items.forEach((item, index) => {
      if (items[index].status !== "CONFIRMED" && items[index].status !== "REPLCAED") {
        items[index].status = itemStatusEnum.REJECTED;
        items[index].comment = reason;
      }
      items[index].isCancelled = true;
    });
    const rawResponse = await fetch(`${BASE_URL}/omsOrderComplete`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(
        {
          "orderNo": order.orderNo,
          action: "CANCELLATION",
        }
      )
    })
    dispatch(
      changeOrderItemStatus({
        orderNo: order.orderNo,
        uid: order.id,
        items: items,
        userUid: uid,
        handleItemStatusChange: () => { },
      })
    );
    const schema = {
      "orderInfo.itemTotal": order.orderInfo.itemTotal,
      "orderInfo.itemCount": order.orderInfo.itemCount,
      "orderInfo.itemQuantity": order.orderInfo.itemQuantity,
      "orderInfo.deliveryFee": order.orderInfo.deliveryFee,
      // subStatus: orderSubStatuses.SELLER_REJECTED,
    };
    dispatch(changeOrderStatuses({ id: order.id, orderNo: order.orderNo, schema, uid }));
    dispatch(
      cancelOrderAction({
        cancelOrderSchema: cancelOrderSchema,
        id: order.id,
        data: triggerEventSchema,
        agentId: order.agent ? order.agent.id : "",
        uid
      })
    );
    handleCloseActionMenu();
  };

  const updateAdditionalPaymentInfoService = async (orderNo) =>{
    try {
      const response = await fetch(`${BASE_URL}/omsOrderOnCartUpdate`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify(
          {
            "orderNo": orderNo,
          }
        )
      })
      const responseJson = await response.json();
      console.log("The received response is", responseJson);
    } catch (error) {
      console.error(`Received error : ${error.message}`);
    }
  }

  const handleOrderReady = () => {
    const schema = {
      primaryStatus: primaryStatus.PENDING,
      subStatus: orderSubStatuses.SELLER_ORDER_READY, //tbd orderNo: order.orderNo,
      "timestamps.sellerOrderReadyAt": firebase.firestore.FieldValue.serverTimestamp(),
    };
    const items = [...order.orderInfo.items];
    order.orderInfo.items.forEach((item, index) => {
      if (items[index].status !== "REJECTED" && items[index].status !== "REPLCAED" && items[index].status !== "NEW_ITEM") {
        items[index].status = itemStatusEnum.CONFIRMED;
      }
    });
    if(items.every((item) => !(item.status === itemStatusEnum.CONFIRMED))){
      updateAdditionalPaymentInfoService(order.orderNo);
    }
    
    dispatch(changeOrderStatuses({ id: order.id, orderId: order.id, orderNo: order.orderNo, schema, uid }));
    dispatch(
      changeOrderItemStatus({
        orderNo: order.orderNo,
        uid: order.id,
        items: items,
        userUid: uid,
        handleItemStatusChange: () => { },
      })
    );
    handleCloseActionMenu();
  };
  return (
    <Menu
      anchorEl={anchorElAction}
      onExited={() => {
        setReject(false);
        setRejectionReason("");
      }}
      keepMounted
      open={Boolean(anchorElAction)}
      onClose={handleCloseActionMenu}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      getContentAnchorEl={null}
    >
      {reject ? (
        <div className={classes.menu}>
          <Typography className={classes.listTitle}>Rejection</Typography>
          <FormControl>
            <RadioGroup
              value={rejectionReason}
              onChange={(event) => {
                setRejectionReason(event.target.value);
                cancelOrder(event.target.value);
              }}
            >
              {rejectionReasons.map((item, key) => {
                return (
                  <FormControlLabel
                    key={`${key}-${item}`}
                    value={item}
                    control={
                      <Radio
                        style={{
                          marginLeft: "16px",
                          color: `${rejectionReason === item
                            ? TEXT_COLOR.ERROR
                            : TEXT_COLOR.BACKGROUND
                            }`,
                        }}
                      />
                    }
                    label={
                      <Typography
                        style={{
                          fontSize: "12px",
                          fontFamily: "Nunito",
                          fontWeight: "bold",
                          color: `${rejectionReason === item
                            ? TEXT_COLOR.ERROR
                            : TEXT_COLOR.DEFAULT
                            }`,
                        }}
                      >
                        {item}
                      </Typography>
                    }
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        </div>
      ) : (
        <div className={classes.menu}>
          <Typography className={classes.listTitle}>Action</Typography>
          {(order.subStatus === "NEW" || order.subStatus === "VERIFIED" || order.subStatus === "SELLER_ORDER_ACCEPTED" || order.subStatus === "BUDDY_UNASSIGNED")?
            <MenuItem
              onClick={handleOrderReady}
              className={classes.checkboxAlignment}
            >
              <Icon
                path={mdiProgressCheck}
                size="16px"
                color={TEXT_COLOR.DEFAULT}
              />
              <Typography
                className={`${classes.listTitle} ${classes.defaultColor}`}
              >
                Ready to dispatch
              </Typography>
            </MenuItem> : null}

          <MenuItem
            onClick={() => setReject(true)}
            className={classes.checkboxAlignment}
          >
            <Icon
              path={mdiProgressClose}
              size="16px"
              color={TEXT_COLOR.ERROR}
            />
            <Typography
              className={`${classes.listTitle} ${classes.errorColor}`}
            >
              Reject
            </Typography>
          </MenuItem>
        </div>
      )}
    </Menu>
  );
}

function DateMenu({ anchorEl, handleCloseMenu, handleSave, handleDatesClear }) {
  const getTodayDate = () => {
    const date = new Date();
    return `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
      }-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}`;
  };
  const defaultDate = getTodayDate();
  const classes = useStyles();
  const { register, errors, handleSubmit } = useForm({
    resolver: yupResolver(dateSchema),
  });
  const [dates, setDates] = useState({
    from: defaultDate,
    to: defaultDate,
  });
  const onSave = () => {
    handleSave(new Date(dates.from), new Date(dates.to));
    handleCloseMenu();
  };
  const handleDateChange = (event) => {
    setDates({
      ...dates,
      [event.target.name]: event.target.value,
    });
  };
  const handleReset = () => {
    setDates({
      from: defaultDate,
      to: defaultDate,
    });
    handleCloseMenu();
    handleDatesClear();
  };
  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleCloseMenu}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      getContentAnchorEl={null}
    >
      <div className={classes.dateMenuRoot}>
        <Typography className={classes.label}>From</Typography>
        <StyledTextField
          error={errors.from ? true : false}
          name="from"
          variant="outlined"
          onChange={handleDateChange}
          inputRef={register}
          type="date"
          InputProps={{
            className: classes.textfieldHeight
          }}
          value={dates.from}
        />
        {errors && errors.from && (
          <ErrorMessage message={errors.from.message} />
        )}
        <Typography className={classes.label}>To</Typography>
        <StyledTextField
          error={errors.to ? true : false}
          onChange={handleDateChange}
          name="to"
          variant="outlined"
          inputRef={register}
          type="date"
          InputProps={{
            className: classes.textfieldHeight
          }}
          value={dates.to}
        />
        {errors && errors.to && <ErrorMessage message={errors.to.message} />}
        <div style={{ display: "flex", marginTop: "8px" }}>
          <Icon
            onClick={handleSubmit(onSave)}
            path={mdiCheckCircle}
            size="16px"
            color={TEXT_COLOR.ACTIVE}
            style={{ cursor: "pointer" }}
          />
          <Typography
            onClick={handleSubmit(onSave)}
            style={{
              color: TEXT_COLOR.ACTIVE,
              fontSize: "12px",
              fontFamily: "Nunito",
              cursor: "pointer",
            }}
          >
            Apply
          </Typography>
          <div
            style={{
              cursor: "pointer",
              marginLeft: "auto",
              marginRight: "4px",
            }}
          >
            <Icon
              onClick={handleReset}
              path={mdiClose}
              size="14px"
              color={TEXT_COLOR.DEFAULT}
              className={classes.listIconStyle}
            />
          </div>

          <Typography
            onClick={handleReset}
            style={{
              color: TEXT_COLOR.DEFAULT,
              fontSize: "12px",
              fontFamily: "Nunito",
              cursor: "pointer",
            }}
          >
            Clear
          </Typography>
        </div>
      </div>
    </Menu>
  );
}
