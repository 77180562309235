import { BASE_URL } from "../../config/baseUrlConfig";
import { DATABASE } from "../../config/fbConfig";
import { docNames } from "../../constants/firebaseDoc";

const updateAdditionalPaymentInfoService = async (orderNo) => {
  try {
    const response = await fetch(`${BASE_URL}/omsOrderOnCartUpdate`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(
        {
          "orderNo": orderNo,
        }
      )
    })
    const responseJson = await response.json();
    console.log("The received response is", responseJson);
  } catch (error) {
    console.error(`Received error : ${error.message}`);
  }
}

export function changeOrderItemService(payload) {
  const { uid, items } = payload;
  return new Promise(function (resolve, reject) {
    DATABASE.collection(docNames.ORDERS)
      .doc(uid)
      .update({ "orderInfo.items": items })
      .then(() => resolve())
      .then(()=>{updateAdditionalPaymentInfoService(payload.orderNo)})
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
}

export function cancelOrderService(payload) {
  const { id, cancelOrderSchema } = payload;
  return new Promise(function (resolve, reject) {
    DATABASE.collection(docNames.ORDERS)
      .doc(id)
      .update({
        cancelInfo: cancelOrderSchema,
        // primaryStatus: "OTHERS",
        // subStatus: "SELLER_REJECTED",
      })
      .then(() => resolve())
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
}
export function changeOrderStatusesService(payload) {
  const { id, schema } = payload;
  return new Promise(function (resolve, reject) {
    DATABASE.collection(docNames.ORDERS)
      .doc(id)
      .update(schema)
      .then(() => resolve())
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
}
export const triggerPubSubEventCloudFunction = async (payload) => {
  const { data, topicName } = payload;
  const bodySchema = [
    {
      data: data,
      eventType: topicName,
    },
  ];
  const temp = JSON.stringify(bodySchema);
  try {
    const response = await fetch(BASE_URL + "triggerPubSubEvent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: temp,
    });
    const responseJson = await response.json();
    console.log("The received response is", responseJson);
  } catch (error) {
    console.error(`Received error : ${error.message}`);
  }
};
