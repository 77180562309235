import { makeStyles } from "@material-ui/core";
import { TEXT_COLOR } from "../../../constants/theme";

const useStyles = makeStyles(theme=>({
    root: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        height: '80vh',
        flexWrap: 'nowrap'
    },
    brandConfig: {
        flex: 1,
        display: 'flex',
        marginRight: 30,
        [theme.breakpoints.between("xs", "sm")]: {
            display: "none"
        },
    },
    onlyMobile:{
        [theme.breakpoints.up("sm")]: {
            display: "none"
        },
    },
    brandEdit: {
        flex: 2,
        display: 'flex',
        padding: 16,
    },
    brandImage: {
        height: 120,
        width: 120,
        borderRadius: 7,
        marginTop: 60
    },
    brandName: {
        fontFamily: 'Nunito',
        fontSize: 20,
        fontWeight: 600,
        color: TEXT_COLOR.DEFAULT,
        textTransform: 'uppercase',
        marginTop: 25
    },
    businessType: {
        fontFamily: 'Nunito',
        fontSize: 14,
        fontWeight: 600,
        color: TEXT_COLOR[848484],
        marginTop: 3
    },
    sellerInfo: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: '0 10%',
        marginTop: 40
    },
    iconContainer: {
        width: 36,
        height: 36,
        background: TEXT_COLOR.F6F7F8,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 50
    },
    sellerDetails: {
        fontFamily: 'Nunito',
        fontSize: 14,
        fontWeight: 600,
        color: TEXT_COLOR.ACTIVE,
        marginLeft: 20
    },
    infoRow: {
        display: 'flex',
        alignItems: 'center'
    },
    editTitle: {
        fontFamily: 'Nunito',
        fontSize: 20,
        fontWeight: 600,
        color: TEXT_COLOR.DEFAULT
    },
    brandLogo: {
        padding: '0px 10px',
        display: 'flex',
        alignItems: 'center',
        border: `1px dashed ${TEXT_COLOR.DEFAULT}`,
        borderRadius: 4,
        background: `${TEXT_COLOR.DEFAULT}05`,
        height: 30
    },
    brandLogoInput: {
        display: 'none'
    },
    editLogoLabel: {
        marginLeft: 10
    },
    editForm: {
        padding:'0px 100px',
        [theme.breakpoints.between("xs", "sm")]: {
            display: "none"
        },
    },
    saveButton: {
        height: "30px",
        backgroundColor: TEXT_COLOR.ACTIVE,
        borderRadius: "4px",
        border: `0px solid ${TEXT_COLOR.ACTIVE}`,
        color:  TEXT_COLOR.WHITE,
        fontSize: "12px",
        cursor: "pointer",
    },
    editActionContainer: {
        display: 'flex',
        flexDirection: 'row-reverse',
        [theme.breakpoints.between("xs", "sm")]: {
            display: "none"
        },
    },
    dimension: {
        width: '100%'
    },
    grid: {
        height: '100%',
        flexWrap: 'nowrap !important',
        [theme.breakpoints.between("xs", "sm")]: {
            padding: "20px"
        },
    },
    error: {
        color: TEXT_COLOR.ERROR,
        fontSize: 12
    }
}));

export default useStyles;