import firebase, { DATABASE } from "../../config/fbConfig";
import { docNames } from "../../constants/firebaseDoc";

export function checkCategoryAlreadyExists(payload) {
  const { categoryName, uid, defaultBranchId } = payload;
  return new Promise(function (resolve, reject) {
    DATABASE.collection(docNames.SELLER)
      .doc(uid)
      .collection(docNames.BRANCHES)
      .doc(defaultBranchId)
      .collection(docNames.CATEGORY)
      .where("categoryName", "==", categoryName)
      .get()
      .then((data) => {
        if (data.docs.length === 0) {
          resolve("Add");
        } else {
          resolve(data.docs[0].id);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
}

export function addCategoryService(payload) {
  const { categoryName, uid, displayIndex, defaultBranchId } = payload;
  return new Promise(function (resolve, reject) {
    DATABASE.collection(docNames.SELLER)
      .doc(uid)
      .collection(docNames.BRANCHES)
      .doc(defaultBranchId)
      .collection(docNames.CATEGORY)
      .add({ categoryName, displayIndex })
      .then((data) => {
        resolve(data.id);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
}

export function updateCategoryService(payload) {
  const { name, uid, id, defaultBranchId } = payload;
  return new Promise((res, rej) => {
    DATABASE.collection(docNames.SELLER)
      .doc(uid)
      .collection(docNames.BRANCHES)
      .doc(defaultBranchId)
      .collection(docNames.CATEGORY)
      .doc(id)
      .update({
        categoryName: name,
      })
      .then(() => {
        DATABASE.collection(docNames.SELLER)
          .doc(uid)
          .collection(docNames.BRANCHES)
          .doc(defaultBranchId)
          .collection(docNames.PRODUCT)
          .where("category.id", "==", id)
          .get()
          .then((snap) => {
            snap.docs.forEach((doc) => {
              DATABASE.collection(docNames.SELLER)
                .doc(uid)
                .collection(docNames.BRANCHES)
                .doc(defaultBranchId)
                .collection(docNames.PRODUCT)
                .doc(doc.id)
                .update({
                  "category.categoryName": name,
                });
            });
          });
        res();
      })
      .catch((err) => {
        rej(err);
      });
  });
}

export function deleteCategoryService(payload) {
  const { uid, id, defaultBranchId } = payload;
  return new Promise((res) => {
    DATABASE.collection(docNames.SELLER)
      .doc(uid)
      .collection(docNames.BRANCHES)
      .doc(defaultBranchId)
      .collection(docNames.CATEGORY)
      .doc(id)
      .delete();
      res();
  });
}
export function getHighestDisplayIndex(payload) {
  const { uid, defaultBranchId } = payload;
  return new Promise(function (resolve, reject) {
    DATABASE.collection(docNames.SELLER)
      .doc(uid)
      .collection(docNames.BRANCHES)
      .doc(defaultBranchId)
      .collection(docNames.CATEGORY)
      .get()
      .then((doc) => {
        const list = [];
        doc.docs.forEach((data) => {
          const displayIndex = data.data().displayIndex;
          list.push(displayIndex);
        });
        if (list.length === 0) {
          resolve(0);
        } else if (list.length === 1) {
          resolve(1);
        } else {
          const maxIndex = list.reduce(function (a, b) {
            return Math.max(a, b);
          });
          resolve(maxIndex + 1);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
}

export function updateDisplayIndex(payload) {
  const { uid, displayIndex, defaultBranchId } = payload;
  return new Promise((res) => {
    DATABASE.collection(docNames.SELLER)
      .doc(uid)
      .collection(docNames.BRANCHES)
      .doc(defaultBranchId)
      .collection(docNames.CATEGORY)
      .where("displayIndex", ">", displayIndex)
      .get()
      .then((data)=>{
        data.docs.forEach((doc)=>{
          DATABASE
          .collection(docNames.SELLER)
          .doc(uid)
          .collection(docNames.BRANCHES)
          .doc(defaultBranchId)
          .collection(docNames.CATEGORY)
          .doc(doc.id)
          .update({displayIndex:firebase.firestore.FieldValue.increment(-1)
          }).then(()=>res())
        })

      })
  });
}

