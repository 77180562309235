const INITIAL_STATE = {
    brandConfig: {},
    sellerDetails: {}
}

const brandReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SAVE_BRAND_DETAILS':
            return {
                ...state,
                brandConfig: action.payload
            };
        case 'SAVE_SELLER_DETAILS':
            return {
                ...state,
                sellerDetails: action.payload
            };
        default: return state;
    }
}

export default brandReducer;