import * as Yup from "yup";
import {
  maxDateMessage,
  endDateMesssage,
  requiredMessage,
} from "./errorMessage";

const today = new Date();
export const DATE_VALIDATION = {
  FROM: Yup.date()
    .required(requiredMessage)
    .max(today, maxDateMessage),
  TO: Yup.date()
    .required(requiredMessage)
    .min(Yup.ref("from", endDateMesssage))
    .max(today, maxDateMessage),
};
