import {
  Grid,
  IconButton,
  Typography,
  InputLabel,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
} from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { TextFieldComponent } from "../../components/Textfield";
import { SIGNUP_VALIDATION } from "../../Validations/signUpSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useStyles } from "./styles";
import * as yup from "yup";
import { useHistory, Link } from "react-router-dom";
import { ErrorMessage } from "../../components/ErrorMessage";
import { signUp } from "../../actions/authActions";
import { routes } from "../../constants";
import { SigninButton, SigninCard } from "../../components/SignupCard";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import "./style.css";

const signupSchema = yup.object().shape({
  email: SIGNUP_VALIDATION.EMAIL,
  password: SIGNUP_VALIDATION.PASSWORD,
  phone: SIGNUP_VALIDATION.PHONE,
});

const styles = theme => ({
  radio: {
    '&$checked': {
      color: '#4B8DF8'
    }
  },
  checked: {}
})

export function SignUp() {
  const { register, errors, handleSubmit } = useForm({
    resolver: yupResolver(signupSchema),
  });
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();
  const [disableLogin, setDisableLogin] = useState(false);
  const [authChannel, setAuthChannelValue] = useState("")
  const classes = useStyles();


  const handleAuthChannelChange = (event) => {
    event.stopPropagation()
    setAuthChannelValue(event.target.value);
  };

  const handleSignup = async(data, e) => {
    const email  = data.email.toLowerCase().trim();
    const password  = data.password;
    const phone = data.phone;

    dispatch(
      signUp({ email, password, history, phone, setDisableLogin })
    );
  };

  // const handleKeyPressed = (event) => {
  //   if (event.key === "Enter") {
  //     handleSubmit(handleSignup)();
  //   }
  // };
  return (
    <SigninCard>
      <Grid container direction="column" style={{ marginLeft: "16px" }}>
        <Grid item className={classes.marginBottom}>
          <Typography className={`${classes.heading} ${classes.blueColor}`}>
            Create a vendor account
          </Typography>
        </Grid>
        <Grid item>
          <InputLabel className={`${classes.inputLabel} ${classes.blueColor}`}>Email</InputLabel>
        </Grid>
        <Grid item className={classes.marginBottom}>
          <TextFieldComponent
            inputRef={register}
            name="email"
            variant="outlined"
            placeholder="Enter your email address"
            styleProps={{
              width: "268px",
            }}
            inputProps={{
              className:classes.inputProps,
            }}
            size="small"
            error={errors.email ? true : false}
          />
          <Grid item className={classes.errorContainer}>
            {errors && errors.email && (
              <ErrorMessage
                style={{ width: "350px" }}
                message={errors.email.message}
              />
            )}
          </Grid>
        </Grid>

        <Grid item>
          <InputLabel className={`${classes.inputLabel} ${classes.blueColor}`}>Mobile number</InputLabel>
        </Grid>
        <Grid item className={classes.marginBottom}>
          <TextFieldComponent
            inputRef={register}
            name="phone"
            variant="outlined"
            placeholder="Enter your contact number "
            styleProps={{
              width: "268px",
            }}
            inputProps={{
              className:classes.inputProps,
            }}
            size="small"
            error={errors.phone ? true : false}
          />
          <Grid item className={classes.errorContainer}>
            {errors && errors.phone && (
              <ErrorMessage
                style={{ width: "350px" }}
                message={errors.phone.message}
              />
            )}
          </Grid>
        </Grid>
        <Grid item>
          <InputLabel className={`${classes.inputLabel} ${classes.blueColor}`}>Password</InputLabel>
        </Grid>
        <Grid item className={classes.marginBottom}>
          <TextFieldComponent
            inputRef={register}
            // onKeyDown={handleKeyPressed}
            name="password"
            variant="outlined"
            placeholder="Enter Password"
            styleProps={{
              width: "268px",
            }}
            inputProps={{
              className:classes.inputProps,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                  >
                    {showPassword ? (
                      <Visibility fontSize="small" className={`${classes.icon} ${classes.blueColor}`} />
                    ) : (
                      <VisibilityOff
                        fontSize="small"
                        className={`${classes.icon} ${classes.blueColor}`}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            type={showPassword ? "text" : "password"}
            size="small"
            error={errors.password ? true : false}
          />
          <Grid item className={classes.errorContainer}>
            {errors && errors.password && (
              <ErrorMessage
                style={{ width: "350px" }}
                message={errors.password.message}
              />
            )}
          </Grid>
        </Grid>
        <Grid item className={classes.marginBottom}>
          <SigninButton
            label={disableLogin?'Signing up...':"SignUp"}
            handleClick={handleSubmit(handleSignup)}
            key={"signup"}
            disabled={disableLogin}
          />
        </Grid>
        <Grid
          container
          direction="row"
          justify="center"
          className={classes.marginBottom}
        >
          <Grid item>
            <Typography className={`${classes.text} ${classes.blueColor}`}>
              Already registerd with us?
            </Typography>
          </Grid>
          <Grid item>
            <Link to={routes.SIGN_IN} style={{ textDecoration: "none" }}>
              <Typography className={classes.link}>Login</Typography>
            </Link>
          </Grid>
        </Grid>
        <Grid item></Grid>
      </Grid>
    </SigninCard>
  );
}
