import { makeStyles } from "@material-ui/core";
import { TEXT_COLOR } from "../../constants/theme";

const useStyles = makeStyles(theme=>({
  root: {
    marginLeft: "32px",
    marginRight: "32px",
    marginTop: "32px",
    height: "100%",
    maxWidth: "90vw"
  },
  addProduct:{
    [theme.breakpoints.down("sm")]: {
      display:"none"
    },
  },
  productsHeader:{
    [theme.breakpoints.between("xs", "sm")]: {
      display:"none"
    },
  },
  mobileHeader:{
    fontFamily: "Nunito",
    display: "flex",
    alignItems: "center",
    paddingLeft: "8px",
    justifyContent: "flex-start",
    color: TEXT_COLOR.DEFAULT,
    backgroundColor: TEXT_COLOR.BACKGROUND,
    height: "32px",
    fontSize: "12px",
    fontWeight: "600",
    marginBottom: "5px",
    [theme.breakpoints.up("md")]: {
      display:"none"
    },
  },
  addProduct:{
    [theme.breakpoints.between("xs", "sm")]: {
      display:"none"
    },
  },
  title: {
    fontFamily: "nunito",
    color:  TEXT_COLOR.DEFAULT,
    fontSize: "24px",
  },
  marginBottom: {
    marginBottom: "16px",
  },
  button: {
    cursor: "pointer",
    fontSize: "14px",
    fontFamily: "Nunito",
    padding: "8px 8px 8px 8px",
  },
  greenColor: {
    color: TEXT_COLOR.ACTIVE,
  },
  emptyImage: {
    alignSelf: "center",
    marginTop: "10%",
  },
  heading: {
    color:   TEXT_COLOR.DEFAULT,
    fontSize: "40px",
    fontWeight: "bold",
    fontFamily: "nunito",
    textAlign: 'center'
  },
  listTitle: {
    color:  TEXT_COLOR.DEFAULT,
    fontSize: "14px",
    fontFamily: "nunito",
    marginLeft: "8px",
  },
  listIconStyle: {
    backgroundColor: TEXT_COLOR.WHITE,
    borderRadius: "50%",
    padding: "2px",
    boxShadow: "0px 0px 0px 2px #C8C8C8",
  },
  container: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // overflow:'scroll'
  },
  tableBody: {
    overflowY: "auto",
    width: "100%",
    maxHeight: "50vh",
  },
  emptyImageContainer: {
    textAlign: "center",
    marginTop: "5%",
    marginBottom: "5%",
  },
  emptyProductsList: {
    textAlign: "center",
    marginTop: "5%",
    marginBottom: "5%",
    height: '40vh',
    [theme.breakpoints.down("sm")]: {
      marginTop: "5vh"
    },
  },
  alignCenter: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '1vh'
  },
  alignRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '1vh'
  },
  noWrap: {
    flexWrap: 'nowrap'
  },
  tableHead: {
    fontFamily: "Nunito",
    display: "flex",
    alignItems: "center",
    paddingLeft: "8px",
    justifyContent: "flex-start",
    color: TEXT_COLOR.DEFAULT,
    height: "32px",
    fontSize: "12px",
    fontWeight: "600",
  },
  flexTwo:{
    flex:2
  },
  flexThree:{
    flex: 3
  },
  flexFour:{
    flex: 4
  }
}));

export default useStyles;
