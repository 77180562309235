import * as Yup from "yup";
import { emailErrorMessage,requiredMessage,phoneMessage } from "./errorMessage";


const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const SIGNUP_VALIDATION = {
   
  EMAIL: Yup.string().email(emailErrorMessage).required(requiredMessage),
  PASSWORD: Yup.string().required(requiredMessage).min(6),
  PHONE: Yup.string()
    .required(requiredMessage)
    // .matches(phoneRegExp, phoneMessage)
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(8, 'Must be exactly 8 digits')
    .max(8, 'Must be exactly 8 digits'),
    BUSINESS_NAME: Yup.string().required(requiredMessage)
};
