import { makeStyles } from "@material-ui/core";
import { TEXT_COLOR } from "../../../constants/theme";

const useStyles = makeStyles(theme=>({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
  },
  finishButton:{
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
  },
  nextButton:{
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    },
  },
  btnContainer: {
    flex: 1,
    display: "flex",
  },
  flexStart: {
    justifyContent: "flex-start",
  },
  flexEnd: {
    justifyContent: "flex-end",
  },
  center: {
    justifyContent: "center",
  },
  btnColor: {
    color: TEXT_COLOR.BLUE,
  },
  marginRight: {
    marginRight: "16px",
  },
}));

export default useStyles;
