const INITIAL_STATE = {};

const notificationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_NOTIFICATION_MESSAGE":
      return action.payload;

    case "RESET_MESSAGE":
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default notificationReducer;
