import { makeStyles } from "@material-ui/core";
import { TEXT_COLOR } from "../../../constants/theme";

const useStyles = makeStyles(theme=>({
  root: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    padding: '0px 16px',
    overflow: 'scroll',
    height: '80vh',
    flexWrap: 'nowrap',
    [theme.breakpoints.up('sm')]: {
      flexDirection: "column"
    },
  },
  container:{
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.between("xs", "sm")]: {
      display: "none"
    },
  },
  operationsInfo:{
    [theme.breakpoints.between("xs", "sm")]: {
        display: "none"
    },
  },
  onlyMobile: {
    [theme.breakpoints.up("sm")]: {
      display: "none"
    },
  },
  label: {
    fontSize: "3vh",
    marginTop: "1vh",
    fontFamily: "Nunito",
    color: TEXT_COLOR.DEFAULT,
  },
  grid: {
    height: '100%',
    flexWrap: 'nowrap !important',
    [theme.breakpoints.between("xs", "sm")]: {
      padding: "20px"
    },
  },
  content: {
    fontSize: "2vh",
    fontFamily: "Nunito",
    color: TEXT_COLOR.DEFAULT,
  },
  imgCtn: {
    display: "flex",
    margin: "10vh auto"
  },
  contentCtn: {
    alignItems: "center",
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "15vh",
  },
  timeCtn: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    marginBottom: "auto",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "-12vh",
  },
  time: {
    height: "5vh",
    width: "8vw",
  },
  saveButton: {
    backgroundColor: TEXT_COLOR.ACTIVE,
    borderRadius: "4px",
    border: `0px solid ${TEXT_COLOR.ACTIVE}`,
    color: TEXT_COLOR.WHITE,
    fontSize: "2vh",
    cursor: "pointer",
  },
  select: {
    height: "5vh",
    width: "8vw",
    display: "table-cell",
    verticalAlign: "middle",
  },
}));

export default useStyles;
