import { makeStyles } from "@material-ui/core";
import { TEXT_COLOR } from "../../constants/theme";

const useStyles = makeStyles({
    root: {
        position: 'relative'
    },
    flowStep: {
        height: 40,
        width: 40,
        borderRadius: 80,
        transitionDelay: '0.5s',
        transitionDuration: '0.5s',
        cursor: 'pointer'
    },
    connector: {
        position: 'relative',
        top: '50%',
        border:`1px dashed ${TEXT_COLOR.ACTIVE}`,
        width: '80%',
        margin: '0px auto',
        transitionDuration: '1s'
    },
    backgroundWhite: {
        background:TEXT_COLOR.WHITE,
        border:`1px solid ${TEXT_COLOR.ACTIVE}`,
    },
    backgroundGreen: {
        background: TEXT_COLOR.ACTIVE
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    element: {
        flex: 1
    },
    titles: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: 12,
        fontFamily: 'nunito',
        textAlign: 'center',
        lineBreak: 'unset',
        textOverflow: 'ellipsis',
        WebkitLineClamp: 1,
        margin: '0px -20px'
    },
    alignChildren:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    boxShadow:{
        boxShadow:`0px 0px 8px 8px ${TEXT_COLOR.ACTIVE}21`
    }
});

export default useStyles;