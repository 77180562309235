import {
  ButtonBase,
  Checkbox,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { AddCircle, CheckBoxOutlineBlank, CheckBox } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { DividerComponent } from "../../components/Divider";
import { useStyles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { setNotificationMessage } from "../../actions/notificationActions";
import { deleteProductAction } from "../../actions/addProducts";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import { TableBody } from "../TableBody";
import { TableHeader } from "../TableHeader";
import { AddProduct } from "../NewProduct";
import { DeleteDialog } from "../../components/AddProductButton";
import { TEXT_COLOR } from "../../constants/theme";

export function Table(props) {
  const {
    setPriceError,
    priceError,
    setDescriptionError,
    descriptionError,
    setNameError,
    nameError,
    setNameArError,
    nameArError,
    setDescription,
    setName,
    setPrice,
    setImage,
    setCategory,
    setBorderCategoryColor,
    borderCategoryColor,
    setBorderImageColor,
    borderImageColor,
    price,
    description,
    name,
    imageUrl,
    category,
    list,
    handleValidateForms,
    add,
    setAdd,
    handleAddProduct,
    setArabicDetails,
    arabicDetails,
    isImageUploading,
    barcode,
    setBarcode,
    setBarcodeError,
    barcodeError

  } = props;
  const { defaultBranchId } = useSelector(
    ({ defaultBranchState }) => defaultBranchState.defaultBranch
  );
  const { uid } = useSelector(({ authState }) => authState.user);
  const dispatch = useDispatch();
  const [checkbox, setCheckbox] = useState(false);
  const [checkboxList, setChcekboxList] = useState([]);
  const classes = useStyles();
  const [deleteAllDialog, setDeleteAllDialog] = useState(false);
  const [deleteDisabled, setDeleteDisabled] = useState(false);
  const [addDelete, setAddDelete] = useState(false);
  const [showDelete, setShowDelete]= useState(false);
  const handleValidateProcuct = () => {
    handleValidateForms(null);
  };
  const handleDialogClose = () => {
    setDeleteAllDialog(false);
  };
  useEffect(() => {
    let listId = {};
    list.forEach((item) => {
      listId[item.id] = false;
    });
    setChcekboxList(listId);
    if (list.length === 0 && add.length === 0) {
      setDeleteDisabled(true);
    } else {
      setDeleteDisabled(false);
    }
  }, [list, add]);
  useEffect(()=>{
    const value = Object.values(checkboxList);
    const isTrue = value.some((item) => item === true);
    setShowDelete(isTrue);
  },[checkboxList]);
  const handleCheckboxChange = (id) => {
    setChcekboxList((value) => ({ ...value, [id]: !checkboxList[id] }));
  };
  const handleAddProductDelete = () => {
    setImage();
    setCategory({ categoryName: "Select Category", id: "" });
    setName("");
    setDescription("");
    setPrice("");
    setBorderImageColor(TEXT_COLOR.ACTIVE);
    setBorderCategoryColor(TEXT_COLOR.ACTIVE);
    setNameError(false);
    setNameArError(false);
    setDescriptionError(false);
    setBarcodeError(false);
    setPriceError(false);
    setAdd([]);
    setAddDelete(false);
  };
  const handleDelete = () => {
    const value = Object.values(checkboxList);
    if (
      (value.every((v) => v === false) && addDelete === false) ||
      (value.length === 0 && addDelete === false)
    ) {
      // no items to delete
      dispatch(setNotificationMessage({ message: "Nothing to delete" }));
    } else if (value.length !== 0 && value.every((v) => v === true)) {
      //all products are to be deleted
      setDeleteAllDialog(true);
    } else {
      //some products are to be deleted
      list.forEach((item) => {
        const id = item.id;
        if (checkboxList[id] === true) {
          dispatch(deleteProductAction({ id: id, product: item, productImageUrl: item.imageUrl, defaultBranchId, uid }));
        }
      });
      if (addDelete === true) {
        handleAddProductDelete();
      }
    }
  };
  const deleteAllProducts = () => {
    list.forEach((item) => {
      dispatch(deleteProductAction({ id: item.id, product: item, productImageUrl: item.imageUrl, defaultBranchId, uid }));
    });
    if (addDelete === true) {
      handleAddProductDelete();
    }
    setCheckbox(false);
  };

  useEffect(() => {
    if (checkbox === true) {
      list.forEach((item) => {
        const id = item.id;
        // tempList[id] = true;
        setChcekboxList((previousState) => ({ ...previousState, [id]: true }));
      });
      setAddDelete(true);
    } else {
      list.forEach((item) => {
        const id = item.id;
        setChcekboxList((previousState) => ({ ...previousState, [id]: false }));
      });
      setAddDelete(false);
    }
  }, [checkbox, list]);

  return (
    <div>
      <Grid className={classes.root} container direction="column">
        {checkbox||showDelete ? (
          <Grid
            container
            direction="row"
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: TEXT_COLOR.BACKGROUND,
            }}
          >
            <Grid item className={`${classes.tableHeadCheckbox} `}>
              <Checkbox
                disableRipple
                icon={
                  <CheckBoxOutlineBlank
                    className={classes.checkboxIconSize}
                    style={{ color: TEXT_COLOR.WHITE, backgroundColor: TEXT_COLOR.WHITE }}
                    fontSize="small"
                  />
                }
                checkedIcon={
                  <CheckBox style={{ color: TEXT_COLOR.ACTIVE }} fontSize="small" />
                }
                checked={checkbox}
                onChange={() => setCheckbox(!checkbox)}
                size="small"
                style={{ display: "flex", alignItems: "center" }}
              />
            </Grid>
            <DividerComponent height="32px" color={TEXT_COLOR.WHITE} />
            <IconButton
              disabled={deleteDisabled}
              size="small"
              onClick={handleDelete}
            >
              <Icon
                path={mdiClose}
                size="16px"
                className={classes.errorColor}
                style={{
                  backgroundColor: TEXT_COLOR.WHITE,
                  borderRadius: "20px",
                  padding: "4px",
                }}
              />
            </IconButton>
            <ButtonBase disabled={deleteDisabled} onClick={handleDelete}>
              <Typography
                className={`${classes.button} ${classes.errorColor}`}
                
              >
                Delete
              </Typography>
            </ButtonBase>
          </Grid>
        ) : (
          <TableHeader
            setCheckbox={setCheckbox}
            checkbox={checkbox}
            comingFrom="onBoarding"
            products={list}
            emptyList={list.length===0? true:false}
          />
        )}
        <Grid item className={`${classes.tableBody} ${classes.bottomMargin}`}>
          {list.map((item, key) => (
            <TableBody
              key={item.id}
              checkboxList={checkboxList}
              setChcekboxList={setChcekboxList}
              item={item}
              handleCheckboxChange={handleCheckboxChange}
              comingFrom="onBoarding"
            />
          ))}
        </Grid>

        {add.map((key) => (
          <AddProduct
            key={key}
            setCategory={setCategory}
            category={category}
            setImage={(imageUrl) => {
              setImage(imageUrl);
            }}
            imageUrl={imageUrl}
            borderCategoryColor={borderCategoryColor}
            borderImageColor={borderImageColor}
            setBorderImageColor={setBorderImageColor}
            setBorderCategoryColor={setBorderCategoryColor}
            setName={setName}
            setDescription={setDescription}
            setPrice={setPrice}
            setPriceError={setPriceError}
            setNameError={setNameError}
            setNameArError={setNameArError}
            setDescriptionError={setDescriptionError}
            priceError={priceError}
            nameError={nameError}
            nameArError={nameArError}
            descriptionError={descriptionError}
            name={name}
            price={price}
            description={description}
            addDelete={addDelete}
            setAddDelete={setAddDelete}
            handleAddProductDelete={handleAddProductDelete}
            arabicDetails={arabicDetails}
            setArabicDetails={setArabicDetails}
            barcode={barcode}
            setBarcode={setBarcode}
            barcodeError={barcodeError}
            setBarcodeError={setBarcodeError}

          />
        ))}
        <Grid container direction="row" style={{ marginTop: "8px" }}>
          <IconButton
          disabled={isImageUploading}
            size="small"
            onClick={
              add.length === 0 ? handleAddProduct : handleValidateProcuct
            }
          >
            <AddCircle className={classes.greenColor} />
          </IconButton>
          <ButtonBase
            style={{ marginLeft: "8px" }}
            disabled={isImageUploading}
            onClick={
              add.length === 0 ? handleAddProduct : handleValidateProcuct
            }
          >
            <Typography className={`${classes.button} ${classes.greenColor}`}>
              Add another
            </Typography>
          </ButtonBase>
        </Grid>
        <DeleteDialog
          open={deleteAllDialog}
          handleClick={() => {
            deleteAllProducts();
            handleDialogClose();
          }}
          handleDialogClose={handleDialogClose}
        />
      </Grid>
    </div>
  );
}
