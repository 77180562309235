import {
  Grid,
  Typography,
  Box,
  MenuItem,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import React, {useEffect , useRef, useState } from "react";
import { DATABASE } from '../../config/fbConfig';
import { setNotificationMessage } from "../../actions/notificationActions";
import { AddImageComponent } from "../../components/AddImage/index";
import { useStyles } from "./styles";
import { StyledTextField } from "../../components/Textfield";

export function BrandSettings({
  brandName,
  brandNameAr,
  logo,
  businessType,
  setLogo,
  setBusinessType,
  setBrandName,
  setBrandNameAr,
}) {
  const [brandNameError, setBrandNameError] = useState(false);
  const [brandNameErrorAr, setBrandNameErrorAr] = useState(false);
  const [businessTypeOptions, setBusinessTypeOptions]= useState(null);
  const dispatch = useDispatch();
  const classes = useStyles();

  const acceptableFormats = [
    "image/jpeg",
    "image/png",
    "image/gif",
    "image/tiff",
    "image/bmp",
  ];
  useEffect(() => {
    //fetch seller business types
    DATABASE.collection("config").doc("seller_config").get().then((data) => {
      const document = data.data();
      setBusinessTypeOptions(document.businessTypes);

    })
  }, [])
  const hiddenFileInput = useRef(null);
  const handleClick = (event) => {
    event.target.value = "";
    hiddenFileInput.current.click();
  };
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    const isFormatAccepted = acceptableFormats.filter(
      (value) => value === fileUploaded.type
    );
    if (fileUploaded.size < 20 * 1024 * 1024 && isFormatAccepted.length !== 0) {
      var imageUrl = new Image();
      const settingsImage = URL.createObjectURL(fileUploaded);
      imageUrl.src = settingsImage;
      imageUrl.onload = function (evt) {
        const width = evt.target.width;
        const height = evt.target.height;
        if (width >= 500 && height >= 500) {
          setLogo(fileUploaded);
        } else {
          dispatch(
            setNotificationMessage({
              type: "error",
              message: "Poor quality image. We recommend an image size of 500x500 px.",
            })
          );
        }
      };
    } else if (fileUploaded.size < 20 * 1024 * 1024) {
      dispatch(
        setNotificationMessage({
          message: "Format should be JPG/PNG/GIF/TIFF/BMP.",
        })
      );
    } else if (isFormatAccepted.length !== 0) {
      dispatch(
        setNotificationMessage({ message: "File should be less than 20MB" })
      );
    }
    
  };
  return (
    <div>
      <Grid direction="column" container alignItems="center" spacing={3}>
        <Grid item>
          <Typography
            variant="body1"
            className={`${classes.title} ${classes.bottomMargin}`}
          >
            Let's setup your brand
          </Typography>
        </Grid>
        <Grid item>
          <StyledTextField
            className={`${classes.dimension}  ${classes.bottomMargin}`}
            size="small"
            variant="outlined"
            label="Brand name[EN]"
            onInput={e => {
              if(e.target.value.length >= 24){
                e.target.value = (e.target.value).toString().slice(0, 25);}
            }}
            value={brandName?brandName:""}
            InputProps={{
              className:`${classes.inputStyle} ${classes.greenColor}`
            }}
            InputLabelProps={{
              className:`${classes.inputStyle} ${classes.defaultColor}`
            }}
            onChange={(event) => {
              if(event.target.value.toString().length > 25){
                setBrandNameError(true)
              }else{
                setBrandName(event.target.value);
              }
            }}
          />
          {(brandNameError || (brandName && brandName.toString().length) > 25)?
              <Typography
              variant="body2"
              component="span"
              className={classes.error}
            >
              Brand name cannot be greater than 25 characters
            </Typography>:null
          }
        </Grid>
        <Grid item>
          <StyledTextField
            className={`${classes.dimension}  ${classes.bottomMargin}`}
            size="small"
            variant="outlined"
            label="Brand name[AR]"
            onInput={e => {
              if(e.target.value.toString().length >= 24){
                e.target.value = (e.target.value).toString().slice(0, 25);}
            }}
            value={brandNameAr?brandNameAr:""}
            InputProps={{
              className:`${classes.inputStyle} ${classes.greenColor}`
            }}
            InputLabelProps={{
              className:`${classes.inputStyle} ${classes.defaultColor}`
            }}
            onChange={(event) => {
              if(event.target.value.toString().length > 25){
                setBrandNameErrorAr(true)
              }else{
                setBrandNameAr(event.target.value);
              }
            }}
          />
          {(brandNameErrorAr || (brandNameAr && brandNameAr.toString().length) > 25)?
              <Typography
              variant="body2"
              component="span"
              className={classes.error}
            >
              Brand name[AR] cannot be greater than 25 characters
            </Typography>:null
          }
        </Grid>
        <Grid item className={classes.mobileItem}>
          <StyledTextField
             className={`${classes.dimension}  ${classes.bottomMargin}`}
            size="small"
            variant="outlined"
            label="Business type"
            InputProps={{
              className:`${classes.inputStyle} ${classes.greenColor}`
            }}
            InputLabelProps={{
              className:`${classes.inputStyle} ${classes.defaultColor}`
            }}
            select
            value={businessType ? businessType : ""}
            onChange={(event) => {
              setBusinessType(event.target.value);
            }}
          >
            {businessTypeOptions && businessTypeOptions.map((item, key) => (
              <MenuItem key={`${key}-${item}`} value={item}>
                {item}
              </MenuItem>
            ))}
          </StyledTextField>
        </Grid>
        <Grid item className={classes.mobileItem}>
          <Box
            onClick={handleClick}
            className={`${classes.imageContainer} ${classes.alignImage} ${classes.dimension} ${classes.bottomMargin}`}
          >
            <AddImageComponent />
            <Typography className={`${classes.imageLabel} ${logo?classes.greenColor:classes.defaultColor}`}>
            {
                logo? "brand logo added" :"choose your brand logo"
            }
            </Typography>
            <input
              name="imageUrl"
              type="file"
              style={{ display: "none" }}
              ref={hiddenFileInput}
              onChange={handleChange}
            />
          </Box>
        </Grid>
        <Grid className={classes.mobileItem2}><Typography>Please use a desktop to complete your brand settings</Typography></Grid>
      </Grid>
    </div>
  );
}
