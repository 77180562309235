import { Avatar, Grid, ListItemIcon, ListItemText, Menu, MenuItem, Typography, withStyles } from '@material-ui/core';
import { ExitToAppOutlined } from '@material-ui/icons';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { signOut } from '../../../actions/authActions';
import NotificationIcon from '../../NotificationIcon';
import useStyles from './styles';

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
));

function Vendor({ imgUrl, name }) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch();

    const openMenu = (event) => {
        setAnchorEl(event.target);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const signout = () => {
        dispatch(signOut());
    }

    return (
        <div className={classes.root}>
            <Grid container direction='row' spacing={2} className={classes.noWrap}>
                <Grid item>
                    <Avatar className={classes.vendorImage} src={imgUrl} />
                </Grid>
                <Grid item className={classes.flexContainer} onClick={openMenu}>
                    <div><Typography>{name}</Typography></div>
                    <div className={`${classes.iconContainer} ${classes.flexContainer}`}>
                        <i className={`${classes.arrow} ${anchorEl ? classes.up : classes.down}`}>
                        </i>
                    </div>
                </Grid>
            </Grid>
            <StyledMenu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={signout}>
                    <ListItemIcon>
                        <ExitToAppOutlined />
                    </ListItemIcon>
                    <ListItemText>
                        <Typography>Sign out</Typography>
                    </ListItemText>
                </MenuItem>
            </StyledMenu>
        </div>
    )
}

export default Vendor;