import { TEXT_COLOR } from "../../constants/theme";
const { makeStyles } = require("@material-ui/core");

const useStyles = makeStyles({
    board: {
        display: "flex",
        margin: "0 auto",
        width: "90vw",
        fontFamily: 'nunito',
        backgroundColor: "white",
        overflowY: "auto"
    },
    column: {
        height: "80vh",
        margin: "0 1vw",
        backgroundColor: "white",
        flex: 1
    },
    columnHead: {
        textAlign: "center",
        padding: 10,
        fontSize: "1.2em",
        backgroundColor: TEXT_COLOR.ACTIVE,
        borderRadius: "20px",
        color: "white",
        height: "5vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    item: {
        padding: 10,
        margin: "10px 0",
        fontSize: "1em",
        cursor: "pointer",
        backgroundColor: TEXT_COLOR.BACKGROUND,
        height: "2.5vw",
        borderRadius: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    header: {
        fontFamily: 'nunito',
        textAlign: "center",
        padding: 10,
        fontSize: "1.5em",
        color: TEXT_COLOR.ORANGE,
        borderRadius: "20px",
        height: "5vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    headerContent: {
        fontSize: "12px",
        fontFamily: "Nunito",
        color: TEXT_COLOR.DEFAULT,
        fontWeight: "600",
        display: "flex",
        textAlign: "center",
        paddingLeft: "8px",
        justifyContent: "flex-start",
        overflowWrap: "break-word",
        wordBreak: "break-word",
        maxHeight: '32px',
        // overflow:'scroll'
    },
    container: {
        flex: 1,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column"
        // alignItems: "center",
        // overflow:'scroll'
    },
    saveButton:{
        background: "turquoise",
        color: "white",
        marginTop: "10px"
    }
});

export default useStyles;
