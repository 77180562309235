import React, { useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  IconButton,
  Typography,
  Avatar,
  Checkbox,
  TextField,
  withStyles,
  Divider,
  CircularProgress,
} from "@material-ui/core";
import Chip from '@material-ui/core/Chip';
import { CheckBox } from "@material-ui/icons";
import {
  deleteProductAction,
  updateProductAction,
} from "../../actions/addProducts";
import { DividerComponent } from "../../components/Divider";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./style";
import Icon from "@mdi/react";
import {
  mdiClose,
  mdiDeleteOutline,
  mdiCircleEditOutline,
  mdiCheckCircle,
  mdiImagePlus,
  mdiAlbum,
  mdiCloseCircle,
  mdiCloseCircleOutline,
  mdiMessageBulleted
} from "@mdi/js";
import { CheckBoxOutlineBlank } from "@material-ui/icons";
import { CategoryMenu } from "../NewProduct";
import { setNotificationMessage } from "../../actions/notificationActions";
import { usePrevious } from "../../hooks";
import { TEXT_COLOR } from "../../constants/theme";
import NumberFormat from 'react-number-format';
import { DATABASE } from "../../config/fbConfig";
import { docNames } from "../../constants/firebaseDoc";
import { StyledButton } from "../../components/StyledButton";


const StyledTextfield = withStyles({
  root: {
    "& .MuiInput-underline:before": {
      content: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "0px none",
    },
  },
})((props) => (
  <TextField
    style={{ width: "100%" }}
    InputProps={{
      style: {
        marginLeft: "8px",
        fontSize: 12,
      },
    }}
    InputLabelProps={{
      style: {
        marginLeft: "8px",
        fontSize: "12px",
        fontFamily: "Nunito",
        color: TEXT_COLOR.ACTIVE,
      },
    }}
    {...props}
  />
));

const getFormatedPrice = (price) => {
  let floatValue = parseFloat(price);
  if (isNaN(floatValue)) {
    return 0;
  }
  const requiredValue =
    Math.floor(floatValue) === floatValue
      ? floatValue
      : floatValue.toString().split(".")[1].length > 3
      ? parseFloat(floatValue.toFixed(3))
      : floatValue;
  return requiredValue;
};

const DisplayImage=({displayImage,handleClose,imageUrl })=>{
  const [loading, setLoading]= useState(false);
  const classes = useStyles();
  useEffect(() => {
    if (imageUrl && displayImage) {
      setLoading(true);
      var imageFile = new Image();
      imageFile.src = imageUrl;
      imageFile.onload = function () {
        setLoading(false);
      };
      imageFile.onerror = function () {
        setLoading(false);
      };
    }
  }, [imageUrl, displayImage]);

  return(
    <Dialog open={displayImage} onClose={handleClose}>
      <IconButton size='small' onClick={handleClose} style={{marginLeft:'auto'}}>
          <Icon path={mdiClose} size='16px'/>
      </IconButton>
      <div className={classes.imageCtn}>
      {loading? <CircularProgress style={{ 'color': '#30D9C4' }} size="12px" />:<img alt='' src={imageUrl} className={classes.img}/>}
      </div>

    </Dialog>
  );
}
const NotesDialog = ({ openNotesDialog, handleClose, imageAnalytics, confidenceLevel }) => {
  const classes = useStyles();

  return (
    <Dialog open={openNotesDialog} onClose={handleClose}>
      <IconButton size='small' onClick={handleClose} style={{ marginLeft: 'auto' }}>
        <Icon path={mdiClose} size='16px' />
      </IconButton>
      {confidenceLevel && confidenceLevel ?
        <div style={{ display: "flex", flexWrap: "wrap", margin: "15px" }}>
          <Typography>{`Policy violation probability found: ${100 - confidenceLevel} % `}</Typography>
        </div> : null}
      {imageAnalytics && imageAnalytics.labelAnnotations && imageAnalytics.labelAnnotations ?
        <div style={{ display: "flex", flexWrap: "wrap", margin: "15px" }}>
          {imageAnalytics && imageAnalytics.labelAnnotations && imageAnalytics.labelAnnotations.map((label, index) => (
            <Grid key={index} style={{ display: "flex", justifyContent: "center", margin: "10px" }}>
              <Chip label={`${label.description}(${parseFloat((label.score * 100).toFixed(2))})`} /></Grid>
          ))}
        </div> : null}

    </Dialog>
  );
}
export function TableBody({
  item,
  checkboxList,
  handleCheckboxChange,
  comingFrom,
  handleAddedProductsList,
  keyId,
  setProductsList,
  duplicateProducts,
  productList,
  deleteProductExcel,
  handleProductReject,
  multipleProductsVerify,
  setMultipleProductsVerify,
  setMultipleProductsReject,
  multipleProductsReject,
  selectedProducts,
  sellerId,
  branchId,
}) {
  const dispatch = useDispatch();
  const { uid } = useSelector(({ authState }) => authState.user);
  const [currentItem, setCurrentItem] = useState({
    name: "",
    price: 0,
    imageUrl: "",
    description: "",
    category: {
      categoryName: "",
      id: "",
    },
    id: "",
    nameAr: "",
    descriptionAr: "",
    barcode:""
  });
  const [nameError, setNameError ] = useState(false)
  const [nameArError, setNameArError ] = useState(false)
  const [descriptionError, setDescriptionError ] = useState(false)
  const [descriptionArError, setDescriptionArError ] = useState(false)
  const [displayImage, setDisplayImage]= useState(false);
  const [openNotesDialog, setOpenNotesDialog] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [action, setAction] = useState("");
  const classes = useStyles();
  const { defaultBranchId } = useSelector(
    ({ defaultBranchState }) => defaultBranchState.defaultBranch
  );

  const fileInputRef = useRef(); 

  const handleSingleProductDelete = () => {
    if (comingFrom === "excelUpload") {
      deleteProductExcel(keyId);
    } else {
      let isSure = window.confirm(`Are you sure you want to delete this product ${name}?`)
        if(isSure){
          dispatch(deleteProductAction({ id: currentItem.id, product: currentItem, productImageUrl: imageUrl, defaultBranchId, uid }));
        }
      
    }
    if (comingFrom === "addProductsHome") {
      handleAddedProductsList(currentItem.id);
    }
  };
  // const [displayIcons, setDisplayIcons] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [editedImage, setEditedImage] = useState(null);
  const [isImageLoading, setLoading] = useState(true);
  const [imageEdited, setImageEdited] = useState(false);
  const [priceZeroError,setPriceZeroError] = useState(false);
  const acceptableFormats = [
    "image/jpeg",
    "image/png",
    "image/gif",
    "image/tiff",
    "image/bmp",
  ];
  const {
    name,
    price,
    imageUrl,
    description,
    category,
    id,
    nameAr,
    descriptionAr,
    thumbnail_100,
    thumbnail_512,
    barcode
  } = currentItem;
  useEffect(() => {
    if (typeof item.imageUrl === "object") {
      var imageUrl = new Image();
      const productImageUrl = URL.createObjectURL(item.imageUrl);
      imageUrl.src = productImageUrl;
      imageUrl.onload = function (evt) {
        const width = evt.target.width;
        const height = evt.target.height;
        setEditedImage(item.imageUrl);
        setCurrentItem((prevState) => ({
          ...prevState,
          imageUrl: productImageUrl,
        }));
        setImageEdited(true);
      };
    }
  }, [item]);

  const prevItem = usePrevious(item);
  useEffect(()=>{
    if(!item.id && item.objectID){
      item.id = item.objectID;
      item.category = item.categoryObj;
    }
    if(!currentItem.id && currentItem.objectID){
      currentItem.id = currentItem.objectID;
      currentItem.category = currentItem.categoryObj;
    }
    if(prevItem && !prevItem.id && prevItem.objectID){
      prevItem.id = prevItem.objectID;
      prevItem.category = prevItem.categoryObj;
    }
  },[currentItem, item, prevItem]);

  useEffect(() => {
    if (item && prevItem) {
      if (item.id !== prevItem.id) {
        setIsEditing(false);
      }
    }
  }, [item, prevItem]);
  useEffect(() => {
    setCurrentItem(JSON.parse(JSON.stringify(item)));
  }, [item]);

  const changeEditMode = () => {
    setIsEditing((prevState) => {
      if (prevState) {
        setCurrentItem(JSON.parse(JSON.stringify(item)));
      }
      return !prevState;
    });
  };

  const handleAvailableProducts = (value) => {
    const schema = {
      availability: Number(value) === 1? true: false,
    };
    dispatch(
      updateProductAction({
        schema,
        defaultBranchId: defaultBranchId,
        productUid: currentItem.id,
        uid
      })
    );
  };

  const handleChange = (evt) => {
    if((evt.currentTarget && evt.currentTarget.name ==="name" ) && evt.currentTarget.value.length>61){
      setNameError(true)
    }else{setNameError(false)}
    if((evt.currentTarget && evt.currentTarget.name ==="nameAr" ) && evt.currentTarget.value.length>61){
      setNameArError(true)
    }else{setNameArError(false)}
    if((evt.currentTarget && evt.currentTarget.name ==="description" ) && evt.currentTarget.value.length>121){
      setDescriptionError(true)
    }else{setDescriptionError(false)}
    if((evt.currentTarget && evt.currentTarget.name ==="descriptionAr" ) && evt.currentTarget.value.length>121){
      setDescriptionArError(true)
    }else{setDescriptionArError(false)}
    if(evt.currentTarget){
      setCurrentItem({
        ...currentItem,
        [evt.currentTarget.name]: evt.target.value
      });
    }else{
      if(evt.target.value < 0.001 || evt.target.value === "0"){
        setPriceZeroError(true)
      }else{
        setPriceZeroError(false)
        setCurrentItem({
          ...currentItem,
          [evt.target.name]:getFormatedPrice(Number(evt.target.value)),
        });
      }
      
    }
  };

  const saveItem = () => {
    let schema;
    let notificationMessage = "";
    let notificationType = "";
    if (item.name.toLowerCase() === currentItem.name.toLowerCase() && item.nameAr.toLowerCase() === currentItem.nameAr.toLowerCase() && !imageEdited && 
      item.description.toLowerCase() === currentItem.description.toLowerCase() && item.descriptionAr.toLowerCase() === currentItem.descriptionAr.toLowerCase()) {
      schema = {
        name: currentItem.name,
        price: Number(currentItem.price),
        description: currentItem.description,
        category: currentItem.category,
        nameAr: currentItem.nameAr,
        descriptionAr: currentItem.descriptionAr,
        barcode: currentItem.barcode || null,
      };
      notificationType = "success"
      notificationMessage = "Changes saved successfully";
    }else{
      schema = {
        name: currentItem.name,
        price: Number(currentItem.price),
        description: currentItem.description,
        category: currentItem.category,
        nameAr: currentItem.nameAr,
        descriptionAr: currentItem.descriptionAr,
        barcode: currentItem.barcode || null,
        verificationStatus: "PENDING"
      };
      notificationType = "Info";
      notificationMessage = "The product is pending for verification and moved to pending list, it will get listed once it is verified."
    }
    if (currentItem.imageUrl === "") {
      dispatch(
        setNotificationMessage({
          type: "error",
          message: "Image field cannot be empty",
        })
      );
    } else if(price <= 0) {
      dispatch(
        setNotificationMessage({
          message: "Price cannot be less than or equal to 0",
        })
      );
    
      }else if(currentItem.name.replace(/^\s+|\s+$/g, "").length===0 && currentItem.nameAr.replace(/^\s+|\s+$/g, "").length===0){
        dispatch(
          setNotificationMessage({
            type: "error",
            message: "Name cannot be empty, please fill the Arabic or English name.",
          })
        );
      }else if(currentItem.name.length>60){
        dispatch(
          setNotificationMessage({
            type: "error",
            message: "Name cannot have more than 120 chars.",
          })
        );
      }else if(currentItem.description.length>120){
        dispatch(
          setNotificationMessage({
            type: "error",
            message: "Description cannot have more than 120 chars.",
          })
        );
      }else if(currentItem.barcode && currentItem.barcode.length>64){
        dispatch(
          setNotificationMessage({
            type: "error",
            message: "Barcode cannot have more than 64 chars.",
          })
        );
      }else {

      if (comingFrom === "excelUpload") {
        const tempList = [...productList];
        tempList[keyId] = currentItem;
        tempList[keyId].imageFile = editedImage
          ? editedImage
          : productList[keyId].imageFile;
        setProductsList(tempList);
      } else {
        dispatch(
          updateProductAction({
            oldImageUrl: item.imageUrl,
            imageUrl: editedImage,
            schema,
            defaultBranchId: defaultBranchId,
            productUid: currentItem.id,
            uid
          })
        );
        dispatch(
          setNotificationMessage({
              type: notificationType,
              message: notificationMessage,
          })
      );
        
      }

      setIsEditing(false);
    }
  };

  const openCategoryMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const setCategory = (val) => {
    setCurrentItem({
      ...currentItem,
      category: val,
    });
  };

  const handleImageDelete = () => {
    setCurrentItem((prevState) => ({
      ...prevState,
      imageUrl: "",
      thumbnail_100:"",
      thumbnail_512:""

    }));
  };

  const openImageSelectDialog = (e) => {
    e.target.value = "";
    fileInputRef.current.click();
  };

  const handleImageClicked=()=>{
    if(!isEditing){
      setDisplayImage(true)
    }
  }

  const handleImageChange = (event) => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded) {
      const isFormatAccepted = acceptableFormats.filter(
        (value) => value === fileUploaded.type
      );
      if (
        fileUploaded.size < 20 * 1024 * 1024 &&
        isFormatAccepted.length !== 0
      ) {
        var imageUrl = new Image();
        const productImageUrl = URL.createObjectURL(fileUploaded);
        imageUrl.src = productImageUrl;
        imageUrl.onload = function (evt) {
          const width = evt.target.width;
          const height = evt.target.height;
          if (width >= 500 && height >= 500) {
            setEditedImage(fileUploaded);
            setCurrentItem((prevState) => ({
              ...prevState,
              imageUrl: productImageUrl,
            }));
            setImageEdited(true);
          } else {
            dispatch(
              setNotificationMessage({
                type: "error",
                message: "Poor quality image. We recommend an image size of 500x500 px.",
              })
            );
          }
        };
        setImageEdited(false);
      } else if (fileUploaded.size < 20 * 1024 * 1024) {
        dispatch(
          setNotificationMessage({
            message: "Format should be JPG/PNG/GIF/TIFF/BMP.",
          })
        );
      } else if (isFormatAccepted.length !== 0) {
        dispatch(
          setNotificationMessage({ message: "File should be less than 20MB" })
        );
      }
    }
  };
  useEffect(() => {
    if (imageUrl || thumbnail_100) {
      var imageFile = new Image();
      imageFile.src = thumbnail_100? thumbnail_100: imageUrl;
      imageFile.onload = function () {
        setLoading(false);
      };
      imageFile.onerror = function () {
        setLoading(false);
      };
    }
  }, [imageUrl, thumbnail_100]);

  useEffect(()=>{
    if(multipleProductsVerify){
      setAction("verify");
      setOpenConfirmationDialog(true);
    }

    if(multipleProductsReject){
      setAction("reject");
      setOpenConfirmationDialog(true);
    }

    if(!multipleProductsVerify && !multipleProductsReject && comingFrom === "productVerification"){
      handleDialogClose();
    }

  },[multipleProductsVerify, multipleProductsReject])

  const verifyProduct = async () => {

    if (multipleProductsVerify && selectedProducts.length) {
      selectedProducts.forEach(async product => {
        await DATABASE.collection(docNames.SELLER).doc(sellerId).collection(docNames.BRANCHES).doc(branchId)
        .collection(docNames.PRODUCT).doc(product).update({ verificationStatus: "VERIFIED" }).then(() => {
          dispatch(
            setNotificationMessage({
              type: "success",
              message: "Products verified successfully."
            })
          );
          handleProductReject(sellerId, product);
        });
      });
    } else {
      await DATABASE.collection(docNames.SELLER).doc(currentItem.sellerId).collection(docNames.BRANCHES).doc(currentItem.branchId)
        .collection(docNames.PRODUCT).doc(currentItem.id).update({ verificationStatus: "VERIFIED" }).then(() => {
          dispatch(
            setNotificationMessage({
              type: "success",
              message: `${currentItem.name || currentItem.nameAr} verified successfully.`
            }));
          handleProductReject(currentItem.sellerId, currentItem.id);
        });
    }
    handleDialogClose();

  }

  const rejectProduct = async () => {
    if (selectedProducts.length) {
      selectedProducts.forEach(async product => {
        await DATABASE.collection(docNames.SELLER).doc(sellerId).collection(docNames.BRANCHES).doc(branchId)
          .collection(docNames.PRODUCT).doc(product).update({ verificationStatus: "REJECTED", rejectReason: "Policy violation" }).then(() => {
            dispatch(
              setNotificationMessage({
                type: "success",
                message: "Products rejected successfully."
              }));
            handleProductReject(sellerId, product);
          });
      });
    } else {
      await DATABASE.collection(docNames.SELLER).doc(currentItem.sellerId).collection(docNames.BRANCHES).doc(currentItem.branchId)
      .collection(docNames.PRODUCT).doc(currentItem.id).update({ verificationStatus: "REJECTED", rejectReason: "Policy violation" }).then(() => {
        dispatch(
          setNotificationMessage({
            type: "success",
            message: `${currentItem.name || currentItem.nameAr} rejected successfully.`
          })
        )
        handleProductReject(currentItem.sellerId, currentItem.id);
      });
    }
    handleDialogClose();
  }

  const handleDialogClose = () =>{
    setOpenConfirmationDialog(false);
    setMultipleProductsReject(false);
    setMultipleProductsVerify(false)
    setAction("");
  }

  return (
    <Grid
      container
      direction="column"
      // onMouseEnter={() => {
      //   if (comingFrom !== "onBoarding") {
      //     setDisplayIcons(true);
      //   }
      // }}
      // onMouseLeave={() => {
      //   if (comingFrom !== "onBoarding") {
      //     setDisplayIcons(false);
      //   }
      // }}
      className={`${classes.bottomMargin} `}
      style={{
        maxWidth:'95vw',
        backgroundColor: `${
          ((comingFrom === "excelUpload" && duplicateProducts.includes(keyId)) || item.verificationStatus === "REJECTED")
            ? "#FFF7F5"
            : checkboxList[item.id]
            ? "#F6FBFA"
            : TEXT_COLOR.WHITE
        }`,
        border: `${
          checkboxList[item.id] ? "0px" : `1px solid ${TEXT_COLOR.BACKGROUND}`
        }`,
      }}
    >
      <Grid item  className={`${classes.tableRow1}`} >
        <Grid container direction="row">
          <Grid item className={`${classes.checkbox} ${classes.flexOne}`}>
            <Checkbox
              disableRipple
              icon={
                <CheckBoxOutlineBlank
                  style={{
                    color: TEXT_COLOR.BACKGROUND,
                    backgroundColor: TEXT_COLOR.BACKGROUND,
                  }}
                  fontSize="small"
                />
              }
              checkedIcon={
                <CheckBox
                  style={{ color: TEXT_COLOR.ACTIVE }}
                  fontSize="small"
                />
              }
              checked={
                checkboxList[comingFrom === "excelUpload" ? keyId : item.id]
                  ? checkboxList[comingFrom === "excelUpload" ? keyId : item.id]
                  : false
              }
              value={
                checkboxList[comingFrom === "excelUpload" ? keyId : item.id]
                  ? checkboxList[comingFrom === "excelUpload" ? keyId : item.id]
                  : false
              }
              onChange={() => {
                if (comingFrom === "excelUpload") {
                  handleCheckboxChange(keyId);
                } else if(comingFrom === "productVerification"){
                  handleCheckboxChange(item.sellerId, item.id);
                }else {
                  handleCheckboxChange(item.id);
                }
              }}
              size="small"
              style={{ display: "flex", alignItems: "center" }}
            />
          <DividerComponent height="32px" color={TEXT_COLOR.BACKGROUND} />
          </Grid>
          <Grid item className={`${classes.tableBody} ${classes.flexFour}`}>
            {!isEditing ? (
              <Typography
                align="left"
                className={`${classes.text} ${classes.fullWidth}`}
              >
                {item.name}
              </Typography>
            ) : (
              <StyledTextfield
                label="Name [EN]"
                value={name}
                name="name"
                onInput={e => {
                  if(e.target.value.length >= 60){
                    e.target.value = (e.target.value).toString().slice(0, 60);}
                }}
                onChange={handleChange}
              />
            )}
            {nameError?<Typography
              variant="body2"
              component="span"
              className={classes.error}
            >
              Max length 60 characters
            </Typography>:null}
          </Grid>
          <Grid item className={`${classes.tableBody} ${classes.flexFour}`}>
            {!isEditing ? (
              <Typography className={`${classes.text} ${classes.fullWidth}`}>
                {nameAr ? nameAr : "-"}
              </Typography>
            ) : (
                <StyledTextfield
                  label="Name [AR]"
                  value={nameAr}
                  name="nameAr"
                  onChange={handleChange}
                  onInput={e => {
                    if (e.target.value.length >= 60) {
                      e.target.value = (e.target.value).toString().slice(0, 60);
                    }
                  }}
                />
              )}
            {nameArError ? <Typography
              variant="body2"
              component="span"
              className={classes.error}
            >
              Max length 60 characters
          </Typography> : null}
          </Grid>
          <DividerComponent height="32px" color={TEXT_COLOR.BACKGROUND} />
          <Grid
            item
            className={`${classes.tableBody} ${classes.flexTwo}`}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                position: "relative",
              }}
            >
          {imageUrl ? (
            isImageLoading ? (
              <CircularProgress style={{ 'color': '#30D9C4' }} size="12px" />
            ) : (
              <Avatar onClick={handleImageClicked} alt="product" src={isEditing ? imageUrl : thumbnail_100? thumbnail_100: imageUrl || imageUrl.name} />
            )): (
                <IconButton
                  size="medium"
                  className={classes.iconButtonStyling}
                  onClick={openImageSelectDialog}
                >
                  <Icon
                    path={mdiImagePlus}
                    size="16px"
                    color={TEXT_COLOR.DEFAULT}
                    className={classes.iconStyling}
                  />
                  <input
                    type="file"
                    name="imageUrl"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                  />
                </IconButton>
              )}
              {isEditing && imageUrl ? (
                <IconButton
                  size="small"
                  className={classes.iconButtonStyling}
                  style={{
                    position: "absolute",
                    display: "flex",
                    top: 0,
                    right: 0,
                  }}
                  onClick={handleImageDelete}
                  edge="end"
                >
                  <Icon
                    path={mdiClose}
                    size="6px"
                    color={TEXT_COLOR.BLACK}
                    className={classes.iconStyling}
                  />
                </IconButton>
              ) : (
                ""
              )}
            </div>
          </Grid>
          <DividerComponent height="32px" color={TEXT_COLOR.BACKGROUND} />
            {comingFrom !== "productVerification"?
              <Grid item className={`${classes.tableBody} ${classes.flexThree}`}>
              {!isEditing ? (
                <Typography
                  align="left"
                  className={`${classes.text} ${classes.fullWidth}`}
                >
                  {`${item.price} KWD`}
                </Typography>
              ) : (
                <NumberFormat
                  value={price}
                  name={"price"}
                  customInput={StyledTextfield}
                  onInput={e => {
                    if(e.target.value.length > 8){
                      e.target.value = Math.max(0, parseFloat(e.target.value)).toString().slice(0, 8);}
                    }}
                    onKeyDown={(evt) => {
                      if (evt.key === "e" || evt.key === "E") {
                        evt.preventDefault();
                      }
                    }}
                  type="text"
                  onChange={handleChange}
                />
              )}
              {priceZeroError?<Typography
                  variant="body2"
                  component="span"
                  className={classes.error}
                >
                  Max length 60 characters
                </Typography>:null}
            </Grid>
          :null}
          
          {comingFrom !== "productVerification"?
            <Grid item className={`${classes.category}`}>
              {comingFrom !== "productVerification" ?
                <DividerComponent height="32px" color={TEXT_COLOR.BACKGROUND} />
                : null}
            {!isEditing ? (
              <Typography
                align="left"
                className={`${classes.text} ${classes.fullWidth}`}
              >
                {(item && item.category && item.category.categoryName) || (item && item.category)}
              </Typography>
            ) : (
              <Typography
                align="left"
                className={`${classes.text} ${classes.fullWidth}`}
                onClick={openCategoryMenu}
              >
                {category.categoryName}
              </Typography>
            )}
          </Grid>
          :null}
          {comingFrom === "productVerification" ?
            <Grid item className={`${classes.tableBody}  ${classes.flexOneHalf}`}>

              {item.confidenceLevel ?
                <Grid>{`${item.confidenceLevel}%`}
                  <IconButton
                    size="small"
                    className={classes.iconButtonStyling}
                    onClick={() => {
                      setOpenNotesDialog(true);
                    }}
                    edge="end"
                  >
                    <Icon
                      path={mdiMessageBulleted}
                      size="16px"
                      color={TEXT_COLOR.DEFAULT}
                      className={classes.iconStyling} />
                  </IconButton>

                </Grid> : null}
            </Grid> : null}
          
          <Grid className={`${classes.actionContainer}`}>
          <DividerComponent height="32px" color={TEXT_COLOR.BACKGROUND} />
          <Grid item className={`${classes.tableBody} ${classes.flexOneHalf}`}>
            {comingFrom ==="productVerification"?
              <Grid container direction="row" alignItems='center' >
                <Grid
                  item style={{ justifyContent: "flex-end" }}
                  className={classes.flexOne}
                >
                  {/* {comingFrom !== "onBoarding" ? ( */}
                  <IconButton
                    size="small"
                    className={classes.iconButtonStyling}
                    onClick={ () => {
                      setAction("verifyOneProduct")
                      setOpenConfirmationDialog(true)
                      }}
                    edge="end"
                  >
                    <Icon
                      path={mdiCheckCircle}
                      size="16px"
                      color={TEXT_COLOR.SUCCESS_COLOR}
                      className={classes.iconStyling}
                    />
                  </IconButton>

                </Grid>
                <Grid
                  item
                  className={classes.flexOne}
                  style={{ marginRight: "4px" }}
                >
                  <IconButton
                    size="small"
                    className={classes.iconButtonStyling}
                    onClick={ () => {
                      setAction("rejectOneProduct")
                      setOpenConfirmationDialog(true);
                      }}
                    edge="end"
                  >
                    <Icon
                      path={mdiCloseCircle}
                      size="16px"
                      color={TEXT_COLOR.ERROR}
                  className={classes.iconStyling}
                />
              </IconButton>
            </Grid>
          </Grid>:
            <Grid container direction="row" alignItems='center' >
              <Grid
                item style={{justifyContent:"flex-end"}}
                className={classes.flexOne}
              >
                {/* {comingFrom !== "onBoarding" ? ( */}
                  <IconButton
                    size="small"
                    className={classes.iconButtonStyling}
                    onClick={!isEditing ? changeEditMode : saveItem}
                    edge="end"
                  >
                    <Icon
                      path={isEditing ? mdiCheckCircle : mdiCircleEditOutline}
                      size="16px"
                      color={TEXT_COLOR.BLACK}
                      className={classes.iconStyling}
                    />
                  </IconButton>
                
              </Grid>
              <Grid
                item
                className={classes.flexOne}
                style={{marginRight:"4px"}}
              >
                <IconButton
                  size="small"
                  className={classes.iconButtonStyling}
                  onClick={
                    isEditing ? changeEditMode : handleSingleProductDelete
                  }
                  edge="end"
                >
                  <Icon
                    path={!isEditing ? mdiDeleteOutline : mdiClose}
                    size="16px"
                    color={
                      // comingFrom === "onBoarding"
                      //   ? TEXT_COLOR.BLACK: 
                        TEXT_COLOR.ERROR
                    }
                    className={classes.iconStyling}
                  />
                </IconButton>
              </Grid>
            </Grid>}
          </Grid></Grid>
          {/* */}
          <CategoryMenu
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            category={category}
            setCategory={setCategory}
          />
        </Grid>
      </Grid>
      <Divider style={{ backgroundColor: TEXT_COLOR.F6F7F8 }} />
      {/* <Grid item style={{ height: "40px" }}> */}
      <Grid container direction="row" className={`${classes.tableRow2}`}>
        {comingFrom !== "productVerification" ?
          <Grid item style={{ flex: 0.5, padding: "0 0.5vw", alignSelf: "center", textAlign: "center" }}>
            <Grid
              item
              className={classes.flexOne}
            >
              <Icon
                path={mdiAlbum}
                size="16px"
                color={Boolean(item.availability) ? TEXT_COLOR.SUCCESS_COLOR : TEXT_COLOR.DELETE_COLOR}
                className={classes.iconStyling}
              />

            </Grid>
          </Grid>
          : null}
        <DividerComponent height="32px" color={TEXT_COLOR.BACKGROUND} />
        <Grid item className={`${classes.tableBody}  ${classes.flexFive}`}>
          {!isEditing ? (
            <Typography
              align="left"
              className={`${classes.text} ${classes.fullWidth}`}
            >
              {description ? description : "Description[EN]"}
            </Typography>
          ) : (
              <StyledTextfield
                label="Description[EN]"
                value={description}
                name="description"
                onChange={handleChange}
                onInput={e => {
                  if (e.target.value.length >= 120) {
                    e.target.value = (e.target.value).toString().slice(0, 120);
                  }
                }}
              />
            )}
        </Grid>

        <DividerComponent height="32px" color={TEXT_COLOR.BACKGROUND} />
        <Grid item className={`${classes.tableBody} ${classes.flexFive}`}>
          {!isEditing ? (
            <Typography className={`${classes.text} ${classes.fullWidth}`}>
              {descriptionAr ? descriptionAr : "Description[Ar]"}
            </Typography>
          ) : (
            <StyledTextfield
              value={descriptionAr}
              name="descriptionAr"
              onChange={handleChange}
              label="Description [AR]"
              onInput={e => {
                if(e.target.value.length >= 120){
                  e.target.value = (e.target.value).toString().slice(0, 120);}
              }}
            />
          )}
          {descriptionArError?<Typography
            variant="body2"
            component="span"
            className={classes.error}
          >
            Max length 120 characters
          </Typography>:null}
        </Grid>
        <DividerComponent height="32px" color={TEXT_COLOR.BACKGROUND} />
        {comingFrom !== "productVerification"?
        <Grid item className={`${classes.tableBody}  ${classes.flexTwo}`}>
          {!isEditing ? (
            <Typography
              align="left"
              className={classes.text}
            >
              {barcode ? barcode : "Barcode"}
            </Typography>
          ) : (

              <StyledTextfield
                label="Barcode"
                value={barcode}
                name="barcode"
                onChange={handleChange}
              />
            )}
          {descriptionError ? <Typography
            variant="body2"
            component="span"
            className={classes.error}
          >
            Max length 120 characters
          </Typography> : null}
          </Grid> : null}


        {comingFrom !== "productVerification" ?
          <Grid className={`${classes.statusContainer}`}><Grid item className={`${classes.tableBody}  ${classes.flexOne}`}>
            {item.verificationStatus !== "REJECTED" ? (
              <Typography
                align="left"
                className={classes.text}
              >
                {item.verificationStatus}
              </Typography>
            ) : (

              <div>
                <Typography
                  variant="body2"
                  component="span"
                  className={classes.error}
                >
                  {item.rejectReason}
                </Typography>
                <Typography
                  align="left"
                  className={classes.text}
                >
                  {item.verificationStatus}
                </Typography>
              </div>
            )}
          </Grid></Grid> : null}
      </Grid>
      <DisplayImage imageUrl={isEditing ? imageUrl : thumbnail_512? thumbnail_512: imageUrl} handleClose={()=>setDisplayImage(false)} displayImage={displayImage}/>
      <NotesDialog imageAnalytics={item.imageAnalytics} confidenceLevel={item.confidenceLevel && item.confidenceLevel} openNotesDialog={openNotesDialog} handleClose={()=>setOpenNotesDialog(false)}></NotesDialog>
      <Dialog
        open={openConfirmationDialog}
        onClose={handleDialogClose}>
        <DialogContent
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            width: "40vw",
          }}
          className={classes.replaceDialog}
        >
          {action === "verify" || action === "verifyOneProduct" ? <Typography
            align="left"
            className={`${classes.buttonLabel} ${classes.headingFont}`}
          >
            The selected product(s) will be verified. Are you sure?
          </Typography>:
          <Typography
            align="left"
            className={`${classes.buttonLabel} ${classes.headingFont}`}
          >
            The selected product(s) will be rejected. Are you sure?
          </Typography>}
        </DialogContent>
        <DialogActions>

          <StyledButton
            onClick={action === "verify" || action === "verifyOneProduct" ? verifyProduct : rejectProduct}
            startIcon={
              <Icon
                path={mdiCheckCircle}
                size="12px"
                color={TEXT_COLOR.WHITE}
              />
            }
            style={{
              backgroundColor: TEXT_COLOR.DEFAULT,
              marginRight: "4px",
              width: "fit-content",
            }}
            size="small"
          >
            <Typography style={{color:TEXT_COLOR.WHITE}}>Confirm</Typography>
          </StyledButton>
          <StyledButton onClick={handleDialogClose}
            startIcon={
              <Icon
                path={mdiCloseCircleOutline}
                size="12px"
                color={TEXT_COLOR.DEFAULT}
              />
            }
            style={{
              backgroundColor: `${TEXT_COLOR.DEFAULT}00`,
              border: `1px solid ${TEXT_COLOR.DEFAULT}`,
              marginRight: "4px",
              width: "fit-content",
            }}
            size="small">
            <Typography style={{ color: TEXT_COLOR.DEFAULT }}>Cancel</Typography>
          </StyledButton>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
