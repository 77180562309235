import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    vendorImage: {
        background: '#e6e6e6',
        height: 25,
        width: 25
    },
    flexContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    },
    iconContainer: {
        marginLeft: 5
    },
    arrow: {
        border: 'solid black',
        borderWidth: '0 2px 2px 0',
        display: 'inline-block',
        padding: '2.5px',
        transitionDuration: '0.5s',
    },
    down: {
        transform: 'rotateX(0deg) rotateZ(45deg)',
        WebkitTransform: 'rotateX(0deg) rotateZ(45deg)'
    },
    up: {
        transform: 'rotateX(180deg) rotateZ(45deg)',
        WebkitTransform: 'rotateX(180deg) rotateZ(45deg)'
    },
    noWrap: {
        flexWrap: 'nowrap',
        alignItems: "center"
    }
});

export default useStyles;