import { Divider } from "@material-ui/core";
import React from "react";
export function DividerComponent(props) {
  return (
    <Divider
      orientation="vertical"
      flexItem
      style={{
        height: props.height,
        alignSelf: "center",
        width: "1px",
        backgroundColor: props.color,
      }}
    />
  );
}
