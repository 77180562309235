import { TEXT_COLOR } from "../../constants/theme";

const { makeStyles } = require("@material-ui/core");

export const useStyles = makeStyles({
  body: {
    height: "48px",
  },
  tableBody: {
    display: "flex",
    alignItems: "center",
    height: "48px",
    // paddingLeft: "8px",
    justifyContent: "flex-start",
  },

  button: {
    cursor: "pointer",
    fontSize: "14px",
    fontFamily: "Nunito",
    padding: "8px 8px 8px 8px",
  },

  buttonEnd: {
    justifyContent: "flex-end",
  },
  greenColor: {
    color: TEXT_COLOR.ACTIVE,
  },
  deleteIcon: {
    color: TEXT_COLOR.BLUE,
    fontSize: "22px",
  },
  categoryMenuTitle: {
    color: TEXT_COLOR.B2B2B2,
    fontFamily: "Nunito",
    fontSize: "16px",
    marginBottom: "8px",
  },
  flexOne: {
    flex: 1,
  },
  flexTwo: {
    flex: 2,
  },
  flexThree: {
    flex: 3,
  },
  flexSeven: {
    flex: 7,
  },
  flexFour: {
    flex: 4,
  },
  flexFive: {
    flex: 5,
  },
  bottomMargin: {
    marginBottom: "4px",
  },
  text: {
    overflowWrap: "break-word",
    wordBreak: "break-word",
    //overflowY: "scroll",
    overflowX: "hidden",
    fontSize: "12px",
    fontFamily: "Nunito",
    maxHeight: "40px",
    marginRight: "auto",
    marginLeft: "8px",
  },
  addProcuctBorder: {
    border: `1px solid ${TEXT_COLOR.ACTIVE}`,
    backgroundColor: TEXT_COLOR.ACTIVE_BACKGROUND,
    borderRadius: "4px",
  },
  iconStyling: {
    backgroundColor: TEXT_COLOR.DEFAULT_BACKGROUND,
    borderRadius: "20px",
    padding: "4px",
  },
  categoryRoot: {
    display: "flex",
    flexDirection: "column",
    width: "200px",
    marginLeft: "16px",
    outline: "none",
  },
  flexFourteen: {
    flex: 14,
  },
  error: {
    color: TEXT_COLOR.ERROR,
    fontSize: 9
}
});
