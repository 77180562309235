import { Button, DialogActions, Grid, InputAdornment, TextField, Typography } from '@material-ui/core';
import { Email } from '@material-ui/icons';
import React from 'react';
import useStyles from './styles';
import { AUTH } from '../../config/fbConfig'
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { setNotificationMessage } from '../../actions/notificationActions';

function ForgotPassword({ onClose }) {
    const classes = useStyles();
    const [email, setEmail] = React.useState('');
    const schema = yup.object().shape({
        email: yup
            .string()
            .email('Enter a valid email')
            .required('Please enter a valid email')
    })
    const { register, handleSubmit, errors } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(schema)
    });
    const dispatch = useDispatch();

    const sendMail = () => {
        AUTH
            .sendPasswordResetEmail(email)
            .then(() => {
                onClose();
            })
            .catch((err) => {
                let payload={
                    type:'error',
                    message: err.message
                }
                if(err.code==='auth/user-not-found'){
                    payload={
                        type:'error',
                        message: 'This email id does not exist in Mashkor.'
                    }
                }
                dispatch(setNotificationMessage(payload))
                onClose();
            })
    }

    return (
        <div className={classes.root}>
            <div
                className={classes.close}
                onClick={onClose}
            >
                &times;
            </div>
            <Grid container direction='column' spacing={3}>
                <Grid item>
                    <Typography variant='h4' component='h4'>
                        Forgot Password
                </Typography>
                </Grid>
                <Grid item>
                    <Typography variant='body2' component='p'>
                        Let us know your registered email id, we will send you the password to your email.
                </Typography>
                </Grid>
                <Grid item>
                    <TextField
                        autoFocus
                        className={classes.textField}
                        variant='outlined'
                        value={email}
                        placeholder='Enter your email'
                        inputRef={register}
                        name='email'
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <Email />
                                </InputAdornment>
                            )
                        }}
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}
                    />
                    {errors && errors.email &&
                        <span className={classes.error}>
                            {errors.email.message}
                        </span>
                    }
                </Grid>
            </Grid>
            <DialogActions>
                <Button
                    variant='contained'
                    className={classes.sendMail}
                    onClick={handleSubmit(sendMail)}
                >
                    Send Reset Link
                </Button>
            </DialogActions>
        </div>
    )
}

export default ForgotPassword;