import { call, put } from "redux-saga/effects";
import { prepTimeService } from "../service/api/sellerCollection";

export function* preparationTimeSaga({ payload }) {
  const { setDisable, uid } = payload;
  try {
    if (uid) {
      yield call(prepTimeService, payload);
      yield put({
        type: "SET_NOTIFICATION_MESSAGE",
        payload: {
          type: "success",
          message: "Preparation time updated successfully.",
        },
      });
      setDisable(false);
    }
  } catch {
    yield put({
      type: "SET_NOTIFICATION_MESSAGE",
      payload: { type: "error", message: "Something went wrong." },
    });
    setDisable(false);
  }
}
