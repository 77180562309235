import { makeStyles } from "@material-ui/core";
import React from "react";
export function ErrorMessage(props) {
  const classes = useStyles();
  const { message } = props;
  return <span style={props.style} className={classes.errorMessage}>{message}</span>;
}

const useStyles = makeStyles({
    
  errorMessage: {
    margin: "0px 0px 0px 3px !important",
    fontSize: "14px !important",
    color: "#ff6347 !important",
    fontFamily: "Montserrat, sans-serif",
    
  },
});
