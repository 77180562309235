import { AUTH } from "../../config/fbConfig";
import { BASE_URL } from "../../config/baseUrlConfig"

export const handleSignInRequest = (payload) => {
  const { email, password } = payload;
  return new Promise(async function (resolve, reject) {
    AUTH.signInWithEmailAndPassword(email, password)
      .then((response) => {
        // var user = response.user;
        // user.updateEmail(email.toLowerCase());
        resolve({ response });
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const sendingVerificationLink = (payload) => {
  return new Promise(async function (resolve, reject) {
    AUTH.currentUser
      .sendEmailVerification()
      .then((response) => {
        resolve({ response });
      })
      .catch(function (error) {
        reject({ error });
      });
  });
};

export const checkIfUserExists = (payload) => {
  const { email, password } = payload;
  return new Promise(function (resolve, reject) {
    AUTH.createUserWithEmailAndPassword(email, password)
      .then((response) => {
        resolve({ value: "created", uid: response.user.uid });
      })
      .catch((error) => {
        if (error.code === "auth/email-already-in-use") {
          resolve({ value: "Already registered", uid: "" });
        } else {
          reject(error);
        }
      });
  });
};

export const handleAuthentication = () => {
  return new Promise((resolve, reject) => {
    AUTH.onAuthStateChanged((res) => {
      resolve(res);
    });
  });
}

export const signOutService = () => {
  return AUTH.signOut();
}