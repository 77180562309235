import { makeStyles } from "@material-ui/core";
import { TEXT_COLOR } from "../../../constants/theme";

const useStyles = makeStyles(theme =>({
    root: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px 16px',
        overflow: 'scroll',
        height: '80vh',
        flexWrap: 'nowrap',
        [theme.breakpoints.up('sm')]: {
            flexDirection: "column"
        },
    },
    operationsInfo:{
        [theme.breakpoints.between("xs", "sm")]: {
            display: "none"
        },
    },
    grid: {
        height: '100%',
        flexWrap: 'nowrap !important',
        [theme.breakpoints.between("xs", "sm")]: {
            padding: "20px"
        },
    },
    onlyMobile:{
        [theme.breakpoints.up("sm")]: {
            display: "none"
        },
    },
    container: {
        overflow: 'scroll',
        height: '60vh',
        margin: "5vh auto",
        [theme.breakpoints.between("xs", "sm")]: {
            display: "none"
        },
    },
    title: {
        fontFamily: 'Nunito',
        fontSize: 20,
        fontWeight: 600,
        color: TEXT_COLOR.DEFAULT
    },
    subTitle:{
        fontFamily: 'Nunito',
        fontSize: 14,
        fontWeight: 600,
        color: TEXT_COLOR.DEFAULT,
        textTransform: 'capitalize'
    },
    day: {
        fontFamily: 'Nunito',
        fontSize: 16,
        fontWeight: 500,
        color: TEXT_COLOR.DEFAULT,
        paddingRight: "10%",
        marginBottom:10,
        alignSelf: "center"
    },

    configContainer: {
        width: '50%'
    },
    configContainerGrid:{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: 0
    },
    configGrid: {
        maxHeight:'fit-content'
    },
    noWrap: {
        flexWrap:'nowrap'
    },
    button: {
        minHeight: 22,
        minWidth: 22,
        height: '3.5vw',
        width: '3.5vw',
        padding: 0,
        alignSelf: "center",
        padding: "12px"
    },
    closeButton: {
        position: 'absolute',
        right: 'calc(-3.5vw - 16px)',
        bottom: 8
    },
    relativeBox: {
        position:'relative'
    },
    switchContainer: {
        // position: 'relative'
        position: 'absolute',
        right: 'calc(-8.5vw - 16px)',
        top: 0
    },
    switch: {
        position: 'relative',
        top: -7,
        marginRight: 10
    },
    flex1: {
        flex: 1
    },
    closeButton2: {
        position: 'absolute',
        right: 8,
        bottom: 8  
    },
    saveButton: {
        backgroundColor: TEXT_COLOR.ACTIVE,
        borderRadius: "4px",
        border: `0px solid ${TEXT_COLOR.ACTIVE}`,
        color: TEXT_COLOR.WHITE,
        fontSize: "2vh",
        cursor: "pointer",
        [theme.breakpoints.between("xs", "sm")]: {
            display: "none"
        },
    },
}));

export default useStyles;