import {
  Grid,
  MenuItem,
  Paper,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import useStyles from "./styles";
import Icon from "@mdi/react";
import { mdiEmailOutline, mdiImagePlus, mdiPhone } from "@mdi/js";
import { updateBrandSettings } from "../../../actions/brandDetailsActions";
import ShadowlessButton from "../../../components/ShadowlessButton";
import { setNotificationMessage } from "../../../actions/notificationActions";
import { StyledTextField } from "../../../components/Textfield";
import { TEXT_COLOR } from "../../../constants/theme";
import { DATABASE } from '../../../config/fbConfig';
import { docNames } from '../../../constants/firebaseDoc';
import { savebranchAddressAction } from "../../../actions/saveBranchAddress"
import Select from 'react-select';

const EditTextField = withStyles({
  root: {
    "& .MuiInputBase-root": {
      height: 40,
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 12px) scale(1)'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)'
    },
  },
})(StyledTextField);

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    '&:focus': { borderColor: "#30D9C4", boxShadow: "none" },
    '&:hover': { borderColor: "#30D9C4", boxShadow: "none" },
    border: "1px solid #30D9C4",
    boxShadow: "none",
    width: '100%'
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? `#30D9C499` : 'black',
  }),
  multiValueLabel: base => ({
    ...base,
    border: "1px solid #30D9C499",
    backgroundColor: 'white',
  }),
  menuPortal: provided => ({ ...provided, zIndex: 9999 }),
  menu: provided => ({ ...provided, zIndex: 9999 })
}

function BrandSettings() {
  const classes = useStyles();
  const { uid } = useSelector(({ authState }) => authState.user);
  const { defaultBranchId } = useSelector(
    ({ defaultBranchState }) => defaultBranchState.defaultBranch
  );
  const brandConfig = useSelector(({ brandSettingState }) => brandSettingState?.brandConfig);
  const sellerDetails = useSelector(({ brandSettingState }) => brandSettingState?.sellerDetails);
  const [brandName, setBrandName] = useState(brandConfig?.brandName);
  const [brandNameAr, setBrandNameAr] = useState(brandConfig?.brandNameAr);
  const [businessType, setBusinessType] = useState(brandConfig?.businessType);
  const [address, setAddress] = useState({});
  const [email, setEmail] = useState(sellerDetails.email);
  const [phone, setPhone] = useState(sellerDetails.phone);
  const [editedImage, setEditedImage] = useState(null);
  const [isDisabled, setDisabled] = useState(false);
  const dispatch = useDispatch();
  const [disableSave, setDisableSave] = useState(false);
  const [duplicatePhone, setDuplicatePhone] = useState(false);
  const [shortPhoneNumber, setShortPhoneNumber] = useState(false);
  const [saveClicked, setSaveClicked] = useState(false)
  const [data, setData] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState({})
  const [loading, setLoading] = useState(true);
  const [addressFetched, setAddressFetched] = useState(false);
  const [selectedArea, setSelectedArea] = useState(null)
  const [selectedBlock, setSelectedBlock] = useState(null)
  const [selectedStreet, setSelectedStreet] = useState(null)
  const [isSearchable, setIsSearchable] = useState(true)
  const [disableMenu, setDisableMenu] = useState(true)
  const [noArea, setNoArea] = useState(false)
  const [baseUrl, setBaseUrl] = useState(null)
  const [businessTypeOptions, setBusinessTypeOptions]= useState(null);
  const fileRef = useRef();

  const docRef = DATABASE
    .collection(docNames.SELLER)
    .doc(uid)
    .collection(docNames.BRANCHES)
    .doc(defaultBranchId)

  const acceptableFormats = [
    "image/jpeg",
    "image/png",
    "image/gif",
    "image/tiff",
    "image/bmp",
  ];
  
  const selectInputRef = useRef();

  useEffect(() => {
    //fetch api details 
    DATABASE.collection("config").doc("courier_orders").get().then((data) => {
      const document = data.data();
      setBaseUrl(document.baseUrl);
    });

    //fetch seller business types
    DATABASE.collection("config").doc("seller_config").get().then((data) => {
      const document = data.data();
      setBusinessTypeOptions(document.businessTypes);

    })
  }, [])

  useEffect(() =>{
    docRef.get().then((doc) => {
      if(doc.data().address){
        setAddress(doc.data().address);
        setSelectedAddress(doc.data().address);
      }
    }).then(()=> setAddressFetched(true))
    .then(()=>{fetchAreasUrl()})
  },[])


  async function fetchAreasUrl() {
    const url = `${baseUrl && baseUrl}/v1/locs/areas`
    const response = await fetch(url);
    const json = await response.json();
    const options = json.data.map(d => ({
      "value": d.id,
      "label": d.name_en,
      "tag": "area"
    }))
    setData(options);
    setLoading(false);
    return options
  }
  async function fetchBlocksUrl(area) {
    console.log(typeof area.value)
    let id = typeof area.value  === "number" ? area.value : selectedAddress.area_id;
    console.log(id);
    const url = `${baseUrl && baseUrl}/v1/locs/areas/${id}/blocks`
    const response = await fetch(url);
    const json = await response.json();
    const options = json.data.map(d => ({
      "value": d.id,
      "label": "block-" + d.name_en,
      "tag": "block"
    }));
    setData(options);
    console.log(data);
    setLoading(false);
    return options
  }

  async function fetchStreetsUrl (block){
    const steerUrl = `${baseUrl && baseUrl}/v1/locs/blocks/${block.value}/streets`
    const streetResponse = await fetch(steerUrl);
    const streetJson = await streetResponse.json();
    const streetOptions = streetJson.data.map(d => ({
      "value": d.id,
      "label": d.name_en,
      "latitude": d.latitude,
      "longitude": d.longitude,
      "tag": "street"
    }))
    setData(streetOptions);
    setLoading(false);
    return streetOptions
  }
  useEffect(() => {
    fetchAreasUrl();
  }, [loading,noArea,addressFetched]);

  async function handleAddressChange(e, action) {
    if(action.action === "clear" ||
    ((action.action === "pop-value" || action.action === "remove-value") && action.removedValue.tag === "area" )){
      setIsSearchable(true);
      setDisableMenu(true);
      e.splice(0, e.length);
      setSelectedAddress({});
      fetchAreasUrl();
    }
    if(e && e.length === 1 && (e && e[0] && e[0].tag === "area") ||
    (action.action === "select-option" && action.option.tag === "area") ){
      console.log(e[0]);
      setSelectedArea(e[0]);
      fetchBlocksUrl(e[0]);
      setSelectedAddress({
        area: e[0].label,
        area_id: e[0].value,
      });
    }
    if(((action.action === "pop-value" || action.action === "remove-value") && action.removedValue.tag === "block" )){
      e.splice(1, e.length)
      setSelectedAddress({
        area: selectedAddress.area,
        area_id: selectedAddress.area_id,
        additionalInfo: selectedAddress.additionalInfo || null
      });
      fetchBlocksUrl(e[0]);
    }

    if((e && e[1] && e[1].tag === "block") ||
    (action.action === "select-option" && action.option.tag === "block")){
      setSelectedBlock(e[1]);
      fetchStreetsUrl(e[1]);
      setSelectedAddress({
        area: selectedAddress.area,
        area_id: selectedAddress.area_id,
        block: e[1].label,
        block_id: e[1].value,
      });
    }
    if(((action.action === "pop-value" || action.action === "remove-value") && action.removedValue.tag === "street" )){
      e.splice(2, e.length);
      setSelectedAddress({
        area: selectedAddress.area,
        area_id: selectedAddress.area_id,
        block: selectedAddress.block,
        block_id: selectedAddress.block_id,
        additionalInfo: selectedAddress.additionalInfo || null
      });
      fetchStreetsUrl(e[1]);
    }

    if((e && e[2] && e[2].tag === "street") ||
    (action.action === "select-option" && action.option.tag === "street")){
      setSelectedStreet(e[2]);
      setIsSearchable(false);
      setDisableMenu(false);
      setSelectedAddress({...selectedAddress,
        street: e[2].label,
        street_id: e[2].value,
        latitude: e[2].latitude,
        longitude: e[2].longitude,
      });
    }else{
      setIsSearchable(true)
      setDisableMenu(true)
    }
        
  }

  useEffect(() => {
    if (brandConfig.brandName || brandConfig.brandNameAr || brandConfig.businessType) {
      if (   
        brandName === brandConfig.brandName &&
        brandNameAr === brandConfig.brandNameAr &&
        businessType === brandConfig.businessType &&
        sellerDetails.phone === phone &&
        !Boolean(editedImage) &&
        ((address && address.area === selectedAddress&& selectedAddress.area) &&
        (address && address.block === selectedAddress&& selectedAddress.block) &&
        (address && address.street === selectedAddress&& selectedAddress.street)) 
      ) {
        setDisableSave(true);
      } else if ((brandName !== brandConfig.brandName || brandNameAr !== brandConfig.brandNameAr ||
        businessType !== brandConfig.businessType) && brandName.toString().trim().length > 0 && brandNameAr.toString().trim().length > 0&& businessType.length > 0) {
        setDisableSave(false);
      } else if (editedImage) { setDisableSave(false); }
      if (selectedAddress && Object.keys(selectedAddress).length < 8) { setDisableSave(false) }
      else{setDisableSave(false) }
    } else if (editedImage || (editedImage && businessType && phone &&
      (sellerDetails.phone !== phone && phone.length >= 7)) && !duplicatePhone (brandName && brandName.toString().trim().length > 0) && (brandNameAr && brandNameAr.toString().trim().length > 0) && businessType.length > 0) {
      setDisableSave(false);
    }
  }, [
    brandName,
    brandNameAr,
    businessType,
    email,
    phone,
    brandConfig,
    sellerDetails,
    disableSave,
    editedImage,
    duplicatePhone,
  ]);

  useEffect(() => {

    let checkIfFalse = [brandName?.toString().trim(), brandNameAr?.toString().trim(), businessType, selectedAddress.area, selectedAddress.block, selectedAddress.street].some( value => !value);

    if(checkIfFalse){
      setDisabled(true);
      setDisableSave(true);
    }else{
      setDisabled(false);
      setDisableSave(false);
    }
  }, [brandName,
    brandNameAr,
    businessType,
    email,
    phone,
    brandConfig,
    sellerDetails,
    disableSave,
    editedImage,
    duplicatePhone,
    selectedAddress]);

  const openFileSelectDialog = () => {
    fileRef.current.click();
  };

  const setImage = (e) => {
    const fileUploaded = e.target.files[0];
    if (fileUploaded) {
      const isFormatAccepted = acceptableFormats.filter(
        (value) => value === fileUploaded.type
      );
      if (
        fileUploaded.size < 20 * 1024 * 1024 &&
        isFormatAccepted.length !== 0
      ) {
        var imageUrl = new Image();
        const settingsImage = URL.createObjectURL(fileUploaded);
        imageUrl.src = settingsImage;
        imageUrl.onload = function (evt) {
          const width = evt.target.width;
          const height = evt.target.height;
          if (width >= 500 && height >= 500) {
            setEditedImage(fileUploaded);
          } else {
            dispatch(
              setNotificationMessage({
                type: "error",
                message: "Poor quality image. We recommend an image size of 500x500 px.",
              })
            );
          }
        };
      } else if (fileUploaded.size < 20 * 1024 * 1024) {
        dispatch(
          setNotificationMessage({
            message: "Format should be JPG/PNG/GIF/TIFF/BMP.",
          })
        );
      } else if (isFormatAccepted.length !== 0) {
        dispatch(
          setNotificationMessage({ message: "File should be less than 20MB" })
        );
      }
    }
  };

  const handleChange = (e) => {
    e.persist();
    switch (e.target.name) {
      case "brandName":
        setBrandName(e.target.value);
        break;
      case "brandNameAr":
        setBrandNameAr(e.target.value);
        break;
      case "businessType":
        setBusinessType(e.target.value);
        break;
      case "phone":
        if (e.target.value.length === 0) {
          setPhone("")
          setDisabled(true)
          setDuplicatePhone(false)
          setShortPhoneNumber(false)
          setDisableSave(true);
        }
        else if (e.target.value.length !== 0 && e.target.value.length < 7) {
          setDisabled(true)
          setDisableSave(true);
          setShortPhoneNumber(true)
        } else if (e.target.value.length >= 7) {
          DATABASE.collection(docNames.SELLER)
            .where("phone", "==", e.target.value)
            .get()
            .then(data => {
              if (data.docs.length === 0) {
                setDuplicatePhone(false)
                setShortPhoneNumber(false)
                setDisabled(false)
                setDisableSave(false);
                setPhone(e.target.value)
              } else {
                setDuplicatePhone(true)
                setShortPhoneNumber(false)
                setDisabled(true)
                setDisableSave(true);
              }
            })
        }

        break;
      case "additionalInfo":
        const value = e.target.value
        const newSelectedAddress = { ...selectedAddress, additionalInfo: value };   
        setSelectedAddress({...newSelectedAddress});
      default:
        break;
    }
  };

  const saveDetails = () => {
    setSaveClicked(true)
    setDisableSave(true);
    const editState = {
      brandName: brandName.toString().trim(),
      brandNameAr: brandNameAr.toString().trim(),
      businessType,
      email,
      phone,
    };
    if (brandName && brandNameAr && businessType && phone.length > 6 && !duplicatePhone && brandName !== "" && brandNameAr !== "" && businessType !== "") {

      setSaveClicked(false)
      dispatch(
        updateBrandSettings({ ...editState, editedImage, setDisableSave, uid })
      );
      dispatch(savebranchAddressAction(selectedAddress));
      // console.log("newSelectedAddress", newSelectedAddress);
      docRef.update({ "address": selectedAddress });
    } else {
      setSaveClicked(true)
      setDisabled(true);
      setDisableSave(true);
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.brandConfig}>
        <Grid
          container
          direction="column"
          alignItems="center"
        >
          <Grid item>
            <img
              alt=""
              src={brandConfig.brandLogo}
              className={classes.brandImage}
            />
          </Grid>
          <Grid item>
            <Typography
              component="p"
              variant="body1"
              className={classes.brandName}
            >
              {brandConfig.brandName}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              component="p"
              variant="body1"
              className={classes.brandNameAr}
            >
              {brandConfig.brandNameAr}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              component="p"
              variant="body1"
              className={classes.businessType}
            >
              {brandConfig.businessType}
            </Typography>
          </Grid>
          <Grid
            item
            style={{
              width: "100%",
            }}
          >
            <div className={classes.sellerInfo}>
              <div className={classes.infoRow}>
                <div className={classes.iconContainer}>
                  <Icon path={mdiPhone} size={1} color={TEXT_COLOR[848484]} />
                </div>
                <Typography
                  component="p"
                  variant="body1"
                  className={classes.sellerDetails}
                >
                  {sellerDetails.phone}
                </Typography>
              </div>
              <div className={classes.infoRow}>
                <div className={classes.iconContainer}>
                  <Icon path={mdiEmailOutline} size={1} color={TEXT_COLOR[848484]} />
                </div>
                <Typography
                  component="p"
                  variant="body1"
                  className={classes.sellerDetails}
                >
                  {sellerDetails.email}
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
      </Paper>
      <Paper className={classes.brandEdit}>
        <Grid container spacing={3} direction='column' className={classes.grid}>
          <Grid item>
            <Typography
              variant="body1"
              component="p"
              className={classes.editTitle}
            >
              Edit brand settings
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              component="p"
              className={classes.onlyMobile}
            >
              Please use a desktop to edit your brand settings.
            </Typography>
          </Grid>
          <Grid item>
            <Grid
              container
              spacing={3}
              direction="column"
              className={classes.editForm}
            >
              <Grid item>
                <input
                  type="file"
                  className={classes.brandLogoInput}
                  ref={(input) => (fileRef.current = input)}
                  onChange={setImage}
                />
                <div
                  className={classes.brandLogo}
                  onClick={openFileSelectDialog}
                >
                  <Icon path={mdiImagePlus} size={1} color={TEXT_COLOR.DEFAULT} />
                  <Typography
                    variant="body2"
                    component="span"
                    className={classes.editLogoLabel}
                  >
                    {editedImage ? editedImage.name : "choose your brand logo"}
                  </Typography>
                </div>
              </Grid>
              <Grid item>
                <EditTextField
                  label="Brand name[EN]"
                  name="brandName"
                  variant="outlined"
                  value={brandName ? brandName : ""}
                  onChange={handleChange}
                  className={classes.dimension}
                  InputLabelProps={{
                    style: {
                      color: TEXT_COLOR.ACTIVE,
                      fontFamily: "Nunito",
                      fontSize: 13
                    },
                  }}
                  onInput={e => {
                    if(e.target.value.length >= 25){
                      e.target.value = (e.target.value).toString().slice(0, 25);}
                  }}
                />
                
                {isDisabled && saveClicked && 
                (brandName === ""  || brandName === undefined  || 
                (brandName && brandName.toString().trim() === "") ) ? (
                  <Typography
                    variant="body2"
                    component="span"
                    className={classes.error}
                  >
                    Brand name cannot be empty
                  </Typography>
                ) : (
                    ""
                  )}
              </Grid>
              <Grid item>
                <EditTextField
                  label="Brand name[AR]"
                  name="brandNameAr"
                  variant="outlined"
                  value={brandNameAr ? brandNameAr : ""}
                  onChange={handleChange}
                  className={classes.dimension}
                  InputLabelProps={{
                    style: {
                      color: TEXT_COLOR.ACTIVE,
                      fontFamily: "Nunito",
                      fontSize: 13
                    },
                  }}
                  onInput={e => {
                    if(e.target.value.length >= 25){
                      e.target.value = (e.target.value).toString().slice(0, 25);}
                  }}
                />
                {isDisabled && saveClicked && 
                (brandNameAr === "" || brandNameAr === undefined  || (brandNameAr && brandNameAr.toString().trim() === "")) ? (
                  <Typography
                    variant="body2"
                    component="span"
                    className={classes.error}
                  >
                    Brand name[AR] cannot be empty
                  </Typography>
                ) : (
                    ""
                  )}

              </Grid>
              <Grid item>
                <EditTextField
                  select
                  size="small"
                  variant="outlined"
                  label="Business type"
                  InputLabelProps={{
                    style: {
                      color: TEXT_COLOR.ACTIVE,
                      fontFamily: "Nunito",
                      fontSize: 13
                    },
                  }}
                  name="businessType"
                  value={businessType ? businessType : ''}
                  onChange={handleChange}
                  className={classes.dimension}
                >
                  {businessTypeOptions && businessTypeOptions.map((type, index) => (
                    <MenuItem key={`${type}-${index}`} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </EditTextField>
                {(isDisabled && saveClicked && businessType === "") || (isDisabled && saveClicked && businessType === undefined) ? (
                  <Typography
                    variant="body2"
                    component="span"
                    className={classes.error}
                  >
                    Please select a category
                  </Typography>
                ) : (
                    ""
                  )}
              </Grid>
              {addressFetched ?
                <Grid item>
                  <Select
                    styles={customStyles}
                    defaultValue={(address) ? [
                      { value: address.area && address.area, label: address.area || "area", tag: "area" },
                      { value: address.block && address.block, label: address.block || "block", tag: "block" },
                      { value: address.street && address.street, label: address.street || "street", tag: "street" }
                    ] : []}
                    isSearchable={isSearchable}
                    openMenuOnClick={disableMenu}
                    isClearable
                    ref={selectInputRef}
                    // value={selectedAddress}
                    onChange={handleAddressChange}
                    isMulti
                    options={data}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                </Grid>
                : null}
              {addressFetched ?
                <Grid item>
                  <EditTextField
                    label="Additional Info"
                    name="additionalInfo"
                    variant="outlined"
                    defaultValue={(address && address.additionalInfo) ? address.additionalInfo : ""}
                    onChange={handleChange}
                    className={classes.dimension}
                    InputLabelProps={{
                      style: {
                        color: TEXT_COLOR.ACTIVE,
                        fontFamily: "Nunito",
                        fontSize: 13
                      },
                    }}
                  />
                </Grid>
                : null}

            </Grid>
          </Grid>
          <Grid item>
            <div className={classes.editActionContainer}>
              <ShadowlessButton
                disabled={disableSave}
                disableElevation
                variant="contained"
                className={classes.saveButton}
                onClick={saveDetails}
                // isClearable={false}
                // clearable={false}
                // styles={customStyles}
              >
                Save
              </ShadowlessButton>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

export default BrandSettings;
