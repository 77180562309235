import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    notificationSign: {
        position: 'absolute',
        borderRadius: 10,
        top: '12%',
        right: '12%',
        background: 'cadetblue',
        height: 7,
        width: 7
    }
});

export default useStyles;