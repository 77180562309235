import {
  Button,
  Grid,
  Typography,
  withStyles,
  makeStyles
} from "@material-ui/core";
import {
  mdiPhoneInTalkOutline,
  mdiAccountCircleOutline,
} from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";
import { ReplaceProducts } from "../../../../../assets/images";
import { TEXT_COLOR } from "../../../../../constants/theme";

export const StyledButton = withStyles({
  root: {
    borderRadius: "20px",
    textTransform: "none",
  },
})(Button);

export const useStyles = makeStyles(theme=>({

  headerContent: {
    fontSize: "12px",
    fontFamily: "Nunito",
    color: TEXT_COLOR.DEFAULT,
    fontWeight: "600",
    display: "flex",
    textAlign: "center",
    paddingLeft: "8px",
    justifyContent: "flex-start",
    overflowWrap: "break-word",
    wordBreak: "break-word",
    maxHeight:'32px',
    // overflow:'scroll'
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "10px",
    },
  },
  
  buttonLabel: {
    fontFamily: "Nunito",
    fontWeight: "bold",
    textTransform: "none",
    color: TEXT_COLOR.DEFAULT,
  },
  labelFont: {
    fontSize: "12px",
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "10px",
    },
  },
  headingFont: {
    fontSize: "24px",
  },
  link: {
    fontFamily: "Nunito",
    color: TEXT_COLOR.ACTIVE,
    textDecoration: "underline",
    cursor: "pointer",
  },
  imageAlignment: {
    display: "flex",
    justifyContent: "center",
  },
  marginRight: {
    marginRight: "8px",
  },
  buddyDetailsCtn: {
    display: "flex",
    flexDirection: "row",
  },
  end: {
    justifyContent: "flex-end",
    [theme.breakpoints.between("xs", "sm")]: {
      margin: "3px",
    },
  },
}));

export default function CustomerDialogContent({order, confirm}) {
  const classes = useStyles();
  return (
    <Grid style={{textAlign: "center"}}>
      <img src={ReplaceProducts} alt="" />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignSelf: "center",
              alignItems: "center",
              marginTop: "16px",
              justifyContent: "center"
            }}
          >
            <Icon
              path={mdiAccountCircleOutline}
              size="16px"
              color={TEXT_COLOR.DEFAULT}
            />
            <Typography
              className={classes.headerContent}
              style={{ marginRight: "16px" }}
            >
              {order.user.name}
            </Typography>
            <Icon
              path={mdiPhoneInTalkOutline}
              size="16px"
              color={TEXT_COLOR.DEFAULT}
            />
            <Typography className={classes.headerContent}>
              {order.user.phone}
            </Typography>
          </div>
          <Typography
            className={`${classes.buttonLabel} ${classes.headingFont}`}
          >
            Let the customer know about the cart modification !
          </Typography>
          <StyledButton
            style={{ background: TEXT_COLOR.ACTIVE }}
            onClick={confirm}
          >
            <Typography
              className={`${classes.buttonLabel} ${classes.labelFont}`}
              style={{ color: TEXT_COLOR.WHITE }}
            >
              I informed the customer
            </Typography>
          </StyledButton>
    </Grid>
  );
}
