import {
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
  withStyles,
} from "@material-ui/core";
import { ExpandLess } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import NavBar from "../../components/NavBar";
import { DATABASE } from "../../config/fbConfig";
import { docNames } from "../../constants/firebaseDoc.ts";
import { TEXT_COLOR } from "../../constants/theme";
import OnBoardingFlow from "../OnBoardingFlow";
import { ProductManagementScreen } from "../ProductsScreen";
import { ProductsVerification } from "../ProductsVerification"
import useStyles from "./styles";
import { AddProductsHome } from "../AddProductsHome";
import { mdiPackageVariantClosed } from "@mdi/js";
import Icon from "@mdi/react";
import Category from "../Category";
import { UploadExcel } from "../ExcelUpload";
import BrandSettings from "../settings/BrandSettings";
import OperationalHours from "../settings/OperationalHours";
import PreparationTime from "../settings/PreparationTime";
import DeliveryFee from "../settings/DeliveryFee";
import LiveOrder from "../order/LiveOrder";
import OrderHistory from "../order/History";
import { saveCategories } from "../../actions/saveCategory";
import { saveProducts } from "../../actions/saveProducts";
import { orderSubStatuses, productField } from "../../constants/firebaseDoc";
import { saveLiveOrders, saveOrderHistory } from "../../actions/saveOrders";
import { saveBrandDetailsAction } from "../../actions/saveBrandDetails";
import { saveDeliveryFeeAction } from "../../actions/saveDeliverFee";
import { saveSellerDetailsAction } from "../../actions/saveSellerDetails";
import { savePreparationTimeAction } from "../../actions/savePreparationTime";
import { savebranchAddressAction } from "../../actions/saveBranchAddress";
import { MashkorIcon } from "../../components/Logo";
import { setNotificationMessage } from "../../actions/notificationActions";
import { checkBrowserAcceptNotifications } from "../../service/sendNotification"
import Notification from "../../../src/assets/sounds/notification.mp3";
import { SellersFunnel } from "../SellersFunnel";
import { SellersInsights } from "../SellersInsights"

const boardClasses = {
  board: {
    display: "flex",
    margin: "0 auto",
    width: "90vw",
    fontFamily: 'Arial, "Helvetica Neue", sans-serif',
  },
  column: {
    minWidth: 200,
    width: "18vw",
    height: "80vh",
    margin: "0 auto",
    backgroundColor: "lightgrey",
  },
  columnHead: {
    textAlign: "center",
    padding: 10,
    fontSize: "1.2em",
    backgroundColor: TEXT_COLOR.ACTIVE,
    color: "white",
  },
  item: {
    padding: 10,
    margin: 10,
    fontSize: "0.8em",
    cursor: "pointer",
    backgroundColor: "white",
  },
};

const StylizedAccordion = withStyles({
  root: {
    background: "inherit",

    "&::before": {
      height: 0,
    },
  },
})(Accordion);

function Menu({ selectedMenu, onMenuOptionClick}) {  
  const { uid } = useSelector(({ authState }) => authState.user);
  const [sellerInfo, setSellerInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const classes = useStyles();

  useEffect(() => {
    setLoading(true);
    const unsubscribe = DATABASE.collection(docNames.SELLER)
      .doc(uid)
      .onSnapshot((querySnapshot) => {
        const sellerData = querySnapshot.data();
        sellerData._id = uid;
        setSellerInfo(sellerData);
      });
      setLoading(false);
    return () => unsubscribe();
  }, [uid]);

  const MENU_OPTION = sellerInfo && sellerInfo.isAdmin ?{
    FUNNEL: {
      label: "Sellers funnel",
      options: [
        {
          label: "Funnel",
        },
      ],
    },
    PRODUCTS_VERIFICATION: {
      label: "Products verification",
      options: [
        {
          label: "Products verification",
          // component: handleComponent(componentvalue),
        },
      ],
      primaryComponent: <div>Products verification</div>,
    },
    INSIGHTS:{
      label: "Sellers Insights",
      options: [
        {
          label: "Insights",
        }
      ]

    }
  }:{
    STORE: {
      label: "Store",
      options: [
        {
          label: "Products",
        },
        {
          label: "Category",
        },
      ],
    },
    ORDER_MANAGEMENT: {
      label: "Order Management",
      options: [
        {
          label: "Live order",
          component: <LiveOrder />,
        },
        {
          label: "History",
          // component: //
        },
      ],
    },
    SETTINGS: {
      label: " Settings",
      options: [
        {
          label: "Delivery Fee",
        },
        {
          label: "Preparation time",
        },
        {
          label: "Operational hours",
        },
        {
          label: "Brand Settings",
        },
      ],
    },
  };

  return (
    <>
      <div className={classes.menuHeader}>
        <div className={classes.brand}>
          <MashkorIcon style={{ width: "20%", height: "60%;" }} />
          <Typography className={classes.mashkorTitle}>mashkor</Typography>
        </div>
      </div>
      <List className={classes.listMenu}>
        {Object.keys(MENU_OPTION).map((key, index) => {
          if (MENU_OPTION[key].options.length > 0) {
            return (
              <StylizedAccordion
                elevation={0}
                key={`menu-${index}`}
                expanded={true}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandLess
                      htmlColor={
                        selectedMenu[0] === index
                          ? TEXT_COLOR.ACTIVE
                          : TEXT_COLOR.DEFAULT
                      }
                    />
                  }
                >
                  <div
                    className={classes.mainMenu}
                    onClick={() => {
                      onMenuOptionClick([index, 0]);
                    }}
                  >
                    <ListItemIcon>
                      <Icon
                        path={mdiPackageVariantClosed}
                        color={
                          selectedMenu[0] === index
                            ? TEXT_COLOR.ACTIVE
                            : TEXT_COLOR.DEFAULT
                        }
                        size={1}
                      />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography
                        variant="body2"
                        component="span"
                        className={classes.menuText}
                        style={{
                          color:
                            selectedMenu[0] === index
                              ? TEXT_COLOR.ACTIVE
                              : TEXT_COLOR.DEFAULT,
                        }}
                      >
                        {MENU_OPTION[key].label}
                      </Typography>
                    </ListItemText>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={classes.optContainer}>
                    {MENU_OPTION[key].options.map((option, optIndex) => (
                      <ListItemText
                        key={`menu-${index}-${optIndex}`}
                        onClick={() => {
                          onMenuOptionClick([index, optIndex]);
                        }}
                      >
                        <Typography
                          variant="body2"
                          component="span"
                          className={`${classes.menuText} ${classes.subMenuPadding}`}
                          style={{
                            color:
                              selectedMenu[0] === index &&
                                selectedMenu[1] === optIndex
                                ? TEXT_COLOR.ACTIVE
                                : TEXT_COLOR.DEFAULT,
                          }}
                        >
                          {option.label}
                        </Typography>
                      </ListItemText>
                    ))}
                  </div>
                </AccordionDetails>
              </StylizedAccordion>
            );
          } else {
            return (
              <div
                className={classes.mainMenu}
                style={{ paddingLeft: 35 }}
                key={`menu-${index}`}
                onClick={() => {
                  onMenuOptionClick([index, 0]);
                }}
              >
                <ListItemIcon>
                  <Icon
                    path={mdiPackageVariantClosed}
                    color={
                      selectedMenu[0] === index
                        ? TEXT_COLOR.ACTIVE
                        : TEXT_COLOR.DEFAULT
                    }
                    size={1}
                  />
                </ListItemIcon>
                <ListItemText>
                  <Typography
                    variant="body2"
                    component="span"
                    className={classes.menuText}
                    style={{
                      color:
                        selectedMenu[0] === index
                          ? TEXT_COLOR.ACTIVE
                          : TEXT_COLOR.DEFAULT,
                    }}
                  >
                    {MENU_OPTION[key].label}
                  </Typography>
                </ListItemText>
              </div>
            );
          }
        })}
      </List>
    </>
  );
}

function Home() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { uid } = useSelector(({ authState }) => authState.user);
  const { defaultBranchId } = useSelector(
    ({ defaultBranchState }) => defaultBranchState.defaultBranch
  );
  const brandConfig = useSelector(({brandSettingState})=>brandSettingState?.brandConfig);
  const { productList } = useSelector(({ productState }) => productState);
  const [sellerInfo, setSellerInfo] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState([0, 0]);
  const [componentvalue, setComponentValue] = useState(null);
  const [limit, setProductsPerPage] = useState(null);
  const [branchProductsCount, setBranchProductsCount] = useState(null);
  const [lastElement, setLastElement] = useState("");
  const [firstElement, setFirstElement] = useState("");
  const [extraElement, setExtraElement] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [disableNextButton, setDisableNextButton] = useState(null);
  const [disablePreviousButton, setDisablePreviousButton] = useState(null);
  const [paginatedProducts, setPaginatedProducts] = useState(null);
  const [addedProducts, setAddedProducts] = useState([]);
  const [deletedListLength, setDeletedProductsListLength] = useState(0);
  const [checkbox, setCheckbox] = useState(false);
  const [loading, setLoading] = useState(null);
  const [verifiedProductsSwitch, setVerifiedProductsSwitch] = useState(true);
  const [showEmptyList, setShowEmptyList] = useState(false);
  const [showAddProducts, setShowAddProducts] = useState(true);

  const docRef = DATABASE
    .collection(docNames.SELLER)
    .doc(uid)
    .collection(docNames.BRANCHES)
    .doc(defaultBranchId)
    

  const baseQuery = DATABASE.collection(docNames.SELLER)
  .doc(uid)
  .collection(docNames.BRANCHES)
  .doc(defaultBranchId)
  .collection(docNames.PRODUCT);

  const initialProductsQuery = verifiedProductsSwitch ? 
  baseQuery.where("verificationStatus", "==", "VERIFIED").orderBy("name" || "nameAr"):
  baseQuery.where("verificationStatus", "!=", "VERIFIED").orderBy("verificationStatus").orderBy("name" || "nameAr") ;


  useEffect(() => {
    setLoading(true);
    const unsub = DATABASE.collection(docNames.SELLER)
      .doc(uid)
      .collection(docNames.BRANCHES)
      .doc(defaultBranchId)
      .collection(docNames.CATEGORY)
      .onSnapshot(function (querySnap) {
        const list = [];
        if (querySnap.docs.length) {
          querySnap.docs.forEach((item) => {
            const data = item.data();
            data.id = item.id;
            data.productNo = productList.filter(
              (product) => product.category.id === item.id
            ).length;
            list.push(data);
          });
        }
        dispatch(saveCategories(list));
      });
    return () => unsub();
  }, [uid, defaultBranchId, productList, dispatch]);

  useEffect(()=>{
    DATABASE.collection("config").doc("seller_config").get().then((sellerConfig)=>{
      const productsPerPage = sellerConfig.data().productsPerPage;
      setProductsPerPage(productsPerPage);
    });

    docRef.onSnapshot((snap)=>{
      if (snap.data() && snap.data().productsConfig){
        setBranchProductsCount(snap.data().productsConfig.branchTotalProductsCount);
      }
    });
  },[uid, defaultBranchId])

  const getSellerProducts = async (querySnap) => {
    let list = [];
    let addedList = [];
    let editedList = [];
    let deletedList = [];
    const newDoc = querySnap.docChanges();

    newDoc.forEach((change) => {
      if (change.type === "added") {
        const data = change.doc.data();
        data.id = change.doc.id;
        if(data.verificationStatus === "VERIFIED" && verifiedProductsSwitch){
          addedList.push(data);
        }else if(data.verificationStatus !== "VERIFIED" && verifiedProductsSwitch){
          addedList.push(data);
        }
      } else if (change.type === "modified") {
        const modifiedDoc = change.doc.data();
        modifiedDoc.id = change.doc.id;
        editedList.push(modifiedDoc);
      } else {
        const deletedItem = change.doc.data();
        deletedItem.id = change.doc.id;
        deletedList.push(deletedItem);
      }
    });
    setDeletedProductsListLength(deletedList.length);

    setAddedProducts((prevState) => {
      let list = [...prevState, ...addedList];
      let updatedList = list
        .filter((data) =>deletedList.filter((doc) => doc.id === data.id).length === 0)
        .map((data) => editedList.find((doc) => doc.id === data.id) || data);
      return updatedList;
    });
    if(querySnap.docs.length === 0){
      setShowEmptyList(true);
      setLoading(true);
      setDisableNextButton(true);
    }else{
      setShowEmptyList(false);
      setLoading(false);
      setDisableNextButton(false);
      querySnap.docs.forEach((item, index) => {
        const data = item.data();
        data.id = item.id;
        list.push(data);
      });
      setFirstElement((list && list[0]));
      setLastElement((list && list[list.length - 2]));
      setExtraElement((list && list[limit])); 
      if(list && list[limit]){
        list = list.slice(0, -1); 
      }
      
    }   
    setPaginatedProducts(list);
    dispatch(saveProducts(list));
  }

  useEffect(()=>{
    if(paginatedProducts !== null && paginatedProducts.length === 0){
      setShowEmptyList(true);
    }

    baseQuery.limit(1).onSnapshot(products=>{
      if(products.docs.length === 0){
        setShowAddProducts(true);
      }else{
        setShowAddProducts(false);
      }
    });
  },[uid, verifiedProductsSwitch, paginatedProducts]);

  useEffect(() => {
    if(limit){
      setAddedProducts([]);
      setCurrentPage(1);
      const unsub = initialProductsQuery.limit(limit + 1)
      .onSnapshot(function (querySnap) {
        getSellerProducts(querySnap)
      });
    return () => unsub();
    }
  }, [uid, defaultBranchId,  dispatch, limit, verifiedProductsSwitch]);

  useEffect(() => {
    if(deletedListLength > 0 && addedProducts.length === 0 ){
      setCurrentPage(1);
      setDisableNextButton(true);
      const unsub = initialProductsQuery.limit(limit + 1)
      .onSnapshot(function (querySnap) {
        getSellerProducts(querySnap)
      });
    return () => unsub();
    }else{
      setDisableNextButton(false);
    }
  }, [paginatedProducts, loading, deletedListLength]);

  useEffect(()=>{

    if((branchProductsCount && limit && (!extraElement)) || disableNextButton || (!paginatedProducts || (paginatedProducts && paginatedProducts.length === 0))){
      setDisableNextButton(true);
    }
    else{
      setDisableNextButton(false);
    }
    if(currentPage === 1){
      setDisablePreviousButton(true);
    }
    else{
      setDisablePreviousButton(false);
    }

  },[branchProductsCount, currentPage, limit, disableNextButton, paginatedProducts]);
  
  const handleNextPage = () => {
    setAddedProducts([]);
    setCheckbox(false);
    setCurrentPage(currentPage + 1);
    if (verifiedProductsSwitch) {
      const nextProductsBatch = initialProductsQuery
        .startAfter(lastElement.name)
        .limit(limit + 1)
        .onSnapshot(function (querySnap) {
          getSellerProducts(querySnap)
        });
      return () => nextProductsBatch();
    } else {
      const nextProductsBatch = initialProductsQuery
        .startAfter(lastElement.verificationStatus, lastElement.name)
        .limit(limit + 1)
        .onSnapshot(function (querySnap) {
          getSellerProducts(querySnap)
        });
      return () => nextProductsBatch();
    }


  }

  const handlePreviousPage = () => {
    setCheckbox(false);
    setAddedProducts([]);
    setCurrentPage(currentPage - 1);
    if (firstElement) {
      if (verifiedProductsSwitch) {
        const previousProductsBatch = initialProductsQuery
          // .startAfter(lastElement)
          .endAt(firstElement.name)
          .limitToLast(limit + 1)
          .onSnapshot(function (querySnap) {
            getSellerProducts(querySnap)
          });
        return () => previousProductsBatch();
      } else {
        const previousProductsBatch = initialProductsQuery
          // .startAfter(lastElement)
          .endAt(firstElement.verificationStatus, firstElement.name)
          .limitToLast(limit + 1)
          .onSnapshot(function (querySnap) {
            getSellerProducts(querySnap)
          });
        return () => previousProductsBatch();
      }

    }
  }

  const playSound = audioFile => {
    const audioPromise = audioFile.play();
    if (audioPromise !== undefined) {
      audioPromise
        .then(_ => {
          audioFile.play().autoplay = true;
        })
        .catch(err => {
          console.info(err)
        })
    }
  };
  
  useEffect(() => {
    const likeAudio = new Audio(Notification);
    const BreakException = {};
    const unsub = DATABASE.collection(docNames.ORDERS)
      .where("sellerInfo.branchId", "==", defaultBranchId)
      .where("subStatus", "not-in", [
        orderSubStatuses.DELIVERED,
        orderSubStatuses.CANCELLED,
        orderSubStatuses.SELLER_REJECTED,
        orderSubStatuses.PAYMENT_PENDING,
      ])
      .onSnapshot((snap) => {
        likeAudio.load()
        snap.docChanges().forEach(function (change, index) {
          if (change.type === "added") {
            if(index === 0){
              playSound(likeAudio)
              checkBrowserAcceptNotifications({
                body: `You have a new Orders`,
                title: `${change.doc.data().orderNo}`,
              });
              return;
            }
          }
        });
        
        let orderList = [];
        const docs = snap.docs;
        docs.forEach((doc) => {
          const data = doc.data();
          data.id = doc.id;
            orderList.push(data)
        });  
        if (orderList.length > 1) {
          orderList.sort(function (a, b) {
            return b.placedAt - a.placedAt;
          });
        }
        dispatch(saveLiveOrders(orderList));
      });
    return () => unsub();
  }, [uid, defaultBranchId, dispatch]);
  useEffect(() => {
    const unsub = DATABASE.collection(docNames.ORDERS)
      .where("sellerInfo.branchId", "==", defaultBranchId)
      .where("subStatus", "in", [
        orderSubStatuses.DELIVERED,
        orderSubStatuses.CANCELLED,
        orderSubStatuses.SELLER_REJECTED,
      ])
      .onSnapshot((snap) => {
        const orderList = [];
        const docs = snap.docs;
        docs.forEach((doc) => {
          const data = doc.data();
          data.id = doc.id;
          orderList.push(data);
        });
        if (orderList.length > 1) {
          orderList.sort(function (a, b) {
            return b.placedAt - a.placedAt;
          });
        }
        dispatch(saveOrderHistory(orderList));
      });
    return () => unsub();
  }, [uid, defaultBranchId, dispatch]);

  useEffect(() => {
    const unsub = DATABASE
      .collection(docNames.SELLER)
      .doc(uid)
      .onSnapshot(snap => {
        let payload = {};
        if (snap.data()) {
          let data = snap.data().brandConfig;
          payload = {
            brandName: data && data.brandName,
            brandNameAr: data && data.brandNameAr,
            businessType: data && data.businessType,
            brandLogo: data && data.brandLogo,
            deliveryFeeSharing: data && data.deliveryFeeSharing
          };
        }
        dispatch(saveBrandDetailsAction(payload));
      });

    return () => unsub();
  }, [uid, dispatch]);

  useEffect(() => {
    const unsub = DATABASE
      .collection(docNames.SELLER)
      .doc(uid)
      .onSnapshot(snap => {
        if (snap.data()) {
          const data = snap.data();
          const payload = {
            email: data.email,
            phone: data.phone
          };

          dispatch(saveSellerDetailsAction(payload));
        }
      });
    return () => unsub();
  }, [uid, dispatch]);

  useEffect(() => {
    const unsub = DATABASE
      .collection(docNames.SELLER)
      .doc(uid)
      .onSnapshot(snap => {
        let payload = null;
        if (snap.data() && snap.data().brandConfig) {
          let data = snap.data().brandConfig;
          payload = data['deliveryFeeSharing'];
        }
        dispatch(saveDeliveryFeeAction(payload));
      });
    return () => unsub();
  }, [uid, dispatch]);

  useEffect(() => {
    let operationalHours= {
      monday: [{
        opening: '08:00',
        closing: '17:00'
      }],
      tuesday: [{
        opening: '08:00',
        closing: '17:00'
      }],
      wednesday: [{
        opening: '08:00',
        closing: '17:00'
      }],
      thursday: [{
        opening: '08:00',
        closing: '17:00'
      }],
      friday: [{
        opening: '08:00',
        closing: '17:00'
      }],
      saturday: [{
        opening: '08:00',
        closing: '17:00'
      }],
      sunday: [{
        opening: '08:00',
        closing: '17:00'
      }]
    };
    let workingDays = {
      monday: [{
        active: "true"
      }],
      tuesday: [{
        active: "true"
      }],
      wednesday: [{
        active: "true"
      }],
      thursday: [{
        active: "true"
      }],
      friday: [{
        active: "true"
      }],
      saturday: [{
        active: "true"
      }],
      sunday: [{
        active: "true"
      }]
    };

      docRef.get()
      .then(doc => {
        
        if (doc.data().branchTimings === undefined) {
          let branchTimings = {}
          branchTimings.operationalHours = operationalHours 
          branchTimings.workingDays = workingDays 
          docRef.set({branchTimings });
        }
      });
  },[uid, defaultBranchId, dispatch])

  
  useEffect(() => {
    const unsub = docRef
      .onSnapshot(snap => {
        let payload = 10;
        if (snap.data() && snap.data().branchConfig) {
          let data = snap.data().branchConfig;
          payload = data['preparationTime'];
          dispatch(savePreparationTimeAction(payload));
          
        }else if(snap.data() && (!snap.data().branchConfig ||( snap.data().branchConfig && !snap.data().branchConfig.preparationTime))){
          let branchConfig = {}
          branchConfig.preparationTime = 10;
          docRef.update({ branchConfig });
        }

        if(snap.data() && snap.data().address){
          dispatch(savebranchAddressAction(snap.data().address));
        }

        if(snap.data() && !snap.data().productsConfig){

          const productsConfig = {
            branchTotalProductsCount: 0,
            productsWithOneLanguage: 0,
            productsWithTwoLanguages: 0,
          }

          docRef.update({ productsConfig });
        }
      });
    return () => unsub();
  }, [uid, defaultBranchId, dispatch]);

  const handleComponent = (val) => {
    switch (val) {
      case 0: //addProduct
        return <AddProductsHome  addedProducts={addedProducts} handleComponentValue={handleComponentValue} 
          setCheckbox={setCheckbox}
          checkbox={checkbox}/>;
      case 1: //displayProducts
        return (
          <ProductManagementScreen
            handleComponentValue={handleComponentValue}
            setCheckbox={setCheckbox}
            checkbox={checkbox}
            verifiedProductsSwitch={verifiedProductsSwitch}
            setVerifiedProductsSwitch={setVerifiedProductsSwitch}
            setShowAddProducts={setShowAddProducts}
            showAddProducts={showAddProducts}
            setShowEmptyList={setShowEmptyList}
            showEmptyList={showEmptyList}
            comingFrom="Home"
          />
        );
      case 2: //excelUpload
        return <UploadExcel handleComponentValue={handleComponentValue} setDisableNextButton={setDisableNextButton}/>;
      default:
        return (
          <ProductManagementScreen
            handleComponentValue={handleComponentValue}
            setCheckbox={setCheckbox}
            checkbox={checkbox}
            verifiedProductsSwitch={verifiedProductsSwitch}
            setVerifiedProductsSwitch={setVerifiedProductsSwitch}
            setShowAddProducts={setShowAddProducts}
            showAddProducts={showAddProducts}
            setShowEmptyList={setShowEmptyList}
            showEmptyList={showEmptyList}
            comingFrom="Home"
          />
        );
    }
  };

  const handleComponentValue = (value) => {
    setComponentValue(value);
  };

  const MENU_OPTION =  sellerInfo && sellerInfo.isAdmin ? 
  {
    FUNNEL: {
      label: "Sellers funnel",
      options: [
        {
          label: "Funnel",
          component: <SellersFunnel />,
        },
      ],
    },
    PRODUCTS_VERIFICATION: {
      label: "Products verification",
      options: [
        {
          label: "Products verification",
          component: <ProductsVerification />,
        },
      ],
      primaryComponent: <div>Products verification</div>,
    },
    INSIGHTS:{
      label: "Sellers Insights",
      options: [
        {
          label: "Insights",
          component: <SellersInsights/>
        }
      ]

    }
  }: {
    STORE: {
      label: "Store",
      options: [
        {
          label: "Products",
          component: handleComponent(componentvalue),
        },
        {
          label: "Category",
          component: <Category />,
        },
      ],
    },
    ORDER_MANAGEMENT: {
      label: "Order Management",
      options: [
        {
          label: "Live order",
          component: <LiveOrder />,
        },
        {
          label: "History",
          component: <OrderHistory />,
        },
      ],
      primaryComponent: <div>Order Management</div>,
    },
    SETTINGS: {
      label: " Settings",
      options: [
        {
          label: "Delivery Fee",
          component: <DeliveryFee />,
        },
        {
          label: "Preparation Time",
          component: <PreparationTime />,
        },
        {
          label: "Operational Hours",
          component: <OperationalHours />,
        },
        {
          label: "Brand Settings",
          component: <BrandSettings />,
        },
      ],
    },
  };

  useEffect(() => {
    setLoading(true);
    const unsubscribe = DATABASE.collection(docNames.SELLER)
      .doc(uid)
      .onSnapshot((querySnapshot) => {
        const sellerData = querySnapshot.data();
        sellerData._id = uid;
        setSellerInfo(sellerData);
      });
      setLoading(false);
    return () => unsubscribe();
  }, [uid]);

  const handleMenuClick = () => {
    setOpen((previousState) => !previousState);
  };

  const handleMenuOptionClick = (val) => {
    setSelectedMenu(val);
    setOpen(false);
  };

  useEffect(() => {
    window.addEventListener("online", () => {
      dispatch(
        setNotificationMessage({
          type: "success",
          message: "You are back online !!"
        }));
    });

    window.addEventListener("offline", () => {
      dispatch(
        setNotificationMessage({
          type: "error",
          message: "Lost Network Connection !!"
        }));
    });
  });

  return (
    <div>
        <div className={`${classes.root} `}>
          <NavBar menuOpen={open} onMenuClick={handleMenuClick} />
          {sellerInfo && sellerInfo.firstLogin && !sellerInfo.isAdmin ? (
            <div className={classes.container}>
              <Paper className={classes.paperContainer}>
                <OnBoardingFlow />
              </Paper>
            </div>
          ) : (
            <>
              <div className={classes.homeContainer}>
                <div
                  className={`${classes.menuContainer} ${open ? classes.open : classes.closed
                    }`}
                >
                  <Menu
                    selectedMenu={selectedMenu}
                    onMenuOptionClick={handleMenuOptionClick}
                  />
                </div>
                <div className={classes.elementContainer}>
                  <Grid
                    container
                    className={classes.mobileScreenGrid}
                    direction="column">
                    <marquee width="95%" direction="left" height="100px" style={{display:"flex", alignItems: "center" }}>
                      <Typography component="p"
                        variant="body1">
                        Please use a desktop to access full functionalities of this page.
                      </Typography>
                    </marquee>
                  </Grid>
                  <div className={classes.element}>
                    {/* <Paper className={classes.container}> */}
                    {MENU_OPTION && MENU_OPTION[Object.keys(MENU_OPTION)[selectedMenu[0]]] && MENU_OPTION[Object.keys(MENU_OPTION)[selectedMenu[0]]].options
                      .length > 0
                      ? MENU_OPTION[Object.keys(MENU_OPTION)[selectedMenu[0]]] && MENU_OPTION[Object.keys(MENU_OPTION)[selectedMenu[0]]]
                        .options[selectedMenu[1]].component
                      : MENU_OPTION[Object.keys(MENU_OPTION)[selectedMenu[0]]] && MENU_OPTION[Object.keys(MENU_OPTION)[selectedMenu[0]]]
                        .primaryComponent}
                    {/* </Paper> */}
                  </div>
                  {MENU_OPTION && MENU_OPTION[Object.keys(MENU_OPTION)[selectedMenu[0]]] &&  
                  MENU_OPTION[Object.keys(MENU_OPTION)[selectedMenu[0]]].options[selectedMenu[1]].label === "Products" ?
                    <div className={classes.paginationContainer}>
                      <button disabled={disablePreviousButton} className={classes.paginationElement} onClick={handlePreviousPage}>{"Previous"}</button>
                      <button disabled={disableNextButton} className={classes.paginationElement} onClick={handleNextPage}>{"Next"}</button>
                    </div>
                    : null}
                  <div className={classes.footer}>
                    <Typography>Copyright © 2021. All rights reserved.</Typography>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        
    </div>
  );
}

export default Home;
