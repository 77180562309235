import { Button, CircularProgress, Grid, IconButton, Paper, TextField, Typography, withStyles } from '@material-ui/core';
import { mdiCircleEditOutline, mdiDeleteOutline } from '@mdi/js';
import React, { useEffect, useState } from 'react';
import { useStyles } from './styles';
import { useSelector, useDispatch } from "react-redux";
import Icon from '@mdi/react';
import { updateCategory } from '../../actions/updateCategory';
import { addCategoryAction } from '../../actions/addCategory';
import { setNotificationMessage } from '../../actions/notificationActions';
import { deleteCategory } from '../../actions/deleteCategory';
import ShadowlessButton from '../../components/ShadowlessButton';
import { TEXT_COLOR } from '../../constants/theme';
import { DATABASE } from '../../config/fbConfig';
import { docNames } from '../../constants/firebaseDoc';

const StyledTextField = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: "#30D9C4",
            },
            '&:hover fieldset': {
                borderColor: "#30D9C4",
            },
            '&.Mui-focused fieldset': {
                borderColor: "#30D9C4",
            },
          },
        "& .MuiInputBase-root": {
            height: 40,
        }

    },

})(TextField);


function CategoryRow({ name, id, productNum, setEditingId, onDelete }) {
    const classes = useStyles();
    const [displayIcons, setDisplayIcons] = useState(false);

    const changeEditMode = () => {
        setEditingId(id);
    }

    const deleteSingle = () => {
        onDelete(id)
    }

    return (
        <Grid item className={classes.gridRow}>
            <div className={`${classes.categoryRow}`}
                onMouseEnter={() => setDisplayIcons(true)}
                onMouseLeave={() => setDisplayIcons(false)}
            >
                <Grid container direction='row' alignItems='center' spacing={1}>
                    <Grid item className={classes.flex4}>
                        <Typography align="left" className={classes.text}>
                            {name}
                        </Typography>
                    </Grid>
                    <Grid item className={classes.flex6}>
                        <IconButton
                            size="small"
                            className={classes.iconButtonStyling}
                            style={{
                                marginLeft: "auto",
                                marginRight: "4px",
                                display: displayIcons ? "flex" : "none",
                            }}
                            onClick={changeEditMode}
                            edge="end"
                        >
                            <Icon
                                path={mdiCircleEditOutline}
                                size="16px"
                                className={classes.iconStyling}
                            />
                        </IconButton>
                        <IconButton
                            size="small"
                            className={classes.iconButtonStyling}
                            style={{
                                marginLeft: 0,
                                marginRight: "8px",
                                display: displayIcons ? "flex" : "none",
                            }}
                            onClick={deleteSingle}
                            edge="end"
                        >
                            <Icon
                                path={mdiDeleteOutline}
                                size="16px"
                                color= {TEXT_COLOR.DELETE_COLOR}
                                className={classes.iconStyling}
                            />
                        </IconButton>
                    </Grid>
                </Grid>
            </div>
        </Grid>
    )
}

function Category() {
    const classes = useStyles();
    const [isEditing, setIsEditing] = useState(false);
    // const [categories, setCategories] = useState([]);
    // const [checkboxList, setCheckboxList] = useState({});
    const uid = useSelector(({ authState }) => authState.user?.uid);
    const { defaultBranchId } = useSelector(
        ({ defaultBranchState }) => defaultBranchState.defaultBranch
    );
    const [categoryName, setCategoryName] = useState('');
    const [categoryId, setCategoryId] = useState(null);
    const [loading] = useState(false);
    const categories = useSelector(
        ({ categoryState }) => categoryState.category
    );
    const dispatch = useDispatch();
    useEffect(() => {
        if (categoryId) {
            setIsEditing(true);
            setCategoryName(categories.filter(category => category.id === categoryId)[0]?.categoryName);
        } else {
            setCategoryName('');
            setCategoryId(null);
        }
    }, [categoryId, categories]);

    const onCancel = () => {
        setIsEditing(false);
        setCategoryName('');
        setCategoryId(null);
    }

    const findDisplayIndex = () => {
        if (categories.length === 0) {
          return 0;
        } else if (categories.length === 1) {
          return categories[0].displayIndex + 1;
        } else {
          const maxIndex = categories.reduce(function (a, b) {
            return a.displayIndex > b.displayIndex ? a : b;
          });
          return maxIndex.displayIndex + 1;
        }
      };

    const onSave = async () => {
        if (categoryName.trim()) {
            let isUsedAlready = categories.map(category => category.categoryName.toLowerCase()).includes(categoryName.toLowerCase());
            if (isUsedAlready) {
                dispatch(setNotificationMessage({ type: 'error', message: 'Category name already in use' }));
            } else {
                if (categoryId) {
                    await dispatch(updateCategory({ id: categoryId, name: categoryName.trim(), uid, defaultBranchId }));
                    const query = DATABASE.collection(docNames.SELLER).doc(uid).collection(docNames.BRANCHES).doc(defaultBranchId).collection(docNames.PRODUCT); 
                    const productsQuery = await query.where("category.id", "==", categoryId).get();
                    if (!productsQuery.empty) {
                        productsQuery.docs && productsQuery.docs.forEach(product => {
                            query.doc(product.id).update({
                                "category.categoryName": categoryName.trim()
                            });
                        });
                        dispatch(setNotificationMessage({ type: "success", message: "Category updated successfuly. The products inside this category will be updated." }));
                    }else{
                        dispatch(setNotificationMessage({ type: "success", message: "Category updated successfuly." }));
                    }
                    
                } else {
                    const displayIndex = findDisplayIndex();
                    await dispatch(addCategoryAction({ categoryName,displayIndex,  uid, setCategory: () => { }, defaultBranchId }));
                    dispatch(setNotificationMessage({ type: "success", message: "Category added successfuly."}));
                }
                setCategoryId(null);
                setCategoryName('');
                setIsEditing(false);
            }
        } else {
            dispatch(setNotificationMessage({ message: 'Category name cannot be empty' }));
            if (categoryId) {
                setCategoryName(categories.filter(category => category.id === categoryId)[0].categoryName);
            }
        }
    }

    const handleChange = (e) => {
        if (e.target.value.length > 60) {
            dispatch(setNotificationMessage({ message: 'Category cannot be more than 60 characters.' }));
        }
        setCategoryName(e.target.value.substr(0, 60));
    }

    const handleDelete = async (id) => {
        let category = categories.filter(data => data.id === id)[0];
        const query = DATABASE.collection(docNames.SELLER).doc(uid).collection(docNames.BRANCHES).doc(defaultBranchId).collection(docNames.PRODUCT).where("category.id", "==", category.id).get();
        if (!(await query).empty) {
            dispatch(setNotificationMessage({ type: "error", message: "There are products inside this category, move them to another category to proceed!" }));
        } else {
            const displayIndex=category.displayIndex;
            dispatch(deleteCategory({ uid, id,displayIndex, defaultBranchId }));
            setCategoryId(null);
            setIsEditing(false);
        }
    }


    return (
        <div className={classes.root}>
            <div className={classes.paddedDiv}>
            {!isEditing ?
                (
                    <Paper className={`${classes.paperContainer} ${classes.fitContentHeight}`}>
                        <Grid container direction='column' spacing={3}>
                            <Grid item>
                                <Typography className={classes.title}>
                                    Create new
                                </Typography>
                            </Grid>
                            <Grid item className={classes.justifyCenter}>
                                <StyledTextField
                                    classes={classes.root}
                                    InputLabelProps={{
                                        style: {
                                          color: TEXT_COLOR.DEFAULT,
                                          fontFamily: "Nunito",
                                          fontSize: 13
                                        },
                                    }}
                                    value={categoryName}
                                    variant='outlined'
                                    label='Category name'
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item>
                                <Grid container direction='row' alignContent='center' justify='flex-end'>
                                    <Grid item>
                                        <ShadowlessButton disableElevation variant='contained' className={classes.button} onClick={onSave}>Save</ShadowlessButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                ) : (
                    <Paper className={`${classes.paperContainer} ${classes.fitContentHeight}`}>
                        <Grid container direction='column' spacing={3}>
                            <Grid item>
                                <Typography className={classes.title}>
                                    Edit category
                                </Typography>
                            </Grid>
                            <Grid item className={classes.justifyCenter}>
                                <StyledTextField
                                    InputProps={{
                                        style: {
                                            fontSize: 13
                                        }
                                    }}
                                    value={categoryName}
                                    variant='outlined'
                                    label='Category name'
                                    className={classes.textField}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item>
                                <Grid container direction='row' alignContent='center' justify='flex-end' spacing={1}>
                                    <Grid item>
                                        <Button disableElevation variant='text' onClick={onCancel}>Cancel</Button>
                                    </Grid>
                                    <Grid item>
                                        <ShadowlessButton disableElevation variant='contained' className={classes.button} onClick={onSave}>Save</ShadowlessButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                    )}
                </div>

            <Paper className={`${classes.paperContainer} ${classes.scroll}`}>
                {
                    loading ?
                        <div className={classes.progressContainer}>
                            <CircularProgress style={{ 'color': '#30D9C4' }}  />
                        </div>
                        :
                        <Grid container direction='column' spacing={1} className={classes.noWrap}>
                             <Grid item>
                                        <Typography className={classes.title}>
                                            Category Control
                                </Typography>
                                    </Grid>
                            <Grid item>
                                <Grid container direction='row' alignItems='center' spacing={1} style={{ height: 32, display: 'flex' }}>
                                    <>
                                        <Grid item className={`${classes.headerField} ${classes.flex4}`}>
                                            <Typography align="left" className={classes.headerText}>
                                                Category name
                                            </Typography>
                                        </Grid>
                                    </>

                                </Grid>
                            </Grid>
                            {categories.map((category, index) => (
                                <CategoryRow
                                    name={category.categoryName}
                                    // checkboxList={checkboxList}
                                    id={category.id} key={`category-row-${index}`}
                                    productNum={category.productNo}
                                    setEditingId={setCategoryId}
                                    onDelete={handleDelete} />
                            ))}
                        </Grid>
                }
                </Paper>
        </div>
    )
}

export default Category;
